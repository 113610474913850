import { useContext, createElement } from "react"
import { Layout } from "antd"
import HeaderProfile from "./HeaderProfile"
import { AppContext, ActionType } from "@lib/AppContext"
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons"

const { Header } = Layout

export default function HeaderComponent() {
  const { state: { sidebarCollapsed }, dispatch } = useContext(AppContext)
  const toggle = () => {
    dispatch({ type: ActionType.SET_SIDEBAR_COLLAPSED, payload: !sidebarCollapsed })
  }
  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      {createElement(sidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggle,
      })}
      <HeaderProfile />
    </Header>
  )
}
