import Cookies from "js-cookie"
import { notification } from "antd"
import { onError } from "@apollo/client/link/error"
import { createUploadLink } from "apollo-upload-client"
import { setContext } from "@apollo/client/link/context"
import { ApolloClient, from, InMemoryCache } from "@apollo/client"

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      notification.error({ message })
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = createUploadLink({
  uri: `${process.env.NEXT_PUBLIC_SERVER_ENDPOINT}/graphql`
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Cookies.get("adminToken")
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "Apollo-Require-Preflight": "true",
      ...(token && { authorization: `Bearer ${token}` }),
    }
  }
})

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  credentials: "include"
})

export default client
