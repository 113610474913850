import React, { useMemo, useRef } from "react"
import dynamic from "next/dynamic"

const ReactQuill = dynamic(
  async () => {
    const { default: RQ } = await import("react-quill")
    // eslint-disable-next-line react/display-name
    return ({ forwardedRef, ...props }: any) => <RQ ref={forwardedRef} {...props} />
  }, { ssr: false })

interface OnChangeHandler {
  (e: any): void
}

type Props = {
  value?: string
  placeholder?: string
  onChange?: OnChangeHandler
  style?: any
}


const TextEditor: React.FC<Props> = ({ value, onChange, placeholder, style = {} }) => {
  const quillRef = useRef<any>(null)

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike", "link"], // toggled buttons
        ["blockquote", "code-block"], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ["clean"], // remove formatting button
        ["image"],
      ],
      handlers: {
        image: async () => {
          const input = document.createElement("input")

          input.setAttribute("type", "file")
          input.setAttribute("accept", "image/*")
          input.click()
          input.onchange = async () => {
            const file: any = input && input.files ? input.files[0] : null
            const formData = new FormData()
            formData.append("file", file)
            console.log(file)
            const range = quillRef.current.editor.getSelection(true)
            quillRef.current.editor.insertEmbed(range.index, "image", "https://plus.unsplash.com/premium_photo-1673002094288-6c4456aa9098?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=100&q=80")

            // let quillObj = quillRef.current.getEditor()
            // await UploadService.uploadFile(formData)
            //   .then((res) => {
            //     let data = get(res, "data.data.url")
            //     const range = quillObj.getSelection()
            //     quillObj.editor.insertEmbed(range.index, "image", data)
            //   })
            //   .catch((err) => {
            //     message.error("This is an error message")
            //     return false
            // })
          }
        }
      }
    }
  }), [])

  return (
    <>
      <ReactQuill
        forwardedRef={quillRef}
        theme="snow"
        style={style}
        value={value || ""}
        modules={modules}
        onChange={onChange}
        placeholder={placeholder}
      />
    </>
  )
}

export default TextEditor
