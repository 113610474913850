import { ApolloProvider } from "@apollo/client"
import client from "apollo/client"
import "antd/dist/reset.css"
import { AppProvider } from "lib/AppContext"
import packageJSON from "../package.json"
import { Layout } from "@modules/Common"
import ability from "@utils/ability/ability"
import { AbilityContext } from "@utils/ability/Can"
import "../src/styles/index.scss"

console.log(`release version: ${packageJSON.version}`)

function MyApp({ Component, pageProps, err }: any) {

  return (
    <AppProvider>
      <AbilityContext.Provider value={ability}>
        <ApolloProvider client={client}>
          <Layout>
            <Component {...pageProps} err={err} />
          </Layout>
        </ApolloProvider>
      </AbilityContext.Provider>
    </AppProvider>
  )
}

export default MyApp
