import { ReactElement } from "react"

export default function ErrorFallback(): ReactElement {
  return (
    <div>
      <p>Something went wrong:</p>
      <button type="button" onClick={() => window.location.reload()}>Try again</button>
    </div>
  )
}
