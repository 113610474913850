import { useContext, useEffect, ReactNode } from "react"
import { Layout } from "antd"
import TagManager from "react-gtm-module"
import { useRouter, usePathname, useSearchParams } from "next/navigation"
import { unpackRules } from "@casl/ability/extra"
import { ActionType, AppContext } from "@lib/AppContext"
import { AbilityContext } from "@utils/ability/Can"
import { Header, Footer, Sidebar } from "@modules/Common"
import { Role, useMeQuery } from "@generated/graphql"

const { Content } = Layout

type Props = {
  children?: ReactNode
}

export default function LayoutComponent({ children }: Props) {
  const router = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { state: { user }, dispatch } = useContext(AppContext)
  const isPublicRoute = pathname === "/" || pathname === "/login" || pathname === "/logout"
  const ability = useContext<any>(AbilityContext)

  const { data: currentUserData, loading: currentUserLoading, error: currentUserError } = useMeQuery({
    skip: isPublicRoute,
  })

  useEffect(() => {
    if (currentUserData && currentUserData.me) {
      if (currentUserData?.me.role === Role.STUDENT) {
        router.replace("/logout")
      }
      if (currentUserData?.me.role === Role.TEACHER) {
        TagManager.initialize({
          gtmId: "GTM-5QS2MKF",
        })
      }
      dispatch({ type: ActionType.SET_USER, payload: currentUserData.me })
      ability.update(unpackRules(currentUserData.me.rules))
    } else if (currentUserError !== undefined) {
      if (!isPublicRoute) {
        router.push(`/login?redirect=${pathname}${window.location.search}`)
      }
    }
  }, [ability, searchParams, currentUserData, currentUserError, dispatch, isPublicRoute, router])

  return (
    <>
      {isPublicRoute ? <>
        <div>
          {children}
        </div>
        <Footer />
      </> : (user?.id !== 0 && <>
        <Layout hasSider>
          <Sidebar />
          <Layout className="site-layout">
            <Header />
            <Content id="site-content-layout">
              {children}
            </Content>
            <Footer />
          </Layout>
        </Layout>
      </>)}
    </>
  )
}
