import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type Activity = {
  __typename?: 'Activity';
  _count?: Maybe<ActivityCount>;
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars['Int']['output']>;
  challengeStatement: Scalars['String']['output'];
  coinsToSpend: Scalars['Int']['output'];
  comments: Array<Comment>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  difficultyLevel: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  insideWorkshops: Array<Workshop>;
  instructionImage?: Maybe<Scalars['String']['output']>;
  instructionText?: Maybe<Scalars['String']['output']>;
  isChallenge: Scalars['Boolean']['output'];
  isCommentEnabled: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isIndexable: Scalars['Boolean']['output'];
  isLive: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  jsonLD?: Maybe<Scalars['JSON']['output']>;
  materialsNeeded?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  nextActivity?: Maybe<Activity>;
  nextActivityId?: Maybe<Scalars['Int']['output']>;
  previousActivities: Array<Activity>;
  products: Array<Product>;
  promoCodes: Array<PromoCode>;
  publishedDate?: Maybe<Scalars['DateTime']['output']>;
  questionXps: Scalars['Int']['output'];
  questions: Array<ActivityQuestion>;
  relatedWorkshops: Array<Workshop>;
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  standards: Array<Standard>;
  status: ActivityStatus;
  subType?: Maybe<Scalars['String']['output']>;
  tags: Array<Tag>;
  timeRequired: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  video?: Maybe<Scalars['String']['output']>;
  xps: Scalars['Int']['output'];
};


export type ActivityCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type ActivityInsideWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityPreviousActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ActivityProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type ActivityPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type ActivityQuestionsArgs = {
  cursor?: InputMaybe<ActivityQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type ActivityRelatedWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type ActivityTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};

export type ActivityAvgAggregate = {
  __typename?: 'ActivityAvgAggregate';
  approvedById?: Maybe<Scalars['Float']['output']>;
  coinsToSpend?: Maybe<Scalars['Float']['output']>;
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  nextActivityId?: Maybe<Scalars['Float']['output']>;
  questionXps?: Maybe<Scalars['Float']['output']>;
  skillId?: Maybe<Scalars['Float']['output']>;
  xps?: Maybe<Scalars['Float']['output']>;
};

export type ActivityBanner = {
  __typename?: 'ActivityBanner';
  createdAt: Scalars['DateTime']['output'];
  destinationUrl: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ActivityBannerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  destinationUrl: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  destinationUrl?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ActivityBannerScalarFieldEnum {
  CREATEDAT = 'createdAt',
  DESTINATIONURL = 'destinationUrl',
  DISPLAYORDER = 'displayOrder',
  ID = 'id',
  IMAGE = 'image',
  UPDATEDAT = 'updatedAt'
}

export type ActivityBannerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destinationUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityBannerWhereInput = {
  AND?: InputMaybe<Array<ActivityBannerWhereInput>>;
  NOT?: InputMaybe<Array<ActivityBannerWhereInput>>;
  OR?: InputMaybe<Array<ActivityBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destinationUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCount = {
  __typename?: 'ActivityCount';
  comments: Scalars['Int']['output'];
  insideWorkshops: Scalars['Int']['output'];
  previousActivities: Scalars['Int']['output'];
  products: Scalars['Int']['output'];
  promoCodes: Scalars['Int']['output'];
  questions: Scalars['Int']['output'];
  relatedWorkshops: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
  tags: Scalars['Int']['output'];
};


export type ActivityCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type ActivityCountInsideWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityCountPreviousActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type ActivityCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type ActivityCountPromoCodesArgs = {
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type ActivityCountQuestionsArgs = {
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type ActivityCountRelatedWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};


export type ActivityCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type ActivityCountTagsArgs = {
  where?: InputMaybe<TagWhereInput>;
};

export type ActivityCountAggregate = {
  __typename?: 'ActivityCountAggregate';
  _all: Scalars['Int']['output'];
  approvedById: Scalars['Int']['output'];
  challengeStatement: Scalars['Int']['output'];
  coinsToSpend: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  difficultyLevel: Scalars['Int']['output'];
  displayOrder: Scalars['Int']['output'];
  featuredImage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  instructionImage: Scalars['Int']['output'];
  instructionText: Scalars['Int']['output'];
  isChallenge: Scalars['Int']['output'];
  isCommentEnabled: Scalars['Int']['output'];
  isFeatured: Scalars['Int']['output'];
  isIndexable: Scalars['Int']['output'];
  isLive: Scalars['Int']['output'];
  isVisible: Scalars['Int']['output'];
  jsonLD: Scalars['Int']['output'];
  materialsNeeded: Scalars['Int']['output'];
  meta: Scalars['Int']['output'];
  metaTagData: Scalars['Int']['output'];
  nextActivityId: Scalars['Int']['output'];
  publishedDate: Scalars['Int']['output'];
  questionXps: Scalars['Int']['output'];
  scheduleTime: Scalars['Int']['output'];
  shortDescription: Scalars['Int']['output'];
  skillId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  subType: Scalars['Int']['output'];
  timeRequired: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  video: Scalars['Int']['output'];
  xps: Scalars['Int']['output'];
};

export type ActivityCreateInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateManyApprovedByInput = {
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivityId?: InputMaybe<Scalars['Int']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skillId?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateManyApprovedByInputEnvelope = {
  data: Array<ActivityCreateManyApprovedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCreateManyNextActivityInput = {
  approvedById?: InputMaybe<Scalars['Int']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skillId?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateManyNextActivityInputEnvelope = {
  data: Array<ActivityCreateManyNextActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCreateManySkillInput = {
  approvedById?: InputMaybe<Scalars['Int']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivityId?: InputMaybe<Scalars['Int']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateManySkillInputEnvelope = {
  data: Array<ActivityCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityCreateNestedManyWithoutApprovedByInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<ActivityCreateManyApprovedByInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutInsideWorkshopsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutInsideWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutInsideWorkshopsInput>>;
};

export type ActivityCreateNestedManyWithoutNextActivityInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutNextActivityInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutNextActivityInput>>;
  createMany?: InputMaybe<ActivityCreateManyNextActivityInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutProductsInput>>;
};

export type ActivityCreateNestedManyWithoutPromoCodesInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutPromoCodesInput>>;
};

export type ActivityCreateNestedManyWithoutRelatedWorkshopsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutRelatedWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutRelatedWorkshopsInput>>;
};

export type ActivityCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ActivityCreateManySkillInputEnvelope>;
};

export type ActivityCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutStandardsInput>>;
};

export type ActivityCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutTagsInput>>;
};

export type ActivityCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
};

export type ActivityCreateNestedOneWithoutPreviousActivitiesInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutPreviousActivitiesInput>;
  create?: InputMaybe<ActivityCreateWithoutPreviousActivitiesInput>;
};

export type ActivityCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<ActivityCreateWithoutQuestionsInput>;
};

export type ActivityCreateOrConnectWithoutApprovedByInput = {
  create: ActivityCreateWithoutApprovedByInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutInsideWorkshopsInput = {
  create: ActivityCreateWithoutInsideWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutNextActivityInput = {
  create: ActivityCreateWithoutNextActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutPreviousActivitiesInput = {
  create: ActivityCreateWithoutPreviousActivitiesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutProductsInput = {
  create: ActivityCreateWithoutProductsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutPromoCodesInput = {
  create: ActivityCreateWithoutPromoCodesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutQuestionsInput = {
  create: ActivityCreateWithoutQuestionsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutRelatedWorkshopsInput = {
  create: ActivityCreateWithoutRelatedWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutSkillInput = {
  create: ActivityCreateWithoutSkillInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutStandardsInput = {
  create: ActivityCreateWithoutStandardsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateOrConnectWithoutTagsInput = {
  create: ActivityCreateWithoutTagsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityCreateWithoutApprovedByInput = {
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutCommentsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutInsideWorkshopsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutNextActivityInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutPreviousActivitiesInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutProductsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutPromoCodesInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutQuestionsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutRelatedWorkshopsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutSkillInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutStandardsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<TagCreateNestedManyWithoutActivitiesInput>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityCreateWithoutTagsInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutActivitiesInput>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutActivityInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  difficultyLevel: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insideWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutInsiderActivitiesInput>;
  instructionImage?: InputMaybe<Scalars['String']['input']>;
  instructionText?: InputMaybe<Scalars['String']['input']>;
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isIndexable?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityCreateNestedOneWithoutPreviousActivitiesInput>;
  previousActivities?: InputMaybe<ActivityCreateNestedManyWithoutNextActivityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutActivityInput>;
  relatedWorkshops?: InputMaybe<WorkshopCreateNestedManyWithoutRelatedActivitiesInput>;
  scheduleTime?: InputMaybe<Scalars['DateTime']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutActivitiesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  subType?: InputMaybe<Scalars['String']['input']>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityListRelationFilter = {
  every?: InputMaybe<ActivityWhereInput>;
  none?: InputMaybe<ActivityWhereInput>;
  some?: InputMaybe<ActivityWhereInput>;
};

export type ActivityMaxAggregate = {
  __typename?: 'ActivityMaxAggregate';
  approvedById?: Maybe<Scalars['Int']['output']>;
  challengeStatement?: Maybe<Scalars['String']['output']>;
  coinsToSpend?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instructionImage?: Maybe<Scalars['String']['output']>;
  instructionText?: Maybe<Scalars['String']['output']>;
  isChallenge?: Maybe<Scalars['Boolean']['output']>;
  isCommentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  isIndexable?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  materialsNeeded?: Maybe<Scalars['String']['output']>;
  nextActivityId?: Maybe<Scalars['Int']['output']>;
  publishedDate?: Maybe<Scalars['DateTime']['output']>;
  questionXps?: Maybe<Scalars['Int']['output']>;
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActivityStatus>;
  subType?: Maybe<Scalars['String']['output']>;
  timeRequired?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

export type ActivityMinAggregate = {
  __typename?: 'ActivityMinAggregate';
  approvedById?: Maybe<Scalars['Int']['output']>;
  challengeStatement?: Maybe<Scalars['String']['output']>;
  coinsToSpend?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  difficultyLevel?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  instructionImage?: Maybe<Scalars['String']['output']>;
  instructionText?: Maybe<Scalars['String']['output']>;
  isChallenge?: Maybe<Scalars['Boolean']['output']>;
  isCommentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  isIndexable?: Maybe<Scalars['Boolean']['output']>;
  isLive?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  materialsNeeded?: Maybe<Scalars['String']['output']>;
  nextActivityId?: Maybe<Scalars['Int']['output']>;
  publishedDate?: Maybe<Scalars['DateTime']['output']>;
  questionXps?: Maybe<Scalars['Int']['output']>;
  scheduleTime?: Maybe<Scalars['DateTime']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActivityStatus>;
  subType?: Maybe<Scalars['String']['output']>;
  timeRequired?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

export type ActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityOrderByWithRelationInput = {
  approvedBy?: InputMaybe<UserOrderByWithRelationInput>;
  approvedById?: InputMaybe<SortOrderInput>;
  challengeStatement?: InputMaybe<SortOrder>;
  coinsToSpend?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  difficultyLevel?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  insideWorkshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
  instructionImage?: InputMaybe<SortOrderInput>;
  instructionText?: InputMaybe<SortOrderInput>;
  isChallenge?: InputMaybe<SortOrder>;
  isCommentEnabled?: InputMaybe<SortOrder>;
  isFeatured?: InputMaybe<SortOrder>;
  isIndexable?: InputMaybe<SortOrder>;
  isLive?: InputMaybe<SortOrder>;
  isVisible?: InputMaybe<SortOrder>;
  jsonLD?: InputMaybe<SortOrderInput>;
  materialsNeeded?: InputMaybe<SortOrderInput>;
  meta?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  nextActivity?: InputMaybe<ActivityOrderByWithRelationInput>;
  nextActivityId?: InputMaybe<SortOrderInput>;
  previousActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  promoCodes?: InputMaybe<PromoCodeOrderByRelationAggregateInput>;
  publishedDate?: InputMaybe<SortOrderInput>;
  questionXps?: InputMaybe<SortOrder>;
  questions?: InputMaybe<ActivityQuestionOrderByRelationAggregateInput>;
  relatedWorkshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
  scheduleTime?: InputMaybe<SortOrderInput>;
  shortDescription?: InputMaybe<SortOrderInput>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  subType?: InputMaybe<SortOrderInput>;
  tags?: InputMaybe<TagOrderByRelationAggregateInput>;
  timeRequired?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
  xps?: InputMaybe<SortOrder>;
};

export type ActivityQuestion = {
  __typename?: 'ActivityQuestion';
  activityId?: Maybe<Scalars['Int']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  answers: Array<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  incorrectText?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  order: Scalars['Int']['output'];
  questionImage?: Maybe<Scalars['String']['output']>;
  questionText: Scalars['String']['output'];
  storyActivityId?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ActivityQuestionCreateManyActivityInput = {
  answerText?: InputMaybe<Scalars['String']['input']>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  incorrectText?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
  storyActivityId?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityQuestionCreateManyActivityInputEnvelope = {
  data: Array<ActivityQuestionCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityQuestionCreateManyInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  answerText?: InputMaybe<Scalars['String']['input']>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  incorrectText?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
  storyActivityId?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityQuestionCreateManyStoryActivityInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  answerText?: InputMaybe<Scalars['String']['input']>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  incorrectText?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityQuestionCreateManyStoryActivityInputEnvelope = {
  data: Array<ActivityQuestionCreateManyStoryActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityQuestionCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyActivityInputEnvelope>;
};

export type ActivityQuestionCreateNestedManyWithoutStoryActivityInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyStoryActivityInputEnvelope>;
};

export type ActivityQuestionCreateOrConnectWithoutActivityInput = {
  create: ActivityQuestionCreateWithoutActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionCreateOrConnectWithoutStoryActivityInput = {
  create: ActivityQuestionCreateWithoutStoryActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionCreateWithoutActivityInput = {
  answerText?: InputMaybe<Scalars['String']['input']>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  incorrectText?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutQuestionsInput>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityQuestionCreateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutQuestionsInput>;
  answerText?: InputMaybe<Scalars['String']['input']>;
  answers?: InputMaybe<ActivityQuestionCreateanswersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  incorrectText?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionText: Scalars['String']['input'];
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ActivityQuestionCreateanswersInput = {
  set: Array<Scalars['String']['input']>;
};

export type ActivityQuestionListRelationFilter = {
  every?: InputMaybe<ActivityQuestionWhereInput>;
  none?: InputMaybe<ActivityQuestionWhereInput>;
  some?: InputMaybe<ActivityQuestionWhereInput>;
};

export type ActivityQuestionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityQuestionOrderByWithRelationInput = {
  activity?: InputMaybe<ActivityOrderByWithRelationInput>;
  activityId?: InputMaybe<SortOrderInput>;
  answerText?: InputMaybe<SortOrderInput>;
  answers?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  incorrectText?: InputMaybe<SortOrderInput>;
  options?: InputMaybe<SortOrderInput>;
  order?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrderInput>;
  questionText?: InputMaybe<SortOrder>;
  storyActivity?: InputMaybe<StoryActivityOrderByWithRelationInput>;
  storyActivityId?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ActivityQuestionScalarFieldEnum {
  ACTIVITYID = 'activityId',
  ANSWERTEXT = 'answerText',
  ANSWERS = 'answers',
  CREATEDAT = 'createdAt',
  ID = 'id',
  INCORRECTTEXT = 'incorrectText',
  OPTIONS = 'options',
  ORDER = 'order',
  QUESTIONIMAGE = 'questionImage',
  QUESTIONTEXT = 'questionText',
  STORYACTIVITYID = 'storyActivityId',
  TYPE = 'type',
  UPDATEDAT = 'updatedAt'
}

export type ActivityQuestionScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  activityId?: InputMaybe<IntNullableFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  answers?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  incorrectText?: InputMaybe<StringNullableFilter>;
  options?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<IntFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionText?: InputMaybe<StringFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityQuestionUpdateInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutQuestionsNestedInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutQuestionsNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateManyMutationInput = {
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateManyWithWhereWithoutActivityInput = {
  data: ActivityQuestionUpdateManyMutationInput;
  where: ActivityQuestionScalarWhereInput;
};

export type ActivityQuestionUpdateManyWithWhereWithoutStoryActivityInput = {
  data: ActivityQuestionUpdateManyMutationInput;
  where: ActivityQuestionScalarWhereInput;
};

export type ActivityQuestionUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityQuestionUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityQuestionUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<ActivityQuestionUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type ActivityQuestionUpdateManyWithoutStoryActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityQuestionCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<ActivityQuestionCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<ActivityQuestionCreateManyStoryActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityQuestionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityQuestionWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityQuestionUpdateWithWhereUniqueWithoutStoryActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityQuestionUpdateManyWithWhereWithoutStoryActivityInput>>;
  upsert?: InputMaybe<Array<ActivityQuestionUpsertWithWhereUniqueWithoutStoryActivityInput>>;
};

export type ActivityQuestionUpdateWithWhereUniqueWithoutActivityInput = {
  data: ActivityQuestionUpdateWithoutActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionUpdateWithWhereUniqueWithoutStoryActivityInput = {
  data: ActivityQuestionUpdateWithoutStoryActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionUpdateWithoutActivityInput = {
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutQuestionsNestedInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutQuestionsNestedInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  answers?: InputMaybe<ActivityQuestionUpdateanswersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  incorrectText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionText?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityQuestionUpdateanswersInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ActivityQuestionUpsertWithWhereUniqueWithoutActivityInput = {
  create: ActivityQuestionCreateWithoutActivityInput;
  update: ActivityQuestionUpdateWithoutActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionUpsertWithWhereUniqueWithoutStoryActivityInput = {
  create: ActivityQuestionCreateWithoutStoryActivityInput;
  update: ActivityQuestionUpdateWithoutStoryActivityInput;
  where: ActivityQuestionWhereUniqueInput;
};

export type ActivityQuestionWhereInput = {
  AND?: InputMaybe<Array<ActivityQuestionWhereInput>>;
  NOT?: InputMaybe<Array<ActivityQuestionWhereInput>>;
  OR?: InputMaybe<Array<ActivityQuestionWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<IntNullableFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  answers?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  incorrectText?: InputMaybe<StringNullableFilter>;
  options?: InputMaybe<JsonNullableFilter>;
  order?: InputMaybe<IntFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionText?: InputMaybe<StringFilter>;
  storyActivity?: InputMaybe<StoryActivityRelationFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ActivityQuestionWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ActivityRelationFilter = {
  is?: InputMaybe<ActivityWhereInput>;
  isNot?: InputMaybe<ActivityWhereInput>;
};

export enum ActivityScalarFieldEnum {
  APPROVEDBYID = 'approvedById',
  CHALLENGESTATEMENT = 'challengeStatement',
  COINSTOSPEND = 'coinsToSpend',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DIFFICULTYLEVEL = 'difficultyLevel',
  DISPLAYORDER = 'displayOrder',
  FEATUREDIMAGE = 'featuredImage',
  ID = 'id',
  IMAGE = 'image',
  INSTRUCTIONIMAGE = 'instructionImage',
  INSTRUCTIONTEXT = 'instructionText',
  ISCHALLENGE = 'isChallenge',
  ISCOMMENTENABLED = 'isCommentEnabled',
  ISFEATURED = 'isFeatured',
  ISINDEXABLE = 'isIndexable',
  ISLIVE = 'isLive',
  ISVISIBLE = 'isVisible',
  JSONLD = 'jsonLD',
  MATERIALSNEEDED = 'materialsNeeded',
  META = 'meta',
  METATAGDATA = 'metaTagData',
  NEXTACTIVITYID = 'nextActivityId',
  PUBLISHEDDATE = 'publishedDate',
  QUESTIONXPS = 'questionXps',
  SCHEDULETIME = 'scheduleTime',
  SHORTDESCRIPTION = 'shortDescription',
  SKILLID = 'skillId',
  SLUG = 'slug',
  STATUS = 'status',
  SUBTYPE = 'subType',
  TIMEREQUIRED = 'timeRequired',
  TITLE = 'title',
  UPDATEDAT = 'updatedAt',
  VIDEO = 'video',
  XPS = 'xps'
}

export type ActivityScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityScalarWhereInput>>;
  approvedById?: InputMaybe<IntNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  difficultyLevel?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  instructionImage?: InputMaybe<StringNullableFilter>;
  instructionText?: InputMaybe<StringNullableFilter>;
  isChallenge?: InputMaybe<BoolFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  isIndexable?: InputMaybe<BoolFilter>;
  isLive?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  materialsNeeded?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  nextActivityId?: InputMaybe<IntNullableFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  scheduleTime?: InputMaybe<DateTimeNullableFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  subType?: InputMaybe<StringNullableFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export enum ActivityStatus {
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PUBLISHED = 'PUBLISHED',
  SCHEDULED = 'SCHEDULED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export type ActivitySumAggregate = {
  __typename?: 'ActivitySumAggregate';
  approvedById?: Maybe<Scalars['Int']['output']>;
  coinsToSpend?: Maybe<Scalars['Int']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nextActivityId?: Maybe<Scalars['Int']['output']>;
  questionXps?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

/** Activity Type Enum */
export enum ActivityType {
  ACTIVITY = 'ACTIVITY',
  STORY_ACTIVITY = 'STORY_ACTIVITY'
}

export type ActivityUpdateInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyMutationInput = {
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateManyWithWhereWithoutApprovedByInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutInsideWorkshopsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutNextActivityInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutProductsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutPromoCodesInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutRelatedWorkshopsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutSkillInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutStandardsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithWhereWithoutTagsInput = {
  data: ActivityUpdateManyMutationInput;
  where: ActivityScalarWhereInput;
};

export type ActivityUpdateManyWithoutApprovedByNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<ActivityCreateManyApprovedByInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutApprovedByInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutApprovedByInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutApprovedByInput>>;
};

export type ActivityUpdateManyWithoutInsideWorkshopsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutInsideWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutInsideWorkshopsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutInsideWorkshopsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutInsideWorkshopsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutInsideWorkshopsInput>>;
};

export type ActivityUpdateManyWithoutNextActivityNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutNextActivityInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutNextActivityInput>>;
  createMany?: InputMaybe<ActivityCreateManyNextActivityInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutNextActivityInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutNextActivityInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutNextActivityInput>>;
};

export type ActivityUpdateManyWithoutProductsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type ActivityUpdateManyWithoutPromoCodesNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutPromoCodesInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutPromoCodesInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutPromoCodesInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutPromoCodesInput>>;
};

export type ActivityUpdateManyWithoutRelatedWorkshopsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutRelatedWorkshopsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutRelatedWorkshopsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutRelatedWorkshopsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutRelatedWorkshopsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutRelatedWorkshopsInput>>;
};

export type ActivityUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ActivityCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type ActivityUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type ActivityUpdateManyWithoutTagsNestedInput = {
  connect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<ActivityCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<ActivityUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<ActivityUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type ActivityUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<ActivityCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ActivityUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutCommentsInput>;
};

export type ActivityUpdateOneWithoutPreviousActivitiesNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutPreviousActivitiesInput>;
  create?: InputMaybe<ActivityCreateWithoutPreviousActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ActivityUpdateWithoutPreviousActivitiesInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutPreviousActivitiesInput>;
};

export type ActivityUpdateOneWithoutQuestionsNestedInput = {
  connect?: InputMaybe<ActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<ActivityCreateWithoutQuestionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ActivityUpdateWithoutQuestionsInput>;
  upsert?: InputMaybe<ActivityUpsertWithoutQuestionsInput>;
};

export type ActivityUpdateWithWhereUniqueWithoutApprovedByInput = {
  data: ActivityUpdateWithoutApprovedByInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutInsideWorkshopsInput = {
  data: ActivityUpdateWithoutInsideWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutNextActivityInput = {
  data: ActivityUpdateWithoutNextActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutProductsInput = {
  data: ActivityUpdateWithoutProductsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutPromoCodesInput = {
  data: ActivityUpdateWithoutPromoCodesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutRelatedWorkshopsInput = {
  data: ActivityUpdateWithoutRelatedWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutSkillInput = {
  data: ActivityUpdateWithoutSkillInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutStandardsInput = {
  data: ActivityUpdateWithoutStandardsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithWhereUniqueWithoutTagsInput = {
  data: ActivityUpdateWithoutTagsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpdateWithoutApprovedByInput = {
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutCommentsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutInsideWorkshopsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutNextActivityInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutPreviousActivitiesInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutProductsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutPromoCodesInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutQuestionsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutRelatedWorkshopsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutSkillInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutStandardsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpdateWithoutTagsInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutActivitiesNestedInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutActivityNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  difficultyLevel?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insideWorkshops?: InputMaybe<WorkshopUpdateManyWithoutInsiderActivitiesNestedInput>;
  instructionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  instructionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isIndexable?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isLive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  materialsNeeded?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  nextActivity?: InputMaybe<ActivityUpdateOneWithoutPreviousActivitiesNestedInput>;
  previousActivities?: InputMaybe<ActivityUpdateManyWithoutNextActivityNestedInput>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutActivityNestedInput>;
  relatedWorkshops?: InputMaybe<WorkshopUpdateManyWithoutRelatedActivitiesNestedInput>;
  scheduleTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  subType?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ActivityUpsertWithWhereUniqueWithoutApprovedByInput = {
  create: ActivityCreateWithoutApprovedByInput;
  update: ActivityUpdateWithoutApprovedByInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutInsideWorkshopsInput = {
  create: ActivityCreateWithoutInsideWorkshopsInput;
  update: ActivityUpdateWithoutInsideWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutNextActivityInput = {
  create: ActivityCreateWithoutNextActivityInput;
  update: ActivityUpdateWithoutNextActivityInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutProductsInput = {
  create: ActivityCreateWithoutProductsInput;
  update: ActivityUpdateWithoutProductsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutPromoCodesInput = {
  create: ActivityCreateWithoutPromoCodesInput;
  update: ActivityUpdateWithoutPromoCodesInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutRelatedWorkshopsInput = {
  create: ActivityCreateWithoutRelatedWorkshopsInput;
  update: ActivityUpdateWithoutRelatedWorkshopsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutSkillInput = {
  create: ActivityCreateWithoutSkillInput;
  update: ActivityUpdateWithoutSkillInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutStandardsInput = {
  create: ActivityCreateWithoutStandardsInput;
  update: ActivityUpdateWithoutStandardsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithWhereUniqueWithoutTagsInput = {
  create: ActivityCreateWithoutTagsInput;
  update: ActivityUpdateWithoutTagsInput;
  where: ActivityWhereUniqueInput;
};

export type ActivityUpsertWithoutCommentsInput = {
  create: ActivityCreateWithoutCommentsInput;
  update: ActivityUpdateWithoutCommentsInput;
};

export type ActivityUpsertWithoutPreviousActivitiesInput = {
  create: ActivityCreateWithoutPreviousActivitiesInput;
  update: ActivityUpdateWithoutPreviousActivitiesInput;
};

export type ActivityUpsertWithoutQuestionsInput = {
  create: ActivityCreateWithoutQuestionsInput;
  update: ActivityUpdateWithoutQuestionsInput;
};

export type ActivityWhereInput = {
  AND?: InputMaybe<Array<ActivityWhereInput>>;
  NOT?: InputMaybe<Array<ActivityWhereInput>>;
  OR?: InputMaybe<Array<ActivityWhereInput>>;
  approvedBy?: InputMaybe<UserRelationFilter>;
  approvedById?: InputMaybe<IntNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  difficultyLevel?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  insideWorkshops?: InputMaybe<WorkshopListRelationFilter>;
  instructionImage?: InputMaybe<StringNullableFilter>;
  instructionText?: InputMaybe<StringNullableFilter>;
  isChallenge?: InputMaybe<BoolFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  isIndexable?: InputMaybe<BoolFilter>;
  isLive?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  materialsNeeded?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  nextActivity?: InputMaybe<ActivityRelationFilter>;
  nextActivityId?: InputMaybe<IntNullableFilter>;
  previousActivities?: InputMaybe<ActivityListRelationFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  promoCodes?: InputMaybe<PromoCodeListRelationFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  questions?: InputMaybe<ActivityQuestionListRelationFilter>;
  relatedWorkshops?: InputMaybe<WorkshopListRelationFilter>;
  scheduleTime?: InputMaybe<DateTimeNullableFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  subType?: InputMaybe<StringNullableFilter>;
  tags?: InputMaybe<TagListRelationFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export type ActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type AdminLeaderboardInput = {
  endDate: Scalars['DateTime']['input'];
  schoolId?: InputMaybe<Scalars['Int']['input']>;
  standardId?: InputMaybe<Scalars['Int']['input']>;
  startDate: Scalars['DateTime']['input'];
};

export type AdminLeaderboardResponse = {
  __typename?: 'AdminLeaderboardResponse';
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  school: Scalars['String']['output'];
  standard: Scalars['String']['output'];
  stars: Scalars['Int']['output'];
  xps: Scalars['Int']['output'];
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int']['output'];
};

export type AggregateActivity = {
  __typename?: 'AggregateActivity';
  _avg?: Maybe<ActivityAvgAggregate>;
  _count?: Maybe<ActivityCountAggregate>;
  _max?: Maybe<ActivityMaxAggregate>;
  _min?: Maybe<ActivityMinAggregate>;
  _sum?: Maybe<ActivitySumAggregate>;
};

export type AggregateBadge = {
  __typename?: 'AggregateBadge';
  _avg?: Maybe<BadgeAvgAggregate>;
  _count?: Maybe<BadgeCountAggregate>;
  _max?: Maybe<BadgeMaxAggregate>;
  _min?: Maybe<BadgeMinAggregate>;
  _sum?: Maybe<BadgeSumAggregate>;
};

export type AggregateComment = {
  __typename?: 'AggregateComment';
  _avg?: Maybe<CommentAvgAggregate>;
  _count?: Maybe<CommentCountAggregate>;
  _max?: Maybe<CommentMaxAggregate>;
  _min?: Maybe<CommentMinAggregate>;
  _sum?: Maybe<CommentSumAggregate>;
};

export type AggregateDailyActivity = {
  __typename?: 'AggregateDailyActivity';
  _avg?: Maybe<DailyActivityAvgAggregate>;
  _count?: Maybe<DailyActivityCountAggregate>;
  _max?: Maybe<DailyActivityMaxAggregate>;
  _min?: Maybe<DailyActivityMinAggregate>;
  _sum?: Maybe<DailyActivitySumAggregate>;
};

export type AggregateGamePointType = {
  __typename?: 'AggregateGamePointType';
  _avg?: Maybe<GamePointTypeAvgAggregate>;
  _count?: Maybe<GamePointTypeCountAggregate>;
  _max?: Maybe<GamePointTypeMaxAggregate>;
  _min?: Maybe<GamePointTypeMinAggregate>;
  _sum?: Maybe<GamePointTypeSumAggregate>;
};

export type AggregateLevel = {
  __typename?: 'AggregateLevel';
  _avg?: Maybe<LevelAvgAggregate>;
  _count?: Maybe<LevelCountAggregate>;
  _max?: Maybe<LevelMaxAggregate>;
  _min?: Maybe<LevelMinAggregate>;
  _sum?: Maybe<LevelSumAggregate>;
};

export type AggregateOrder = {
  __typename?: 'AggregateOrder';
  _avg?: Maybe<OrderAvgAggregate>;
  _count?: Maybe<OrderCountAggregate>;
  _max?: Maybe<OrderMaxAggregate>;
  _min?: Maybe<OrderMinAggregate>;
  _sum?: Maybe<OrderSumAggregate>;
};

export type AggregatePrivacyPolicy = {
  __typename?: 'AggregatePrivacyPolicy';
  _avg?: Maybe<PrivacyPolicyAvgAggregate>;
  _count?: Maybe<PrivacyPolicyCountAggregate>;
  _max?: Maybe<PrivacyPolicyMaxAggregate>;
  _min?: Maybe<PrivacyPolicyMinAggregate>;
  _sum?: Maybe<PrivacyPolicySumAggregate>;
};

export type AggregatePrivacyPolicyUpdate = {
  __typename?: 'AggregatePrivacyPolicyUpdate';
  _avg?: Maybe<PrivacyPolicyUpdateAvgAggregate>;
  _count?: Maybe<PrivacyPolicyUpdateCountAggregate>;
  _max?: Maybe<PrivacyPolicyUpdateMaxAggregate>;
  _min?: Maybe<PrivacyPolicyUpdateMinAggregate>;
  _sum?: Maybe<PrivacyPolicyUpdateSumAggregate>;
};

export type AggregateProduct = {
  __typename?: 'AggregateProduct';
  _avg?: Maybe<ProductAvgAggregate>;
  _count?: Maybe<ProductCountAggregate>;
  _max?: Maybe<ProductMaxAggregate>;
  _min?: Maybe<ProductMinAggregate>;
  _sum?: Maybe<ProductSumAggregate>;
};

export type AggregatePromoCode = {
  __typename?: 'AggregatePromoCode';
  _avg?: Maybe<PromoCodeAvgAggregate>;
  _count?: Maybe<PromoCodeCountAggregate>;
  _max?: Maybe<PromoCodeMaxAggregate>;
  _min?: Maybe<PromoCodeMinAggregate>;
  _sum?: Maybe<PromoCodeSumAggregate>;
};

export type AggregatePromoCodeGroup = {
  __typename?: 'AggregatePromoCodeGroup';
  _avg?: Maybe<PromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeGroupSumAggregate>;
};

export type AggregatePromoCodeHistory = {
  __typename?: 'AggregatePromoCodeHistory';
  _avg?: Maybe<PromoCodeHistoryAvgAggregate>;
  _count?: Maybe<PromoCodeHistoryCountAggregate>;
  _max?: Maybe<PromoCodeHistoryMaxAggregate>;
  _min?: Maybe<PromoCodeHistoryMinAggregate>;
  _sum?: Maybe<PromoCodeHistorySumAggregate>;
};

export type AggregatePromoCodeToPromoCodeGroup = {
  __typename?: 'AggregatePromoCodeToPromoCodeGroup';
  _avg?: Maybe<PromoCodeToPromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeToPromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeToPromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeToPromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeToPromoCodeGroupSumAggregate>;
};

export type AggregateReferralCode = {
  __typename?: 'AggregateReferralCode';
  _avg?: Maybe<ReferralCodeAvgAggregate>;
  _count?: Maybe<ReferralCodeCountAggregate>;
  _max?: Maybe<ReferralCodeMaxAggregate>;
  _min?: Maybe<ReferralCodeMinAggregate>;
  _sum?: Maybe<ReferralCodeSumAggregate>;
};

export type AggregateReferralCodeHistory = {
  __typename?: 'AggregateReferralCodeHistory';
  _avg?: Maybe<ReferralCodeHistoryAvgAggregate>;
  _count?: Maybe<ReferralCodeHistoryCountAggregate>;
  _max?: Maybe<ReferralCodeHistoryMaxAggregate>;
  _min?: Maybe<ReferralCodeHistoryMinAggregate>;
  _sum?: Maybe<ReferralCodeHistorySumAggregate>;
};

export type AggregateReport = {
  __typename?: 'AggregateReport';
  _avg?: Maybe<ReportAvgAggregate>;
  _count?: Maybe<ReportCountAggregate>;
  _max?: Maybe<ReportMaxAggregate>;
  _min?: Maybe<ReportMinAggregate>;
  _sum?: Maybe<ReportSumAggregate>;
};

export type AggregateSamskara = {
  __typename?: 'AggregateSamskara';
  _avg?: Maybe<SamskaraAvgAggregate>;
  _count?: Maybe<SamskaraCountAggregate>;
  _max?: Maybe<SamskaraMaxAggregate>;
  _min?: Maybe<SamskaraMinAggregate>;
  _sum?: Maybe<SamskaraSumAggregate>;
};

export type AggregateSamskaraCategory = {
  __typename?: 'AggregateSamskaraCategory';
  _avg?: Maybe<SamskaraCategoryAvgAggregate>;
  _count?: Maybe<SamskaraCategoryCountAggregate>;
  _max?: Maybe<SamskaraCategoryMaxAggregate>;
  _min?: Maybe<SamskaraCategoryMinAggregate>;
  _sum?: Maybe<SamskaraCategorySumAggregate>;
};

export type AggregateSchool = {
  __typename?: 'AggregateSchool';
  _avg?: Maybe<SchoolAvgAggregate>;
  _count?: Maybe<SchoolCountAggregate>;
  _max?: Maybe<SchoolMaxAggregate>;
  _min?: Maybe<SchoolMinAggregate>;
  _sum?: Maybe<SchoolSumAggregate>;
};

export type AggregateSchoolUpdate = {
  __typename?: 'AggregateSchoolUpdate';
  _avg?: Maybe<SchoolUpdateAvgAggregate>;
  _count?: Maybe<SchoolUpdateCountAggregate>;
  _max?: Maybe<SchoolUpdateMaxAggregate>;
  _min?: Maybe<SchoolUpdateMinAggregate>;
  _sum?: Maybe<SchoolUpdateSumAggregate>;
};

export type AggregateSkill = {
  __typename?: 'AggregateSkill';
  _avg?: Maybe<SkillAvgAggregate>;
  _count?: Maybe<SkillCountAggregate>;
  _max?: Maybe<SkillMaxAggregate>;
  _min?: Maybe<SkillMinAggregate>;
  _sum?: Maybe<SkillSumAggregate>;
};

export type AggregateStandard = {
  __typename?: 'AggregateStandard';
  _avg?: Maybe<StandardAvgAggregate>;
  _count?: Maybe<StandardCountAggregate>;
  _max?: Maybe<StandardMaxAggregate>;
  _min?: Maybe<StandardMinAggregate>;
  _sum?: Maybe<StandardSumAggregate>;
};

export type AggregateStory = {
  __typename?: 'AggregateStory';
  _avg?: Maybe<StoryAvgAggregate>;
  _count?: Maybe<StoryCountAggregate>;
  _max?: Maybe<StoryMaxAggregate>;
  _min?: Maybe<StoryMinAggregate>;
  _sum?: Maybe<StorySumAggregate>;
};

export type AggregateStoryActivity = {
  __typename?: 'AggregateStoryActivity';
  _avg?: Maybe<StoryActivityAvgAggregate>;
  _count?: Maybe<StoryActivityCountAggregate>;
  _max?: Maybe<StoryActivityMaxAggregate>;
  _min?: Maybe<StoryActivityMinAggregate>;
  _sum?: Maybe<StoryActivitySumAggregate>;
};

export type AggregateStoryTag = {
  __typename?: 'AggregateStoryTag';
  _avg?: Maybe<StoryTagAvgAggregate>;
  _count?: Maybe<StoryTagCountAggregate>;
  _max?: Maybe<StoryTagMaxAggregate>;
  _min?: Maybe<StoryTagMinAggregate>;
  _sum?: Maybe<StoryTagSumAggregate>;
};

export type AggregateStudent = {
  __typename?: 'AggregateStudent';
  _avg?: Maybe<StudentAvgAggregate>;
  _count?: Maybe<StudentCountAggregate>;
  _max?: Maybe<StudentMaxAggregate>;
  _min?: Maybe<StudentMinAggregate>;
  _sum?: Maybe<StudentSumAggregate>;
};

export type AggregateStudentGameLog = {
  __typename?: 'AggregateStudentGameLog';
  _avg?: Maybe<StudentGameLogAvgAggregate>;
  _count?: Maybe<StudentGameLogCountAggregate>;
  _max?: Maybe<StudentGameLogMaxAggregate>;
  _min?: Maybe<StudentGameLogMinAggregate>;
  _sum?: Maybe<StudentGameLogSumAggregate>;
};

export type AggregateStudentRecord = {
  __typename?: 'AggregateStudentRecord';
  _avg?: Maybe<StudentRecordAvgAggregate>;
  _count?: Maybe<StudentRecordCountAggregate>;
  _max?: Maybe<StudentRecordMaxAggregate>;
  _min?: Maybe<StudentRecordMinAggregate>;
  _sum?: Maybe<StudentRecordSumAggregate>;
};

export type AggregateTag = {
  __typename?: 'AggregateTag';
  _avg?: Maybe<TagAvgAggregate>;
  _count?: Maybe<TagCountAggregate>;
  _max?: Maybe<TagMaxAggregate>;
  _min?: Maybe<TagMinAggregate>;
  _sum?: Maybe<TagSumAggregate>;
};

export type AggregateTrigger = {
  __typename?: 'AggregateTrigger';
  _avg?: Maybe<TriggerAvgAggregate>;
  _count?: Maybe<TriggerCountAggregate>;
  _max?: Maybe<TriggerMaxAggregate>;
  _min?: Maybe<TriggerMinAggregate>;
  _sum?: Maybe<TriggerSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
};

export type AggregateUserLog = {
  __typename?: 'AggregateUserLog';
  _avg?: Maybe<UserLogAvgAggregate>;
  _count?: Maybe<UserLogCountAggregate>;
  _max?: Maybe<UserLogMaxAggregate>;
  _min?: Maybe<UserLogMinAggregate>;
  _sum?: Maybe<UserLogSumAggregate>;
};

export type AggregateWorkshop = {
  __typename?: 'AggregateWorkshop';
  _avg?: Maybe<WorkshopAvgAggregate>;
  _count?: Maybe<WorkshopCountAggregate>;
  _max?: Maybe<WorkshopMaxAggregate>;
  _min?: Maybe<WorkshopMinAggregate>;
  _sum?: Maybe<WorkshopSumAggregate>;
};

export type AggregateWorkshopRegistration = {
  __typename?: 'AggregateWorkshopRegistration';
  _avg?: Maybe<WorkshopRegistrationAvgAggregate>;
  _count?: Maybe<WorkshopRegistrationCountAggregate>;
  _max?: Maybe<WorkshopRegistrationMaxAggregate>;
  _min?: Maybe<WorkshopRegistrationMinAggregate>;
  _sum?: Maybe<WorkshopRegistrationSumAggregate>;
};

export type AnswerStudentDailyActivityInput = {
  answer: Scalars['Int']['input'];
  timeDiff: Scalars['Int']['input'];
};

export type ApplyPromoCodeOutput = {
  __typename?: 'ApplyPromoCodeOutput';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Badge = {
  __typename?: 'Badge';
  _count?: Maybe<BadgeCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  studentBadges: Array<StudentBadge>;
  triggers: Array<Trigger>;
  updatedAt: Scalars['DateTime']['output'];
};


export type BadgeStudentBadgesArgs = {
  cursor?: InputMaybe<StudentBadgeWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentBadgeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentBadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type BadgeTriggersArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TriggerWhereInput>;
};

export type BadgeAvgAggregate = {
  __typename?: 'BadgeAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type BadgeCount = {
  __typename?: 'BadgeCount';
  studentBadges: Scalars['Int']['output'];
  triggers: Scalars['Int']['output'];
};


export type BadgeCountStudentBadgesArgs = {
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type BadgeCountTriggersArgs = {
  where?: InputMaybe<TriggerWhereInput>;
};

export type BadgeCountAggregate = {
  __typename?: 'BadgeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type BadgeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  studentBadges?: InputMaybe<StudentBadgeCreateNestedManyWithoutBadgeInput>;
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BadgeCreateNestedOneWithoutStudentBadgesInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutStudentBadgesInput>;
  create?: InputMaybe<BadgeCreateWithoutStudentBadgesInput>;
};

export type BadgeCreateNestedOneWithoutTriggersInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<BadgeCreateWithoutTriggersInput>;
};

export type BadgeCreateOrConnectWithoutStudentBadgesInput = {
  create: BadgeCreateWithoutStudentBadgesInput;
  where: BadgeWhereUniqueInput;
};

export type BadgeCreateOrConnectWithoutTriggersInput = {
  create: BadgeCreateWithoutTriggersInput;
  where: BadgeWhereUniqueInput;
};

export type BadgeCreateWithoutStudentBadgesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BadgeCreateWithoutTriggersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  studentBadges?: InputMaybe<StudentBadgeCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BadgeMaxAggregate = {
  __typename?: 'BadgeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BadgeMinAggregate = {
  __typename?: 'BadgeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BadgeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  studentBadges?: InputMaybe<StudentBadgeOrderByRelationAggregateInput>;
  triggers?: InputMaybe<TriggerOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type BadgeRelationFilter = {
  is?: InputMaybe<BadgeWhereInput>;
  isNot?: InputMaybe<BadgeWhereInput>;
};

export enum BadgeScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  IMAGE = 'image',
  NAME = 'name',
  SLUG = 'slug',
  UPDATEDAT = 'updatedAt'
}

export type BadgeSumAggregate = {
  __typename?: 'BadgeSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type BadgeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentBadges?: InputMaybe<StudentBadgeUpdateManyWithoutBadgeNestedInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutBadgeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BadgeUpdateOneRequiredWithoutStudentBadgesNestedInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutStudentBadgesInput>;
  create?: InputMaybe<BadgeCreateWithoutStudentBadgesInput>;
  update?: InputMaybe<BadgeUpdateWithoutStudentBadgesInput>;
  upsert?: InputMaybe<BadgeUpsertWithoutStudentBadgesInput>;
};

export type BadgeUpdateOneWithoutTriggersNestedInput = {
  connect?: InputMaybe<BadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BadgeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<BadgeCreateWithoutTriggersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<BadgeUpdateWithoutTriggersInput>;
  upsert?: InputMaybe<BadgeUpsertWithoutTriggersInput>;
};

export type BadgeUpdateWithoutStudentBadgesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutBadgeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BadgeUpdateWithoutTriggersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentBadges?: InputMaybe<StudentBadgeUpdateManyWithoutBadgeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BadgeUpsertWithoutStudentBadgesInput = {
  create: BadgeCreateWithoutStudentBadgesInput;
  update: BadgeUpdateWithoutStudentBadgesInput;
};

export type BadgeUpsertWithoutTriggersInput = {
  create: BadgeCreateWithoutTriggersInput;
  update: BadgeUpdateWithoutTriggersInput;
};

export type BadgeWhereInput = {
  AND?: InputMaybe<Array<BadgeWhereInput>>;
  NOT?: InputMaybe<Array<BadgeWhereInput>>;
  OR?: InputMaybe<Array<BadgeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  studentBadges?: InputMaybe<StudentBadgeListRelationFilter>;
  triggers?: InputMaybe<TriggerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Comment = {
  __typename?: 'Comment';
  activity?: Maybe<Activity>;
  activityId?: Maybe<Scalars['Int']['output']>;
  approvedBy?: Maybe<User>;
  approvedById?: Maybe<Scalars['Int']['output']>;
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Student>;
  createdById?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDailyChallenge: Scalars['Boolean']['output'];
  isDeleted: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  quality: Scalars['Int']['output'];
  status?: Maybe<CommentStatus>;
  storyActivity?: Maybe<StoryActivity>;
  storyActivityId?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** Comment Activity Type Enum */
export enum CommentActivityType {
  ACTIVITY = 'ACTIVITY',
  STORY_ACTIVITY = 'STORY_ACTIVITY'
}

export type CommentAvgAggregate = {
  __typename?: 'CommentAvgAggregate';
  activityId?: Maybe<Scalars['Float']['output']>;
  approvedById?: Maybe<Scalars['Float']['output']>;
  createdById?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  quality?: Maybe<Scalars['Float']['output']>;
  storyActivityId?: Maybe<Scalars['Float']['output']>;
};

export type CommentCountAggregate = {
  __typename?: 'CommentCountAggregate';
  _all: Scalars['Int']['output'];
  activityId: Scalars['Int']['output'];
  approvedById: Scalars['Int']['output'];
  attachmentUrl: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  createdById: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isDailyChallenge: Scalars['Int']['output'];
  isDeleted: Scalars['Int']['output'];
  isFeatured: Scalars['Int']['output'];
  meta: Scalars['Int']['output'];
  quality: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  storyActivityId: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type CommentCreateManyActivityInput = {
  approvedById?: InputMaybe<Scalars['Int']['input']>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  storyActivityId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateManyActivityInputEnvelope = {
  data: Array<CommentCreateManyActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyApprovedByInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  storyActivityId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateManyApprovedByInputEnvelope = {
  data: Array<CommentCreateManyApprovedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyCreatedByInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  approvedById?: InputMaybe<Scalars['Int']['input']>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  storyActivityId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateManyCreatedByInputEnvelope = {
  data: Array<CommentCreateManyCreatedByInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateManyStoryActivityInput = {
  activityId?: InputMaybe<Scalars['Int']['input']>;
  approvedById?: InputMaybe<Scalars['Int']['input']>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdById?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateManyStoryActivityInputEnvelope = {
  data: Array<CommentCreateManyStoryActivityInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CommentCreateNestedManyWithoutActivityInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyActivityInputEnvelope>;
};

export type CommentCreateNestedManyWithoutApprovedByInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<CommentCreateManyApprovedByInputEnvelope>;
};

export type CommentCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<CommentCreateManyCreatedByInputEnvelope>;
};

export type CommentCreateNestedManyWithoutStoryActivityInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyStoryActivityInputEnvelope>;
};

export type CommentCreateOrConnectWithoutActivityInput = {
  create: CommentCreateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutApprovedByInput = {
  create: CommentCreateWithoutApprovedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutCreatedByInput = {
  create: CommentCreateWithoutCreatedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateOrConnectWithoutStoryActivityInput = {
  create: CommentCreateWithoutStoryActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentCreateWithoutActivityInput = {
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<StudentCreateNestedOneWithoutCommentsInput>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutCommentsInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateWithoutApprovedByInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<StudentCreateNestedOneWithoutCommentsInput>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutCommentsInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateWithoutCreatedByInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutCommentsInput>;
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  storyActivity?: InputMaybe<StoryActivityCreateNestedOneWithoutCommentsInput>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentCreateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityCreateNestedOneWithoutCommentsInput>;
  approvedBy?: InputMaybe<UserCreateNestedOneWithoutCommentsInput>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdBy?: InputMaybe<StudentCreateNestedOneWithoutCommentsInput>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<CommentStatus>;
  text: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CommentListRelationFilter = {
  every?: InputMaybe<CommentWhereInput>;
  none?: InputMaybe<CommentWhereInput>;
  some?: InputMaybe<CommentWhereInput>;
};

export type CommentMaxAggregate = {
  __typename?: 'CommentMaxAggregate';
  activityId?: Maybe<Scalars['Int']['output']>;
  approvedById?: Maybe<Scalars['Int']['output']>;
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDailyChallenge?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  quality?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<CommentStatus>;
  storyActivityId?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommentMinAggregate = {
  __typename?: 'CommentMinAggregate';
  activityId?: Maybe<Scalars['Int']['output']>;
  approvedById?: Maybe<Scalars['Int']['output']>;
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isDailyChallenge?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  quality?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<CommentStatus>;
  storyActivityId?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type CommentOrderByWithRelationInput = {
  activity?: InputMaybe<ActivityOrderByWithRelationInput>;
  activityId?: InputMaybe<SortOrderInput>;
  approvedBy?: InputMaybe<UserOrderByWithRelationInput>;
  approvedById?: InputMaybe<SortOrderInput>;
  attachmentUrl?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<StudentOrderByWithRelationInput>;
  createdById?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isDailyChallenge?: InputMaybe<SortOrder>;
  isDeleted?: InputMaybe<SortOrder>;
  isFeatured?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  quality?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrderInput>;
  storyActivity?: InputMaybe<StoryActivityOrderByWithRelationInput>;
  storyActivityId?: InputMaybe<SortOrderInput>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CommentScalarFieldEnum {
  ACTIVITYID = 'activityId',
  APPROVEDBYID = 'approvedById',
  ATTACHMENTURL = 'attachmentUrl',
  CREATEDAT = 'createdAt',
  CREATEDBYID = 'createdById',
  ID = 'id',
  ISDAILYCHALLENGE = 'isDailyChallenge',
  ISDELETED = 'isDeleted',
  ISFEATURED = 'isFeatured',
  META = 'meta',
  QUALITY = 'quality',
  STATUS = 'status',
  STORYACTIVITYID = 'storyActivityId',
  TEXT = 'text',
  UPDATEDAT = 'updatedAt'
}

export type CommentScalarWhereInput = {
  AND?: InputMaybe<Array<CommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommentScalarWhereInput>>;
  OR?: InputMaybe<Array<CommentScalarWhereInput>>;
  activityId?: InputMaybe<IntNullableFilter>;
  approvedById?: InputMaybe<IntNullableFilter>;
  attachmentUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isDailyChallenge?: InputMaybe<BoolFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  quality?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumCommentStatusNullableFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CommentStatus {
  APPROVED = 'APPROVED',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type CommentSumAggregate = {
  __typename?: 'CommentSumAggregate';
  activityId?: Maybe<Scalars['Int']['output']>;
  approvedById?: Maybe<Scalars['Int']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  quality?: Maybe<Scalars['Int']['output']>;
  storyActivityId?: Maybe<Scalars['Int']['output']>;
};

export type CommentUpdateInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyMutationInput = {
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateManyWithWhereWithoutActivityInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutApprovedByInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutCreatedByInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithWhereWithoutStoryActivityInput = {
  data: CommentUpdateManyMutationInput;
  where: CommentScalarWhereInput;
};

export type CommentUpdateManyWithoutActivityNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyActivityInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutActivityInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutActivityInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutActivityInput>>;
};

export type CommentUpdateManyWithoutApprovedByNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutApprovedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutApprovedByInput>>;
  createMany?: InputMaybe<CommentCreateManyApprovedByInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutApprovedByInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutApprovedByInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutApprovedByInput>>;
};

export type CommentUpdateManyWithoutCreatedByNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutCreatedByInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<CommentCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutCreatedByInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutCreatedByInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutCreatedByInput>>;
};

export type CommentUpdateManyWithoutStoryActivityNestedInput = {
  connect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommentCreateOrConnectWithoutStoryActivityInput>>;
  create?: InputMaybe<Array<CommentCreateWithoutStoryActivityInput>>;
  createMany?: InputMaybe<CommentCreateManyStoryActivityInputEnvelope>;
  delete?: InputMaybe<Array<CommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommentWhereUniqueInput>>;
  set?: InputMaybe<Array<CommentWhereUniqueInput>>;
  update?: InputMaybe<Array<CommentUpdateWithWhereUniqueWithoutStoryActivityInput>>;
  updateMany?: InputMaybe<Array<CommentUpdateManyWithWhereWithoutStoryActivityInput>>;
  upsert?: InputMaybe<Array<CommentUpsertWithWhereUniqueWithoutStoryActivityInput>>;
};

export type CommentUpdateWithWhereUniqueWithoutActivityInput = {
  data: CommentUpdateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutApprovedByInput = {
  data: CommentUpdateWithoutApprovedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: CommentUpdateWithoutCreatedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithWhereUniqueWithoutStoryActivityInput = {
  data: CommentUpdateWithoutStoryActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpdateWithoutActivityInput = {
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutApprovedByInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutCreatedByInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  storyActivity?: InputMaybe<StoryActivityUpdateOneWithoutCommentsNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpdateWithoutStoryActivityInput = {
  activity?: InputMaybe<ActivityUpdateOneWithoutCommentsNestedInput>;
  approvedBy?: InputMaybe<UserUpdateOneWithoutCommentsNestedInput>;
  attachmentUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StudentUpdateOneWithoutCommentsNestedInput>;
  isDailyChallenge?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isDeleted?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  quality?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<NullableEnumCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommentUpsertWithWhereUniqueWithoutActivityInput = {
  create: CommentCreateWithoutActivityInput;
  update: CommentUpdateWithoutActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutApprovedByInput = {
  create: CommentCreateWithoutApprovedByInput;
  update: CommentUpdateWithoutApprovedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: CommentCreateWithoutCreatedByInput;
  update: CommentUpdateWithoutCreatedByInput;
  where: CommentWhereUniqueInput;
};

export type CommentUpsertWithWhereUniqueWithoutStoryActivityInput = {
  create: CommentCreateWithoutStoryActivityInput;
  update: CommentUpdateWithoutStoryActivityInput;
  where: CommentWhereUniqueInput;
};

export type CommentWhereInput = {
  AND?: InputMaybe<Array<CommentWhereInput>>;
  NOT?: InputMaybe<Array<CommentWhereInput>>;
  OR?: InputMaybe<Array<CommentWhereInput>>;
  activity?: InputMaybe<ActivityRelationFilter>;
  activityId?: InputMaybe<IntNullableFilter>;
  approvedBy?: InputMaybe<UserRelationFilter>;
  approvedById?: InputMaybe<IntNullableFilter>;
  attachmentUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StudentRelationFilter>;
  createdById?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isDailyChallenge?: InputMaybe<BoolFilter>;
  isDeleted?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  quality?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumCommentStatusNullableFilter>;
  storyActivity?: InputMaybe<StoryActivityRelationFilter>;
  storyActivityId?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type DailyActivity = {
  __typename?: 'DailyActivity';
  _count?: Maybe<DailyActivityCount>;
  answer: Scalars['Int']['output'];
  answerText?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  options: Scalars['JSON']['output'];
  question: Scalars['String']['output'];
  questionImage?: Maybe<Scalars['String']['output']>;
  questionVideo?: Maybe<Scalars['String']['output']>;
  scheduledOn: Scalars['DateTime']['output'];
  standards: Array<Standard>;
  updatedAt: Scalars['DateTime']['output'];
};


export type DailyActivityStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};

export type DailyActivityAvgAggregate = {
  __typename?: 'DailyActivityAvgAggregate';
  answer?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type DailyActivityAvgOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DailyActivityCount = {
  __typename?: 'DailyActivityCount';
  standards: Scalars['Int']['output'];
};


export type DailyActivityCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type DailyActivityCountAggregate = {
  __typename?: 'DailyActivityCountAggregate';
  _all: Scalars['Int']['output'];
  answer: Scalars['Int']['output'];
  answerText: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  options: Scalars['Int']['output'];
  question: Scalars['Int']['output'];
  questionImage: Scalars['Int']['output'];
  questionVideo: Scalars['Int']['output'];
  scheduledOn: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type DailyActivityCountOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  options?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrder>;
  questionVideo?: InputMaybe<SortOrder>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityCreateInput = {
  answer: Scalars['Int']['input'];
  answerText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  options: Scalars['JSON']['input'];
  question: Scalars['String']['input'];
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionVideo?: InputMaybe<Scalars['String']['input']>;
  scheduledOn: Scalars['DateTime']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutDailyActivitiesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DailyActivityCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DailyActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<DailyActivityCreateWithoutStandardsInput>>;
};

export type DailyActivityCreateOrConnectWithoutStandardsInput = {
  create: DailyActivityCreateWithoutStandardsInput;
  where: DailyActivityWhereUniqueInput;
};

export type DailyActivityCreateWithoutStandardsInput = {
  answer: Scalars['Int']['input'];
  answerText?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  options: Scalars['JSON']['input'];
  question: Scalars['String']['input'];
  questionImage?: InputMaybe<Scalars['String']['input']>;
  questionVideo?: InputMaybe<Scalars['String']['input']>;
  scheduledOn: Scalars['DateTime']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DailyActivityGroupBy = {
  __typename?: 'DailyActivityGroupBy';
  _avg?: Maybe<DailyActivityAvgAggregate>;
  _count?: Maybe<DailyActivityCountAggregate>;
  _max?: Maybe<DailyActivityMaxAggregate>;
  _min?: Maybe<DailyActivityMinAggregate>;
  _sum?: Maybe<DailyActivitySumAggregate>;
  answer: Scalars['Int']['output'];
  answerText?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  options: Scalars['JSON']['output'];
  question: Scalars['String']['output'];
  questionImage?: Maybe<Scalars['String']['output']>;
  questionVideo?: Maybe<Scalars['String']['output']>;
  scheduledOn: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type DailyActivityListRelationFilter = {
  every?: InputMaybe<DailyActivityWhereInput>;
  none?: InputMaybe<DailyActivityWhereInput>;
  some?: InputMaybe<DailyActivityWhereInput>;
};

export type DailyActivityMaxAggregate = {
  __typename?: 'DailyActivityMaxAggregate';
  answer?: Maybe<Scalars['Int']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionImage?: Maybe<Scalars['String']['output']>;
  questionVideo?: Maybe<Scalars['String']['output']>;
  scheduledOn?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DailyActivityMaxOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrder>;
  questionVideo?: InputMaybe<SortOrder>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityMinAggregate = {
  __typename?: 'DailyActivityMinAggregate';
  answer?: Maybe<Scalars['Int']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionImage?: Maybe<Scalars['String']['output']>;
  questionVideo?: Maybe<Scalars['String']['output']>;
  scheduledOn?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DailyActivityMinOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrder>;
  questionVideo?: InputMaybe<SortOrder>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type DailyActivityOrderByWithAggregationInput = {
  _avg?: InputMaybe<DailyActivityAvgOrderByAggregateInput>;
  _count?: InputMaybe<DailyActivityCountOrderByAggregateInput>;
  _max?: InputMaybe<DailyActivityMaxOrderByAggregateInput>;
  _min?: InputMaybe<DailyActivityMinOrderByAggregateInput>;
  _sum?: InputMaybe<DailyActivitySumOrderByAggregateInput>;
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  options?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrderInput>;
  questionVideo?: InputMaybe<SortOrderInput>;
  scheduledOn?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DailyActivityOrderByWithRelationInput = {
  answer?: InputMaybe<SortOrder>;
  answerText?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  options?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  questionImage?: InputMaybe<SortOrderInput>;
  questionVideo?: InputMaybe<SortOrderInput>;
  scheduledOn?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum DailyActivityScalarFieldEnum {
  ANSWER = 'answer',
  ANSWERTEXT = 'answerText',
  CREATEDAT = 'createdAt',
  ID = 'id',
  OPTIONS = 'options',
  QUESTION = 'question',
  QUESTIONIMAGE = 'questionImage',
  QUESTIONVIDEO = 'questionVideo',
  SCHEDULEDON = 'scheduledOn',
  UPDATEDAT = 'updatedAt'
}

export type DailyActivityScalarWhereInput = {
  AND?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  answer?: InputMaybe<IntFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  options?: InputMaybe<JsonFilter>;
  question?: InputMaybe<StringFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionVideo?: InputMaybe<StringNullableFilter>;
  scheduledOn?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DailyActivityScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<DailyActivityScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<DailyActivityScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<DailyActivityScalarWhereWithAggregatesInput>>;
  answer?: InputMaybe<IntWithAggregatesFilter>;
  answerText?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  options?: InputMaybe<JsonWithAggregatesFilter>;
  question?: InputMaybe<StringWithAggregatesFilter>;
  questionImage?: InputMaybe<StringNullableWithAggregatesFilter>;
  questionVideo?: InputMaybe<StringNullableWithAggregatesFilter>;
  scheduledOn?: InputMaybe<DateTimeWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type DailyActivitySumAggregate = {
  __typename?: 'DailyActivitySumAggregate';
  answer?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type DailyActivitySumOrderByAggregateInput = {
  answer?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
};

export type DailyActivityUpdateInput = {
  answer?: InputMaybe<IntFieldUpdateOperationsInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scheduledOn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutDailyActivitiesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DailyActivityUpdateManyMutationInput = {
  answer?: InputMaybe<IntFieldUpdateOperationsInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scheduledOn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DailyActivityUpdateManyWithWhereWithoutStandardsInput = {
  data: DailyActivityUpdateManyMutationInput;
  where: DailyActivityScalarWhereInput;
};

export type DailyActivityUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DailyActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<DailyActivityCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DailyActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<DailyActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<DailyActivityUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<DailyActivityUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<DailyActivityUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type DailyActivityUpdateWithWhereUniqueWithoutStandardsInput = {
  data: DailyActivityUpdateWithoutStandardsInput;
  where: DailyActivityWhereUniqueInput;
};

export type DailyActivityUpdateWithoutStandardsInput = {
  answer?: InputMaybe<IntFieldUpdateOperationsInput>;
  answerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  questionImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  questionVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scheduledOn?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DailyActivityUpsertWithWhereUniqueWithoutStandardsInput = {
  create: DailyActivityCreateWithoutStandardsInput;
  update: DailyActivityUpdateWithoutStandardsInput;
  where: DailyActivityWhereUniqueInput;
};

export type DailyActivityWhereInput = {
  AND?: InputMaybe<Array<DailyActivityWhereInput>>;
  NOT?: InputMaybe<Array<DailyActivityWhereInput>>;
  OR?: InputMaybe<Array<DailyActivityWhereInput>>;
  answer?: InputMaybe<IntFilter>;
  answerText?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  options?: InputMaybe<JsonFilter>;
  question?: InputMaybe<StringFilter>;
  questionImage?: InputMaybe<StringNullableFilter>;
  questionVideo?: InputMaybe<StringNullableFilter>;
  scheduledOn?: InputMaybe<DateTimeFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DailyActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DecryptUserTokenInput = {
  token: Scalars['String']['input'];
};

export type EnumActivityStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ActivityStatus>;
};

export type EnumActivityStatusFilter = {
  equals?: InputMaybe<ActivityStatus>;
  in?: InputMaybe<Array<ActivityStatus>>;
  not?: InputMaybe<NestedEnumActivityStatusFilter>;
  notIn?: InputMaybe<Array<ActivityStatus>>;
};

export type EnumCommentStatusNullableFilter = {
  equals?: InputMaybe<CommentStatus>;
  in?: InputMaybe<Array<CommentStatus>>;
  not?: InputMaybe<NestedEnumCommentStatusNullableFilter>;
  notIn?: InputMaybe<Array<CommentStatus>>;
};

export type EnumLogAccessTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<LogAccessType>;
};

export type EnumLogAccessTypeFilter = {
  equals?: InputMaybe<LogAccessType>;
  in?: InputMaybe<Array<LogAccessType>>;
  not?: InputMaybe<NestedEnumLogAccessTypeFilter>;
  notIn?: InputMaybe<Array<LogAccessType>>;
};

export type EnumOrderStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<OrderStatus>;
};

export type EnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type EnumPaymentMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<PaymentMethod>;
};

export type EnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<Role>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumSkillTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SkillType>;
};

export type EnumSkillTypeFilter = {
  equals?: InputMaybe<SkillType>;
  in?: InputMaybe<Array<SkillType>>;
  not?: InputMaybe<NestedEnumSkillTypeFilter>;
  notIn?: InputMaybe<Array<SkillType>>;
};

export type EnumStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<Status>;
};

export type EnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type EnumTriggerTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TriggerType>;
};

export type EnumTriggerTypeFilter = {
  equals?: InputMaybe<TriggerType>;
  in?: InputMaybe<Array<TriggerType>>;
  not?: InputMaybe<NestedEnumTriggerTypeFilter>;
  notIn?: InputMaybe<Array<TriggerType>>;
};

export type EnumWorkshopStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<WorkshopStatus>;
};

export type EnumWorkshopStatusFilter = {
  equals?: InputMaybe<WorkshopStatus>;
  in?: InputMaybe<Array<WorkshopStatus>>;
  not?: InputMaybe<NestedEnumWorkshopStatusFilter>;
  notIn?: InputMaybe<Array<WorkshopStatus>>;
};

export type ExportGameLogsInput = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type File = {
  __typename?: 'File';
  name?: Maybe<Scalars['String']['output']>;
  signedUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** File Access Enums */
export enum FileAccess {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type FindPublicManyActivityInput = {
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  skill?: InputMaybe<SkillWhereUniqueInput>;
  tag?: InputMaybe<TagWhereUniqueInput>;
};

export type FindPublicManyWorkshopWhereInput = {
  tag?: InputMaybe<Scalars['String']['input']>;
};

export type FindStudentActivityAccessOutput = {
  __typename?: 'FindStudentActivityAccessOutput';
  isQaCompleted: Scalars['Boolean']['output'];
  isUnlocked: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type FindStudentActivityCommentsInput = {
  createdBy?: Scalars['Boolean']['input'];
  slug: Scalars['String']['input'];
  type: CommentActivityType;
};

export type FindStudentActivityCommentsOutput = {
  __typename?: 'FindStudentActivityCommentsOutput';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<FindStudentActivityCommentsStudentOutput>;
  id: Scalars['Int']['output'];
  isFeatured: Scalars['Boolean']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<CommentStatus>;
  text: Scalars['String']['output'];
};

export type FindStudentActivityCommentsStudentOutput = {
  __typename?: 'FindStudentActivityCommentsStudentOutput';
  displayName: Scalars['String']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
};

export type FindStudentManyActivityInput = {
  isChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  isLive?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isPending?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  skill?: InputMaybe<SkillWhereUniqueInput>;
  tag?: InputMaybe<TagWhereUniqueInput>;
};

export type FindStudentManySkillWhereInput = {
  showNoActivitiesSkills?: InputMaybe<Scalars['Boolean']['input']>;
  showNoProductSkills?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FindStudentStoryActivityAccessResponse = {
  __typename?: 'FindStudentStoryActivityAccessResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type FindStudentStoryActivityInput = {
  story?: InputMaybe<FindStudentStoryActivitySkillInput>;
  tag?: InputMaybe<StoryTagWhereUniqueInput>;
};

export type FindStudentStoryActivitySkillInput = {
  slug: Scalars['String']['input'];
};

export type FindStudentUniqueActivityOutput = {
  __typename?: 'FindStudentUniqueActivityOutput';
  challengeStatement: Scalars['String']['output'];
  coinsToSpend: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  difficultyLevel: Scalars['String']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  instructionImage?: Maybe<Scalars['String']['output']>;
  instructionText?: Maybe<Scalars['String']['output']>;
  isCommentEnabled: Scalars['Boolean']['output'];
  jsonLD?: Maybe<Scalars['JSON']['output']>;
  materialsNeeded?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  nextActivity?: Maybe<PublicActivity>;
  questionXps: Scalars['Int']['output'];
  questions?: Maybe<Array<ActivityQuestion>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skill?: Maybe<PublicSkill>;
  slug: Scalars['String']['output'];
  tags?: Maybe<Array<PublicTag>>;
  timeRequired: Scalars['String']['output'];
  title: Scalars['String']['output'];
  video?: Maybe<Scalars['String']['output']>;
  xps: Scalars['Int']['output'];
};

export type FindStudentWorkshopAccessResponse = {
  __typename?: 'FindStudentWorkshopAccessResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type FindTriggerManyActivityInput = {
  title: StringFilter;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']['input']>;
  divide?: InputMaybe<Scalars['Float']['input']>;
  increment?: InputMaybe<Scalars['Float']['input']>;
  multiply?: InputMaybe<Scalars['Float']['input']>;
  set?: InputMaybe<Scalars['Float']['input']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GamePointType = {
  __typename?: 'GamePointType';
  _count?: Maybe<GamePointTypeCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  pluralName: Scalars['String']['output'];
  singularName: Scalars['String']['output'];
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  studentGameLogs: Array<StudentGameLog>;
  triggers: Array<Trigger>;
  updatedAt: Scalars['DateTime']['output'];
};


export type GamePointTypeStudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type GamePointTypeTriggersArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TriggerWhereInput>;
};

export type GamePointTypeAvgAggregate = {
  __typename?: 'GamePointTypeAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  skillId?: Maybe<Scalars['Float']['output']>;
};

export type GamePointTypeCount = {
  __typename?: 'GamePointTypeCount';
  studentGameLogs: Scalars['Int']['output'];
  triggers: Scalars['Int']['output'];
};


export type GamePointTypeCountStudentGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type GamePointTypeCountTriggersArgs = {
  where?: InputMaybe<TriggerWhereInput>;
};

export type GamePointTypeCountAggregate = {
  __typename?: 'GamePointTypeCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  pluralName: Scalars['Int']['output'];
  singularName: Scalars['Int']['output'];
  skillId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type GamePointTypeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  pluralName: Scalars['String']['input'];
  singularName: Scalars['String']['input'];
  skill?: InputMaybe<SkillCreateNestedOneWithoutGamePointTypeInput>;
  slug: Scalars['String']['input'];
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutPointTypeInput>;
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutGamePointTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GamePointTypeCreateNestedOneWithoutSkillInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutSkillInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutSkillInput>;
};

export type GamePointTypeCreateNestedOneWithoutStudentGameLogsInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutStudentGameLogsInput>;
};

export type GamePointTypeCreateNestedOneWithoutTriggersInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutTriggersInput>;
};

export type GamePointTypeCreateOrConnectWithoutSkillInput = {
  create: GamePointTypeCreateWithoutSkillInput;
  where: GamePointTypeWhereUniqueInput;
};

export type GamePointTypeCreateOrConnectWithoutStudentGameLogsInput = {
  create: GamePointTypeCreateWithoutStudentGameLogsInput;
  where: GamePointTypeWhereUniqueInput;
};

export type GamePointTypeCreateOrConnectWithoutTriggersInput = {
  create: GamePointTypeCreateWithoutTriggersInput;
  where: GamePointTypeWhereUniqueInput;
};

export type GamePointTypeCreateWithoutSkillInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  pluralName: Scalars['String']['input'];
  singularName: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutPointTypeInput>;
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutGamePointTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GamePointTypeCreateWithoutStudentGameLogsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  pluralName: Scalars['String']['input'];
  singularName: Scalars['String']['input'];
  skill?: InputMaybe<SkillCreateNestedOneWithoutGamePointTypeInput>;
  slug: Scalars['String']['input'];
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutGamePointTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GamePointTypeCreateWithoutTriggersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  pluralName: Scalars['String']['input'];
  singularName: Scalars['String']['input'];
  skill?: InputMaybe<SkillCreateNestedOneWithoutGamePointTypeInput>;
  slug: Scalars['String']['input'];
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutPointTypeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type GamePointTypeMaxAggregate = {
  __typename?: 'GamePointTypeMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  pluralName?: Maybe<Scalars['String']['output']>;
  singularName?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GamePointTypeMinAggregate = {
  __typename?: 'GamePointTypeMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  pluralName?: Maybe<Scalars['String']['output']>;
  singularName?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type GamePointTypeOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  pluralName?: InputMaybe<SortOrder>;
  singularName?: InputMaybe<SortOrder>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  studentGameLogs?: InputMaybe<StudentGameLogOrderByRelationAggregateInput>;
  triggers?: InputMaybe<TriggerOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type GamePointTypeRelationFilter = {
  is?: InputMaybe<GamePointTypeWhereInput>;
  isNot?: InputMaybe<GamePointTypeWhereInput>;
};

export enum GamePointTypeScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  IMAGE = 'image',
  PLURALNAME = 'pluralName',
  SINGULARNAME = 'singularName',
  SKILLID = 'skillId',
  SLUG = 'slug',
  UPDATEDAT = 'updatedAt'
}

export type GamePointTypeSumAggregate = {
  __typename?: 'GamePointTypeSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
};

export type GamePointTypeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutGamePointTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutPointTypeNestedInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutGamePointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpdateOneWithoutSkillNestedInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutSkillInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutSkillInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<GamePointTypeUpdateWithoutSkillInput>;
  upsert?: InputMaybe<GamePointTypeUpsertWithoutSkillInput>;
};

export type GamePointTypeUpdateOneWithoutStudentGameLogsNestedInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutStudentGameLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<GamePointTypeUpdateWithoutStudentGameLogsInput>;
  upsert?: InputMaybe<GamePointTypeUpsertWithoutStudentGameLogsInput>;
};

export type GamePointTypeUpdateOneWithoutTriggersNestedInput = {
  connect?: InputMaybe<GamePointTypeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<GamePointTypeCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<GamePointTypeCreateWithoutTriggersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<GamePointTypeUpdateWithoutTriggersInput>;
  upsert?: InputMaybe<GamePointTypeUpsertWithoutTriggersInput>;
};

export type GamePointTypeUpdateWithoutSkillInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutPointTypeNestedInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutGamePointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpdateWithoutStudentGameLogsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutGamePointTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutGamePointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpdateWithoutTriggersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pluralName?: InputMaybe<StringFieldUpdateOperationsInput>;
  singularName?: InputMaybe<StringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutGamePointTypeNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutPointTypeNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type GamePointTypeUpsertWithoutSkillInput = {
  create: GamePointTypeCreateWithoutSkillInput;
  update: GamePointTypeUpdateWithoutSkillInput;
};

export type GamePointTypeUpsertWithoutStudentGameLogsInput = {
  create: GamePointTypeCreateWithoutStudentGameLogsInput;
  update: GamePointTypeUpdateWithoutStudentGameLogsInput;
};

export type GamePointTypeUpsertWithoutTriggersInput = {
  create: GamePointTypeCreateWithoutTriggersInput;
  update: GamePointTypeUpdateWithoutTriggersInput;
};

export type GamePointTypeWhereInput = {
  AND?: InputMaybe<Array<GamePointTypeWhereInput>>;
  NOT?: InputMaybe<Array<GamePointTypeWhereInput>>;
  OR?: InputMaybe<Array<GamePointTypeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  pluralName?: InputMaybe<StringFilter>;
  singularName?: InputMaybe<StringFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  studentGameLogs?: InputMaybe<StudentGameLogListRelationFilter>;
  triggers?: InputMaybe<TriggerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type GamePointTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  skillId?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateUploadSignedUrlFileInput = {
  access?: InputMaybe<FileAccess>;
  contentType: Scalars['String']['input'];
  key: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GenerateUploadSignedUrlInput = {
  files: Array<GenerateUploadSignedUrlFileInput>;
};

export type ImportStudentsResponse = {
  __typename?: 'ImportStudentsResponse';
  data?: Maybe<Scalars['JSON']['output']>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type Level = {
  __typename?: 'Level';
  _count?: Maybe<LevelCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sequence: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LevelAvgAggregate = {
  __typename?: 'LevelAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  sequence?: Maybe<Scalars['Float']['output']>;
};

export type LevelCount = {
  __typename?: 'LevelCount';
  triggers: Scalars['Int']['output'];
};


export type LevelCountTriggersArgs = {
  where?: InputMaybe<TriggerWhereInput>;
};

export type LevelCountAggregate = {
  __typename?: 'LevelCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  sequence: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type LevelCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sequence?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  triggers?: InputMaybe<TriggerCreateNestedManyWithoutLevelInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LevelCreateNestedOneWithoutTriggersInput = {
  connect?: InputMaybe<LevelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LevelCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<LevelCreateWithoutTriggersInput>;
};

export type LevelCreateOrConnectWithoutTriggersInput = {
  create: LevelCreateWithoutTriggersInput;
  where: LevelWhereUniqueInput;
};

export type LevelCreateWithoutTriggersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sequence?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LevelMaxAggregate = {
  __typename?: 'LevelMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LevelMinAggregate = {
  __typename?: 'LevelMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LevelOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  sequence?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  triggers?: InputMaybe<TriggerOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LevelRelationFilter = {
  is?: InputMaybe<LevelWhereInput>;
  isNot?: InputMaybe<LevelWhereInput>;
};

export enum LevelScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  IMAGE = 'image',
  NAME = 'name',
  SEQUENCE = 'sequence',
  SLUG = 'slug',
  UPDATEDAT = 'updatedAt'
}

export type LevelSumAggregate = {
  __typename?: 'LevelSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
};

export type LevelUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggers?: InputMaybe<TriggerUpdateManyWithoutLevelNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LevelUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LevelUpdateOneWithoutTriggersNestedInput = {
  connect?: InputMaybe<LevelWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LevelCreateOrConnectWithoutTriggersInput>;
  create?: InputMaybe<LevelCreateWithoutTriggersInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<LevelUpdateWithoutTriggersInput>;
  upsert?: InputMaybe<LevelUpsertWithoutTriggersInput>;
};

export type LevelUpdateWithoutTriggersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sequence?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LevelUpsertWithoutTriggersInput = {
  create: LevelCreateWithoutTriggersInput;
  update: LevelUpdateWithoutTriggersInput;
};

export type LevelWhereInput = {
  AND?: InputMaybe<Array<LevelWhereInput>>;
  NOT?: InputMaybe<Array<LevelWhereInput>>;
  OR?: InputMaybe<Array<LevelWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sequence?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  triggers?: InputMaybe<TriggerListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LevelWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export enum LogAccessType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type LogInInput = {
  idToken: Scalars['String']['input'];
};

export type LogInResponse = {
  __typename?: 'LogInResponse';
  authToken?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  message: Scalars['String']['output'];
  rules?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type MeResponse = {
  __typename?: 'MeResponse';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  allowStandardUpdate: Scalars['Boolean']['output'];
  city?: Maybe<Scalars['String']['output']>;
  coins: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  dailyActivityAnswered: Scalars['Boolean']['output'];
  dailyChallengeAnswered?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isHomeschooler: Scalars['Boolean']['output'];
  isMeta: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  level: Scalars['Float']['output'];
  levelPercentage: Scalars['Float']['output'];
  message?: Maybe<Scalars['String']['output']>;
  notificationToken: Scalars['Boolean']['output'];
  phoneNumber: Scalars['String']['output'];
  postcode?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  rules: Scalars['JSON']['output'];
  schoolText?: Maybe<Scalars['String']['output']>;
  standard: Standard;
  stars: Scalars['Int']['output'];
  state?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  totalXps: Scalars['Int']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activityQuestionSubmit: ValidateActivityQuestionSubmitOutput;
  adminLogIn: LogInResponse;
  answerStudentDailyActivity?: Maybe<StudentDailyActivity>;
  applyPromoCode: ApplyPromoCodeOutput;
  createActivity: Activity;
  createActivityBanner: ActivityBanner;
  createBadge: Badge;
  createComment: Comment;
  createDailyActivity: DailyActivity;
  createGamePointType: GamePointType;
  createLevel: Level;
  createManyActivityQuestion: AffectedRowsOutput;
  createManyPromoCodeToPromoCodeGroup: AffectedRowsOutput;
  createOrder: Order;
  createPrivacyPolicy: PrivacyPolicy;
  createProduct: Product;
  createProductBanner: ProductBanner;
  createPromoCode: PromoCode;
  createPromoCodeGroup: PromoCodeGroup;
  createPromoCodeHistory: PromoCodeHistory;
  createPromoCodeToPromoCodeGroup: PromoCodeToPromoCodeGroup;
  createReferralCode: ReferralCode;
  createReferralCodeHistory: ReferralCodeHistory;
  createReport: Report;
  createSamskara: Samskara;
  createSamskaraCategory: SamskaraCategory;
  createSchool: School;
  createSchoolUpdate: SchoolUpdate;
  createSkill: Skill;
  createStandard: Standard;
  createStory: Story;
  createStoryActivity: StoryActivity;
  createStoryBanner: StoryBanner;
  createStoryTag: StoryTag;
  createStudent: Student;
  createStudentGameLog: StudentGameLog;
  createStudentRecord: StudentRecord;
  createTag: Tag;
  createTrigger: Trigger;
  createUser: User;
  createUserLog: UserLog;
  createWorkshop: Workshop;
  createWorkshopRegistration: WorkshopRegistration;
  decryptUserToken?: Maybe<User>;
  deleteActivity?: Maybe<Activity>;
  deleteActivityBanner?: Maybe<ActivityBanner>;
  deleteBadge?: Maybe<Badge>;
  deleteComment: Comment;
  deleteDailyActivity?: Maybe<DailyActivity>;
  deleteGamePointType?: Maybe<GamePointType>;
  deleteLevel?: Maybe<Level>;
  deleteManyActivityQuestion: AffectedRowsOutput;
  deleteManyLevel: AffectedRowsOutput;
  deleteManyOrder: AffectedRowsOutput;
  deleteManyPromoCodeToPromoCodeGroup: AffectedRowsOutput;
  deleteManyStoryTag: AffectedRowsOutput;
  deleteOrder?: Maybe<Order>;
  deleteProduct?: Maybe<Product>;
  deleteProductBanner?: Maybe<ProductBanner>;
  deletePromoCode?: Maybe<PromoCode>;
  deletePromoCodeGroup?: Maybe<PromoCodeGroup>;
  deletePromoCodeHistory?: Maybe<PromoCodeHistory>;
  deletePromoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  deleteReferralCode?: Maybe<ReferralCode>;
  deleteReferralCodeHistory?: Maybe<ReferralCodeHistory>;
  deleteReport?: Maybe<Report>;
  deleteSamskara?: Maybe<Samskara>;
  deleteSchool?: Maybe<School>;
  deleteSchoolUpdate?: Maybe<SchoolUpdate>;
  deleteSkill?: Maybe<Skill>;
  deleteStandard?: Maybe<Standard>;
  deleteStory?: Maybe<Story>;
  deleteStoryActivity?: Maybe<StoryActivity>;
  deleteStoryBanner?: Maybe<StoryBanner>;
  deleteStoryTag?: Maybe<StoryTag>;
  deleteStudent?: Maybe<Student>;
  deleteStudentGameLog?: Maybe<StudentGameLog>;
  deleteStudentGameLogs: AffectedRowsOutput;
  deleteTag?: Maybe<Tag>;
  deleteTrigger?: Maybe<Trigger>;
  deleteUser?: Maybe<User>;
  deleteWorkshop?: Maybe<Workshop>;
  deleteWorkshopRegistration?: Maybe<WorkshopRegistration>;
  generateUploadSignedUrl: Array<File>;
  importStudents?: Maybe<ImportStudentsResponse>;
  logIn: LogInResponse;
  logout: RefreshTokenResponse;
  privacyPolicies: PrivacyPolicyUpdate;
  refreshToken: RefreshTokenResponse;
  signUp: LogInResponse;
  studentAssignBadge?: Maybe<StudentAssignBadgeResponse>;
  studentCreateOrder?: Maybe<StudentCreateOrderOutput>;
  studentDeregisterWorkshop?: Maybe<StudentRegisterWorkshopOutput>;
  studentRegisterWorkshop?: Maybe<StudentRegisterWorkshopOutput>;
  studentRemoveBadge?: Maybe<StudentAssignBadgeResponse>;
  unlockStudentActivity?: Maybe<Activity>;
  unlockStudentStoryActivity?: Maybe<StoryActivity>;
  updateActivity?: Maybe<Activity>;
  updateActivityBanner?: Maybe<ActivityBanner>;
  updateBadge?: Maybe<Badge>;
  updateComment?: Maybe<Comment>;
  updateComments: AffectedRowsOutput;
  updateDailyActivity?: Maybe<DailyActivity>;
  updateGamePointType?: Maybe<GamePointType>;
  updateLevel?: Maybe<Level>;
  updateManyLevel: AffectedRowsOutput;
  updateManyOrder: AffectedRowsOutput;
  updateManyPromoCodeToPromoCodeGroup: AffectedRowsOutput;
  updateManyWorkshopRegistration: AffectedRowsOutput;
  updateOneActivityQuestion?: Maybe<ActivityQuestion>;
  updateOrder?: Maybe<Order>;
  updateProduct?: Maybe<Product>;
  updateProductBanner?: Maybe<ProductBanner>;
  updateProfile?: Maybe<Student>;
  updatePromoCode?: Maybe<PromoCode>;
  updatePromoCodeGroup?: Maybe<PromoCodeGroup>;
  updatePromoCodeHistory?: Maybe<PromoCodeHistory>;
  updatePromoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  updateReferralCode?: Maybe<ReferralCode>;
  updateReferralCodeHistory?: Maybe<ReferralCodeHistory>;
  updateReport?: Maybe<Report>;
  updateSamskara?: Maybe<Samskara>;
  updateSamskaraCategory?: Maybe<SamskaraCategory>;
  updateSchool?: Maybe<School>;
  updateSchoolUpdate?: Maybe<SchoolUpdate>;
  updateSkill?: Maybe<Skill>;
  updateStandard?: Maybe<Standard>;
  updateStory?: Maybe<Story>;
  updateStoryActivity?: Maybe<StoryActivity>;
  updateStoryBanner?: Maybe<StoryBanner>;
  updateStoryTag?: Maybe<StoryTag>;
  updateStudent?: Maybe<Student>;
  updateStudentMeta?: Maybe<UpdateStudentMetaResponse>;
  updateTag?: Maybe<Tag>;
  updateTrigger?: Maybe<Trigger>;
  updateUser?: Maybe<User>;
  updateWorkshop?: Maybe<Workshop>;
  updateWorkshopRegistration?: Maybe<WorkshopRegistration>;
  upsertPromoCodeToPromoCodeGroup: PromoCodeToPromoCodeGroup;
  validateActivityQuestionAnswer: ValidateActivityQuestionAnswerOutput;
  verifySignUpCode: VerifyUserResponse;
  verifyUser: VerifyUserResponse;
};


export type MutationActivityQuestionSubmitArgs = {
  activity: ActivityWhereUniqueInput;
  data: Array<ValidateActivityQuestionSubmitInput>;
};


export type MutationAdminLogInArgs = {
  data: LogInInput;
};


export type MutationAnswerStudentDailyActivityArgs = {
  data: AnswerStudentDailyActivityInput;
  where: DailyActivityWhereUniqueInput;
};


export type MutationApplyPromoCodeArgs = {
  code: Scalars['String']['input'];
  studentId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateActivityArgs = {
  data: ActivityCreateInput;
};


export type MutationCreateActivityBannerArgs = {
  data: ActivityBannerCreateInput;
};


export type MutationCreateBadgeArgs = {
  data: BadgeCreateInput;
};


export type MutationCreateCommentArgs = {
  data: StudentCreateCommentInput;
};


export type MutationCreateDailyActivityArgs = {
  data: DailyActivityCreateInput;
};


export type MutationCreateGamePointTypeArgs = {
  data: GamePointTypeCreateInput;
};


export type MutationCreateLevelArgs = {
  data: LevelCreateInput;
};


export type MutationCreateManyActivityQuestionArgs = {
  data: Array<ActivityQuestionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateManyPromoCodeToPromoCodeGroupArgs = {
  data: Array<PromoCodeToPromoCodeGroupCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateOrderArgs = {
  data: OrderCreateInput;
};


export type MutationCreatePrivacyPolicyArgs = {
  data: PrivacyPolicyCreateInput;
};


export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};


export type MutationCreateProductBannerArgs = {
  data: ProductBannerCreateInput;
};


export type MutationCreatePromoCodeArgs = {
  data: PromoCodeCreateInput;
};


export type MutationCreatePromoCodeGroupArgs = {
  data: PromoCodeGroupCreateInput;
};


export type MutationCreatePromoCodeHistoryArgs = {
  data: PromoCodeHistoryCreateInput;
};


export type MutationCreatePromoCodeToPromoCodeGroupArgs = {
  data: PromoCodeToPromoCodeGroupCreateInput;
};


export type MutationCreateReferralCodeArgs = {
  data: ReferralCodeCreateInput;
};


export type MutationCreateReferralCodeHistoryArgs = {
  data: ReferralCodeHistoryCreateInput;
};


export type MutationCreateReportArgs = {
  data: ReportCreateInput;
};


export type MutationCreateSamskaraArgs = {
  data: SamskaraCreateInput;
};


export type MutationCreateSamskaraCategoryArgs = {
  data: SamskaraCategoryCreateInput;
};


export type MutationCreateSchoolArgs = {
  data: SchoolCreateInput;
};


export type MutationCreateSchoolUpdateArgs = {
  data: SchoolUpdateCreateInput;
};


export type MutationCreateSkillArgs = {
  data: SkillCreateInput;
};


export type MutationCreateStandardArgs = {
  data: StandardCreateInput;
};


export type MutationCreateStoryArgs = {
  data: StoryCreateInput;
};


export type MutationCreateStoryActivityArgs = {
  data: StoryActivityCreateInput;
};


export type MutationCreateStoryBannerArgs = {
  data: StoryBannerCreateInput;
};


export type MutationCreateStoryTagArgs = {
  data: StoryTagCreateInput;
};


export type MutationCreateStudentArgs = {
  data: StudentCreateInput;
};


export type MutationCreateStudentGameLogArgs = {
  data: StudentGameLogCreateInput;
};


export type MutationCreateStudentRecordArgs = {
  data: StudentRecordCreateInput;
};


export type MutationCreateTagArgs = {
  data: TagCreateInput;
};


export type MutationCreateTriggerArgs = {
  data: TriggerCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateUserLogArgs = {
  data: UserLogCreateInput;
};


export type MutationCreateWorkshopArgs = {
  data: WorkshopCreateInput;
};


export type MutationCreateWorkshopRegistrationArgs = {
  data: WorkshopRegistrationCreateInput;
};


export type MutationDecryptUserTokenArgs = {
  data: DecryptUserTokenInput;
};


export type MutationDeleteActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type MutationDeleteActivityBannerArgs = {
  where: ActivityBannerWhereUniqueInput;
};


export type MutationDeleteBadgeArgs = {
  where: BadgeWhereUniqueInput;
};


export type MutationDeleteCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type MutationDeleteDailyActivityArgs = {
  where: DailyActivityWhereUniqueInput;
};


export type MutationDeleteGamePointTypeArgs = {
  where: GamePointTypeWhereUniqueInput;
};


export type MutationDeleteLevelArgs = {
  where: LevelWhereUniqueInput;
};


export type MutationDeleteManyActivityQuestionArgs = {
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type MutationDeleteManyLevelArgs = {
  where?: InputMaybe<LevelWhereInput>;
};


export type MutationDeleteManyOrderArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationDeleteManyPromoCodeToPromoCodeGroupArgs = {
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type MutationDeleteManyStoryTagArgs = {
  where?: InputMaybe<StoryTagWhereInput>;
};


export type MutationDeleteOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationDeleteProductBannerArgs = {
  where: ProductBannerWhereUniqueInput;
};


export type MutationDeletePromoCodeArgs = {
  where: PromoCodeWhereUniqueInput;
};


export type MutationDeletePromoCodeGroupArgs = {
  where: PromoCodeGroupWhereUniqueInput;
};


export type MutationDeletePromoCodeHistoryArgs = {
  where: PromoCodeHistoryWhereUniqueInput;
};


export type MutationDeletePromoCodeToPromoCodeGroupArgs = {
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type MutationDeleteReferralCodeArgs = {
  where: ReferralCodeWhereUniqueInput;
};


export type MutationDeleteReferralCodeHistoryArgs = {
  where: ReferralCodeHistoryWhereUniqueInput;
};


export type MutationDeleteReportArgs = {
  where: ReportWhereUniqueInput;
};


export type MutationDeleteSamskaraArgs = {
  where: SamskaraWhereUniqueInput;
};


export type MutationDeleteSchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type MutationDeleteSchoolUpdateArgs = {
  where: SchoolUpdateWhereUniqueInput;
};


export type MutationDeleteSkillArgs = {
  where: SkillWhereUniqueInput;
};


export type MutationDeleteStandardArgs = {
  where: StandardWhereUniqueInput;
};


export type MutationDeleteStoryArgs = {
  where: StoryWhereUniqueInput;
};


export type MutationDeleteStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type MutationDeleteStoryBannerArgs = {
  where: StoryBannerWhereUniqueInput;
};


export type MutationDeleteStoryTagArgs = {
  where: StoryTagWhereUniqueInput;
};


export type MutationDeleteStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type MutationDeleteStudentGameLogArgs = {
  where: StudentGameLogWhereUniqueInput;
};


export type MutationDeleteStudentGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type MutationDeleteTagArgs = {
  where: TagWhereUniqueInput;
};


export type MutationDeleteTriggerArgs = {
  where: TriggerWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteWorkshopArgs = {
  where: WorkshopWhereUniqueInput;
};


export type MutationDeleteWorkshopRegistrationArgs = {
  where: WorkshopRegistrationWhereUniqueInput;
};


export type MutationGenerateUploadSignedUrlArgs = {
  data: GenerateUploadSignedUrlInput;
};


export type MutationImportStudentsArgs = {
  file: Scalars['Upload']['input'];
};


export type MutationLogInArgs = {
  data: LogInInput;
};


export type MutationPrivacyPoliciesArgs = {
  data: PrivacyPolicyUpdateCreateInput;
};


export type MutationSignUpArgs = {
  data: SignUpInput;
};


export type MutationStudentAssignBadgeArgs = {
  data: StudentAssignBadgeInput;
};


export type MutationStudentCreateOrderArgs = {
  data: StudentCreateOrderProductInput;
};


export type MutationStudentDeregisterWorkshopArgs = {
  where: StudentWorkshopUniqueInput;
};


export type MutationStudentRegisterWorkshopArgs = {
  where: StudentWorkshopUniqueInput;
};


export type MutationStudentRemoveBadgeArgs = {
  where: StudentBadgeWhereUniqueInput;
};


export type MutationUnlockStudentActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type MutationUnlockStudentStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type MutationUpdateActivityArgs = {
  data: ActivityUpdateInput;
  where: ActivityWhereUniqueInput;
};


export type MutationUpdateActivityBannerArgs = {
  data: ActivityBannerUpdateInput;
  where: ActivityBannerWhereUniqueInput;
};


export type MutationUpdateBadgeArgs = {
  data: BadgeUpdateInput;
  where: BadgeWhereUniqueInput;
};


export type MutationUpdateCommentArgs = {
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
};


export type MutationUpdateCommentsArgs = {
  data: CommentUpdateManyMutationInput;
  where?: InputMaybe<CommentWhereInput>;
};


export type MutationUpdateDailyActivityArgs = {
  data: DailyActivityUpdateInput;
  where: DailyActivityWhereUniqueInput;
};


export type MutationUpdateGamePointTypeArgs = {
  data: GamePointTypeUpdateInput;
  where: GamePointTypeWhereUniqueInput;
};


export type MutationUpdateLevelArgs = {
  data: LevelUpdateInput;
  where: LevelWhereUniqueInput;
};


export type MutationUpdateManyLevelArgs = {
  data: LevelUpdateManyMutationInput;
  where?: InputMaybe<LevelWhereInput>;
};


export type MutationUpdateManyOrderArgs = {
  data: OrderUpdateManyMutationInput;
  where?: InputMaybe<OrderWhereInput>;
};


export type MutationUpdateManyPromoCodeToPromoCodeGroupArgs = {
  data: PromoCodeToPromoCodeGroupUpdateManyMutationInput;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type MutationUpdateManyWorkshopRegistrationArgs = {
  data: WorkshopRegistrationUpdateManyMutationInput;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};


export type MutationUpdateOneActivityQuestionArgs = {
  data: ActivityQuestionUpdateInput;
  where: ActivityQuestionWhereUniqueInput;
};


export type MutationUpdateOrderArgs = {
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
};


export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationUpdateProductBannerArgs = {
  data: ProductBannerUpdateInput;
  where: ProductBannerWhereUniqueInput;
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};


export type MutationUpdatePromoCodeArgs = {
  data: PromoCodeUpdateInput;
  where: PromoCodeWhereUniqueInput;
};


export type MutationUpdatePromoCodeGroupArgs = {
  data: PromoCodeGroupUpdateInput;
  where: PromoCodeGroupWhereUniqueInput;
};


export type MutationUpdatePromoCodeHistoryArgs = {
  data: PromoCodeHistoryUpdateInput;
  where: PromoCodeHistoryWhereUniqueInput;
};


export type MutationUpdatePromoCodeToPromoCodeGroupArgs = {
  data: PromoCodeToPromoCodeGroupUpdateInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type MutationUpdateReferralCodeArgs = {
  data: ReferralCodeUpdateInput;
  where: ReferralCodeWhereUniqueInput;
};


export type MutationUpdateReferralCodeHistoryArgs = {
  data: ReferralCodeHistoryUpdateInput;
  where: ReferralCodeHistoryWhereUniqueInput;
};


export type MutationUpdateReportArgs = {
  data: ReportUpdateInput;
  where: ReportWhereUniqueInput;
};


export type MutationUpdateSamskaraArgs = {
  data: SamskaraUpdateInput;
  where: SamskaraWhereUniqueInput;
};


export type MutationUpdateSamskaraCategoryArgs = {
  data: SamskaraCategoryUpdateInput;
  where: SamskaraCategoryWhereUniqueInput;
};


export type MutationUpdateSchoolArgs = {
  data: SchoolUpdateInput;
  where: SchoolWhereUniqueInput;
};


export type MutationUpdateSchoolUpdateArgs = {
  data: SchoolUpdateUpdateInput;
  where: SchoolUpdateWhereUniqueInput;
};


export type MutationUpdateSkillArgs = {
  data: SkillUpdateInput;
  where: SkillWhereUniqueInput;
};


export type MutationUpdateStandardArgs = {
  data: StandardUpdateInput;
  where: StandardWhereUniqueInput;
};


export type MutationUpdateStoryArgs = {
  data: StoryUpdateInput;
  where: StoryWhereUniqueInput;
};


export type MutationUpdateStoryActivityArgs = {
  data: StoryActivityUpdateInput;
  where: StoryActivityWhereUniqueInput;
};


export type MutationUpdateStoryBannerArgs = {
  data: StoryBannerUpdateInput;
  where: StoryBannerWhereUniqueInput;
};


export type MutationUpdateStoryTagArgs = {
  data: StoryTagUpdateInput;
  where: StoryTagWhereUniqueInput;
};


export type MutationUpdateStudentArgs = {
  data: StudentUpdateInput;
  where: StudentWhereUniqueInput;
};


export type MutationUpdateStudentMetaArgs = {
  data: UpdateStudentMetaInput;
};


export type MutationUpdateTagArgs = {
  data: TagUpdateInput;
  where: TagWhereUniqueInput;
};


export type MutationUpdateTriggerArgs = {
  data: TriggerUpdateInput;
  where: TriggerWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateWorkshopArgs = {
  data: WorkshopUpdateInput;
  where: WorkshopWhereUniqueInput;
};


export type MutationUpdateWorkshopRegistrationArgs = {
  data: WorkshopRegistrationUpdateInput;
  where: WorkshopRegistrationWhereUniqueInput;
};


export type MutationUpsertPromoCodeToPromoCodeGroupArgs = {
  create: PromoCodeToPromoCodeGroupCreateInput;
  update: PromoCodeToPromoCodeGroupUpdateInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type MutationValidateActivityQuestionAnswerArgs = {
  data: ValidateActivityQuestionAnswerInput;
  where: ActivityQuestionWhereUniqueInput;
};


export type MutationVerifySignUpCodeArgs = {
  data: VerifySignUpCodeInput;
};


export type MutationVerifyUserArgs = {
  data: VerifyUserInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedEnumActivityStatusFilter = {
  equals?: InputMaybe<ActivityStatus>;
  in?: InputMaybe<Array<ActivityStatus>>;
  not?: InputMaybe<NestedEnumActivityStatusFilter>;
  notIn?: InputMaybe<Array<ActivityStatus>>;
};

export type NestedEnumCommentStatusNullableFilter = {
  equals?: InputMaybe<CommentStatus>;
  in?: InputMaybe<Array<CommentStatus>>;
  not?: InputMaybe<NestedEnumCommentStatusNullableFilter>;
  notIn?: InputMaybe<Array<CommentStatus>>;
};

export type NestedEnumLogAccessTypeFilter = {
  equals?: InputMaybe<LogAccessType>;
  in?: InputMaybe<Array<LogAccessType>>;
  not?: InputMaybe<NestedEnumLogAccessTypeFilter>;
  notIn?: InputMaybe<Array<LogAccessType>>;
};

export type NestedEnumOrderStatusFilter = {
  equals?: InputMaybe<OrderStatus>;
  in?: InputMaybe<Array<OrderStatus>>;
  not?: InputMaybe<NestedEnumOrderStatusFilter>;
  notIn?: InputMaybe<Array<OrderStatus>>;
};

export type NestedEnumPaymentMethodFilter = {
  equals?: InputMaybe<PaymentMethod>;
  in?: InputMaybe<Array<PaymentMethod>>;
  not?: InputMaybe<NestedEnumPaymentMethodFilter>;
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumSkillTypeFilter = {
  equals?: InputMaybe<SkillType>;
  in?: InputMaybe<Array<SkillType>>;
  not?: InputMaybe<NestedEnumSkillTypeFilter>;
  notIn?: InputMaybe<Array<SkillType>>;
};

export type NestedEnumStatusFilter = {
  equals?: InputMaybe<Status>;
  in?: InputMaybe<Array<Status>>;
  not?: InputMaybe<NestedEnumStatusFilter>;
  notIn?: InputMaybe<Array<Status>>;
};

export type NestedEnumTriggerTypeFilter = {
  equals?: InputMaybe<TriggerType>;
  in?: InputMaybe<Array<TriggerType>>;
  not?: InputMaybe<NestedEnumTriggerTypeFilter>;
  notIn?: InputMaybe<Array<TriggerType>>;
};

export type NestedEnumWorkshopStatusFilter = {
  equals?: InputMaybe<WorkshopStatus>;
  in?: InputMaybe<Array<WorkshopStatus>>;
  not?: InputMaybe<NestedEnumWorkshopStatusFilter>;
  notIn?: InputMaybe<Array<WorkshopStatus>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedJsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']['input']>;
};

export type NullableEnumCommentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<CommentStatus>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']['input']>;
  divide?: InputMaybe<Scalars['Int']['input']>;
  increment?: InputMaybe<Scalars['Int']['input']>;
  multiply?: InputMaybe<Scalars['Int']['input']>;
  set?: InputMaybe<Scalars['Int']['input']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export enum NullsOrder {
  FIRST = 'first',
  LAST = 'last'
}

export type Order = {
  __typename?: 'Order';
  billingAddress1?: Maybe<Scalars['String']['output']>;
  billingAddress2?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingFirstName?: Maybe<Scalars['String']['output']>;
  billingLastName?: Maybe<Scalars['String']['output']>;
  billingPhone?: Maybe<Scalars['String']['output']>;
  billingPostcode?: Maybe<Scalars['String']['output']>;
  billingState?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer: Student;
  customerId: Scalars['Int']['output'];
  customerIpAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  paymentMethod: PaymentMethod;
  paymentStatus: Scalars['String']['output'];
  product: Product;
  productId: Scalars['Int']['output'];
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingFirstName?: Maybe<Scalars['String']['output']>;
  shippingLastName?: Maybe<Scalars['String']['output']>;
  shippingPhone?: Maybe<Scalars['String']['output']>;
  shippingPostcode?: Maybe<Scalars['String']['output']>;
  shippingState?: Maybe<Scalars['String']['output']>;
  status: OrderStatus;
  total: Scalars['Int']['output'];
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workshopRegistration?: Maybe<WorkshopRegistration>;
};

export type OrderAvgAggregate = {
  __typename?: 'OrderAvgAggregate';
  customerId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type OrderCountAggregate = {
  __typename?: 'OrderCountAggregate';
  _all: Scalars['Int']['output'];
  billingAddress1: Scalars['Int']['output'];
  billingAddress2: Scalars['Int']['output'];
  billingCity: Scalars['Int']['output'];
  billingCountry: Scalars['Int']['output'];
  billingFirstName: Scalars['Int']['output'];
  billingLastName: Scalars['Int']['output'];
  billingPhone: Scalars['Int']['output'];
  billingPostcode: Scalars['Int']['output'];
  billingState: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  customerId: Scalars['Int']['output'];
  customerIpAddress: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  paymentMethod: Scalars['Int']['output'];
  paymentStatus: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  shippingAddress1: Scalars['Int']['output'];
  shippingAddress2: Scalars['Int']['output'];
  shippingCity: Scalars['Int']['output'];
  shippingCountry: Scalars['Int']['output'];
  shippingFirstName: Scalars['Int']['output'];
  shippingLastName: Scalars['Int']['output'];
  shippingPhone: Scalars['Int']['output'];
  shippingPostcode: Scalars['Int']['output'];
  shippingState: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  transactionId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type OrderCreateInput = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  billingFirstName?: InputMaybe<Scalars['String']['input']>;
  billingLastName?: InputMaybe<Scalars['String']['input']>;
  billingPhone?: InputMaybe<Scalars['String']['input']>;
  billingPostcode?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer: StudentCreateNestedOneWithoutOrdersInput;
  customerIpAddress?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars['String']['input'];
  product: ProductCreateNestedOneWithoutOrdersInput;
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateManyCustomerInput = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  billingFirstName?: InputMaybe<Scalars['String']['input']>;
  billingLastName?: InputMaybe<Scalars['String']['input']>;
  billingPhone?: InputMaybe<Scalars['String']['input']>;
  billingPostcode?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerIpAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars['String']['input'];
  productId: Scalars['Int']['input'];
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderCreateManyCustomerInputEnvelope = {
  data: Array<OrderCreateManyCustomerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderCreateManyProductInput = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  billingFirstName?: InputMaybe<Scalars['String']['input']>;
  billingLastName?: InputMaybe<Scalars['String']['input']>;
  billingPhone?: InputMaybe<Scalars['String']['input']>;
  billingPostcode?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerId: Scalars['Int']['input'];
  customerIpAddress?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars['String']['input'];
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderCreateManyProductInputEnvelope = {
  data: Array<OrderCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrderCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<OrderCreateManyCustomerInputEnvelope>;
};

export type OrderCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutProductInput>>;
  createMany?: InputMaybe<OrderCreateManyProductInputEnvelope>;
};

export type OrderCreateNestedOneWithoutWorkshopRegistrationInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutWorkshopRegistrationInput>;
  create?: InputMaybe<OrderCreateWithoutWorkshopRegistrationInput>;
};

export type OrderCreateOrConnectWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutProductInput = {
  create: OrderCreateWithoutProductInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateOrConnectWithoutWorkshopRegistrationInput = {
  create: OrderCreateWithoutWorkshopRegistrationInput;
  where: OrderWhereUniqueInput;
};

export type OrderCreateWithoutCustomerInput = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  billingFirstName?: InputMaybe<Scalars['String']['input']>;
  billingLastName?: InputMaybe<Scalars['String']['input']>;
  billingPhone?: InputMaybe<Scalars['String']['input']>;
  billingPostcode?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customerIpAddress?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars['String']['input'];
  product: ProductCreateNestedOneWithoutOrdersInput;
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutProductInput = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  billingFirstName?: InputMaybe<Scalars['String']['input']>;
  billingLastName?: InputMaybe<Scalars['String']['input']>;
  billingPhone?: InputMaybe<Scalars['String']['input']>;
  billingPostcode?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer: StudentCreateNestedOneWithoutOrdersInput;
  customerIpAddress?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars['String']['input'];
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationCreateNestedOneWithoutOrderInput>;
};

export type OrderCreateWithoutWorkshopRegistrationInput = {
  billingAddress1?: InputMaybe<Scalars['String']['input']>;
  billingAddress2?: InputMaybe<Scalars['String']['input']>;
  billingCity?: InputMaybe<Scalars['String']['input']>;
  billingCountry?: InputMaybe<Scalars['String']['input']>;
  billingFirstName?: InputMaybe<Scalars['String']['input']>;
  billingLastName?: InputMaybe<Scalars['String']['input']>;
  billingPhone?: InputMaybe<Scalars['String']['input']>;
  billingPostcode?: InputMaybe<Scalars['String']['input']>;
  billingState?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  customer: StudentCreateNestedOneWithoutOrdersInput;
  customerIpAddress?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentStatus: Scalars['String']['input'];
  product: ProductCreateNestedOneWithoutOrdersInput;
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  total?: InputMaybe<Scalars['Int']['input']>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type OrderListRelationFilter = {
  every?: InputMaybe<OrderWhereInput>;
  none?: InputMaybe<OrderWhereInput>;
  some?: InputMaybe<OrderWhereInput>;
};

export type OrderMaxAggregate = {
  __typename?: 'OrderMaxAggregate';
  billingAddress1?: Maybe<Scalars['String']['output']>;
  billingAddress2?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingFirstName?: Maybe<Scalars['String']['output']>;
  billingLastName?: Maybe<Scalars['String']['output']>;
  billingPhone?: Maybe<Scalars['String']['output']>;
  billingPostcode?: Maybe<Scalars['String']['output']>;
  billingState?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  customerIpAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingFirstName?: Maybe<Scalars['String']['output']>;
  shippingLastName?: Maybe<Scalars['String']['output']>;
  shippingPhone?: Maybe<Scalars['String']['output']>;
  shippingPostcode?: Maybe<Scalars['String']['output']>;
  shippingState?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  total?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderMinAggregate = {
  __typename?: 'OrderMinAggregate';
  billingAddress1?: Maybe<Scalars['String']['output']>;
  billingAddress2?: Maybe<Scalars['String']['output']>;
  billingCity?: Maybe<Scalars['String']['output']>;
  billingCountry?: Maybe<Scalars['String']['output']>;
  billingFirstName?: Maybe<Scalars['String']['output']>;
  billingLastName?: Maybe<Scalars['String']['output']>;
  billingPhone?: Maybe<Scalars['String']['output']>;
  billingPostcode?: Maybe<Scalars['String']['output']>;
  billingState?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  customerIpAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  shippingAddress1?: Maybe<Scalars['String']['output']>;
  shippingAddress2?: Maybe<Scalars['String']['output']>;
  shippingCity?: Maybe<Scalars['String']['output']>;
  shippingCountry?: Maybe<Scalars['String']['output']>;
  shippingFirstName?: Maybe<Scalars['String']['output']>;
  shippingLastName?: Maybe<Scalars['String']['output']>;
  shippingPhone?: Maybe<Scalars['String']['output']>;
  shippingPostcode?: Maybe<Scalars['String']['output']>;
  shippingState?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  total?: Maybe<Scalars['Int']['output']>;
  transactionId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type OrderOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type OrderOrderByWithRelationInput = {
  billingAddress1?: InputMaybe<SortOrderInput>;
  billingAddress2?: InputMaybe<SortOrderInput>;
  billingCity?: InputMaybe<SortOrderInput>;
  billingCountry?: InputMaybe<SortOrderInput>;
  billingFirstName?: InputMaybe<SortOrderInput>;
  billingLastName?: InputMaybe<SortOrderInput>;
  billingPhone?: InputMaybe<SortOrderInput>;
  billingPostcode?: InputMaybe<SortOrderInput>;
  billingState?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  customer?: InputMaybe<StudentOrderByWithRelationInput>;
  customerId?: InputMaybe<SortOrder>;
  customerIpAddress?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  paymentStatus?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrder>;
  shippingAddress1?: InputMaybe<SortOrderInput>;
  shippingAddress2?: InputMaybe<SortOrderInput>;
  shippingCity?: InputMaybe<SortOrderInput>;
  shippingCountry?: InputMaybe<SortOrderInput>;
  shippingFirstName?: InputMaybe<SortOrderInput>;
  shippingLastName?: InputMaybe<SortOrderInput>;
  shippingPhone?: InputMaybe<SortOrderInput>;
  shippingPostcode?: InputMaybe<SortOrderInput>;
  shippingState?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  total?: InputMaybe<SortOrder>;
  transactionId?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationOrderByWithRelationInput>;
};

export type OrderRelationFilter = {
  is?: InputMaybe<OrderWhereInput>;
  isNot?: InputMaybe<OrderWhereInput>;
};

export enum OrderScalarFieldEnum {
  BILLINGADDRESS1 = 'billingAddress1',
  BILLINGADDRESS2 = 'billingAddress2',
  BILLINGCITY = 'billingCity',
  BILLINGCOUNTRY = 'billingCountry',
  BILLINGFIRSTNAME = 'billingFirstName',
  BILLINGLASTNAME = 'billingLastName',
  BILLINGPHONE = 'billingPhone',
  BILLINGPOSTCODE = 'billingPostcode',
  BILLINGSTATE = 'billingState',
  CREATEDAT = 'createdAt',
  CUSTOMERID = 'customerId',
  CUSTOMERIPADDRESS = 'customerIpAddress',
  ID = 'id',
  PAYMENTMETHOD = 'paymentMethod',
  PAYMENTSTATUS = 'paymentStatus',
  PRODUCTID = 'productId',
  SHIPPINGADDRESS1 = 'shippingAddress1',
  SHIPPINGADDRESS2 = 'shippingAddress2',
  SHIPPINGCITY = 'shippingCity',
  SHIPPINGCOUNTRY = 'shippingCountry',
  SHIPPINGFIRSTNAME = 'shippingFirstName',
  SHIPPINGLASTNAME = 'shippingLastName',
  SHIPPINGPHONE = 'shippingPhone',
  SHIPPINGPOSTCODE = 'shippingPostcode',
  SHIPPINGSTATE = 'shippingState',
  STATUS = 'status',
  TOTAL = 'total',
  TRANSACTIONID = 'transactionId',
  UPDATEDAT = 'updatedAt'
}

export type OrderScalarWhereInput = {
  AND?: InputMaybe<Array<OrderScalarWhereInput>>;
  NOT?: InputMaybe<Array<OrderScalarWhereInput>>;
  OR?: InputMaybe<Array<OrderScalarWhereInput>>;
  billingAddress1?: InputMaybe<StringNullableFilter>;
  billingAddress2?: InputMaybe<StringNullableFilter>;
  billingCity?: InputMaybe<StringNullableFilter>;
  billingCountry?: InputMaybe<StringNullableFilter>;
  billingFirstName?: InputMaybe<StringNullableFilter>;
  billingLastName?: InputMaybe<StringNullableFilter>;
  billingPhone?: InputMaybe<StringNullableFilter>;
  billingPostcode?: InputMaybe<StringNullableFilter>;
  billingState?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<IntFilter>;
  customerIpAddress?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<StringFilter>;
  productId?: InputMaybe<IntFilter>;
  shippingAddress1?: InputMaybe<StringNullableFilter>;
  shippingAddress2?: InputMaybe<StringNullableFilter>;
  shippingCity?: InputMaybe<StringNullableFilter>;
  shippingCountry?: InputMaybe<StringNullableFilter>;
  shippingFirstName?: InputMaybe<StringNullableFilter>;
  shippingLastName?: InputMaybe<StringNullableFilter>;
  shippingPhone?: InputMaybe<StringNullableFilter>;
  shippingPostcode?: InputMaybe<StringNullableFilter>;
  shippingState?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  total?: InputMaybe<IntFilter>;
  transactionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum OrderStatus {
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  IGNORED = 'IGNORED',
  INVOICE_CREATED = 'INVOICE_CREATED',
  PENDING = 'PENDING',
  SHIPPED = 'SHIPPED'
}

export type OrderSumAggregate = {
  __typename?: 'OrderSumAggregate';
  customerId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type OrderUpdateInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StudentUpdateOneRequiredWithoutOrdersNestedInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutOrdersNestedInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateManyMutationInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpdateManyWithWhereWithoutCustomerInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithWhereWithoutProductInput = {
  data: OrderUpdateManyMutationInput;
  where: OrderScalarWhereInput;
};

export type OrderUpdateManyWithoutCustomerNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<OrderCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type OrderUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OrderCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<OrderCreateWithoutProductInput>>;
  createMany?: InputMaybe<OrderCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<OrderWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OrderScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OrderWhereUniqueInput>>;
  set?: InputMaybe<Array<OrderWhereUniqueInput>>;
  update?: InputMaybe<Array<OrderUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<OrderUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<OrderUpsertWithWhereUniqueWithoutProductInput>>;
};

export type OrderUpdateOneWithoutWorkshopRegistrationNestedInput = {
  connect?: InputMaybe<OrderWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OrderCreateOrConnectWithoutWorkshopRegistrationInput>;
  create?: InputMaybe<OrderCreateWithoutWorkshopRegistrationInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<OrderUpdateWithoutWorkshopRegistrationInput>;
  upsert?: InputMaybe<OrderUpsertWithoutWorkshopRegistrationInput>;
};

export type OrderUpdateWithWhereUniqueWithoutCustomerInput = {
  data: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithWhereUniqueWithoutProductInput = {
  data: OrderUpdateWithoutProductInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpdateWithoutCustomerInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutOrdersNestedInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutProductInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StudentUpdateOneRequiredWithoutOrdersNestedInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationUpdateOneWithoutOrderNestedInput>;
};

export type OrderUpdateWithoutWorkshopRegistrationInput = {
  billingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  billingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<StudentUpdateOneRequiredWithoutOrdersNestedInput>;
  customerIpAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFieldUpdateOperationsInput>;
  paymentStatus?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutOrdersNestedInput>;
  shippingAddress1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingAddress2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCity?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingCountry?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingFirstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingLastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPhone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingPostcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shippingState?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOrderStatusFieldUpdateOperationsInput>;
  total?: InputMaybe<IntFieldUpdateOperationsInput>;
  transactionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OrderUpsertWithWhereUniqueWithoutCustomerInput = {
  create: OrderCreateWithoutCustomerInput;
  update: OrderUpdateWithoutCustomerInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithWhereUniqueWithoutProductInput = {
  create: OrderCreateWithoutProductInput;
  update: OrderUpdateWithoutProductInput;
  where: OrderWhereUniqueInput;
};

export type OrderUpsertWithoutWorkshopRegistrationInput = {
  create: OrderCreateWithoutWorkshopRegistrationInput;
  update: OrderUpdateWithoutWorkshopRegistrationInput;
};

export type OrderWhereInput = {
  AND?: InputMaybe<Array<OrderWhereInput>>;
  NOT?: InputMaybe<Array<OrderWhereInput>>;
  OR?: InputMaybe<Array<OrderWhereInput>>;
  billingAddress1?: InputMaybe<StringNullableFilter>;
  billingAddress2?: InputMaybe<StringNullableFilter>;
  billingCity?: InputMaybe<StringNullableFilter>;
  billingCountry?: InputMaybe<StringNullableFilter>;
  billingFirstName?: InputMaybe<StringNullableFilter>;
  billingLastName?: InputMaybe<StringNullableFilter>;
  billingPhone?: InputMaybe<StringNullableFilter>;
  billingPostcode?: InputMaybe<StringNullableFilter>;
  billingState?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<StudentRelationFilter>;
  customerId?: InputMaybe<IntFilter>;
  customerIpAddress?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  paymentMethod?: InputMaybe<EnumPaymentMethodFilter>;
  paymentStatus?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntFilter>;
  shippingAddress1?: InputMaybe<StringNullableFilter>;
  shippingAddress2?: InputMaybe<StringNullableFilter>;
  shippingCity?: InputMaybe<StringNullableFilter>;
  shippingCountry?: InputMaybe<StringNullableFilter>;
  shippingFirstName?: InputMaybe<StringNullableFilter>;
  shippingLastName?: InputMaybe<StringNullableFilter>;
  shippingPhone?: InputMaybe<StringNullableFilter>;
  shippingPostcode?: InputMaybe<StringNullableFilter>;
  shippingState?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumOrderStatusFilter>;
  total?: InputMaybe<IntFilter>;
  transactionId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopRegistration?: InputMaybe<WorkshopRegistrationRelationFilter>;
};

export type OrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum PaymentMethod {
  CARD = 'CARD',
  COIN = 'COIN'
}

export type PrivacyPolicy = {
  __typename?: 'PrivacyPolicy';
  _count?: Maybe<PrivacyPolicyCount>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  privacyPolicyUpdates: Array<PrivacyPolicyUpdate>;
  required: Scalars['Boolean']['output'];
};


export type PrivacyPolicyPrivacyPolicyUpdatesArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};

export type PrivacyPolicyAvgAggregate = {
  __typename?: 'PrivacyPolicyAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type PrivacyPolicyCount = {
  __typename?: 'PrivacyPolicyCount';
  privacyPolicyUpdates: Scalars['Int']['output'];
};


export type PrivacyPolicyCountPrivacyPolicyUpdatesArgs = {
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};

export type PrivacyPolicyCountAggregate = {
  __typename?: 'PrivacyPolicyCountAggregate';
  _all: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  required: Scalars['Int']['output'];
};

export type PrivacyPolicyCreateInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutPrivacyPolicyInput>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrivacyPolicyCreateNestedOneWithoutPrivacyPolicyUpdatesInput = {
  connect?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrivacyPolicyCreateOrConnectWithoutPrivacyPolicyUpdatesInput>;
  create?: InputMaybe<PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput>;
};

export type PrivacyPolicyCreateOrConnectWithoutPrivacyPolicyUpdatesInput = {
  create: PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput;
  where: PrivacyPolicyWhereUniqueInput;
};

export type PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput = {
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrivacyPolicyMaxAggregate = {
  __typename?: 'PrivacyPolicyMaxAggregate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type PrivacyPolicyMinAggregate = {
  __typename?: 'PrivacyPolicyMinAggregate';
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
};

export type PrivacyPolicyOrderByWithRelationInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateOrderByRelationAggregateInput>;
  required?: InputMaybe<SortOrder>;
};

export type PrivacyPolicyRelationFilter = {
  is?: InputMaybe<PrivacyPolicyWhereInput>;
  isNot?: InputMaybe<PrivacyPolicyWhereInput>;
};

export enum PrivacyPolicyScalarFieldEnum {
  CONTENT = 'content',
  CREATEDAT = 'createdAt',
  ID = 'id',
  REQUIRED = 'required'
}

export type PrivacyPolicySumAggregate = {
  __typename?: 'PrivacyPolicySumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type PrivacyPolicyUpdate = {
  __typename?: 'PrivacyPolicyUpdate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  privacyPolicy: PrivacyPolicy;
  privacyPolicyId: Scalars['Int']['output'];
  student: Student;
  studentId: Scalars['Int']['output'];
};

export type PrivacyPolicyUpdateAvgAggregate = {
  __typename?: 'PrivacyPolicyUpdateAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  privacyPolicyId?: Maybe<Scalars['Float']['output']>;
  studentId?: Maybe<Scalars['Float']['output']>;
};

export type PrivacyPolicyUpdateCountAggregate = {
  __typename?: 'PrivacyPolicyUpdateCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  privacyPolicyId: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
};

export type PrivacyPolicyUpdateCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  privacyPolicy: PrivacyPolicyCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
  student: StudentCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyUpdateCreateManyPrivacyPolicyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['Int']['input'];
};

export type PrivacyPolicyUpdateCreateManyPrivacyPolicyInputEnvelope = {
  data: Array<PrivacyPolicyUpdateCreateManyPrivacyPolicyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrivacyPolicyUpdateCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  privacyPolicyId: Scalars['Int']['input'];
};

export type PrivacyPolicyUpdateCreateManyStudentInputEnvelope = {
  data: Array<PrivacyPolicyUpdateCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PrivacyPolicyUpdateCreateNestedManyWithoutPrivacyPolicyInput = {
  connect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrivacyPolicyUpdateCreateOrConnectWithoutPrivacyPolicyInput>>;
  create?: InputMaybe<Array<PrivacyPolicyUpdateCreateWithoutPrivacyPolicyInput>>;
  createMany?: InputMaybe<PrivacyPolicyUpdateCreateManyPrivacyPolicyInputEnvelope>;
};

export type PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrivacyPolicyUpdateCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PrivacyPolicyUpdateCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PrivacyPolicyUpdateCreateManyStudentInputEnvelope>;
};

export type PrivacyPolicyUpdateCreateOrConnectWithoutPrivacyPolicyInput = {
  create: PrivacyPolicyUpdateCreateWithoutPrivacyPolicyInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateCreateOrConnectWithoutStudentInput = {
  create: PrivacyPolicyUpdateCreateWithoutStudentInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateCreateWithoutPrivacyPolicyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  student: StudentCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyUpdateCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  privacyPolicy: PrivacyPolicyCreateNestedOneWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyUpdateListRelationFilter = {
  every?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
  none?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
  some?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};

export type PrivacyPolicyUpdateMaxAggregate = {
  __typename?: 'PrivacyPolicyUpdateMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  privacyPolicyId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type PrivacyPolicyUpdateMinAggregate = {
  __typename?: 'PrivacyPolicyUpdateMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  privacyPolicyId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type PrivacyPolicyUpdateOneRequiredWithoutPrivacyPolicyUpdatesNestedInput = {
  connect?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PrivacyPolicyCreateOrConnectWithoutPrivacyPolicyUpdatesInput>;
  create?: InputMaybe<PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput>;
  update?: InputMaybe<PrivacyPolicyUpdateWithoutPrivacyPolicyUpdatesInput>;
  upsert?: InputMaybe<PrivacyPolicyUpsertWithoutPrivacyPolicyUpdatesInput>;
};

export type PrivacyPolicyUpdateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PrivacyPolicyUpdateOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  privacyPolicy?: InputMaybe<PrivacyPolicyOrderByWithRelationInput>;
  privacyPolicyId?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum PrivacyPolicyUpdateScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  PRIVACYPOLICYID = 'privacyPolicyId',
  STUDENTID = 'studentId'
}

export type PrivacyPolicyUpdateScalarWhereInput = {
  AND?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  NOT?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  OR?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  privacyPolicyId?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PrivacyPolicyUpdateSumAggregate = {
  __typename?: 'PrivacyPolicyUpdateSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  privacyPolicyId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type PrivacyPolicyUpdateUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PrivacyPolicyUpdateUpdateManyWithWhereWithoutStudentInput = {
  data: PrivacyPolicyUpdateUpdateManyMutationInput;
  where: PrivacyPolicyUpdateScalarWhereInput;
};

export type PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PrivacyPolicyUpdateCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PrivacyPolicyUpdateCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PrivacyPolicyUpdateCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PrivacyPolicyUpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<PrivacyPolicyUpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<PrivacyPolicyUpdateUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<PrivacyPolicyUpdateUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<PrivacyPolicyUpdateUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type PrivacyPolicyUpdateUpdateWithWhereUniqueWithoutStudentInput = {
  data: PrivacyPolicyUpdateUpdateWithoutStudentInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  privacyPolicy?: InputMaybe<PrivacyPolicyUpdateOneRequiredWithoutPrivacyPolicyUpdatesNestedInput>;
};

export type PrivacyPolicyUpdateUpsertWithWhereUniqueWithoutStudentInput = {
  create: PrivacyPolicyUpdateCreateWithoutStudentInput;
  update: PrivacyPolicyUpdateUpdateWithoutStudentInput;
  where: PrivacyPolicyUpdateWhereUniqueInput;
};

export type PrivacyPolicyUpdateWhereInput = {
  AND?: InputMaybe<Array<PrivacyPolicyUpdateWhereInput>>;
  NOT?: InputMaybe<Array<PrivacyPolicyUpdateWhereInput>>;
  OR?: InputMaybe<Array<PrivacyPolicyUpdateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  privacyPolicy?: InputMaybe<PrivacyPolicyRelationFilter>;
  privacyPolicyId?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PrivacyPolicyUpdateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PrivacyPolicyUpdateWithoutPrivacyPolicyUpdatesInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  required?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PrivacyPolicyUpsertWithoutPrivacyPolicyUpdatesInput = {
  create: PrivacyPolicyCreateWithoutPrivacyPolicyUpdatesInput;
  update: PrivacyPolicyUpdateWithoutPrivacyPolicyUpdatesInput;
};

export type PrivacyPolicyWhereInput = {
  AND?: InputMaybe<Array<PrivacyPolicyWhereInput>>;
  NOT?: InputMaybe<Array<PrivacyPolicyWhereInput>>;
  OR?: InputMaybe<Array<PrivacyPolicyWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateListRelationFilter>;
  required?: InputMaybe<BoolFilter>;
};

export type PrivacyPolicyWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type Product = {
  __typename?: 'Product';
  _count?: Maybe<ProductCount>;
  age?: Maybe<Scalars['String']['output']>;
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discountPrice?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['String']['output']>;
  inCurrency: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isBestSelling: Scalars['Boolean']['output'];
  isNewReleased: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  isVisible: Scalars['Boolean']['output'];
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  orders: Array<Order>;
  price: Scalars['Int']['output'];
  purchaseLink?: Maybe<Scalars['String']['output']>;
  relatedActivities: Array<Activity>;
  relatedStoryActivities: Array<StoryActivity>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skill?: Maybe<Skill>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  standards: Array<Standard>;
  updatedAt: Scalars['DateTime']['output'];
  video?: Maybe<Scalars['String']['output']>;
  workshops: Array<Workshop>;
};


export type ProductOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type ProductRelatedActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type ProductRelatedStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type ProductStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type ProductWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type ProductAvgAggregate = {
  __typename?: 'ProductAvgAggregate';
  coins?: Maybe<Scalars['Float']['output']>;
  discountPrice?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  inCurrency?: Maybe<Scalars['Float']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  skillId?: Maybe<Scalars['Float']['output']>;
};

export type ProductBanner = {
  __typename?: 'ProductBanner';
  createdAt: Scalars['DateTime']['output'];
  destinationUrl: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ProductBannerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  destinationUrl: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProductBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  destinationUrl?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ProductBannerScalarFieldEnum {
  CREATEDAT = 'createdAt',
  DESTINATIONURL = 'destinationUrl',
  DISPLAYORDER = 'displayOrder',
  ID = 'id',
  IMAGE = 'image',
  UPDATEDAT = 'updatedAt'
}

export type ProductBannerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destinationUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductBannerWhereInput = {
  AND?: InputMaybe<Array<ProductBannerWhereInput>>;
  NOT?: InputMaybe<Array<ProductBannerWhereInput>>;
  OR?: InputMaybe<Array<ProductBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destinationUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductCount = {
  __typename?: 'ProductCount';
  orders: Scalars['Int']['output'];
  relatedActivities: Scalars['Int']['output'];
  relatedStoryActivities: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type ProductCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type ProductCountRelatedActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type ProductCountRelatedStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type ProductCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type ProductCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type ProductCountAggregate = {
  __typename?: 'ProductCountAggregate';
  _all: Scalars['Int']['output'];
  age: Scalars['Int']['output'];
  coins: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  discountPrice: Scalars['Int']['output'];
  featuredImage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  images: Scalars['Int']['output'];
  inCurrency: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  isBestSelling: Scalars['Int']['output'];
  isNewReleased: Scalars['Int']['output'];
  isVirtual: Scalars['Int']['output'];
  isVisible: Scalars['Int']['output'];
  metaTagData: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  purchaseLink: Scalars['Int']['output'];
  shortDescription: Scalars['Int']['output'];
  skillId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  video: Scalars['Int']['output'];
};

export type ProductCreateInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateManySkillInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type ProductCreateManySkillInputEnvelope = {
  data: Array<ProductCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductCreateNestedManyWithoutRelatedActivitiesInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedActivitiesInput>>;
};

export type ProductCreateNestedManyWithoutRelatedStoryActivitiesInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedStoryActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedStoryActivitiesInput>>;
};

export type ProductCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ProductCreateManySkillInputEnvelope>;
};

export type ProductCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutStandardsInput>>;
};

export type ProductCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<ProductCreateWithoutOrdersInput>;
};

export type ProductCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<ProductCreateWithoutWorkshopsInput>;
};

export type ProductCreateOrConnectWithoutOrdersInput = {
  create: ProductCreateWithoutOrdersInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutRelatedActivitiesInput = {
  create: ProductCreateWithoutRelatedActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutRelatedStoryActivitiesInput = {
  create: ProductCreateWithoutRelatedStoryActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSkillInput = {
  create: ProductCreateWithoutSkillInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutStandardsInput = {
  create: ProductCreateWithoutStandardsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutWorkshopsInput = {
  create: ProductCreateWithoutWorkshopsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutOrdersInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutRelatedActivitiesInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutRelatedStoryActivitiesInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutSkillInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutStandardsInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutProductInput>;
};

export type ProductCreateWithoutWorkshopsInput = {
  age?: InputMaybe<Scalars['String']['input']>;
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discountPrice?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<ProductCreateimagesInput>;
  inCurrency: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isBestSelling?: InputMaybe<Scalars['Boolean']['input']>;
  isNewReleased?: InputMaybe<Scalars['Boolean']['input']>;
  isVirtual?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  orders?: InputMaybe<OrderCreateNestedManyWithoutProductInput>;
  price: Scalars['Int']['input'];
  purchaseLink?: InputMaybe<Scalars['String']['input']>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutProductsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutProductsInput>;
  shortDescription?: InputMaybe<Scalars['String']['input']>;
  skill?: InputMaybe<SkillCreateNestedOneWithoutProductsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutProductsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type ProductCreateimagesInput = {
  set: Array<Scalars['String']['input']>;
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductMaxAggregate = {
  __typename?: 'ProductMaxAggregate';
  age?: Maybe<Scalars['String']['output']>;
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountPrice?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inCurrency?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBestSelling?: Maybe<Scalars['Boolean']['output']>;
  isNewReleased?: Maybe<Scalars['Boolean']['output']>;
  isVirtual?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  purchaseLink?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
};

export type ProductMinAggregate = {
  __typename?: 'ProductMinAggregate';
  age?: Maybe<Scalars['String']['output']>;
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountPrice?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inCurrency?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isBestSelling?: Maybe<Scalars['Boolean']['output']>;
  isNewReleased?: Maybe<Scalars['Boolean']['output']>;
  isVirtual?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  purchaseLink?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
};

export type ProductOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ProductOrderByWithRelationInput = {
  age?: InputMaybe<SortOrderInput>;
  coins?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  discountPrice?: InputMaybe<SortOrderInput>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  images?: InputMaybe<SortOrder>;
  inCurrency?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isBestSelling?: InputMaybe<SortOrder>;
  isNewReleased?: InputMaybe<SortOrder>;
  isVirtual?: InputMaybe<SortOrder>;
  isVisible?: InputMaybe<SortOrder>;
  metaTagData?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  price?: InputMaybe<SortOrder>;
  purchaseLink?: InputMaybe<SortOrderInput>;
  relatedActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  shortDescription?: InputMaybe<SortOrderInput>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type ProductRelationFilter = {
  is?: InputMaybe<ProductWhereInput>;
  isNot?: InputMaybe<ProductWhereInput>;
};

export enum ProductScalarFieldEnum {
  AGE = 'age',
  COINS = 'coins',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DISCOUNTPRICE = 'discountPrice',
  FEATUREDIMAGE = 'featuredImage',
  ID = 'id',
  IMAGES = 'images',
  INCURRENCY = 'inCurrency',
  ISACTIVE = 'isActive',
  ISBESTSELLING = 'isBestSelling',
  ISNEWRELEASED = 'isNewReleased',
  ISVIRTUAL = 'isVirtual',
  ISVISIBLE = 'isVisible',
  METATAGDATA = 'metaTagData',
  NAME = 'name',
  PRICE = 'price',
  PURCHASELINK = 'purchaseLink',
  SHORTDESCRIPTION = 'shortDescription',
  SKILLID = 'skillId',
  SLUG = 'slug',
  UPDATEDAT = 'updatedAt',
  VIDEO = 'video'
}

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  age?: InputMaybe<StringNullableFilter>;
  coins?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountPrice?: InputMaybe<IntNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableListFilter>;
  inCurrency?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isBestSelling?: InputMaybe<BoolFilter>;
  isNewReleased?: InputMaybe<BoolFilter>;
  isVirtual?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<IntFilter>;
  purchaseLink?: InputMaybe<StringNullableFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type ProductSumAggregate = {
  __typename?: 'ProductSumAggregate';
  coins?: Maybe<Scalars['Int']['output']>;
  discountPrice?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inCurrency?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
};

export type ProductUpdateInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateManyMutationInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutRelatedActivitiesInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutRelatedStoryActivitiesInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutSkillInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutStandardsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutRelatedActivitiesNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedActivitiesInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutRelatedActivitiesInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutRelatedActivitiesInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutRelatedActivitiesInput>>;
};

export type ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutRelatedStoryActivitiesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutRelatedStoryActivitiesInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutRelatedStoryActivitiesInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutRelatedStoryActivitiesInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutRelatedStoryActivitiesInput>>;
};

export type ProductUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSkillInput>>;
  createMany?: InputMaybe<ProductCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type ProductUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type ProductUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<ProductCreateWithoutOrdersInput>;
  update?: InputMaybe<ProductUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<ProductUpsertWithoutOrdersInput>;
};

export type ProductUpdateOneWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<ProductCreateWithoutWorkshopsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ProductUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<ProductUpsertWithoutWorkshopsInput>;
};

export type ProductUpdateWithWhereUniqueWithoutRelatedActivitiesInput = {
  data: ProductUpdateWithoutRelatedActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutRelatedStoryActivitiesInput = {
  data: ProductUpdateWithoutRelatedStoryActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutSkillInput = {
  data: ProductUpdateWithoutSkillInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutStandardsInput = {
  data: ProductUpdateWithoutStandardsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutOrdersInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutRelatedActivitiesInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutRelatedStoryActivitiesInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutSkillInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutStandardsInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutProductNestedInput>;
};

export type ProductUpdateWithoutWorkshopsInput = {
  age?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coins?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  discountPrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  images?: InputMaybe<ProductUpdateimagesInput>;
  inCurrency?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isBestSelling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isNewReleased?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVirtual?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isVisible?: InputMaybe<BoolFieldUpdateOperationsInput>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutProductNestedInput>;
  price?: InputMaybe<IntFieldUpdateOperationsInput>;
  purchaseLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutProductsNestedInput>;
  relatedStoryActivities?: InputMaybe<StoryActivityUpdateManyWithoutProductsNestedInput>;
  shortDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneWithoutProductsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutProductsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type ProductUpdateimagesInput = {
  push?: InputMaybe<Array<Scalars['String']['input']>>;
  set?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProductUpsertWithWhereUniqueWithoutRelatedActivitiesInput = {
  create: ProductCreateWithoutRelatedActivitiesInput;
  update: ProductUpdateWithoutRelatedActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutRelatedStoryActivitiesInput = {
  create: ProductCreateWithoutRelatedStoryActivitiesInput;
  update: ProductUpdateWithoutRelatedStoryActivitiesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutSkillInput = {
  create: ProductCreateWithoutSkillInput;
  update: ProductUpdateWithoutSkillInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutStandardsInput = {
  create: ProductCreateWithoutStandardsInput;
  update: ProductUpdateWithoutStandardsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutOrdersInput = {
  create: ProductCreateWithoutOrdersInput;
  update: ProductUpdateWithoutOrdersInput;
};

export type ProductUpsertWithoutWorkshopsInput = {
  create: ProductCreateWithoutWorkshopsInput;
  update: ProductUpdateWithoutWorkshopsInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  age?: InputMaybe<StringNullableFilter>;
  coins?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  discountPrice?: InputMaybe<IntNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  images?: InputMaybe<StringNullableListFilter>;
  inCurrency?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isBestSelling?: InputMaybe<BoolFilter>;
  isNewReleased?: InputMaybe<BoolFilter>;
  isVirtual?: InputMaybe<BoolFilter>;
  isVisible?: InputMaybe<BoolFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  price?: InputMaybe<IntFilter>;
  purchaseLink?: InputMaybe<StringNullableFilter>;
  relatedActivities?: InputMaybe<ActivityListRelationFilter>;
  relatedStoryActivities?: InputMaybe<StoryActivityListRelationFilter>;
  shortDescription?: InputMaybe<StringNullableFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type PromoCode = {
  __typename?: 'PromoCode';
  _count?: Maybe<PromoCodeCount>;
  activities: Array<Activity>;
  code: Scalars['String']['output'];
  coins: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  promoCodeHistories: Array<PromoCodeHistory>;
  promoCodeToPromoCodeGroups: Array<PromoCodeToPromoCodeGroup>;
  startsAt: Scalars['DateTime']['output'];
  storyActivities: Array<StoryActivity>;
  updatedAt: Scalars['DateTime']['output'];
  workshops: Array<Workshop>;
};


export type PromoCodeActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type PromoCodePromoCodeHistoriesArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type PromoCodePromoCodeToPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type PromoCodeStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type PromoCodeWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type PromoCodeAvgAggregate = {
  __typename?: 'PromoCodeAvgAggregate';
  coins?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type PromoCodeCount = {
  __typename?: 'PromoCodeCount';
  activities: Scalars['Int']['output'];
  promoCodeHistories: Scalars['Int']['output'];
  promoCodeToPromoCodeGroups: Scalars['Int']['output'];
  storyActivities: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type PromoCodeCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type PromoCodeCountPromoCodeHistoriesArgs = {
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type PromoCodeCountPromoCodeToPromoCodeGroupsArgs = {
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type PromoCodeCountStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type PromoCodeCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type PromoCodeCountAggregate = {
  __typename?: 'PromoCodeCountAggregate';
  _all: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  coins: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  expiresAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  startsAt: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PromoCodeCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutActivitiesInput>>;
};

export type PromoCodeCreateNestedManyWithoutStoryActivitiesInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutStoryActivitiesInput>>;
};

export type PromoCodeCreateNestedManyWithoutWorkshopsInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutWorkshopsInput>>;
};

export type PromoCodeCreateNestedOneWithoutPromoCodeHistoriesInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeHistoriesInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeHistoriesInput>;
};

export type PromoCodeCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeCreateOrConnectWithoutActivitiesInput = {
  create: PromoCodeCreateWithoutActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutPromoCodeHistoriesInput = {
  create: PromoCodeCreateWithoutPromoCodeHistoriesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutStoryActivitiesInput = {
  create: PromoCodeCreateWithoutStoryActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateOrConnectWithoutWorkshopsInput = {
  create: PromoCodeCreateWithoutWorkshopsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeCreateWithoutActivitiesInput = {
  code: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutPromoCodeHistoriesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutPromoCodesInput>;
};

export type PromoCodeCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutPromoCodesInput>;
  code: Scalars['String']['input'];
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  expiresAt: Scalars['DateTime']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput>;
  startsAt?: InputMaybe<Scalars['DateTime']['input']>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutPromoCodesInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromoCodeGroup = {
  __typename?: 'PromoCodeGroup';
  _count?: Maybe<PromoCodeGroupCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  promoCodeToPromoCodeGroups: Array<PromoCodeToPromoCodeGroup>;
  updatedAt: Scalars['DateTime']['output'];
};


export type PromoCodeGroupPromoCodeToPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};

export type PromoCodeGroupAvgAggregate = {
  __typename?: 'PromoCodeGroupAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
};

export type PromoCodeGroupAvgOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupCount = {
  __typename?: 'PromoCodeGroupCount';
  promoCodeToPromoCodeGroups: Scalars['Int']['output'];
};


export type PromoCodeGroupCountPromoCodeToPromoCodeGroupsArgs = {
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};

export type PromoCodeGroupCountAggregate = {
  __typename?: 'PromoCodeGroupCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type PromoCodeGroupCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeGroupInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromoCodeGroupCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput = {
  connect?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeGroupCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeGroupCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput;
  where: PromoCodeGroupWhereUniqueInput;
};

export type PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromoCodeGroupGroupBy = {
  __typename?: 'PromoCodeGroupGroupBy';
  _avg?: Maybe<PromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeGroupSumAggregate>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PromoCodeGroupMaxAggregate = {
  __typename?: 'PromoCodeGroupMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeGroupMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupMinAggregate = {
  __typename?: 'PromoCodeGroupMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeGroupMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupOrderByWithAggregationInput = {
  _avg?: InputMaybe<PromoCodeGroupAvgOrderByAggregateInput>;
  _count?: InputMaybe<PromoCodeGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<PromoCodeGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<PromoCodeGroupMinOrderByAggregateInput>;
  _sum?: InputMaybe<PromoCodeGroupSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupRelationFilter = {
  is?: InputMaybe<PromoCodeGroupWhereInput>;
  isNot?: InputMaybe<PromoCodeGroupWhereInput>;
};

export enum PromoCodeGroupScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  LIMIT = 'limit',
  NAME = 'name',
  UPDATEDAT = 'updatedAt'
}

export type PromoCodeGroupScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PromoCodeGroupScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<PromoCodeGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PromoCodeGroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<IntWithAggregatesFilter>;
  limit?: InputMaybe<IntWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PromoCodeGroupSumAggregate = {
  __typename?: 'PromoCodeGroupSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeGroupSumOrderByAggregateInput = {
  id?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
};

export type PromoCodeGroupUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeGroupNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeGroupUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput = {
  connect?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeGroupCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput>;
  update?: InputMaybe<PromoCodeGroupUpdateWithoutPromoCodeToPromoCodeGroupsInput>;
  upsert?: InputMaybe<PromoCodeGroupUpsertWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeGroupUpdateWithoutPromoCodeToPromoCodeGroupsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeGroupUpsertWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeGroupCreateWithoutPromoCodeToPromoCodeGroupsInput;
  update: PromoCodeGroupUpdateWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeGroupWhereInput = {
  AND?: InputMaybe<Array<PromoCodeGroupWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeGroupWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  limit?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromoCodeGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoCodeHistory = {
  __typename?: 'PromoCodeHistory';
  coins: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  promoCode: PromoCode;
  promoCodeId: Scalars['Int']['output'];
  student: Student;
  studentId: Scalars['Int']['output'];
};

export type PromoCodeHistoryAvgAggregate = {
  __typename?: 'PromoCodeHistoryAvgAggregate';
  coins?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  promoCodeId?: Maybe<Scalars['Float']['output']>;
  studentId?: Maybe<Scalars['Float']['output']>;
};

export type PromoCodeHistoryCountAggregate = {
  __typename?: 'PromoCodeHistoryCountAggregate';
  _all: Scalars['Int']['output'];
  coins: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  promoCodeId: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
};

export type PromoCodeHistoryCreateInput = {
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeHistoriesInput;
  student: StudentCreateNestedOneWithoutPromoCodeHistoryInput;
};

export type PromoCodeHistoryCreateManyPromoCodeInput = {
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['Int']['input'];
};

export type PromoCodeHistoryCreateManyPromoCodeInputEnvelope = {
  data: Array<PromoCodeHistoryCreateManyPromoCodeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromoCodeHistoryCreateManyStudentInput = {
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  promoCodeId: Scalars['Int']['input'];
};

export type PromoCodeHistoryCreateManyStudentInputEnvelope = {
  data: Array<PromoCodeHistoryCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromoCodeHistoryCreateNestedManyWithoutPromoCodeInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyPromoCodeInputEnvelope>;
};

export type PromoCodeHistoryCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyStudentInputEnvelope>;
};

export type PromoCodeHistoryCreateOrConnectWithoutPromoCodeInput = {
  create: PromoCodeHistoryCreateWithoutPromoCodeInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryCreateOrConnectWithoutStudentInput = {
  create: PromoCodeHistoryCreateWithoutStudentInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryCreateWithoutPromoCodeInput = {
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  student: StudentCreateNestedOneWithoutPromoCodeHistoryInput;
};

export type PromoCodeHistoryCreateWithoutStudentInput = {
  coins?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeHistoriesInput;
};

export type PromoCodeHistoryListRelationFilter = {
  every?: InputMaybe<PromoCodeHistoryWhereInput>;
  none?: InputMaybe<PromoCodeHistoryWhereInput>;
  some?: InputMaybe<PromoCodeHistoryWhereInput>;
};

export type PromoCodeHistoryMaxAggregate = {
  __typename?: 'PromoCodeHistoryMaxAggregate';
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  promoCodeId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeHistoryMinAggregate = {
  __typename?: 'PromoCodeHistoryMinAggregate';
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  promoCodeId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromoCodeHistoryOrderByWithRelationInput = {
  coins?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  promoCode?: InputMaybe<PromoCodeOrderByWithRelationInput>;
  promoCodeId?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum PromoCodeHistoryScalarFieldEnum {
  COINS = 'coins',
  CREATEDAT = 'createdAt',
  ID = 'id',
  PROMOCODEID = 'promoCodeId',
  STUDENTID = 'studentId'
}

export type PromoCodeHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  promoCodeId?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PromoCodeHistorySumAggregate = {
  __typename?: 'PromoCodeHistorySumAggregate';
  coins?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  promoCodeId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeHistoryUpdateInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeHistoriesNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutPromoCodeHistoryNestedInput>;
};

export type PromoCodeHistoryUpdateManyMutationInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeHistoryUpdateManyWithWhereWithoutPromoCodeInput = {
  data: PromoCodeHistoryUpdateManyMutationInput;
  where: PromoCodeHistoryScalarWhereInput;
};

export type PromoCodeHistoryUpdateManyWithWhereWithoutStudentInput = {
  data: PromoCodeHistoryUpdateManyMutationInput;
  where: PromoCodeHistoryScalarWhereInput;
};

export type PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyPromoCodeInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeHistoryUpdateWithWhereUniqueWithoutPromoCodeInput>>;
  updateMany?: InputMaybe<Array<PromoCodeHistoryUpdateManyWithWhereWithoutPromoCodeInput>>;
  upsert?: InputMaybe<Array<PromoCodeHistoryUpsertWithWhereUniqueWithoutPromoCodeInput>>;
};

export type PromoCodeHistoryUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeHistoryCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<PromoCodeHistoryCreateWithoutStudentInput>>;
  createMany?: InputMaybe<PromoCodeHistoryCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeHistoryUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<PromoCodeHistoryUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<PromoCodeHistoryUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type PromoCodeHistoryUpdateWithWhereUniqueWithoutPromoCodeInput = {
  data: PromoCodeHistoryUpdateWithoutPromoCodeInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryUpdateWithWhereUniqueWithoutStudentInput = {
  data: PromoCodeHistoryUpdateWithoutStudentInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryUpdateWithoutPromoCodeInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutPromoCodeHistoryNestedInput>;
};

export type PromoCodeHistoryUpdateWithoutStudentInput = {
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeHistoriesNestedInput>;
};

export type PromoCodeHistoryUpsertWithWhereUniqueWithoutPromoCodeInput = {
  create: PromoCodeHistoryCreateWithoutPromoCodeInput;
  update: PromoCodeHistoryUpdateWithoutPromoCodeInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryUpsertWithWhereUniqueWithoutStudentInput = {
  create: PromoCodeHistoryCreateWithoutStudentInput;
  update: PromoCodeHistoryUpdateWithoutStudentInput;
  where: PromoCodeHistoryWhereUniqueInput;
};

export type PromoCodeHistoryWhereInput = {
  AND?: InputMaybe<Array<PromoCodeHistoryWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeHistoryWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeHistoryWhereInput>>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  promoCode?: InputMaybe<PromoCodeRelationFilter>;
  promoCodeId?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type PromoCodeHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PromoCodeListRelationFilter = {
  every?: InputMaybe<PromoCodeWhereInput>;
  none?: InputMaybe<PromoCodeWhereInput>;
  some?: InputMaybe<PromoCodeWhereInput>;
};

export type PromoCodeMaxAggregate = {
  __typename?: 'PromoCodeMaxAggregate';
  code?: Maybe<Scalars['String']['output']>;
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeMinAggregate = {
  __typename?: 'PromoCodeMinAggregate';
  code?: Maybe<Scalars['String']['output']>;
  coins?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  startsAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromoCodeOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  code?: InputMaybe<SortOrder>;
  coins?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryOrderByRelationAggregateInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupOrderByRelationAggregateInput>;
  startsAt?: InputMaybe<SortOrder>;
  storyActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type PromoCodeRelationFilter = {
  is?: InputMaybe<PromoCodeWhereInput>;
  isNot?: InputMaybe<PromoCodeWhereInput>;
};

export enum PromoCodeScalarFieldEnum {
  CODE = 'code',
  COINS = 'coins',
  CREATEDAT = 'createdAt',
  EXPIRESAT = 'expiresAt',
  ID = 'id',
  ISACTIVE = 'isActive',
  STARTSAT = 'startsAt',
  UPDATEDAT = 'updatedAt'
}

export type PromoCodeScalarWhereInput = {
  AND?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromoCodeSumAggregate = {
  __typename?: 'PromoCodeSumAggregate';
  coins?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeToPromoCodeGroup = {
  __typename?: 'PromoCodeToPromoCodeGroup';
  A: Scalars['Int']['output'];
  B: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  promoCode: PromoCode;
  promoCodeGroup: PromoCodeGroup;
};

export type PromoCodeToPromoCodeGroupAbCompoundUniqueInput = {
  A: Scalars['Int']['input'];
  B: Scalars['Int']['input'];
};

export type PromoCodeToPromoCodeGroupAvgAggregate = {
  __typename?: 'PromoCodeToPromoCodeGroupAvgAggregate';
  A?: Maybe<Scalars['Float']['output']>;
  B?: Maybe<Scalars['Float']['output']>;
};

export type PromoCodeToPromoCodeGroupAvgOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupCountAggregate = {
  __typename?: 'PromoCodeToPromoCodeGroupCountAggregate';
  A: Scalars['Int']['output'];
  B: Scalars['Int']['output'];
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
};

export type PromoCodeToPromoCodeGroupCountOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
  promoCodeGroup: PromoCodeGroupCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeToPromoCodeGroupCreateManyInput = {
  A: Scalars['Int']['input'];
  B: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInput = {
  A: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInputEnvelope = {
  data: Array<PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeInput = {
  B: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PromoCodeToPromoCodeGroupCreateManyPromoCodeInputEnvelope = {
  data: Array<PromoCodeToPromoCodeGroupCreateManyPromoCodeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeGroupInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeGroupInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInputEnvelope>;
};

export type PromoCodeToPromoCodeGroupCreateNestedManyWithoutPromoCodeInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeInputEnvelope>;
};

export type PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeGroupInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  promoCode: PromoCodeCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  promoCodeGroup: PromoCodeGroupCreateNestedOneWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeToPromoCodeGroupGroupBy = {
  __typename?: 'PromoCodeToPromoCodeGroupGroupBy';
  A: Scalars['Int']['output'];
  B: Scalars['Int']['output'];
  _avg?: Maybe<PromoCodeToPromoCodeGroupAvgAggregate>;
  _count?: Maybe<PromoCodeToPromoCodeGroupCountAggregate>;
  _max?: Maybe<PromoCodeToPromoCodeGroupMaxAggregate>;
  _min?: Maybe<PromoCodeToPromoCodeGroupMinAggregate>;
  _sum?: Maybe<PromoCodeToPromoCodeGroupSumAggregate>;
  createdAt: Scalars['DateTime']['output'];
};

export type PromoCodeToPromoCodeGroupListRelationFilter = {
  every?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
  none?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
  some?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};

export type PromoCodeToPromoCodeGroupMaxAggregate = {
  __typename?: 'PromoCodeToPromoCodeGroupMaxAggregate';
  A?: Maybe<Scalars['Int']['output']>;
  B?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeToPromoCodeGroupMaxOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupMinAggregate = {
  __typename?: 'PromoCodeToPromoCodeGroupMinAggregate';
  A?: Maybe<Scalars['Int']['output']>;
  B?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PromoCodeToPromoCodeGroupMinOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupOrderByWithAggregationInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<PromoCodeToPromoCodeGroupAvgOrderByAggregateInput>;
  _count?: InputMaybe<PromoCodeToPromoCodeGroupCountOrderByAggregateInput>;
  _max?: InputMaybe<PromoCodeToPromoCodeGroupMaxOrderByAggregateInput>;
  _min?: InputMaybe<PromoCodeToPromoCodeGroupMinOrderByAggregateInput>;
  _sum?: InputMaybe<PromoCodeToPromoCodeGroupSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupOrderByWithRelationInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  promoCode?: InputMaybe<PromoCodeOrderByWithRelationInput>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupOrderByWithRelationInput>;
};

export enum PromoCodeToPromoCodeGroupScalarFieldEnum {
  A = 'A',
  B = 'B',
  CREATEDAT = 'createdAt'
}

export type PromoCodeToPromoCodeGroupScalarWhereInput = {
  A?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  B?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
};

export type PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput = {
  A?: InputMaybe<IntWithAggregatesFilter>;
  AND?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>>;
  B?: InputMaybe<IntWithAggregatesFilter>;
  NOT?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type PromoCodeToPromoCodeGroupSumAggregate = {
  __typename?: 'PromoCodeToPromoCodeGroupSumAggregate';
  A?: Maybe<Scalars['Int']['output']>;
  B?: Maybe<Scalars['Int']['output']>;
};

export type PromoCodeToPromoCodeGroupSumOrderByAggregateInput = {
  A?: InputMaybe<SortOrder>;
  B?: InputMaybe<SortOrder>;
};

export type PromoCodeToPromoCodeGroupUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
};

export type PromoCodeToPromoCodeGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeGroupInput = {
  data: PromoCodeToPromoCodeGroupUpdateManyMutationInput;
  where: PromoCodeToPromoCodeGroupScalarWhereInput;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeInput = {
  data: PromoCodeToPromoCodeGroupUpdateManyMutationInput;
  where: PromoCodeToPromoCodeGroupScalarWhereInput;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeGroupNestedInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeGroupInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeGroupInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeGroupInput>>;
  updateMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeGroupInput>>;
  upsert?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeGroupInput>>;
};

export type PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput = {
  connect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateOrConnectWithoutPromoCodeInput>>;
  create?: InputMaybe<Array<PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput>>;
  createMany?: InputMaybe<PromoCodeToPromoCodeGroupCreateManyPromoCodeInputEnvelope>;
  delete?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeInput>>;
  updateMany?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpdateManyWithWhereWithoutPromoCodeInput>>;
  upsert?: InputMaybe<Array<PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeInput>>;
};

export type PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeGroupInput = {
  data: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeGroupInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupUpdateWithWhereUniqueWithoutPromoCodeInput = {
  data: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeGroupInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCode?: InputMaybe<PromoCodeUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
};

export type PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput>;
};

export type PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeGroupInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeGroupInput;
  update: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeGroupInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupUpsertWithWhereUniqueWithoutPromoCodeInput = {
  create: PromoCodeToPromoCodeGroupCreateWithoutPromoCodeInput;
  update: PromoCodeToPromoCodeGroupUpdateWithoutPromoCodeInput;
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};

export type PromoCodeToPromoCodeGroupWhereInput = {
  A?: InputMaybe<IntFilter>;
  AND?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereInput>>;
  B?: InputMaybe<IntFilter>;
  NOT?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeToPromoCodeGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  promoCode?: InputMaybe<PromoCodeRelationFilter>;
  promoCodeGroup?: InputMaybe<PromoCodeGroupRelationFilter>;
};

export type PromoCodeToPromoCodeGroupWhereUniqueInput = {
  A_B?: InputMaybe<PromoCodeToPromoCodeGroupAbCompoundUniqueInput>;
};

export type PromoCodeUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeUpdateManyWithWhereWithoutActivitiesInput = {
  data: PromoCodeUpdateManyMutationInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithWhereWithoutStoryActivitiesInput = {
  data: PromoCodeUpdateManyMutationInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithWhereWithoutWorkshopsInput = {
  data: PromoCodeUpdateManyMutationInput;
  where: PromoCodeScalarWhereInput;
};

export type PromoCodeUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<PromoCodeUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<PromoCodeUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type PromoCodeUpdateManyWithoutStoryActivitiesNestedInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutStoryActivitiesInput>>;
  delete?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeUpdateWithWhereUniqueWithoutStoryActivitiesInput>>;
  updateMany?: InputMaybe<Array<PromoCodeUpdateManyWithWhereWithoutStoryActivitiesInput>>;
  upsert?: InputMaybe<Array<PromoCodeUpsertWithWhereUniqueWithoutStoryActivitiesInput>>;
};

export type PromoCodeUpdateManyWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromoCodeCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<PromoCodeCreateWithoutWorkshopsInput>>;
  delete?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromoCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromoCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromoCodeUpdateWithWhereUniqueWithoutWorkshopsInput>>;
  updateMany?: InputMaybe<Array<PromoCodeUpdateManyWithWhereWithoutWorkshopsInput>>;
  upsert?: InputMaybe<Array<PromoCodeUpsertWithWhereUniqueWithoutWorkshopsInput>>;
};

export type PromoCodeUpdateOneRequiredWithoutPromoCodeHistoriesNestedInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeHistoriesInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeHistoriesInput>;
  update?: InputMaybe<PromoCodeUpdateWithoutPromoCodeHistoriesInput>;
  upsert?: InputMaybe<PromoCodeUpsertWithoutPromoCodeHistoriesInput>;
};

export type PromoCodeUpdateOneRequiredWithoutPromoCodeToPromoCodeGroupsNestedInput = {
  connect?: InputMaybe<PromoCodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromoCodeCreateOrConnectWithoutPromoCodeToPromoCodeGroupsInput>;
  create?: InputMaybe<PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput>;
  update?: InputMaybe<PromoCodeUpdateWithoutPromoCodeToPromoCodeGroupsInput>;
  upsert?: InputMaybe<PromoCodeUpsertWithoutPromoCodeToPromoCodeGroupsInput>;
};

export type PromoCodeUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: PromoCodeUpdateWithoutActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithWhereUniqueWithoutStoryActivitiesInput = {
  data: PromoCodeUpdateWithoutStoryActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithWhereUniqueWithoutWorkshopsInput = {
  data: PromoCodeUpdateWithoutWorkshopsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpdateWithoutActivitiesInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutPromoCodeHistoriesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutPromoCodeToPromoCodeGroupsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutPromoCodesNestedInput>;
};

export type PromoCodeUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutPromoCodesNestedInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  coins?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expiresAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryUpdateManyWithoutPromoCodeNestedInput>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupUpdateManyWithoutPromoCodeNestedInput>;
  startsAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutPromoCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromoCodeUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: PromoCodeCreateWithoutActivitiesInput;
  update: PromoCodeUpdateWithoutActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpsertWithWhereUniqueWithoutStoryActivitiesInput = {
  create: PromoCodeCreateWithoutStoryActivitiesInput;
  update: PromoCodeUpdateWithoutStoryActivitiesInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpsertWithWhereUniqueWithoutWorkshopsInput = {
  create: PromoCodeCreateWithoutWorkshopsInput;
  update: PromoCodeUpdateWithoutWorkshopsInput;
  where: PromoCodeWhereUniqueInput;
};

export type PromoCodeUpsertWithoutPromoCodeHistoriesInput = {
  create: PromoCodeCreateWithoutPromoCodeHistoriesInput;
  update: PromoCodeUpdateWithoutPromoCodeHistoriesInput;
};

export type PromoCodeUpsertWithoutPromoCodeToPromoCodeGroupsInput = {
  create: PromoCodeCreateWithoutPromoCodeToPromoCodeGroupsInput;
  update: PromoCodeUpdateWithoutPromoCodeToPromoCodeGroupsInput;
};

export type PromoCodeWhereInput = {
  AND?: InputMaybe<Array<PromoCodeWhereInput>>;
  NOT?: InputMaybe<Array<PromoCodeWhereInput>>;
  OR?: InputMaybe<Array<PromoCodeWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  coins?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expiresAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  promoCodeHistories?: InputMaybe<PromoCodeHistoryListRelationFilter>;
  promoCodeToPromoCodeGroups?: InputMaybe<PromoCodeToPromoCodeGroupListRelationFilter>;
  startsAt?: InputMaybe<DateTimeFilter>;
  storyActivities?: InputMaybe<StoryActivityListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type PromoCodeWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type PublicActivity = {
  __typename?: 'PublicActivity';
  challengeStatement?: Maybe<Scalars['String']['output']>;
  commentsCount?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  difficultyLevel: Scalars['String']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  instructionImage?: Maybe<Scalars['String']['output']>;
  instructionText?: Maybe<Scalars['String']['output']>;
  isIndexable: Scalars['Boolean']['output'];
  jsonLD?: Maybe<Scalars['JSON']['output']>;
  materialsNeeded?: Maybe<Scalars['String']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skill?: Maybe<PublicSkill>;
  slug: Scalars['String']['output'];
  tags?: Maybe<Array<PublicTag>>;
  timeRequired: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

export type PublicProduct = {
  __typename?: 'PublicProduct';
  activities?: Maybe<Array<PublicActivity>>;
  age?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discountPrice?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Array<Scalars['String']['output']>>;
  inCurrency: Scalars['Int']['output'];
  isBestSelling: Scalars['Boolean']['output'];
  isNewReleased: Scalars['Boolean']['output'];
  isVirtual: Scalars['Boolean']['output'];
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  purchaseLink?: Maybe<Scalars['String']['output']>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  skill?: Maybe<PublicSkill>;
  slug: Scalars['String']['output'];
  video?: Maybe<Scalars['String']['output']>;
};

export type PublicSkill = {
  __typename?: 'PublicSkill';
  bundle?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type PublicStoryActivity = {
  __typename?: 'PublicStoryActivity';
  audio?: Maybe<Scalars['String']['output']>;
  challengeStatement: Scalars['String']['output'];
  coinsToSpend: Scalars['Int']['output'];
  content: Scalars['String']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  skill: PublicSkill;
  slug: Scalars['String']['output'];
  timeRequired: Scalars['String']['output'];
  title: Scalars['String']['output'];
  video?: Maybe<Scalars['String']['output']>;
  xps: Scalars['Int']['output'];
};

export type PublicTag = {
  __typename?: 'PublicTag';
  buyScript?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  jsonLD?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  productScript?: Maybe<Scalars['String']['output']>;
  seoContent?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};

export type PublicWorkshopOutput = {
  __typename?: 'PublicWorkshopOutput';
  address?: Maybe<Scalars['String']['output']>;
  ageGroup?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayTime?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  feesInCurrency?: Maybe<Scalars['Int']['output']>;
  feesWithKit?: Maybe<Scalars['Int']['output']>;
  feesWithoutKit?: Maybe<Scalars['Int']['output']>;
  footerImageLarge?: Maybe<Scalars['String']['output']>;
  footerImageSmall?: Maybe<Scalars['String']['output']>;
  headerImageLarge?: Maybe<Scalars['String']['output']>;
  headerImageSmall?: Maybe<Scalars['String']['output']>;
  headerText?: Maybe<Scalars['String']['output']>;
  headerVideo?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  insiderActivities?: Maybe<Array<PublicActivity>>;
  isOpen: Scalars['Boolean']['output'];
  liveUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  product?: Maybe<PublicProduct>;
  registrationUrl?: Maybe<Scalars['String']['output']>;
  relatedActivities?: Maybe<Array<PublicActivity>>;
  schoolId: Scalars['Int']['output'];
  skillId: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  standards?: Maybe<Array<Standard>>;
  startDate: Scalars['DateTime']['output'];
  tags?: Maybe<Array<PublicTag>>;
  trainer?: Maybe<User>;
  trainerId?: Maybe<Scalars['Int']['output']>;
  trainerImage?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type PublicWorkshopsOutput = {
  __typename?: 'PublicWorkshopsOutput';
  description?: Maybe<Scalars['String']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  activities: Array<Activity>;
  activity?: Maybe<Activity>;
  activityBanner: ActivityBanner;
  activityBanners: Array<ActivityBanner>;
  activityQuestion?: Maybe<ActivityQuestion>;
  activityQuestions: Array<ActivityQuestion>;
  adminLeaderboard?: Maybe<Array<AdminLeaderboardResponse>>;
  aggregateActivity: AggregateActivity;
  aggregateBadge: AggregateBadge;
  aggregateComment: AggregateComment;
  aggregateDailyActivity: AggregateDailyActivity;
  aggregateGamePointType: AggregateGamePointType;
  aggregateLevel: AggregateLevel;
  aggregateOrder: AggregateOrder;
  aggregatePrivacyPolicy: AggregatePrivacyPolicy;
  aggregatePrivacyPolicyUpdate: AggregatePrivacyPolicyUpdate;
  aggregateProduct: AggregateProduct;
  aggregatePromoCode: AggregatePromoCode;
  aggregatePromoCodeGroup: AggregatePromoCodeGroup;
  aggregatePromoCodeHistory: AggregatePromoCodeHistory;
  aggregatePromoCodeToPromoCodeGroup: AggregatePromoCodeToPromoCodeGroup;
  aggregateReferralCode: AggregateReferralCode;
  aggregateReferralCodeHistory: AggregateReferralCodeHistory;
  aggregateReport: AggregateReport;
  aggregateSamskara: AggregateSamskara;
  aggregateSamskaraCategory: AggregateSamskaraCategory;
  aggregateSchool: AggregateSchool;
  aggregateSchoolUpdate: AggregateSchoolUpdate;
  aggregateSkill: AggregateSkill;
  aggregateStandard: AggregateStandard;
  aggregateStory: AggregateStory;
  aggregateStoryActivity: AggregateStoryActivity;
  aggregateStoryTag: AggregateStoryTag;
  aggregateStudent: AggregateStudent;
  aggregateStudentGameLog: AggregateStudentGameLog;
  aggregateStudentRecord: AggregateStudentRecord;
  aggregateTag: AggregateTag;
  aggregateTrigger: AggregateTrigger;
  aggregateUser: AggregateUser;
  aggregateUserLog: AggregateUserLog;
  aggregateWorkshop: AggregateWorkshop;
  aggregateWorkshopRegistration: AggregateWorkshopRegistration;
  badge?: Maybe<Badge>;
  badges: Array<Badge>;
  comment?: Maybe<Comment>;
  comments: Array<Comment>;
  dailyActivities: Array<DailyActivity>;
  dailyActivity?: Maybe<DailyActivity>;
  exportStudentGameLogs: Scalars['JSON']['output'];
  featuredStudentActivities: Array<PublicActivity>;
  featuredStudentStories: Array<StudentStoryOutput>;
  findFirstLevel?: Maybe<Level>;
  findFirstProductBanner?: Maybe<ProductBanner>;
  findFirstPromoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  findFirstStudentRecord?: Maybe<StudentRecord>;
  findFirstUserLog?: Maybe<UserLog>;
  findManyPrivacyPolicy: Array<PrivacyPolicy>;
  findManyPrivacyPolicyUpdate: Array<PrivacyPolicyUpdate>;
  gamePointType?: Maybe<GamePointType>;
  gamePointTypes: Array<GamePointType>;
  groupByDailyActivity: Array<DailyActivityGroupBy>;
  groupByPromoCodeGroup: Array<PromoCodeGroupGroupBy>;
  groupByPromoCodeToPromoCodeGroup: Array<PromoCodeToPromoCodeGroupGroupBy>;
  level?: Maybe<Level>;
  levels: Array<Level>;
  me?: Maybe<MeResponse>;
  order?: Maybe<Order>;
  orders: Array<Order>;
  privacyPolicy?: Maybe<PrivacyPolicy>;
  privacyPolicyUpdate?: Maybe<PrivacyPolicyUpdate>;
  product?: Maybe<Product>;
  productBanner?: Maybe<ProductBanner>;
  productBanners: Array<ProductBanner>;
  products: Array<Product>;
  promoCode?: Maybe<PromoCode>;
  promoCodeGroup?: Maybe<PromoCodeGroup>;
  promoCodeGroups: Array<PromoCodeGroup>;
  promoCodeHistories: Array<PromoCodeHistory>;
  promoCodeHistory?: Maybe<PromoCodeHistory>;
  promoCodeToPromoCodeGroup?: Maybe<PromoCodeToPromoCodeGroup>;
  promoCodeToPromoCodeGroups: Array<PromoCodeToPromoCodeGroup>;
  promoCodes: Array<PromoCode>;
  publicActivities: Array<PublicActivity>;
  publicActivity?: Maybe<PublicActivity>;
  publicActivityBanners: Array<ActivityBanner>;
  publicPastWorkshops: Array<PublicWorkshopsOutput>;
  publicProductBanners: Array<ProductBanner>;
  publicProducts: Array<PublicProduct>;
  publicSkill: PublicSkill;
  publicSkills: Array<PublicSkill>;
  publicStoryActivities: Array<PublicStoryActivity>;
  publicStoryActivity?: Maybe<PublicStoryActivity>;
  publicTag?: Maybe<PublicTag>;
  publicUpcomingWorkshops: Array<PublicWorkshopsOutput>;
  publicWorkshop?: Maybe<PublicWorkshopOutput>;
  referralCode?: Maybe<ReferralCode>;
  referralCodeHistories: Array<ReferralCodeHistory>;
  referralCodeHistory?: Maybe<ReferralCodeHistory>;
  referralCodes: Array<ReferralCode>;
  report?: Maybe<Report>;
  reports: Array<Report>;
  samskara?: Maybe<Samskara>;
  samskaraCategories: Array<SamskaraCategory>;
  samskaraCategory?: Maybe<SamskaraCategory>;
  samskaras: Array<Samskara>;
  school?: Maybe<School>;
  schoolUpdate?: Maybe<SchoolUpdate>;
  schoolUpdates: Array<SchoolUpdate>;
  schools: Array<School>;
  searchActivities: Array<SearchActivity>;
  sitemap: Array<Sitemap>;
  skill?: Maybe<Skill>;
  skills: Array<Skill>;
  standard?: Maybe<Standard>;
  standards: Array<Standard>;
  stories: Array<Story>;
  story?: Maybe<Story>;
  storyActivities: Array<StoryActivity>;
  storyActivity?: Maybe<StoryActivity>;
  storyBanner?: Maybe<StoryBanner>;
  storyBanners: Array<StoryBanner>;
  storyTag?: Maybe<StoryTag>;
  storyTags: Array<StoryTag>;
  student?: Maybe<Student>;
  studentActivities: Array<PublicActivity>;
  studentActivity?: Maybe<FindStudentUniqueActivityOutput>;
  studentActivityAccess?: Maybe<FindStudentActivityAccessOutput>;
  studentActivityComments: Array<FindStudentActivityCommentsOutput>;
  studentActivitySelfComments: Array<FindStudentActivityCommentsOutput>;
  studentCoinLogs?: Maybe<StudentCoinLogsResponse>;
  studentDailyActivity?: Maybe<StudentDailyActivity>;
  studentDailyChallenge?: Maybe<StudentDailyChallenge>;
  studentGameLog?: Maybe<StudentGameLog>;
  studentGameLogs: Array<StudentGameLog>;
  studentGamePoints?: Maybe<StudentGamePointsResponse>;
  studentLeaderboard?: Maybe<Array<StudentLeaderboardResponse>>;
  studentNextLevelRequirement?: Maybe<StudentNextLevelRequirementResponse>;
  studentNextRandomActivity?: Maybe<FindStudentUniqueActivityOutput>;
  studentPastWorkshops: Array<StudentWorkshopsOutput>;
  studentPendingActivities: Array<PublicActivity>;
  studentProduct?: Maybe<PublicProduct>;
  studentRecord?: Maybe<StudentRecord>;
  studentRecords: Array<StudentRecord>;
  studentReferralCode: StudentReferralCodeResponse;
  studentRegisteredWorkshops: Array<StudentWorkshopsOutput>;
  studentReward?: Maybe<StudentRewardResponse>;
  studentSkill?: Maybe<PublicSkill>;
  studentSkillXps?: Maybe<StudentSkillXpsResponse>;
  studentSkills: Array<PublicSkill>;
  studentStories: Array<StudentStoryOutput>;
  studentStory?: Maybe<StudentStoryOutput>;
  studentStoryActivities: Array<StudentStoryActivity>;
  studentStoryActivity?: Maybe<StoryActivity>;
  studentStoryActivityAccess?: Maybe<FindStudentStoryActivityAccessResponse>;
  studentStoryBanners: Array<StoryBanner>;
  studentStoryTags: Array<StoryTag>;
  studentTopPerformerMonthLeaderboard?: Maybe<Array<StudentLeaderboardResponse>>;
  studentTopPerformerWeekLeaderboard?: Maybe<Array<StudentLeaderboardResponse>>;
  studentUpcomingWorkshops: Array<StudentUpcomingWorkshopOutput>;
  studentWorkshopAccess?: Maybe<FindStudentWorkshopAccessResponse>;
  students: Array<Student>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  trigger?: Maybe<Trigger>;
  triggers: Array<Trigger>;
  user?: Maybe<User>;
  userLog?: Maybe<UserLog>;
  userLogs: Array<UserLog>;
  users: Array<User>;
  workshop?: Maybe<Workshop>;
  workshopRegistration?: Maybe<WorkshopRegistration>;
  workshopRegistrations: Array<WorkshopRegistration>;
  workshops: Array<Workshop>;
};


export type QueryActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryActivityBannerArgs = {
  where: ActivityBannerWhereUniqueInput;
};


export type QueryActivityBannersArgs = {
  cursor?: InputMaybe<ActivityBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityBannerWhereInput>;
};


export type QueryActivityQuestionArgs = {
  where: ActivityQuestionWhereUniqueInput;
};


export type QueryActivityQuestionsArgs = {
  cursor?: InputMaybe<ActivityQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type QueryAdminLeaderboardArgs = {
  skip: Scalars['Int']['input'];
  starsOrderBy?: InputMaybe<SortOrder>;
  take: Scalars['Int']['input'];
  where: AdminLeaderboardInput;
  xpsOrderBy?: InputMaybe<SortOrder>;
};


export type QueryAggregateActivityArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryAggregateBadgeArgs = {
  cursor?: InputMaybe<BadgeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<BadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BadgeWhereInput>;
};


export type QueryAggregateCommentArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryAggregateDailyActivityArgs = {
  cursor?: InputMaybe<DailyActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type QueryAggregateGamePointTypeArgs = {
  cursor?: InputMaybe<GamePointTypeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<GamePointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GamePointTypeWhereInput>;
};


export type QueryAggregateLevelArgs = {
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LevelWhereInput>;
};


export type QueryAggregateOrderArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryAggregatePrivacyPolicyArgs = {
  cursor?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PrivacyPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrivacyPolicyWhereInput>;
};


export type QueryAggregatePrivacyPolicyUpdateArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type QueryAggregateProductArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAggregatePromoCodeArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type QueryAggregatePromoCodeGroupArgs = {
  cursor?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeGroupWhereInput>;
};


export type QueryAggregatePromoCodeHistoryArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type QueryAggregatePromoCodeToPromoCodeGroupArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryAggregateReferralCodeArgs = {
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type QueryAggregateReferralCodeHistoryArgs = {
  cursor?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReferralCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferralCodeHistoryWhereInput>;
};


export type QueryAggregateReportArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QueryAggregateSamskaraArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SamskaraWhereInput>;
};


export type QueryAggregateSamskaraCategoryArgs = {
  cursor?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SamskaraCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SamskaraCategoryWhereInput>;
};


export type QueryAggregateSchoolArgs = {
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolWhereInput>;
};


export type QueryAggregateSchoolUpdateArgs = {
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type QueryAggregateSkillArgs = {
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SkillWhereInput>;
};


export type QueryAggregateStandardArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type QueryAggregateStoryArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryWhereInput>;
};


export type QueryAggregateStoryActivityArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type QueryAggregateStoryTagArgs = {
  cursor?: InputMaybe<StoryTagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StoryTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryTagWhereInput>;
};


export type QueryAggregateStudentArgs = {
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentWhereInput>;
};


export type QueryAggregateStudentGameLogArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type QueryAggregateStudentRecordArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type QueryAggregateTagArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryAggregateTriggerArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TriggerWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateUserLogArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type QueryAggregateWorkshopArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};


export type QueryAggregateWorkshopRegistrationArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};


export type QueryBadgeArgs = {
  where: BadgeWhereUniqueInput;
};


export type QueryBadgesArgs = {
  cursor?: InputMaybe<BadgeWhereUniqueInput>;
  distinct?: InputMaybe<Array<BadgeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<BadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BadgeWhereInput>;
};


export type QueryCommentArgs = {
  where: CommentWhereUniqueInput;
};


export type QueryCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type QueryDailyActivitiesArgs = {
  cursor?: InputMaybe<DailyActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<DailyActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type QueryDailyActivityArgs = {
  where: DailyActivityWhereUniqueInput;
};


export type QueryExportStudentGameLogsArgs = {
  where: ExportGameLogsInput;
};


export type QueryFindFirstLevelArgs = {
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  distinct?: InputMaybe<Array<LevelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LevelWhereInput>;
};


export type QueryFindFirstProductBannerArgs = {
  cursor?: InputMaybe<ProductBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductBannerWhereInput>;
};


export type QueryFindFirstPromoCodeToPromoCodeGroupArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryFindFirstStudentRecordArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type QueryFindFirstUserLogArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type QueryFindManyPrivacyPolicyArgs = {
  cursor?: InputMaybe<PrivacyPolicyWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrivacyPolicyWhereInput>;
};


export type QueryFindManyPrivacyPolicyUpdateArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type QueryGamePointTypeArgs = {
  where: GamePointTypeWhereUniqueInput;
};


export type QueryGamePointTypesArgs = {
  cursor?: InputMaybe<GamePointTypeWhereUniqueInput>;
  distinct?: InputMaybe<Array<GamePointTypeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<GamePointTypeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GamePointTypeWhereInput>;
};


export type QueryGroupByDailyActivityArgs = {
  by: Array<DailyActivityScalarFieldEnum>;
  having?: InputMaybe<DailyActivityScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type QueryGroupByPromoCodeGroupArgs = {
  by: Array<PromoCodeGroupScalarFieldEnum>;
  having?: InputMaybe<PromoCodeGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeGroupWhereInput>;
};


export type QueryGroupByPromoCodeToPromoCodeGroupArgs = {
  by: Array<PromoCodeToPromoCodeGroupScalarFieldEnum>;
  having?: InputMaybe<PromoCodeToPromoCodeGroupScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryLevelArgs = {
  where: LevelWhereUniqueInput;
};


export type QueryLevelsArgs = {
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  distinct?: InputMaybe<Array<LevelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<LevelWhereInput>;
};


export type QueryOrderArgs = {
  where: OrderWhereUniqueInput;
};


export type QueryOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type QueryPrivacyPolicyArgs = {
  where: PrivacyPolicyWhereUniqueInput;
};


export type QueryPrivacyPolicyUpdateArgs = {
  where: PrivacyPolicyUpdateWhereUniqueInput;
};


export type QueryProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryProductBannerArgs = {
  where: ProductBannerWhereUniqueInput;
};


export type QueryProductBannersArgs = {
  cursor?: InputMaybe<ProductBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductBannerWhereInput>;
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryPromoCodeArgs = {
  where: PromoCodeWhereUniqueInput;
};


export type QueryPromoCodeGroupArgs = {
  where: PromoCodeGroupWhereUniqueInput;
};


export type QueryPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeGroupWhereInput>;
};


export type QueryPromoCodeHistoriesArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type QueryPromoCodeHistoryArgs = {
  where: PromoCodeHistoryWhereUniqueInput;
};


export type QueryPromoCodeToPromoCodeGroupArgs = {
  where: PromoCodeToPromoCodeGroupWhereUniqueInput;
};


export type QueryPromoCodeToPromoCodeGroupsArgs = {
  cursor?: InputMaybe<PromoCodeToPromoCodeGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeToPromoCodeGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeToPromoCodeGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeToPromoCodeGroupWhereInput>;
};


export type QueryPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type QueryPublicActivitiesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindPublicManyActivityInput>;
};


export type QueryPublicActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryPublicActivityBannersArgs = {
  cursor?: InputMaybe<ActivityBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityBannerWhereInput>;
};


export type QueryPublicPastWorkshopsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPublicProductsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentProductWhereInput>;
};


export type QueryPublicSkillArgs = {
  where: SkillWhereUniqueInput;
};


export type QueryPublicSkillsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindStudentManySkillWhereInput>;
};


export type QueryPublicStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type QueryPublicStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type QueryPublicTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryPublicUpcomingWorkshopsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindPublicManyWorkshopWhereInput>;
};


export type QueryPublicWorkshopArgs = {
  where: StudentWorkshopUniqueInput;
};


export type QueryReferralCodeArgs = {
  where: ReferralCodeWhereUniqueInput;
};


export type QueryReferralCodeHistoriesArgs = {
  cursor?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReferralCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReferralCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferralCodeHistoryWhereInput>;
};


export type QueryReferralCodeHistoryArgs = {
  where: ReferralCodeHistoryWhereUniqueInput;
};


export type QueryReferralCodesArgs = {
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReferralCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type QueryReportArgs = {
  where: ReportWhereUniqueInput;
};


export type QueryReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type QuerySamskaraArgs = {
  where: SamskaraWhereUniqueInput;
};


export type QuerySamskaraCategoriesArgs = {
  cursor?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraCategoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraCategoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SamskaraCategoryWhereInput>;
};


export type QuerySamskaraCategoryArgs = {
  where: SamskaraCategoryWhereUniqueInput;
};


export type QuerySamskarasArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SamskaraWhereInput>;
};


export type QuerySchoolArgs = {
  where: SchoolWhereUniqueInput;
};


export type QuerySchoolUpdateArgs = {
  where: SchoolUpdateWhereUniqueInput;
};


export type QuerySchoolUpdatesArgs = {
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type QuerySchoolsArgs = {
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolWhereInput>;
};


export type QuerySearchActivitiesArgs = {
  where: FindTriggerManyActivityInput;
};


export type QuerySkillArgs = {
  where: SkillWhereUniqueInput;
};


export type QuerySkillsArgs = {
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  distinct?: InputMaybe<Array<SkillScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SkillWhereInput>;
};


export type QueryStandardArgs = {
  where: StandardWhereUniqueInput;
};


export type QueryStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type QueryStoriesArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryWhereInput>;
};


export type QueryStoryArgs = {
  where: StoryWhereUniqueInput;
};


export type QueryStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type QueryStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type QueryStoryBannerArgs = {
  where: StoryBannerWhereUniqueInput;
};


export type QueryStoryBannersArgs = {
  cursor?: InputMaybe<StoryBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryBannerWhereInput>;
};


export type QueryStoryTagArgs = {
  where: StoryTagWhereUniqueInput;
};


export type QueryStoryTagsArgs = {
  cursor?: InputMaybe<StoryTagWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryTagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryTagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryTagWhereInput>;
};


export type QueryStudentArgs = {
  where: StudentWhereUniqueInput;
};


export type QueryStudentActivitiesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: FindStudentManyActivityInput;
};


export type QueryStudentActivityArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentActivityAccessArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentActivityCommentsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: FindStudentActivityCommentsInput;
};


export type QueryStudentActivitySelfCommentsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: FindStudentActivityCommentsInput;
};


export type QueryStudentDailyActivityArgs = {
  data: StudentDailyActivityInput;
};


export type QueryStudentDailyChallengeArgs = {
  data: StudentDailyChallengeInput;
};


export type QueryStudentGameLogArgs = {
  where: StudentGameLogWhereUniqueInput;
};


export type QueryStudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type QueryStudentGamePointsArgs = {
  where: StudentWhereUniqueInput;
};


export type QueryStudentNextRandomActivityArgs = {
  where?: InputMaybe<StudentNextRandomActivityInput>;
};


export type QueryStudentPastWorkshopsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStudentProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryStudentRecordArgs = {
  where: StudentRecordWhereUniqueInput;
};


export type QueryStudentRecordsArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type QueryStudentRegisteredWorkshopsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStudentSkillArgs = {
  where: SkillWhereUniqueInput;
};


export type QueryStudentSkillsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindStudentManySkillWhereInput>;
};


export type QueryStudentStoriesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<StoryTagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStudentStoryArgs = {
  where: SkillWhereUniqueInput;
};


export type QueryStudentStoryActivitiesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindStudentStoryActivityInput>;
};


export type QueryStudentStoryActivityArgs = {
  where: StoryActivityWhereUniqueInput;
};


export type QueryStudentStoryActivityAccessArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentUpcomingWorkshopsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryStudentWorkshopAccessArgs = {
  where: ActivityWhereUniqueInput;
};


export type QueryStudentsArgs = {
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentWhereInput>;
};


export type QueryTagArgs = {
  where: TagWhereUniqueInput;
};


export type QueryTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryTriggerArgs = {
  where: TriggerWhereUniqueInput;
};


export type QueryTriggersArgs = {
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TriggerWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUserLogArgs = {
  where: UserLogWhereUniqueInput;
};


export type QueryUserLogsArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryWorkshopArgs = {
  where: WorkshopWhereUniqueInput;
};


export type QueryWorkshopRegistrationArgs = {
  where: WorkshopRegistrationWhereUniqueInput;
};


export type QueryWorkshopRegistrationsArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};


export type QueryWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export enum QueryMode {
  DEFAULT = 'default',
  INSENSITIVE = 'insensitive'
}

export type ReferralCode = {
  __typename?: 'ReferralCode';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  inviteeCoins: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  referralCoins: Scalars['Int']['output'];
  student: Student;
  studentId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReferralCodeAvgAggregate = {
  __typename?: 'ReferralCodeAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  inviteeCoins?: Maybe<Scalars['Float']['output']>;
  referralCoins?: Maybe<Scalars['Float']['output']>;
  studentId?: Maybe<Scalars['Float']['output']>;
};

export type ReferralCodeCountAggregate = {
  __typename?: 'ReferralCodeCountAggregate';
  _all: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inviteeCoins: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  referralCoins: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ReferralCodeCreateInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  inviteeCoins?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  referralCoins?: InputMaybe<Scalars['Int']['input']>;
  student: StudentCreateNestedOneWithoutReferralCodesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReferralCodeCreateManyStudentInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  inviteeCoins?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  referralCoins?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReferralCodeCreateManyStudentInputEnvelope = {
  data: Array<ReferralCodeCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReferralCodeCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReferralCodeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<ReferralCodeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<ReferralCodeCreateManyStudentInputEnvelope>;
};

export type ReferralCodeCreateOrConnectWithoutStudentInput = {
  create: ReferralCodeCreateWithoutStudentInput;
  where: ReferralCodeWhereUniqueInput;
};

export type ReferralCodeCreateWithoutStudentInput = {
  code: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  inviteeCoins?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  referralCoins?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReferralCodeHistory = {
  __typename?: 'ReferralCodeHistory';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  invitedStudent: Student;
  invitedStudentId: Scalars['Int']['output'];
  inviteeCoins: Scalars['Int']['output'];
  referralCode: Scalars['String']['output'];
  referralCoins: Scalars['Int']['output'];
  referralStudent: Student;
  referralStudentId: Scalars['Int']['output'];
};

export type ReferralCodeHistoryAvgAggregate = {
  __typename?: 'ReferralCodeHistoryAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  invitedStudentId?: Maybe<Scalars['Float']['output']>;
  inviteeCoins?: Maybe<Scalars['Float']['output']>;
  referralCoins?: Maybe<Scalars['Float']['output']>;
  referralStudentId?: Maybe<Scalars['Float']['output']>;
};

export type ReferralCodeHistoryCountAggregate = {
  __typename?: 'ReferralCodeHistoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  invitedStudentId: Scalars['Int']['output'];
  inviteeCoins: Scalars['Int']['output'];
  referralCode: Scalars['Int']['output'];
  referralCoins: Scalars['Int']['output'];
  referralStudentId: Scalars['Int']['output'];
};

export type ReferralCodeHistoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  invitedStudent: StudentCreateNestedOneWithoutInvitedStudentsInput;
  inviteeCoins?: InputMaybe<Scalars['Int']['input']>;
  referralCode: Scalars['String']['input'];
  referralCoins?: InputMaybe<Scalars['Int']['input']>;
  referralStudent: StudentCreateNestedOneWithoutReferralStudentsInput;
};

export type ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutInvitedStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutInvitedStudentInput>;
};

export type ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutReferralStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutReferralStudentInput>;
};

export type ReferralCodeHistoryCreateOrConnectWithoutInvitedStudentInput = {
  create: ReferralCodeHistoryCreateWithoutInvitedStudentInput;
  where: ReferralCodeHistoryWhereUniqueInput;
};

export type ReferralCodeHistoryCreateOrConnectWithoutReferralStudentInput = {
  create: ReferralCodeHistoryCreateWithoutReferralStudentInput;
  where: ReferralCodeHistoryWhereUniqueInput;
};

export type ReferralCodeHistoryCreateWithoutInvitedStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  inviteeCoins?: InputMaybe<Scalars['Int']['input']>;
  referralCode: Scalars['String']['input'];
  referralCoins?: InputMaybe<Scalars['Int']['input']>;
  referralStudent: StudentCreateNestedOneWithoutReferralStudentsInput;
};

export type ReferralCodeHistoryCreateWithoutReferralStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  invitedStudent: StudentCreateNestedOneWithoutInvitedStudentsInput;
  inviteeCoins?: InputMaybe<Scalars['Int']['input']>;
  referralCode: Scalars['String']['input'];
  referralCoins?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferralCodeHistoryMaxAggregate = {
  __typename?: 'ReferralCodeHistoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invitedStudentId?: Maybe<Scalars['Int']['output']>;
  inviteeCoins?: Maybe<Scalars['Int']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referralCoins?: Maybe<Scalars['Int']['output']>;
  referralStudentId?: Maybe<Scalars['Int']['output']>;
};

export type ReferralCodeHistoryMinAggregate = {
  __typename?: 'ReferralCodeHistoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invitedStudentId?: Maybe<Scalars['Int']['output']>;
  inviteeCoins?: Maybe<Scalars['Int']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referralCoins?: Maybe<Scalars['Int']['output']>;
  referralStudentId?: Maybe<Scalars['Int']['output']>;
};

export type ReferralCodeHistoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invitedStudent?: InputMaybe<StudentOrderByWithRelationInput>;
  invitedStudentId?: InputMaybe<SortOrder>;
  inviteeCoins?: InputMaybe<SortOrder>;
  referralCode?: InputMaybe<SortOrder>;
  referralCoins?: InputMaybe<SortOrder>;
  referralStudent?: InputMaybe<StudentOrderByWithRelationInput>;
  referralStudentId?: InputMaybe<SortOrder>;
};

export type ReferralCodeHistoryRelationFilter = {
  is?: InputMaybe<ReferralCodeHistoryWhereInput>;
  isNot?: InputMaybe<ReferralCodeHistoryWhereInput>;
};

export enum ReferralCodeHistoryScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  INVITEDSTUDENTID = 'invitedStudentId',
  INVITEECOINS = 'inviteeCoins',
  REFERRALCODE = 'referralCode',
  REFERRALCOINS = 'referralCoins',
  REFERRALSTUDENTID = 'referralStudentId'
}

export type ReferralCodeHistorySumAggregate = {
  __typename?: 'ReferralCodeHistorySumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  invitedStudentId?: Maybe<Scalars['Int']['output']>;
  inviteeCoins?: Maybe<Scalars['Int']['output']>;
  referralCoins?: Maybe<Scalars['Int']['output']>;
  referralStudentId?: Maybe<Scalars['Int']['output']>;
};

export type ReferralCodeHistoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  invitedStudent?: InputMaybe<StudentUpdateOneRequiredWithoutInvitedStudentsNestedInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralStudent?: InputMaybe<StudentUpdateOneRequiredWithoutReferralStudentsNestedInput>;
};

export type ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutInvitedStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutInvitedStudentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReferralCodeHistoryUpdateWithoutInvitedStudentInput>;
  upsert?: InputMaybe<ReferralCodeHistoryUpsertWithoutInvitedStudentInput>;
};

export type ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput = {
  connect?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ReferralCodeHistoryCreateOrConnectWithoutReferralStudentInput>;
  create?: InputMaybe<ReferralCodeHistoryCreateWithoutReferralStudentInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<ReferralCodeHistoryUpdateWithoutReferralStudentInput>;
  upsert?: InputMaybe<ReferralCodeHistoryUpsertWithoutReferralStudentInput>;
};

export type ReferralCodeHistoryUpdateWithoutInvitedStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralStudent?: InputMaybe<StudentUpdateOneRequiredWithoutReferralStudentsNestedInput>;
};

export type ReferralCodeHistoryUpdateWithoutReferralStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  invitedStudent?: InputMaybe<StudentUpdateOneRequiredWithoutInvitedStudentsNestedInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  referralCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type ReferralCodeHistoryUpsertWithoutInvitedStudentInput = {
  create: ReferralCodeHistoryCreateWithoutInvitedStudentInput;
  update: ReferralCodeHistoryUpdateWithoutInvitedStudentInput;
};

export type ReferralCodeHistoryUpsertWithoutReferralStudentInput = {
  create: ReferralCodeHistoryCreateWithoutReferralStudentInput;
  update: ReferralCodeHistoryUpdateWithoutReferralStudentInput;
};

export type ReferralCodeHistoryWhereInput = {
  AND?: InputMaybe<Array<ReferralCodeHistoryWhereInput>>;
  NOT?: InputMaybe<Array<ReferralCodeHistoryWhereInput>>;
  OR?: InputMaybe<Array<ReferralCodeHistoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  invitedStudent?: InputMaybe<StudentRelationFilter>;
  invitedStudentId?: InputMaybe<IntFilter>;
  inviteeCoins?: InputMaybe<IntFilter>;
  referralCode?: InputMaybe<StringFilter>;
  referralCoins?: InputMaybe<IntFilter>;
  referralStudent?: InputMaybe<StudentRelationFilter>;
  referralStudentId?: InputMaybe<IntFilter>;
};

export type ReferralCodeHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  invitedStudentId?: InputMaybe<Scalars['Int']['input']>;
  referralStudentId?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferralCodeListRelationFilter = {
  every?: InputMaybe<ReferralCodeWhereInput>;
  none?: InputMaybe<ReferralCodeWhereInput>;
  some?: InputMaybe<ReferralCodeWhereInput>;
};

export type ReferralCodeMaxAggregate = {
  __typename?: 'ReferralCodeMaxAggregate';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inviteeCoins?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  referralCoins?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReferralCodeMinAggregate = {
  __typename?: 'ReferralCodeMinAggregate';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inviteeCoins?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  referralCoins?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReferralCodeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReferralCodeOrderByWithRelationInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inviteeCoins?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  referralCoins?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReferralCodeScalarFieldEnum {
  CODE = 'code',
  CREATEDAT = 'createdAt',
  ID = 'id',
  INVITEECOINS = 'inviteeCoins',
  ISACTIVE = 'isActive',
  REFERRALCOINS = 'referralCoins',
  STUDENTID = 'studentId',
  UPDATEDAT = 'updatedAt'
}

export type ReferralCodeScalarWhereInput = {
  AND?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inviteeCoins?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  referralCoins?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReferralCodeSumAggregate = {
  __typename?: 'ReferralCodeSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  inviteeCoins?: Maybe<Scalars['Int']['output']>;
  referralCoins?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type ReferralCodeUpdateInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutReferralCodesNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReferralCodeUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReferralCodeUpdateManyWithWhereWithoutStudentInput = {
  data: ReferralCodeUpdateManyMutationInput;
  where: ReferralCodeScalarWhereInput;
};

export type ReferralCodeUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReferralCodeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<ReferralCodeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<ReferralCodeCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReferralCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<ReferralCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<ReferralCodeUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<ReferralCodeUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<ReferralCodeUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type ReferralCodeUpdateWithWhereUniqueWithoutStudentInput = {
  data: ReferralCodeUpdateWithoutStudentInput;
  where: ReferralCodeWhereUniqueInput;
};

export type ReferralCodeUpdateWithoutStudentInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  inviteeCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  referralCoins?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReferralCodeUpsertWithWhereUniqueWithoutStudentInput = {
  create: ReferralCodeCreateWithoutStudentInput;
  update: ReferralCodeUpdateWithoutStudentInput;
  where: ReferralCodeWhereUniqueInput;
};

export type ReferralCodeWhereInput = {
  AND?: InputMaybe<Array<ReferralCodeWhereInput>>;
  NOT?: InputMaybe<Array<ReferralCodeWhereInput>>;
  OR?: InputMaybe<Array<ReferralCodeWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inviteeCoins?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  referralCoins?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReferralCodeWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  authToken?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  message: Scalars['String']['output'];
  rules?: Maybe<Scalars['JSON']['output']>;
  success: Scalars['Boolean']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type Report = {
  __typename?: 'Report';
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  school: School;
  schoolId: Scalars['Int']['output'];
  standard: Standard;
  standardId: Scalars['Int']['output'];
  status: Status;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ReportAvgAggregate = {
  __typename?: 'ReportAvgAggregate';
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
  standardId?: Maybe<Scalars['Float']['output']>;
};

export type ReportCountAggregate = {
  __typename?: 'ReportCountAggregate';
  _all: Scalars['Int']['output'];
  attachment: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  standardId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type ReportCreateInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  school: SchoolCreateNestedOneWithoutReportsInput;
  standard: StandardCreateNestedOneWithoutReportsInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReportCreateManySchoolInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  standardId: Scalars['Int']['input'];
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReportCreateManySchoolInputEnvelope = {
  data: Array<ReportCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportCreateManyStandardInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  schoolId: Scalars['Int']['input'];
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReportCreateManyStandardInputEnvelope = {
  data: Array<ReportCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ReportCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ReportCreateManySchoolInputEnvelope>;
};

export type ReportCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutStandardInput>>;
  createMany?: InputMaybe<ReportCreateManyStandardInputEnvelope>;
};

export type ReportCreateOrConnectWithoutSchoolInput = {
  create: ReportCreateWithoutSchoolInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateOrConnectWithoutStandardInput = {
  create: ReportCreateWithoutStandardInput;
  where: ReportWhereUniqueInput;
};

export type ReportCreateWithoutSchoolInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  standard: StandardCreateNestedOneWithoutReportsInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReportCreateWithoutStandardInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  school: SchoolCreateNestedOneWithoutReportsInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReportListRelationFilter = {
  every?: InputMaybe<ReportWhereInput>;
  none?: InputMaybe<ReportWhereInput>;
  some?: InputMaybe<ReportWhereInput>;
};

export type ReportMaxAggregate = {
  __typename?: 'ReportMaxAggregate';
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReportMinAggregate = {
  __typename?: 'ReportMinAggregate';
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReportOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ReportOrderByWithRelationInput = {
  attachment?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ReportScalarFieldEnum {
  ATTACHMENT = 'attachment',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DISPLAYORDER = 'displayOrder',
  ID = 'id',
  SCHOOLID = 'schoolId',
  STANDARDID = 'standardId',
  STATUS = 'status',
  TITLE = 'title',
  UPDATEDAT = 'updatedAt'
}

export type ReportScalarWhereInput = {
  AND?: InputMaybe<Array<ReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ReportScalarWhereInput>>;
  OR?: InputMaybe<Array<ReportScalarWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportSumAggregate = {
  __typename?: 'ReportSumAggregate';
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
};

export type ReportUpdateInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutReportsNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutReportsNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateManyMutationInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateManyWithWhereWithoutSchoolInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithWhereWithoutStandardInput = {
  data: ReportUpdateManyMutationInput;
  where: ReportScalarWhereInput;
};

export type ReportUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<ReportCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type ReportUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ReportCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<ReportCreateWithoutStandardInput>>;
  createMany?: InputMaybe<ReportCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<ReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ReportUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<ReportUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<ReportUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type ReportUpdateWithWhereUniqueWithoutSchoolInput = {
  data: ReportUpdateWithoutSchoolInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithWhereUniqueWithoutStandardInput = {
  data: ReportUpdateWithoutStandardInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpdateWithoutSchoolInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutReportsNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpdateWithoutStandardInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutReportsNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ReportUpsertWithWhereUniqueWithoutSchoolInput = {
  create: ReportCreateWithoutSchoolInput;
  update: ReportUpdateWithoutSchoolInput;
  where: ReportWhereUniqueInput;
};

export type ReportUpsertWithWhereUniqueWithoutStandardInput = {
  create: ReportCreateWithoutStandardInput;
  update: ReportUpdateWithoutStandardInput;
  where: ReportWhereUniqueInput;
};

export type ReportWhereInput = {
  AND?: InputMaybe<Array<ReportWhereInput>>;
  NOT?: InputMaybe<Array<ReportWhereInput>>;
  OR?: InputMaybe<Array<ReportWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export enum Role {
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR',
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  TRAINER = 'TRAINER'
}

export type Samskara = {
  __typename?: 'Samskara';
  attachment?: Maybe<Scalars['String']['output']>;
  category: SamskaraCategory;
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  school: School;
  schoolId: Scalars['Int']['output'];
  standard: Standard;
  standardId: Scalars['Int']['output'];
  status: Status;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  video?: Maybe<Scalars['String']['output']>;
};

export type SamskaraAvgAggregate = {
  __typename?: 'SamskaraAvgAggregate';
  categoryId?: Maybe<Scalars['Float']['output']>;
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
  standardId?: Maybe<Scalars['Float']['output']>;
};

export type SamskaraCategory = {
  __typename?: 'SamskaraCategory';
  _count?: Maybe<SamskaraCategoryCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['String']['output'];
  key: Scalars['String']['output'];
  samskaras: Array<Samskara>;
  value?: Maybe<Scalars['String']['output']>;
};


export type SamskaraCategorySamskarasArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SamskaraWhereInput>;
};

export type SamskaraCategoryAvgAggregate = {
  __typename?: 'SamskaraCategoryAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type SamskaraCategoryCount = {
  __typename?: 'SamskaraCategoryCount';
  samskaras: Scalars['Int']['output'];
};


export type SamskaraCategoryCountSamskarasArgs = {
  where?: InputMaybe<SamskaraWhereInput>;
};

export type SamskaraCategoryCountAggregate = {
  __typename?: 'SamskaraCategoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type SamskaraCategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image: Scalars['String']['input'];
  key: Scalars['String']['input'];
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutCategoryInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCategoryCreateNestedOneWithoutSamskarasInput = {
  connect?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SamskaraCategoryCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SamskaraCategoryCreateWithoutSamskarasInput>;
};

export type SamskaraCategoryCreateOrConnectWithoutSamskarasInput = {
  create: SamskaraCategoryCreateWithoutSamskarasInput;
  where: SamskaraCategoryWhereUniqueInput;
};

export type SamskaraCategoryCreateWithoutSamskarasInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image: Scalars['String']['input'];
  key: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCategoryMaxAggregate = {
  __typename?: 'SamskaraCategoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SamskaraCategoryMinAggregate = {
  __typename?: 'SamskaraCategoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SamskaraCategoryOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  samskaras?: InputMaybe<SamskaraOrderByRelationAggregateInput>;
  value?: InputMaybe<SortOrderInput>;
};

export type SamskaraCategoryRelationFilter = {
  is?: InputMaybe<SamskaraCategoryWhereInput>;
  isNot?: InputMaybe<SamskaraCategoryWhereInput>;
};

export enum SamskaraCategoryScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  IMAGE = 'image',
  KEY = 'key',
  VALUE = 'value'
}

export type SamskaraCategorySumAggregate = {
  __typename?: 'SamskaraCategorySumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type SamskaraCategoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutCategoryNestedInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput = {
  connect?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SamskaraCategoryCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SamskaraCategoryCreateWithoutSamskarasInput>;
  update?: InputMaybe<SamskaraCategoryUpdateWithoutSamskarasInput>;
  upsert?: InputMaybe<SamskaraCategoryUpsertWithoutSamskarasInput>;
};

export type SamskaraCategoryUpdateWithoutSamskarasInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<StringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraCategoryUpsertWithoutSamskarasInput = {
  create: SamskaraCategoryCreateWithoutSamskarasInput;
  update: SamskaraCategoryUpdateWithoutSamskarasInput;
};

export type SamskaraCategoryWhereInput = {
  AND?: InputMaybe<Array<SamskaraCategoryWhereInput>>;
  NOT?: InputMaybe<Array<SamskaraCategoryWhereInput>>;
  OR?: InputMaybe<Array<SamskaraCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringFilter>;
  key?: InputMaybe<StringFilter>;
  samskaras?: InputMaybe<SamskaraListRelationFilter>;
  value?: InputMaybe<StringNullableFilter>;
};

export type SamskaraCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCountAggregate = {
  __typename?: 'SamskaraCountAggregate';
  _all: Scalars['Int']['output'];
  attachment: Scalars['Int']['output'];
  categoryId: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  standardId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  video: Scalars['Int']['output'];
};

export type SamskaraCreateInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  category: SamskaraCategoryCreateNestedOneWithoutSamskarasInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  school: SchoolCreateNestedOneWithoutSamskarasInput;
  standard: StandardCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCreateManyCategoryInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  schoolId: Scalars['Int']['input'];
  standardId: Scalars['Int']['input'];
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCreateManyCategoryInputEnvelope = {
  data: Array<SamskaraCreateManyCategoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SamskaraCreateManySchoolInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  standardId: Scalars['Int']['input'];
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCreateManySchoolInputEnvelope = {
  data: Array<SamskaraCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SamskaraCreateManyStandardInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  schoolId: Scalars['Int']['input'];
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCreateManyStandardInputEnvelope = {
  data: Array<SamskaraCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SamskaraCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SamskaraCreateManyCategoryInputEnvelope>;
};

export type SamskaraCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SamskaraCreateManySchoolInputEnvelope>;
};

export type SamskaraCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutStandardInput>>;
  createMany?: InputMaybe<SamskaraCreateManyStandardInputEnvelope>;
};

export type SamskaraCreateOrConnectWithoutCategoryInput = {
  create: SamskaraCreateWithoutCategoryInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraCreateOrConnectWithoutSchoolInput = {
  create: SamskaraCreateWithoutSchoolInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraCreateOrConnectWithoutStandardInput = {
  create: SamskaraCreateWithoutStandardInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraCreateWithoutCategoryInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  school: SchoolCreateNestedOneWithoutSamskarasInput;
  standard: StandardCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCreateWithoutSchoolInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  category: SamskaraCategoryCreateNestedOneWithoutSamskarasInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  standard: StandardCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraCreateWithoutStandardInput = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  category: SamskaraCategoryCreateNestedOneWithoutSamskarasInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  school: SchoolCreateNestedOneWithoutSamskarasInput;
  status?: InputMaybe<Status>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
};

export type SamskaraListRelationFilter = {
  every?: InputMaybe<SamskaraWhereInput>;
  none?: InputMaybe<SamskaraWhereInput>;
  some?: InputMaybe<SamskaraWhereInput>;
};

export type SamskaraMaxAggregate = {
  __typename?: 'SamskaraMaxAggregate';
  attachment?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
};

export type SamskaraMinAggregate = {
  __typename?: 'SamskaraMinAggregate';
  attachment?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Status>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
};

export type SamskaraOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SamskaraOrderByWithRelationInput = {
  attachment?: InputMaybe<SortOrderInput>;
  category?: InputMaybe<SamskaraCategoryOrderByWithRelationInput>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
};

export enum SamskaraScalarFieldEnum {
  ATTACHMENT = 'attachment',
  CATEGORYID = 'categoryId',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DISPLAYORDER = 'displayOrder',
  ID = 'id',
  SCHOOLID = 'schoolId',
  STANDARDID = 'standardId',
  STATUS = 'status',
  TITLE = 'title',
  UPDATEDAT = 'updatedAt',
  VIDEO = 'video'
}

export type SamskaraScalarWhereInput = {
  AND?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  NOT?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  OR?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  categoryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type SamskaraSumAggregate = {
  __typename?: 'SamskaraSumAggregate';
  categoryId?: Maybe<Scalars['Int']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
};

export type SamskaraUpdateInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSamskarasNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateManyMutationInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateManyWithWhereWithoutCategoryInput = {
  data: SamskaraUpdateManyMutationInput;
  where: SamskaraScalarWhereInput;
};

export type SamskaraUpdateManyWithWhereWithoutSchoolInput = {
  data: SamskaraUpdateManyMutationInput;
  where: SamskaraScalarWhereInput;
};

export type SamskaraUpdateManyWithWhereWithoutStandardInput = {
  data: SamskaraUpdateManyMutationInput;
  where: SamskaraScalarWhereInput;
};

export type SamskaraUpdateManyWithoutCategoryNestedInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<SamskaraCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  set?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  update?: InputMaybe<Array<SamskaraUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<SamskaraUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<SamskaraUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type SamskaraUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SamskaraCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  set?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  update?: InputMaybe<Array<SamskaraUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SamskaraUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SamskaraUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SamskaraUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SamskaraCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<SamskaraCreateWithoutStandardInput>>;
  createMany?: InputMaybe<SamskaraCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SamskaraScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  set?: InputMaybe<Array<SamskaraWhereUniqueInput>>;
  update?: InputMaybe<Array<SamskaraUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<SamskaraUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<SamskaraUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type SamskaraUpdateWithWhereUniqueWithoutCategoryInput = {
  data: SamskaraUpdateWithoutCategoryInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SamskaraUpdateWithoutSchoolInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpdateWithWhereUniqueWithoutStandardInput = {
  data: SamskaraUpdateWithoutStandardInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpdateWithoutCategoryInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSamskarasNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateWithoutSchoolInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpdateWithoutStandardInput = {
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  category?: InputMaybe<SamskaraCategoryUpdateOneRequiredWithoutSamskarasNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSamskarasNestedInput>;
  status?: InputMaybe<EnumStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type SamskaraUpsertWithWhereUniqueWithoutCategoryInput = {
  create: SamskaraCreateWithoutCategoryInput;
  update: SamskaraUpdateWithoutCategoryInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SamskaraCreateWithoutSchoolInput;
  update: SamskaraUpdateWithoutSchoolInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraUpsertWithWhereUniqueWithoutStandardInput = {
  create: SamskaraCreateWithoutStandardInput;
  update: SamskaraUpdateWithoutStandardInput;
  where: SamskaraWhereUniqueInput;
};

export type SamskaraWhereInput = {
  AND?: InputMaybe<Array<SamskaraWhereInput>>;
  NOT?: InputMaybe<Array<SamskaraWhereInput>>;
  OR?: InputMaybe<Array<SamskaraWhereInput>>;
  attachment?: InputMaybe<StringNullableFilter>;
  category?: InputMaybe<SamskaraCategoryRelationFilter>;
  categoryId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
};

export type SamskaraWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type School = {
  __typename?: 'School';
  _count?: Maybe<SchoolCount>;
  area?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  group?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  reports: Array<Report>;
  samskaras: Array<Samskara>;
  schoolUpdates: Array<SchoolUpdate>;
  skills: Array<Skill>;
  state?: Maybe<Scalars['String']['output']>;
  stories: Array<Story>;
  studentRecords: Array<StudentRecord>;
  students: Array<Student>;
  updatedAt: Scalars['DateTime']['output'];
  users: Array<User>;
  workshops: Array<Workshop>;
};


export type SchoolReportsArgs = {
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReportWhereInput>;
};


export type SchoolSamskarasArgs = {
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SamskaraWhereInput>;
};


export type SchoolSchoolUpdatesArgs = {
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type SchoolSkillsArgs = {
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  distinct?: InputMaybe<Array<SkillScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SkillWhereInput>;
};


export type SchoolStoriesArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryWhereInput>;
};


export type SchoolStudentRecordsArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type SchoolStudentsArgs = {
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentWhereInput>;
};


export type SchoolUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type SchoolWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SchoolAvgAggregate = {
  __typename?: 'SchoolAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type SchoolCount = {
  __typename?: 'SchoolCount';
  reports: Scalars['Int']['output'];
  samskaras: Scalars['Int']['output'];
  schoolUpdates: Scalars['Int']['output'];
  skills: Scalars['Int']['output'];
  stories: Scalars['Int']['output'];
  studentRecords: Scalars['Int']['output'];
  students: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type SchoolCountReportsArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type SchoolCountSamskarasArgs = {
  where?: InputMaybe<SamskaraWhereInput>;
};


export type SchoolCountSchoolUpdatesArgs = {
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type SchoolCountSkillsArgs = {
  where?: InputMaybe<SkillWhereInput>;
};


export type SchoolCountStoriesArgs = {
  where?: InputMaybe<StoryWhereInput>;
};


export type SchoolCountStudentRecordsArgs = {
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type SchoolCountStudentsArgs = {
  where?: InputMaybe<StudentWhereInput>;
};


export type SchoolCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type SchoolCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SchoolCountAggregate = {
  __typename?: 'SchoolCountAggregate';
  _all: Scalars['Int']['output'];
  area: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  code: Scalars['Int']['output'];
  country: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  group: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type SchoolCreateInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<SchoolCreateWithoutUsersInput>>;
};

export type SchoolCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<SchoolCreateWithoutReportsInput>;
};

export type SchoolCreateNestedOneWithoutSamskarasInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SchoolCreateWithoutSamskarasInput>;
};

export type SchoolCreateNestedOneWithoutSchoolUpdatesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolUpdatesInput>;
  create?: InputMaybe<SchoolCreateWithoutSchoolUpdatesInput>;
};

export type SchoolCreateNestedOneWithoutSkillsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSkillsInput>;
  create?: InputMaybe<SchoolCreateWithoutSkillsInput>;
};

export type SchoolCreateNestedOneWithoutStoriesInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<SchoolCreateWithoutStoriesInput>;
};

export type SchoolCreateNestedOneWithoutStudentRecordsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentRecordsInput>;
};

export type SchoolCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentsInput>;
};

export type SchoolCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SchoolCreateWithoutWorkshopsInput>;
};

export type SchoolCreateOrConnectWithoutReportsInput = {
  create: SchoolCreateWithoutReportsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSamskarasInput = {
  create: SchoolCreateWithoutSamskarasInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSchoolUpdatesInput = {
  create: SchoolCreateWithoutSchoolUpdatesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutSkillsInput = {
  create: SchoolCreateWithoutSkillsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStoriesInput = {
  create: SchoolCreateWithoutStoriesInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStudentRecordsInput = {
  create: SchoolCreateWithoutStudentRecordsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutStudentsInput = {
  create: SchoolCreateWithoutStudentsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutUsersInput = {
  create: SchoolCreateWithoutUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateOrConnectWithoutWorkshopsInput = {
  create: SchoolCreateWithoutWorkshopsInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolCreateWithoutReportsInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutSamskarasInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutSchoolUpdatesInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutSkillsInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutStoriesInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutStudentRecordsInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutStudentsInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutUsersInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSchoolInput>;
};

export type SchoolCreateWithoutWorkshopsInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutSchoolInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutSchoolInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutSchoolInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutSchoolInput>;
  state?: InputMaybe<Scalars['String']['input']>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutSchoolInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutSchoolInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutSchoolInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  users?: InputMaybe<UserCreateNestedManyWithoutSchoolsInput>;
};

export type SchoolListRelationFilter = {
  every?: InputMaybe<SchoolWhereInput>;
  none?: InputMaybe<SchoolWhereInput>;
  some?: InputMaybe<SchoolWhereInput>;
};

export type SchoolMaxAggregate = {
  __typename?: 'SchoolMaxAggregate';
  area?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SchoolMinAggregate = {
  __typename?: 'SchoolMinAggregate';
  area?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SchoolOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SchoolOrderByWithRelationInput = {
  area?: InputMaybe<SortOrderInput>;
  city?: InputMaybe<SortOrderInput>;
  code?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  group?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  samskaras?: InputMaybe<SamskaraOrderByRelationAggregateInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateOrderByRelationAggregateInput>;
  skills?: InputMaybe<SkillOrderByRelationAggregateInput>;
  state?: InputMaybe<SortOrderInput>;
  stories?: InputMaybe<StoryOrderByRelationAggregateInput>;
  studentRecords?: InputMaybe<StudentRecordOrderByRelationAggregateInput>;
  students?: InputMaybe<StudentOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type SchoolRelationFilter = {
  is?: InputMaybe<SchoolWhereInput>;
  isNot?: InputMaybe<SchoolWhereInput>;
};

export enum SchoolScalarFieldEnum {
  AREA = 'area',
  CITY = 'city',
  CODE = 'code',
  COUNTRY = 'country',
  CREATEDAT = 'createdAt',
  GROUP = 'group',
  ID = 'id',
  NAME = 'name',
  STATE = 'state',
  UPDATEDAT = 'updatedAt'
}

export type SchoolScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolScalarWhereInput>>;
  area?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  group?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolSumAggregate = {
  __typename?: 'SchoolSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type SchoolUpdate = {
  __typename?: 'SchoolUpdate';
  _count?: Maybe<SchoolUpdateCount>;
  asset?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  school: School;
  schoolId: Scalars['Int']['output'];
  standards: Array<Standard>;
  tags: Scalars['JSON']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type SchoolUpdateStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};

export type SchoolUpdateAvgAggregate = {
  __typename?: 'SchoolUpdateAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
};

export type SchoolUpdateCount = {
  __typename?: 'SchoolUpdateCount';
  standards: Scalars['Int']['output'];
};


export type SchoolUpdateCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type SchoolUpdateCountAggregate = {
  __typename?: 'SchoolUpdateCountAggregate';
  _all: Scalars['Int']['output'];
  asset: Scalars['Int']['output'];
  attachment: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  tags: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type SchoolUpdateCreateInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  school: SchoolCreateNestedOneWithoutSchoolUpdatesInput;
  standards?: InputMaybe<StandardCreateNestedManyWithoutSchoolUpdatesInput>;
  tags: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SchoolUpdateCreateManySchoolInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  tags: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SchoolUpdateCreateManySchoolInputEnvelope = {
  data: Array<SchoolUpdateCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SchoolUpdateCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolUpdateCreateManySchoolInputEnvelope>;
};

export type SchoolUpdateCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutStandardsInput>>;
};

export type SchoolUpdateCreateOrConnectWithoutSchoolInput = {
  create: SchoolUpdateCreateWithoutSchoolInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateCreateOrConnectWithoutStandardsInput = {
  create: SchoolUpdateCreateWithoutStandardsInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateCreateWithoutSchoolInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutSchoolUpdatesInput>;
  tags: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SchoolUpdateCreateWithoutStandardsInput = {
  asset?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  school: SchoolCreateNestedOneWithoutSchoolUpdatesInput;
  tags: Scalars['JSON']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SchoolUpdateInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateListRelationFilter = {
  every?: InputMaybe<SchoolUpdateWhereInput>;
  none?: InputMaybe<SchoolUpdateWhereInput>;
  some?: InputMaybe<SchoolUpdateWhereInput>;
};

export type SchoolUpdateManyMutationInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateManyWithWhereWithoutUsersInput = {
  data: SchoolUpdateManyMutationInput;
  where: SchoolScalarWhereInput;
};

export type SchoolUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<SchoolCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<SchoolUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type SchoolUpdateMaxAggregate = {
  __typename?: 'SchoolUpdateMaxAggregate';
  asset?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SchoolUpdateMinAggregate = {
  __typename?: 'SchoolUpdateMinAggregate';
  asset?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SchoolUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<SchoolCreateWithoutReportsInput>;
  update?: InputMaybe<SchoolUpdateWithoutReportsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutReportsInput>;
};

export type SchoolUpdateOneRequiredWithoutSamskarasNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<SchoolCreateWithoutSamskarasInput>;
  update?: InputMaybe<SchoolUpdateWithoutSamskarasInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSamskarasInput>;
};

export type SchoolUpdateOneRequiredWithoutSchoolUpdatesNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSchoolUpdatesInput>;
  create?: InputMaybe<SchoolCreateWithoutSchoolUpdatesInput>;
  update?: InputMaybe<SchoolUpdateWithoutSchoolUpdatesInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSchoolUpdatesInput>;
};

export type SchoolUpdateOneRequiredWithoutStudentRecordsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentRecordsInput>;
  update?: InputMaybe<SchoolUpdateWithoutStudentRecordsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStudentRecordsInput>;
};

export type SchoolUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<SchoolCreateWithoutStudentsInput>;
  update?: InputMaybe<SchoolUpdateWithoutStudentsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStudentsInput>;
};

export type SchoolUpdateOneRequiredWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SchoolCreateWithoutWorkshopsInput>;
  update?: InputMaybe<SchoolUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutWorkshopsInput>;
};

export type SchoolUpdateOneWithoutSkillsNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutSkillsInput>;
  create?: InputMaybe<SchoolCreateWithoutSkillsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SchoolUpdateWithoutSkillsInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutSkillsInput>;
};

export type SchoolUpdateOneWithoutStoriesNestedInput = {
  connect?: InputMaybe<SchoolWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SchoolCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<SchoolCreateWithoutStoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SchoolUpdateWithoutStoriesInput>;
  upsert?: InputMaybe<SchoolUpsertWithoutStoriesInput>;
};

export type SchoolUpdateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SchoolUpdateOrderByWithRelationInput = {
  asset?: InputMaybe<SortOrderInput>;
  attachment?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum SchoolUpdateScalarFieldEnum {
  ASSET = 'asset',
  ATTACHMENT = 'attachment',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  ID = 'id',
  SCHOOLID = 'schoolId',
  TAGS = 'tags',
  TITLE = 'title',
  UPDATEDAT = 'updatedAt'
}

export type SchoolUpdateScalarWhereInput = {
  AND?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  NOT?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  OR?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  asset?: InputMaybe<StringNullableFilter>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  schoolId?: InputMaybe<IntFilter>;
  tags?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolUpdateSumAggregate = {
  __typename?: 'SchoolUpdateSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
};

export type SchoolUpdateUpdateInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSchoolUpdatesNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSchoolUpdatesNestedInput>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpdateManyMutationInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpdateManyWithWhereWithoutSchoolInput = {
  data: SchoolUpdateUpdateManyMutationInput;
  where: SchoolUpdateScalarWhereInput;
};

export type SchoolUpdateUpdateManyWithWhereWithoutStandardsInput = {
  data: SchoolUpdateUpdateManyMutationInput;
  where: SchoolUpdateScalarWhereInput;
};

export type SchoolUpdateUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SchoolUpdateCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SchoolUpdateUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SchoolUpdateUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SchoolUpdateCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SchoolUpdateCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SchoolUpdateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  set?: InputMaybe<Array<SchoolUpdateWhereUniqueInput>>;
  update?: InputMaybe<Array<SchoolUpdateUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<SchoolUpdateUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<SchoolUpdateUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type SchoolUpdateUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SchoolUpdateUpdateWithoutSchoolInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateUpdateWithWhereUniqueWithoutStandardsInput = {
  data: SchoolUpdateUpdateWithoutStandardsInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateUpdateWithoutSchoolInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSchoolUpdatesNestedInput>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpdateWithoutStandardsInput = {
  asset?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachment?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutSchoolUpdatesNestedInput>;
  tags?: InputMaybe<Scalars['JSON']['input']>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SchoolUpdateUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SchoolUpdateCreateWithoutSchoolInput;
  update: SchoolUpdateUpdateWithoutSchoolInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateUpsertWithWhereUniqueWithoutStandardsInput = {
  create: SchoolUpdateCreateWithoutStandardsInput;
  update: SchoolUpdateUpdateWithoutStandardsInput;
  where: SchoolUpdateWhereUniqueInput;
};

export type SchoolUpdateWhereInput = {
  AND?: InputMaybe<Array<SchoolUpdateWhereInput>>;
  NOT?: InputMaybe<Array<SchoolUpdateWhereInput>>;
  OR?: InputMaybe<Array<SchoolUpdateWhereInput>>;
  asset?: InputMaybe<StringNullableFilter>;
  attachment?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  tags?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SchoolUpdateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type SchoolUpdateWithWhereUniqueWithoutUsersInput = {
  data: SchoolUpdateWithoutUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolUpdateWithoutReportsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutSamskarasInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutSchoolUpdatesInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutSkillsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutStoriesInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutStudentRecordsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutStudentsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutUsersInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSchoolNestedInput>;
};

export type SchoolUpdateWithoutWorkshopsInput = {
  area?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  group?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutSchoolNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutSchoolNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutSchoolNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutSchoolNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutSchoolNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutSchoolNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutSchoolNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutSchoolsNestedInput>;
};

export type SchoolUpsertWithWhereUniqueWithoutUsersInput = {
  create: SchoolCreateWithoutUsersInput;
  update: SchoolUpdateWithoutUsersInput;
  where: SchoolWhereUniqueInput;
};

export type SchoolUpsertWithoutReportsInput = {
  create: SchoolCreateWithoutReportsInput;
  update: SchoolUpdateWithoutReportsInput;
};

export type SchoolUpsertWithoutSamskarasInput = {
  create: SchoolCreateWithoutSamskarasInput;
  update: SchoolUpdateWithoutSamskarasInput;
};

export type SchoolUpsertWithoutSchoolUpdatesInput = {
  create: SchoolCreateWithoutSchoolUpdatesInput;
  update: SchoolUpdateWithoutSchoolUpdatesInput;
};

export type SchoolUpsertWithoutSkillsInput = {
  create: SchoolCreateWithoutSkillsInput;
  update: SchoolUpdateWithoutSkillsInput;
};

export type SchoolUpsertWithoutStoriesInput = {
  create: SchoolCreateWithoutStoriesInput;
  update: SchoolUpdateWithoutStoriesInput;
};

export type SchoolUpsertWithoutStudentRecordsInput = {
  create: SchoolCreateWithoutStudentRecordsInput;
  update: SchoolUpdateWithoutStudentRecordsInput;
};

export type SchoolUpsertWithoutStudentsInput = {
  create: SchoolCreateWithoutStudentsInput;
  update: SchoolUpdateWithoutStudentsInput;
};

export type SchoolUpsertWithoutWorkshopsInput = {
  create: SchoolCreateWithoutWorkshopsInput;
  update: SchoolUpdateWithoutWorkshopsInput;
};

export type SchoolWhereInput = {
  AND?: InputMaybe<Array<SchoolWhereInput>>;
  NOT?: InputMaybe<Array<SchoolWhereInput>>;
  OR?: InputMaybe<Array<SchoolWhereInput>>;
  area?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  group?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  samskaras?: InputMaybe<SamskaraListRelationFilter>;
  schoolUpdates?: InputMaybe<SchoolUpdateListRelationFilter>;
  skills?: InputMaybe<SkillListRelationFilter>;
  state?: InputMaybe<StringNullableFilter>;
  stories?: InputMaybe<StoryListRelationFilter>;
  studentRecords?: InputMaybe<StudentRecordListRelationFilter>;
  students?: InputMaybe<StudentListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type SchoolWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchActivity = {
  __typename?: 'SearchActivity';
  id: Scalars['Int']['output'];
  modelId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: ActivityType;
};

export type SignUpInput = {
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  idToken: Scalars['String']['input'];
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  schoolText?: InputMaybe<Scalars['String']['input']>;
  signUpCode?: InputMaybe<Scalars['String']['input']>;
  standard: StandardWhereUniqueInput;
};

export type Sitemap = {
  __typename?: 'Sitemap';
  lastmod?: Maybe<Scalars['DateTime']['output']>;
  loc: Scalars['String']['output'];
};

export type Skill = {
  __typename?: 'Skill';
  _count?: Maybe<SkillCount>;
  activities: Array<Activity>;
  bundle?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayOrder: Scalars['Int']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  gamePointType?: Maybe<GamePointType>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  products: Array<Product>;
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  standards: Array<Standard>;
  storyActivities: Array<StoryActivity>;
  type: SkillType;
  updatedAt: Scalars['DateTime']['output'];
  weightage: Scalars['Float']['output'];
  workshops: Array<Workshop>;
};


export type SkillActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type SkillProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type SkillStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type SkillStoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type SkillWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SkillAvgAggregate = {
  __typename?: 'SkillAvgAggregate';
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
  weightage?: Maybe<Scalars['Float']['output']>;
};

export type SkillCount = {
  __typename?: 'SkillCount';
  activities: Scalars['Int']['output'];
  products: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
  storyActivities: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type SkillCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type SkillCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type SkillCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type SkillCountStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type SkillCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type SkillCountAggregate = {
  __typename?: 'SkillCountAggregate';
  _all: Scalars['Int']['output'];
  bundle: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  displayOrder: Scalars['Int']['output'];
  featuredImage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  meta: Scalars['Int']['output'];
  metaTagData: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  weightage: Scalars['Int']['output'];
};

export type SkillCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateManySchoolInput = {
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  slug: Scalars['String']['input'];
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
};

export type SkillCreateManySchoolInputEnvelope = {
  data: Array<SkillCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SkillCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SkillCreateManySchoolInputEnvelope>;
};

export type SkillCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutStandardsInput>>;
};

export type SkillCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutActivitiesInput>;
};

export type SkillCreateNestedOneWithoutGamePointTypeInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutGamePointTypeInput>;
  create?: InputMaybe<SkillCreateWithoutGamePointTypeInput>;
};

export type SkillCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<SkillCreateWithoutProductsInput>;
};

export type SkillCreateNestedOneWithoutStoryActivitiesInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutStoryActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutStoryActivitiesInput>;
};

export type SkillCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SkillCreateWithoutWorkshopsInput>;
};

export type SkillCreateOrConnectWithoutActivitiesInput = {
  create: SkillCreateWithoutActivitiesInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutGamePointTypeInput = {
  create: SkillCreateWithoutGamePointTypeInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutProductsInput = {
  create: SkillCreateWithoutProductsInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutSchoolInput = {
  create: SkillCreateWithoutSchoolInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutStandardsInput = {
  create: SkillCreateWithoutStandardsInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutStoryActivitiesInput = {
  create: SkillCreateWithoutStoryActivitiesInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateOrConnectWithoutWorkshopsInput = {
  create: SkillCreateWithoutWorkshopsInput;
  where: SkillWhereUniqueInput;
};

export type SkillCreateWithoutActivitiesInput = {
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutGamePointTypeInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutProductsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutSchoolInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutSkillInput>;
};

export type SkillCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutSkillInput>;
  bundle?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutSkillInput>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutSkillInput>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutSkillsInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutSkillsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutSkillInput>;
  type: SkillType;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  weightage?: InputMaybe<Scalars['Float']['input']>;
};

export type SkillListRelationFilter = {
  every?: InputMaybe<SkillWhereInput>;
  none?: InputMaybe<SkillWhereInput>;
  some?: InputMaybe<SkillWhereInput>;
};

export type SkillMaxAggregate = {
  __typename?: 'SkillMaxAggregate';
  bundle?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SkillType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weightage?: Maybe<Scalars['Float']['output']>;
};

export type SkillMinAggregate = {
  __typename?: 'SkillMinAggregate';
  bundle?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SkillType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weightage?: Maybe<Scalars['Float']['output']>;
};

export type SkillOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SkillOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  bundle?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  gamePointType?: InputMaybe<GamePointTypeOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  isActive?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  storyActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  weightage?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type SkillRelationFilter = {
  is?: InputMaybe<SkillWhereInput>;
  isNot?: InputMaybe<SkillWhereInput>;
};

export enum SkillScalarFieldEnum {
  BUNDLE = 'bundle',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DISPLAYORDER = 'displayOrder',
  FEATUREDIMAGE = 'featuredImage',
  ID = 'id',
  IMAGE = 'image',
  ISACTIVE = 'isActive',
  META = 'meta',
  METATAGDATA = 'metaTagData',
  NAME = 'name',
  SCHOOLID = 'schoolId',
  SLUG = 'slug',
  TYPE = 'type',
  UPDATEDAT = 'updatedAt',
  WEIGHTAGE = 'weightage'
}

export type SkillScalarWhereInput = {
  AND?: InputMaybe<Array<SkillScalarWhereInput>>;
  NOT?: InputMaybe<Array<SkillScalarWhereInput>>;
  OR?: InputMaybe<Array<SkillScalarWhereInput>>;
  bundle?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumSkillTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightage?: InputMaybe<FloatFilter>;
};

export type SkillSumAggregate = {
  __typename?: 'SkillSumAggregate';
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  weightage?: Maybe<Scalars['Float']['output']>;
};

export enum SkillType {
  APPLIED_ACADEMICS = 'APPLIED_ACADEMICS',
  MAKER = 'MAKER',
  SOFT = 'SOFT'
}

export type SkillUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateManyMutationInput = {
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type SkillUpdateManyWithWhereWithoutSchoolInput = {
  data: SkillUpdateManyMutationInput;
  where: SkillScalarWhereInput;
};

export type SkillUpdateManyWithWhereWithoutStandardsInput = {
  data: SkillUpdateManyMutationInput;
  where: SkillScalarWhereInput;
};

export type SkillUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<SkillCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<SkillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SkillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  set?: InputMaybe<Array<SkillWhereUniqueInput>>;
  update?: InputMaybe<Array<SkillUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<SkillUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<SkillUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type SkillUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SkillCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<SkillCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<SkillWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SkillScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SkillWhereUniqueInput>>;
  set?: InputMaybe<Array<SkillWhereUniqueInput>>;
  update?: InputMaybe<Array<SkillUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<SkillUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<SkillUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutStoryActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutStoryActivitiesInput>;
  update?: InputMaybe<SkillUpdateWithoutStoryActivitiesInput>;
  upsert?: InputMaybe<SkillUpsertWithoutStoryActivitiesInput>;
};

export type SkillUpdateOneRequiredWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<SkillCreateWithoutWorkshopsInput>;
  update?: InputMaybe<SkillUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<SkillUpsertWithoutWorkshopsInput>;
};

export type SkillUpdateOneWithoutActivitiesNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<SkillCreateWithoutActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SkillUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<SkillUpsertWithoutActivitiesInput>;
};

export type SkillUpdateOneWithoutGamePointTypeNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutGamePointTypeInput>;
  create?: InputMaybe<SkillCreateWithoutGamePointTypeInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SkillUpdateWithoutGamePointTypeInput>;
  upsert?: InputMaybe<SkillUpsertWithoutGamePointTypeInput>;
};

export type SkillUpdateOneWithoutProductsNestedInput = {
  connect?: InputMaybe<SkillWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SkillCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<SkillCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<SkillUpdateWithoutProductsInput>;
  upsert?: InputMaybe<SkillUpsertWithoutProductsInput>;
};

export type SkillUpdateWithWhereUniqueWithoutSchoolInput = {
  data: SkillUpdateWithoutSchoolInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpdateWithWhereUniqueWithoutStandardsInput = {
  data: SkillUpdateWithoutStandardsInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpdateWithoutActivitiesInput = {
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutGamePointTypeInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutProductsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutSchoolInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutSkillNestedInput>;
};

export type SkillUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutSkillNestedInput>;
  bundle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutSkillNestedInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutSkillNestedInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutSkillsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutSkillsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutSkillNestedInput>;
  type?: InputMaybe<EnumSkillTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  weightage?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type SkillUpsertWithWhereUniqueWithoutSchoolInput = {
  create: SkillCreateWithoutSchoolInput;
  update: SkillUpdateWithoutSchoolInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpsertWithWhereUniqueWithoutStandardsInput = {
  create: SkillCreateWithoutStandardsInput;
  update: SkillUpdateWithoutStandardsInput;
  where: SkillWhereUniqueInput;
};

export type SkillUpsertWithoutActivitiesInput = {
  create: SkillCreateWithoutActivitiesInput;
  update: SkillUpdateWithoutActivitiesInput;
};

export type SkillUpsertWithoutGamePointTypeInput = {
  create: SkillCreateWithoutGamePointTypeInput;
  update: SkillUpdateWithoutGamePointTypeInput;
};

export type SkillUpsertWithoutProductsInput = {
  create: SkillCreateWithoutProductsInput;
  update: SkillUpdateWithoutProductsInput;
};

export type SkillUpsertWithoutStoryActivitiesInput = {
  create: SkillCreateWithoutStoryActivitiesInput;
  update: SkillUpdateWithoutStoryActivitiesInput;
};

export type SkillUpsertWithoutWorkshopsInput = {
  create: SkillCreateWithoutWorkshopsInput;
  update: SkillUpdateWithoutWorkshopsInput;
};

export type SkillWhereInput = {
  AND?: InputMaybe<Array<SkillWhereInput>>;
  NOT?: InputMaybe<Array<SkillWhereInput>>;
  OR?: InputMaybe<Array<SkillWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  bundle?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  gamePointType?: InputMaybe<GamePointTypeRelationFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  storyActivities?: InputMaybe<StoryActivityListRelationFilter>;
  type?: InputMaybe<EnumSkillTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  weightage?: InputMaybe<FloatFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type SkillWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type Standard = {
  __typename?: 'Standard';
  _count?: Maybe<StandardCount>;
  id: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type StandardAvgAggregate = {
  __typename?: 'StandardAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type StandardCount = {
  __typename?: 'StandardCount';
  activities: Scalars['Int']['output'];
  dailyActivities: Scalars['Int']['output'];
  products: Scalars['Int']['output'];
  reports: Scalars['Int']['output'];
  samskaras: Scalars['Int']['output'];
  schoolUpdates: Scalars['Int']['output'];
  skills: Scalars['Int']['output'];
  stories: Scalars['Int']['output'];
  storyActivities: Scalars['Int']['output'];
  studentRecords: Scalars['Int']['output'];
  students: Scalars['Int']['output'];
  users: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type StandardCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type StandardCountDailyActivitiesArgs = {
  where?: InputMaybe<DailyActivityWhereInput>;
};


export type StandardCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type StandardCountReportsArgs = {
  where?: InputMaybe<ReportWhereInput>;
};


export type StandardCountSamskarasArgs = {
  where?: InputMaybe<SamskaraWhereInput>;
};


export type StandardCountSchoolUpdatesArgs = {
  where?: InputMaybe<SchoolUpdateWhereInput>;
};


export type StandardCountSkillsArgs = {
  where?: InputMaybe<SkillWhereInput>;
};


export type StandardCountStoriesArgs = {
  where?: InputMaybe<StoryWhereInput>;
};


export type StandardCountStoryActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type StandardCountStudentRecordsArgs = {
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type StandardCountStudentsArgs = {
  where?: InputMaybe<StudentWhereInput>;
};


export type StandardCountUsersArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type StandardCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type StandardCountAggregate = {
  __typename?: 'StandardCountAggregate';
  _all: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  value: Scalars['Int']['output'];
};

export type StandardCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutActivitiesInput>>;
};

export type StandardCreateNestedManyWithoutDailyActivitiesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutDailyActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutDailyActivitiesInput>>;
};

export type StandardCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutProductsInput>>;
};

export type StandardCreateNestedManyWithoutSchoolUpdatesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSchoolUpdatesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSchoolUpdatesInput>>;
};

export type StandardCreateNestedManyWithoutSkillsInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSkillsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSkillsInput>>;
};

export type StandardCreateNestedManyWithoutStoriesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoriesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoriesInput>>;
};

export type StandardCreateNestedManyWithoutStoryActivitiesInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoryActivitiesInput>>;
};

export type StandardCreateNestedManyWithoutUsersInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutUsersInput>>;
};

export type StandardCreateNestedManyWithoutWorkshopsInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutWorkshopsInput>>;
};

export type StandardCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<StandardCreateWithoutReportsInput>;
};

export type StandardCreateNestedOneWithoutSamskarasInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<StandardCreateWithoutSamskarasInput>;
};

export type StandardCreateNestedOneWithoutStudentRecordsInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentRecordsInput>;
};

export type StandardCreateNestedOneWithoutStudentsInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentsInput>;
};

export type StandardCreateOrConnectWithoutActivitiesInput = {
  create: StandardCreateWithoutActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutDailyActivitiesInput = {
  create: StandardCreateWithoutDailyActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutProductsInput = {
  create: StandardCreateWithoutProductsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutReportsInput = {
  create: StandardCreateWithoutReportsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutSamskarasInput = {
  create: StandardCreateWithoutSamskarasInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutSchoolUpdatesInput = {
  create: StandardCreateWithoutSchoolUpdatesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutSkillsInput = {
  create: StandardCreateWithoutSkillsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStoriesInput = {
  create: StandardCreateWithoutStoriesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStoryActivitiesInput = {
  create: StandardCreateWithoutStoryActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStudentRecordsInput = {
  create: StandardCreateWithoutStudentRecordsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutStudentsInput = {
  create: StandardCreateWithoutStudentsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutUsersInput = {
  create: StandardCreateWithoutUsersInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateOrConnectWithoutWorkshopsInput = {
  create: StandardCreateWithoutWorkshopsInput;
  where: StandardWhereUniqueInput;
};

export type StandardCreateWithoutActivitiesInput = {
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutDailyActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutProductsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutReportsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutSamskarasInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutSchoolUpdatesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutSkillsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStoriesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStudentRecordsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutStudentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutUsersInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  value: Scalars['String']['input'];
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutStandardsInput>;
};

export type StandardCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutStandardsInput>;
  dailyActivities?: InputMaybe<DailyActivityCreateNestedManyWithoutStandardsInput>;
  key: Scalars['String']['input'];
  products?: InputMaybe<ProductCreateNestedManyWithoutStandardsInput>;
  reports?: InputMaybe<ReportCreateNestedManyWithoutStandardInput>;
  samskaras?: InputMaybe<SamskaraCreateNestedManyWithoutStandardInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateCreateNestedManyWithoutStandardsInput>;
  skills?: InputMaybe<SkillCreateNestedManyWithoutStandardsInput>;
  stories?: InputMaybe<StoryCreateNestedManyWithoutStandardsInput>;
  storyActivities?: InputMaybe<StoryActivityCreateNestedManyWithoutStandardsInput>;
  studentRecords?: InputMaybe<StudentRecordCreateNestedManyWithoutStandardInput>;
  students?: InputMaybe<StudentCreateNestedManyWithoutStandardInput>;
  users?: InputMaybe<UserCreateNestedManyWithoutStandardsInput>;
  value: Scalars['String']['input'];
};

export type StandardListRelationFilter = {
  every?: InputMaybe<StandardWhereInput>;
  none?: InputMaybe<StandardWhereInput>;
  some?: InputMaybe<StandardWhereInput>;
};

export type StandardMaxAggregate = {
  __typename?: 'StandardMaxAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StandardMinAggregate = {
  __typename?: 'StandardMinAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type StandardOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StandardOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  dailyActivities?: InputMaybe<DailyActivityOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  reports?: InputMaybe<ReportOrderByRelationAggregateInput>;
  samskaras?: InputMaybe<SamskaraOrderByRelationAggregateInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateOrderByRelationAggregateInput>;
  skills?: InputMaybe<SkillOrderByRelationAggregateInput>;
  stories?: InputMaybe<StoryOrderByRelationAggregateInput>;
  storyActivities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  studentRecords?: InputMaybe<StudentRecordOrderByRelationAggregateInput>;
  students?: InputMaybe<StudentOrderByRelationAggregateInput>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
  value?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type StandardRelationFilter = {
  is?: InputMaybe<StandardWhereInput>;
  isNot?: InputMaybe<StandardWhereInput>;
};

export enum StandardScalarFieldEnum {
  ID = 'id',
  KEY = 'key',
  VALUE = 'value'
}

export type StandardScalarWhereInput = {
  AND?: InputMaybe<Array<StandardScalarWhereInput>>;
  NOT?: InputMaybe<Array<StandardScalarWhereInput>>;
  OR?: InputMaybe<Array<StandardScalarWhereInput>>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<StringFilter>;
  value?: InputMaybe<StringFilter>;
};

export type StandardSumAggregate = {
  __typename?: 'StandardSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type StandardUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateManyMutationInput = {
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StandardUpdateManyWithWhereWithoutActivitiesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutDailyActivitiesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutProductsInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutSchoolUpdatesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutSkillsInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutStoriesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutStoryActivitiesInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutUsersInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithWhereWithoutWorkshopsInput = {
  data: StandardUpdateManyMutationInput;
  where: StandardScalarWhereInput;
};

export type StandardUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type StandardUpdateManyWithoutDailyActivitiesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutDailyActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutDailyActivitiesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutDailyActivitiesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutDailyActivitiesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutDailyActivitiesInput>>;
};

export type StandardUpdateManyWithoutProductsNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type StandardUpdateManyWithoutSchoolUpdatesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSchoolUpdatesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSchoolUpdatesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutSchoolUpdatesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutSchoolUpdatesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutSchoolUpdatesInput>>;
};

export type StandardUpdateManyWithoutSkillsNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutSkillsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutSkillsInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutSkillsInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutSkillsInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutSkillsInput>>;
};

export type StandardUpdateManyWithoutStoriesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoriesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoriesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutStoriesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutStoriesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutStoriesInput>>;
};

export type StandardUpdateManyWithoutStoryActivitiesNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutStoryActivitiesInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutStoryActivitiesInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutStoryActivitiesInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutStoryActivitiesInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutStoryActivitiesInput>>;
};

export type StandardUpdateManyWithoutUsersNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutUsersInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutUsersInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutUsersInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutUsersInput>>;
};

export type StandardUpdateManyWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StandardCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<StandardCreateWithoutWorkshopsInput>>;
  delete?: InputMaybe<Array<StandardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StandardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StandardWhereUniqueInput>>;
  set?: InputMaybe<Array<StandardWhereUniqueInput>>;
  update?: InputMaybe<Array<StandardUpdateWithWhereUniqueWithoutWorkshopsInput>>;
  updateMany?: InputMaybe<Array<StandardUpdateManyWithWhereWithoutWorkshopsInput>>;
  upsert?: InputMaybe<Array<StandardUpsertWithWhereUniqueWithoutWorkshopsInput>>;
};

export type StandardUpdateOneRequiredWithoutReportsNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<StandardCreateWithoutReportsInput>;
  update?: InputMaybe<StandardUpdateWithoutReportsInput>;
  upsert?: InputMaybe<StandardUpsertWithoutReportsInput>;
};

export type StandardUpdateOneRequiredWithoutSamskarasNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutSamskarasInput>;
  create?: InputMaybe<StandardCreateWithoutSamskarasInput>;
  update?: InputMaybe<StandardUpdateWithoutSamskarasInput>;
  upsert?: InputMaybe<StandardUpsertWithoutSamskarasInput>;
};

export type StandardUpdateOneRequiredWithoutStudentRecordsNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentRecordsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentRecordsInput>;
  update?: InputMaybe<StandardUpdateWithoutStudentRecordsInput>;
  upsert?: InputMaybe<StandardUpsertWithoutStudentRecordsInput>;
};

export type StandardUpdateOneRequiredWithoutStudentsNestedInput = {
  connect?: InputMaybe<StandardWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StandardCreateOrConnectWithoutStudentsInput>;
  create?: InputMaybe<StandardCreateWithoutStudentsInput>;
  update?: InputMaybe<StandardUpdateWithoutStudentsInput>;
  upsert?: InputMaybe<StandardUpsertWithoutStudentsInput>;
};

export type StandardUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: StandardUpdateWithoutActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutDailyActivitiesInput = {
  data: StandardUpdateWithoutDailyActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutProductsInput = {
  data: StandardUpdateWithoutProductsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutSchoolUpdatesInput = {
  data: StandardUpdateWithoutSchoolUpdatesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutSkillsInput = {
  data: StandardUpdateWithoutSkillsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutStoriesInput = {
  data: StandardUpdateWithoutStoriesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutStoryActivitiesInput = {
  data: StandardUpdateWithoutStoryActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutUsersInput = {
  data: StandardUpdateWithoutUsersInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithWhereUniqueWithoutWorkshopsInput = {
  data: StandardUpdateWithoutWorkshopsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpdateWithoutActivitiesInput = {
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutDailyActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutProductsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutReportsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutSamskarasInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutSchoolUpdatesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutSkillsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStoriesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStoryActivitiesInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStudentRecordsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutStudentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutUsersInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutStandardsNestedInput>;
};

export type StandardUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutStandardsNestedInput>;
  dailyActivities?: InputMaybe<DailyActivityUpdateManyWithoutStandardsNestedInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutStandardsNestedInput>;
  reports?: InputMaybe<ReportUpdateManyWithoutStandardNestedInput>;
  samskaras?: InputMaybe<SamskaraUpdateManyWithoutStandardNestedInput>;
  schoolUpdates?: InputMaybe<SchoolUpdateUpdateManyWithoutStandardsNestedInput>;
  skills?: InputMaybe<SkillUpdateManyWithoutStandardsNestedInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutStandardsNestedInput>;
  storyActivities?: InputMaybe<StoryActivityUpdateManyWithoutStandardsNestedInput>;
  studentRecords?: InputMaybe<StudentRecordUpdateManyWithoutStandardNestedInput>;
  students?: InputMaybe<StudentUpdateManyWithoutStandardNestedInput>;
  users?: InputMaybe<UserUpdateManyWithoutStandardsNestedInput>;
  value?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StandardUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: StandardCreateWithoutActivitiesInput;
  update: StandardUpdateWithoutActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutDailyActivitiesInput = {
  create: StandardCreateWithoutDailyActivitiesInput;
  update: StandardUpdateWithoutDailyActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutProductsInput = {
  create: StandardCreateWithoutProductsInput;
  update: StandardUpdateWithoutProductsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutSchoolUpdatesInput = {
  create: StandardCreateWithoutSchoolUpdatesInput;
  update: StandardUpdateWithoutSchoolUpdatesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutSkillsInput = {
  create: StandardCreateWithoutSkillsInput;
  update: StandardUpdateWithoutSkillsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutStoriesInput = {
  create: StandardCreateWithoutStoriesInput;
  update: StandardUpdateWithoutStoriesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutStoryActivitiesInput = {
  create: StandardCreateWithoutStoryActivitiesInput;
  update: StandardUpdateWithoutStoryActivitiesInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutUsersInput = {
  create: StandardCreateWithoutUsersInput;
  update: StandardUpdateWithoutUsersInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithWhereUniqueWithoutWorkshopsInput = {
  create: StandardCreateWithoutWorkshopsInput;
  update: StandardUpdateWithoutWorkshopsInput;
  where: StandardWhereUniqueInput;
};

export type StandardUpsertWithoutReportsInput = {
  create: StandardCreateWithoutReportsInput;
  update: StandardUpdateWithoutReportsInput;
};

export type StandardUpsertWithoutSamskarasInput = {
  create: StandardCreateWithoutSamskarasInput;
  update: StandardUpdateWithoutSamskarasInput;
};

export type StandardUpsertWithoutStudentRecordsInput = {
  create: StandardCreateWithoutStudentRecordsInput;
  update: StandardUpdateWithoutStudentRecordsInput;
};

export type StandardUpsertWithoutStudentsInput = {
  create: StandardCreateWithoutStudentsInput;
  update: StandardUpdateWithoutStudentsInput;
};

export type StandardWhereInput = {
  AND?: InputMaybe<Array<StandardWhereInput>>;
  NOT?: InputMaybe<Array<StandardWhereInput>>;
  OR?: InputMaybe<Array<StandardWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  dailyActivities?: InputMaybe<DailyActivityListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  key?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  reports?: InputMaybe<ReportListRelationFilter>;
  samskaras?: InputMaybe<SamskaraListRelationFilter>;
  schoolUpdates?: InputMaybe<SchoolUpdateListRelationFilter>;
  skills?: InputMaybe<SkillListRelationFilter>;
  stories?: InputMaybe<StoryListRelationFilter>;
  storyActivities?: InputMaybe<StoryActivityListRelationFilter>;
  studentRecords?: InputMaybe<StudentRecordListRelationFilter>;
  students?: InputMaybe<StudentListRelationFilter>;
  users?: InputMaybe<UserListRelationFilter>;
  value?: InputMaybe<StringFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type StandardWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export enum Status {
  DRAFT = 'DRAFT',
  PENDING_REVIEW = 'PENDING_REVIEW',
  PUBLISHED = 'PUBLISHED'
}

export type Story = {
  __typename?: 'Story';
  _count?: Maybe<StoryCount>;
  activities: Array<StoryActivity>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayOrder: Scalars['Int']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  school?: Maybe<School>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  standards: Array<Standard>;
  tag?: Maybe<StoryTag>;
  tagId?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type StoryActivitiesArgs = {
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type StoryStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryActivity = {
  __typename?: 'StoryActivity';
  _count?: Maybe<StoryActivityCount>;
  audio?: Maybe<Scalars['String']['output']>;
  challengeStatement: Scalars['String']['output'];
  coinsToSpend: Scalars['Int']['output'];
  comments: Array<Comment>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayOrder: Scalars['Int']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  isCommentEnabled: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  products: Array<Product>;
  promoCodes: Array<PromoCode>;
  publishedDate?: Maybe<Scalars['DateTime']['output']>;
  questionXps: Scalars['Int']['output'];
  questions: Array<ActivityQuestion>;
  skill: Skill;
  skillId: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  standards: Array<Standard>;
  status: ActivityStatus;
  story: Story;
  storyId: Scalars['Int']['output'];
  timeRequired: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  video?: Maybe<Scalars['String']['output']>;
  xps: Scalars['Int']['output'];
};


export type StoryActivityCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type StoryActivityProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type StoryActivityPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type StoryActivityQuestionsArgs = {
  cursor?: InputMaybe<ActivityQuestionWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityQuestionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityQuestionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type StoryActivityStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryActivityAvgAggregate = {
  __typename?: 'StoryActivityAvgAggregate';
  coinsToSpend?: Maybe<Scalars['Float']['output']>;
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  questionXps?: Maybe<Scalars['Float']['output']>;
  skillId?: Maybe<Scalars['Float']['output']>;
  storyId?: Maybe<Scalars['Float']['output']>;
  xps?: Maybe<Scalars['Float']['output']>;
};

export type StoryActivityCount = {
  __typename?: 'StoryActivityCount';
  comments: Scalars['Int']['output'];
  products: Scalars['Int']['output'];
  promoCodes: Scalars['Int']['output'];
  questions: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
};


export type StoryActivityCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type StoryActivityCountProductsArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type StoryActivityCountPromoCodesArgs = {
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type StoryActivityCountQuestionsArgs = {
  where?: InputMaybe<ActivityQuestionWhereInput>;
};


export type StoryActivityCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryActivityCountAggregate = {
  __typename?: 'StoryActivityCountAggregate';
  _all: Scalars['Int']['output'];
  audio: Scalars['Int']['output'];
  challengeStatement: Scalars['Int']['output'];
  coinsToSpend: Scalars['Int']['output'];
  content: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  displayOrder: Scalars['Int']['output'];
  featuredImage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  isCommentEnabled: Scalars['Int']['output'];
  isFeatured: Scalars['Int']['output'];
  meta: Scalars['Int']['output'];
  publishedDate: Scalars['Int']['output'];
  questionXps: Scalars['Int']['output'];
  skillId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  storyId: Scalars['Int']['output'];
  timeRequired: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  video: Scalars['Int']['output'];
  xps: Scalars['Int']['output'];
};

export type StoryActivityCreateInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateManySkillInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  storyId: Scalars['Int']['input'];
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateManySkillInputEnvelope = {
  data: Array<StoryActivityCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoryActivityCreateManyStoryInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  skillId: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateManyStoryInputEnvelope = {
  data: Array<StoryActivityCreateManyStoryInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoryActivityCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutProductsInput>>;
};

export type StoryActivityCreateNestedManyWithoutPromoCodesInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutPromoCodesInput>>;
};

export type StoryActivityCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<StoryActivityCreateManySkillInputEnvelope>;
};

export type StoryActivityCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStandardsInput>>;
};

export type StoryActivityCreateNestedManyWithoutStoryInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStoryInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStoryInput>>;
  createMany?: InputMaybe<StoryActivityCreateManyStoryInputEnvelope>;
};

export type StoryActivityCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutCommentsInput>;
};

export type StoryActivityCreateNestedOneWithoutQuestionsInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutQuestionsInput>;
};

export type StoryActivityCreateOrConnectWithoutCommentsInput = {
  create: StoryActivityCreateWithoutCommentsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutProductsInput = {
  create: StoryActivityCreateWithoutProductsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutPromoCodesInput = {
  create: StoryActivityCreateWithoutPromoCodesInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutQuestionsInput = {
  create: StoryActivityCreateWithoutQuestionsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutSkillInput = {
  create: StoryActivityCreateWithoutSkillInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutStandardsInput = {
  create: StoryActivityCreateWithoutStandardsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateOrConnectWithoutStoryInput = {
  create: StoryActivityCreateWithoutStoryInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityCreateWithoutCommentsInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateWithoutProductsInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateWithoutPromoCodesInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateWithoutQuestionsInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateWithoutSkillInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateWithoutStandardsInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  status?: InputMaybe<ActivityStatus>;
  story: StoryCreateNestedOneWithoutActivitiesInput;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityCreateWithoutStoryInput = {
  audio?: InputMaybe<Scalars['String']['input']>;
  challengeStatement: Scalars['String']['input'];
  coinsToSpend?: InputMaybe<Scalars['Int']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutStoryActivityInput>;
  content: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isCommentEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutRelatedStoryActivitiesInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutStoryActivitiesInput>;
  publishedDate?: InputMaybe<Scalars['DateTime']['input']>;
  questionXps?: InputMaybe<Scalars['Int']['input']>;
  questions?: InputMaybe<ActivityQuestionCreateNestedManyWithoutStoryActivityInput>;
  skill: SkillCreateNestedOneWithoutStoryActivitiesInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoryActivitiesInput>;
  status?: InputMaybe<ActivityStatus>;
  timeRequired: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  video?: InputMaybe<Scalars['String']['input']>;
  xps?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryActivityListRelationFilter = {
  every?: InputMaybe<StoryActivityWhereInput>;
  none?: InputMaybe<StoryActivityWhereInput>;
  some?: InputMaybe<StoryActivityWhereInput>;
};

export type StoryActivityMaxAggregate = {
  __typename?: 'StoryActivityMaxAggregate';
  audio?: Maybe<Scalars['String']['output']>;
  challengeStatement?: Maybe<Scalars['String']['output']>;
  coinsToSpend?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isCommentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  publishedDate?: Maybe<Scalars['DateTime']['output']>;
  questionXps?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActivityStatus>;
  storyId?: Maybe<Scalars['Int']['output']>;
  timeRequired?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

export type StoryActivityMinAggregate = {
  __typename?: 'StoryActivityMinAggregate';
  audio?: Maybe<Scalars['String']['output']>;
  challengeStatement?: Maybe<Scalars['String']['output']>;
  coinsToSpend?: Maybe<Scalars['Int']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isCommentEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  publishedDate?: Maybe<Scalars['DateTime']['output']>;
  questionXps?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActivityStatus>;
  storyId?: Maybe<Scalars['Int']['output']>;
  timeRequired?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  video?: Maybe<Scalars['String']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

export type StoryActivityOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoryActivityOrderByWithRelationInput = {
  audio?: InputMaybe<SortOrderInput>;
  challengeStatement?: InputMaybe<SortOrder>;
  coinsToSpend?: InputMaybe<SortOrder>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  isCommentEnabled?: InputMaybe<SortOrder>;
  isFeatured?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  products?: InputMaybe<ProductOrderByRelationAggregateInput>;
  promoCodes?: InputMaybe<PromoCodeOrderByRelationAggregateInput>;
  publishedDate?: InputMaybe<SortOrderInput>;
  questionXps?: InputMaybe<SortOrder>;
  questions?: InputMaybe<ActivityQuestionOrderByRelationAggregateInput>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  status?: InputMaybe<SortOrder>;
  story?: InputMaybe<StoryOrderByWithRelationInput>;
  storyId?: InputMaybe<SortOrder>;
  timeRequired?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  video?: InputMaybe<SortOrderInput>;
  xps?: InputMaybe<SortOrder>;
};

export type StoryActivityRelationFilter = {
  is?: InputMaybe<StoryActivityWhereInput>;
  isNot?: InputMaybe<StoryActivityWhereInput>;
};

export enum StoryActivityScalarFieldEnum {
  AUDIO = 'audio',
  CHALLENGESTATEMENT = 'challengeStatement',
  COINSTOSPEND = 'coinsToSpend',
  CONTENT = 'content',
  CREATEDAT = 'createdAt',
  DISPLAYORDER = 'displayOrder',
  FEATUREDIMAGE = 'featuredImage',
  ID = 'id',
  IMAGE = 'image',
  ISCOMMENTENABLED = 'isCommentEnabled',
  ISFEATURED = 'isFeatured',
  META = 'meta',
  PUBLISHEDDATE = 'publishedDate',
  QUESTIONXPS = 'questionXps',
  SKILLID = 'skillId',
  SLUG = 'slug',
  STATUS = 'status',
  STORYID = 'storyId',
  TIMEREQUIRED = 'timeRequired',
  TITLE = 'title',
  UPDATEDAT = 'updatedAt',
  VIDEO = 'video',
  XPS = 'xps'
}

export type StoryActivityScalarWhereInput = {
  AND?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  OR?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  audio?: InputMaybe<StringNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  storyId?: InputMaybe<IntFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export type StoryActivitySumAggregate = {
  __typename?: 'StoryActivitySumAggregate';
  coinsToSpend?: Maybe<Scalars['Int']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  questionXps?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  storyId?: Maybe<Scalars['Int']['output']>;
  xps?: Maybe<Scalars['Int']['output']>;
};

export type StoryActivityUpdateInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateManyMutationInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateManyWithWhereWithoutProductsInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutPromoCodesInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutSkillInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutStandardsInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithWhereWithoutStoryInput = {
  data: StoryActivityUpdateManyMutationInput;
  where: StoryActivityScalarWhereInput;
};

export type StoryActivityUpdateManyWithoutProductsNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type StoryActivityUpdateManyWithoutPromoCodesNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutPromoCodesInput>>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutPromoCodesInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutPromoCodesInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutPromoCodesInput>>;
};

export type StoryActivityUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutSkillInput>>;
  createMany?: InputMaybe<StoryActivityCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type StoryActivityUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type StoryActivityUpdateManyWithoutStoryNestedInput = {
  connect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryActivityCreateOrConnectWithoutStoryInput>>;
  create?: InputMaybe<Array<StoryActivityCreateWithoutStoryInput>>;
  createMany?: InputMaybe<StoryActivityCreateManyStoryInputEnvelope>;
  delete?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryActivityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryActivityWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryActivityUpdateWithWhereUniqueWithoutStoryInput>>;
  updateMany?: InputMaybe<Array<StoryActivityUpdateManyWithWhereWithoutStoryInput>>;
  upsert?: InputMaybe<Array<StoryActivityUpsertWithWhereUniqueWithoutStoryInput>>;
};

export type StoryActivityUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StoryActivityUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<StoryActivityUpsertWithoutCommentsInput>;
};

export type StoryActivityUpdateOneWithoutQuestionsNestedInput = {
  connect?: InputMaybe<StoryActivityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryActivityCreateOrConnectWithoutQuestionsInput>;
  create?: InputMaybe<StoryActivityCreateWithoutQuestionsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StoryActivityUpdateWithoutQuestionsInput>;
  upsert?: InputMaybe<StoryActivityUpsertWithoutQuestionsInput>;
};

export type StoryActivityUpdateWithWhereUniqueWithoutProductsInput = {
  data: StoryActivityUpdateWithoutProductsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutPromoCodesInput = {
  data: StoryActivityUpdateWithoutPromoCodesInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutSkillInput = {
  data: StoryActivityUpdateWithoutSkillInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutStandardsInput = {
  data: StoryActivityUpdateWithoutStandardsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithWhereUniqueWithoutStoryInput = {
  data: StoryActivityUpdateWithoutStoryInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpdateWithoutCommentsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutProductsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutPromoCodesInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutQuestionsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutSkillInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutStandardsInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  story?: InputMaybe<StoryUpdateOneRequiredWithoutActivitiesNestedInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpdateWithoutStoryInput = {
  audio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  challengeStatement?: InputMaybe<StringFieldUpdateOperationsInput>;
  coinsToSpend?: InputMaybe<IntFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutStoryActivityNestedInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isCommentEnabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isFeatured?: InputMaybe<BoolFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  products?: InputMaybe<ProductUpdateManyWithoutRelatedStoryActivitiesNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutStoryActivitiesNestedInput>;
  publishedDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  questionXps?: InputMaybe<IntFieldUpdateOperationsInput>;
  questions?: InputMaybe<ActivityQuestionUpdateManyWithoutStoryActivityNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutStoryActivitiesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoryActivitiesNestedInput>;
  status?: InputMaybe<EnumActivityStatusFieldUpdateOperationsInput>;
  timeRequired?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  video?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  xps?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type StoryActivityUpsertWithWhereUniqueWithoutProductsInput = {
  create: StoryActivityCreateWithoutProductsInput;
  update: StoryActivityUpdateWithoutProductsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutPromoCodesInput = {
  create: StoryActivityCreateWithoutPromoCodesInput;
  update: StoryActivityUpdateWithoutPromoCodesInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutSkillInput = {
  create: StoryActivityCreateWithoutSkillInput;
  update: StoryActivityUpdateWithoutSkillInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutStandardsInput = {
  create: StoryActivityCreateWithoutStandardsInput;
  update: StoryActivityUpdateWithoutStandardsInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithWhereUniqueWithoutStoryInput = {
  create: StoryActivityCreateWithoutStoryInput;
  update: StoryActivityUpdateWithoutStoryInput;
  where: StoryActivityWhereUniqueInput;
};

export type StoryActivityUpsertWithoutCommentsInput = {
  create: StoryActivityCreateWithoutCommentsInput;
  update: StoryActivityUpdateWithoutCommentsInput;
};

export type StoryActivityUpsertWithoutQuestionsInput = {
  create: StoryActivityCreateWithoutQuestionsInput;
  update: StoryActivityUpdateWithoutQuestionsInput;
};

export type StoryActivityWhereInput = {
  AND?: InputMaybe<Array<StoryActivityWhereInput>>;
  NOT?: InputMaybe<Array<StoryActivityWhereInput>>;
  OR?: InputMaybe<Array<StoryActivityWhereInput>>;
  audio?: InputMaybe<StringNullableFilter>;
  challengeStatement?: InputMaybe<StringFilter>;
  coinsToSpend?: InputMaybe<IntFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isCommentEnabled?: InputMaybe<BoolFilter>;
  isFeatured?: InputMaybe<BoolFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  promoCodes?: InputMaybe<PromoCodeListRelationFilter>;
  publishedDate?: InputMaybe<DateTimeNullableFilter>;
  questionXps?: InputMaybe<IntFilter>;
  questions?: InputMaybe<ActivityQuestionListRelationFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  status?: InputMaybe<EnumActivityStatusFilter>;
  story?: InputMaybe<StoryRelationFilter>;
  storyId?: InputMaybe<IntFilter>;
  timeRequired?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  video?: InputMaybe<StringNullableFilter>;
  xps?: InputMaybe<IntFilter>;
};

export type StoryActivityWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type StoryAvgAggregate = {
  __typename?: 'StoryAvgAggregate';
  displayOrder?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
  tagId?: Maybe<Scalars['Float']['output']>;
};

export type StoryBanner = {
  __typename?: 'StoryBanner';
  createdAt: Scalars['DateTime']['output'];
  destinationUrl: Scalars['String']['output'];
  displayOrder: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type StoryBannerCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  destinationUrl: Scalars['String']['input'];
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryBannerOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  destinationUrl?: InputMaybe<SortOrder>;
  displayOrder?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum StoryBannerScalarFieldEnum {
  CREATEDAT = 'createdAt',
  DESTINATIONURL = 'destinationUrl',
  DISPLAYORDER = 'displayOrder',
  ID = 'id',
  IMAGE = 'image',
  UPDATEDAT = 'updatedAt'
}

export type StoryBannerUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  destinationUrl?: InputMaybe<StringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryBannerWhereInput = {
  AND?: InputMaybe<Array<StoryBannerWhereInput>>;
  NOT?: InputMaybe<Array<StoryBannerWhereInput>>;
  OR?: InputMaybe<Array<StoryBannerWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  destinationUrl?: InputMaybe<StringFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StoryBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StoryCount = {
  __typename?: 'StoryCount';
  activities: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
};


export type StoryCountActivitiesArgs = {
  where?: InputMaybe<StoryActivityWhereInput>;
};


export type StoryCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};

export type StoryCountAggregate = {
  __typename?: 'StoryCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  displayOrder: Scalars['Int']['output'];
  featuredImage: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  tagId: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type StoryCreateInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryCreateManySchoolInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
  tagId?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryCreateManySchoolInputEnvelope = {
  data: Array<StoryCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoryCreateManyTagInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  schoolId?: InputMaybe<Scalars['Int']['input']>;
  slug: Scalars['String']['input'];
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryCreateManyTagInputEnvelope = {
  data: Array<StoryCreateManyTagInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoryCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StoryCreateManySchoolInputEnvelope>;
};

export type StoryCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutStandardsInput>>;
};

export type StoryCreateNestedManyWithoutTagInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutTagInput>>;
  createMany?: InputMaybe<StoryCreateManyTagInputEnvelope>;
};

export type StoryCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<StoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<StoryCreateWithoutActivitiesInput>;
};

export type StoryCreateOrConnectWithoutActivitiesInput = {
  create: StoryCreateWithoutActivitiesInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateOrConnectWithoutSchoolInput = {
  create: StoryCreateWithoutSchoolInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateOrConnectWithoutStandardsInput = {
  create: StoryCreateWithoutStandardsInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateOrConnectWithoutTagInput = {
  create: StoryCreateWithoutTagInput;
  where: StoryWhereUniqueInput;
};

export type StoryCreateWithoutActivitiesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryCreateWithoutSchoolInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryCreateWithoutStandardsInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars['String']['input'];
  tag?: InputMaybe<StoryTagCreateNestedOneWithoutStoriesInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryCreateWithoutTagInput = {
  activities?: InputMaybe<StoryActivityCreateNestedManyWithoutStoryInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayOrder?: InputMaybe<Scalars['Int']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  school?: InputMaybe<SchoolCreateNestedOneWithoutStoriesInput>;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutStoriesInput>;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StoryListRelationFilter = {
  every?: InputMaybe<StoryWhereInput>;
  none?: InputMaybe<StoryWhereInput>;
  some?: InputMaybe<StoryWhereInput>;
};

export type StoryMaxAggregate = {
  __typename?: 'StoryMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tagId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StoryMinAggregate = {
  __typename?: 'StoryMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayOrder?: Maybe<Scalars['Int']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  tagId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StoryOrderByWithRelationInput = {
  activities?: InputMaybe<StoryActivityOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  displayOrder?: InputMaybe<SortOrder>;
  featuredImage?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  isActive?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  tag?: InputMaybe<StoryTagOrderByWithRelationInput>;
  tagId?: InputMaybe<SortOrderInput>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type StoryRelationFilter = {
  is?: InputMaybe<StoryWhereInput>;
  isNot?: InputMaybe<StoryWhereInput>;
};

export enum StoryScalarFieldEnum {
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DISPLAYORDER = 'displayOrder',
  FEATUREDIMAGE = 'featuredImage',
  ID = 'id',
  IMAGE = 'image',
  ISACTIVE = 'isActive',
  SCHOOLID = 'schoolId',
  SLUG = 'slug',
  TAGID = 'tagId',
  TITLE = 'title',
  UPDATEDAT = 'updatedAt'
}

export type StoryScalarWhereInput = {
  AND?: InputMaybe<Array<StoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<StoryScalarWhereInput>>;
  OR?: InputMaybe<Array<StoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  tagId?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StorySumAggregate = {
  __typename?: 'StorySumAggregate';
  displayOrder?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  tagId?: Maybe<Scalars['Int']['output']>;
};

export type StoryTag = {
  __typename?: 'StoryTag';
  _count?: Maybe<StoryTagCount>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  stories: Array<Story>;
  text?: Maybe<Scalars['String']['output']>;
};


export type StoryTagStoriesArgs = {
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StoryWhereInput>;
};

export type StoryTagAvgAggregate = {
  __typename?: 'StoryTagAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type StoryTagCount = {
  __typename?: 'StoryTagCount';
  stories: Scalars['Int']['output'];
};


export type StoryTagCountStoriesArgs = {
  where?: InputMaybe<StoryWhereInput>;
};

export type StoryTagCountAggregate = {
  __typename?: 'StoryTagCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
};

export type StoryTagCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  stories?: InputMaybe<StoryCreateNestedManyWithoutTagInput>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type StoryTagCreateNestedOneWithoutStoriesInput = {
  connect?: InputMaybe<StoryTagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryTagCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<StoryTagCreateWithoutStoriesInput>;
};

export type StoryTagCreateOrConnectWithoutStoriesInput = {
  create: StoryTagCreateWithoutStoriesInput;
  where: StoryTagWhereUniqueInput;
};

export type StoryTagCreateWithoutStoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  key: Scalars['String']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export type StoryTagMaxAggregate = {
  __typename?: 'StoryTagMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type StoryTagMinAggregate = {
  __typename?: 'StoryTagMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type StoryTagOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  key?: InputMaybe<SortOrder>;
  stories?: InputMaybe<StoryOrderByRelationAggregateInput>;
  text?: InputMaybe<SortOrderInput>;
};

export type StoryTagRelationFilter = {
  is?: InputMaybe<StoryTagWhereInput>;
  isNot?: InputMaybe<StoryTagWhereInput>;
};

export enum StoryTagScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  IMAGE = 'image',
  KEY = 'key',
  TEXT = 'text'
}

export type StoryTagSumAggregate = {
  __typename?: 'StoryTagSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type StoryTagUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  stories?: InputMaybe<StoryUpdateManyWithoutTagNestedInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StoryTagUpdateOneWithoutStoriesNestedInput = {
  connect?: InputMaybe<StoryTagWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryTagCreateOrConnectWithoutStoriesInput>;
  create?: InputMaybe<StoryTagCreateWithoutStoriesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StoryTagUpdateWithoutStoriesInput>;
  upsert?: InputMaybe<StoryTagUpsertWithoutStoriesInput>;
};

export type StoryTagUpdateWithoutStoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type StoryTagUpsertWithoutStoriesInput = {
  create: StoryTagCreateWithoutStoriesInput;
  update: StoryTagUpdateWithoutStoriesInput;
};

export type StoryTagWhereInput = {
  AND?: InputMaybe<Array<StoryTagWhereInput>>;
  NOT?: InputMaybe<Array<StoryTagWhereInput>>;
  OR?: InputMaybe<Array<StoryTagWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  key?: InputMaybe<StringFilter>;
  stories?: InputMaybe<StoryListRelationFilter>;
  text?: InputMaybe<StringNullableFilter>;
};

export type StoryTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type StoryUpdateInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateManyWithWhereWithoutSchoolInput = {
  data: StoryUpdateManyMutationInput;
  where: StoryScalarWhereInput;
};

export type StoryUpdateManyWithWhereWithoutStandardsInput = {
  data: StoryUpdateManyMutationInput;
  where: StoryScalarWhereInput;
};

export type StoryUpdateManyWithWhereWithoutTagInput = {
  data: StoryUpdateManyMutationInput;
  where: StoryScalarWhereInput;
};

export type StoryUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StoryCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<StoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<StoryUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<StoryUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type StoryUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<StoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<StoryUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<StoryUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type StoryUpdateManyWithoutTagNestedInput = {
  connect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StoryCreateOrConnectWithoutTagInput>>;
  create?: InputMaybe<Array<StoryCreateWithoutTagInput>>;
  createMany?: InputMaybe<StoryCreateManyTagInputEnvelope>;
  delete?: InputMaybe<Array<StoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StoryWhereUniqueInput>>;
  set?: InputMaybe<Array<StoryWhereUniqueInput>>;
  update?: InputMaybe<Array<StoryUpdateWithWhereUniqueWithoutTagInput>>;
  updateMany?: InputMaybe<Array<StoryUpdateManyWithWhereWithoutTagInput>>;
  upsert?: InputMaybe<Array<StoryUpsertWithWhereUniqueWithoutTagInput>>;
};

export type StoryUpdateOneRequiredWithoutActivitiesNestedInput = {
  connect?: InputMaybe<StoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StoryCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<StoryCreateWithoutActivitiesInput>;
  update?: InputMaybe<StoryUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<StoryUpsertWithoutActivitiesInput>;
};

export type StoryUpdateWithWhereUniqueWithoutSchoolInput = {
  data: StoryUpdateWithoutSchoolInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpdateWithWhereUniqueWithoutStandardsInput = {
  data: StoryUpdateWithoutStandardsInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpdateWithWhereUniqueWithoutTagInput = {
  data: StoryUpdateWithoutTagInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpdateWithoutActivitiesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateWithoutSchoolInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateWithoutStandardsInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  tag?: InputMaybe<StoryTagUpdateOneWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpdateWithoutTagInput = {
  activities?: InputMaybe<StoryActivityUpdateManyWithoutStoryNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayOrder?: InputMaybe<IntFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneWithoutStoriesNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutStoriesNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StoryUpsertWithWhereUniqueWithoutSchoolInput = {
  create: StoryCreateWithoutSchoolInput;
  update: StoryUpdateWithoutSchoolInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpsertWithWhereUniqueWithoutStandardsInput = {
  create: StoryCreateWithoutStandardsInput;
  update: StoryUpdateWithoutStandardsInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpsertWithWhereUniqueWithoutTagInput = {
  create: StoryCreateWithoutTagInput;
  update: StoryUpdateWithoutTagInput;
  where: StoryWhereUniqueInput;
};

export type StoryUpsertWithoutActivitiesInput = {
  create: StoryCreateWithoutActivitiesInput;
  update: StoryUpdateWithoutActivitiesInput;
};

export type StoryWhereInput = {
  AND?: InputMaybe<Array<StoryWhereInput>>;
  NOT?: InputMaybe<Array<StoryWhereInput>>;
  OR?: InputMaybe<Array<StoryWhereInput>>;
  activities?: InputMaybe<StoryActivityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayOrder?: InputMaybe<IntFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isActive?: InputMaybe<BoolFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  tag?: InputMaybe<StoryTagRelationFilter>;
  tagId?: InputMaybe<IntNullableFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']['input']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Student = {
  __typename?: 'Student';
  _count?: Maybe<StudentCount>;
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  badges: Array<StudentBadge>;
  city?: Maybe<Scalars['String']['output']>;
  comments: Array<Comment>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayName: Scalars['String']['output'];
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  gameLogs: Array<StudentGameLog>;
  id: Scalars['Int']['output'];
  invitedStudents?: Maybe<ReferralCodeHistory>;
  isActive: Scalars['Boolean']['output'];
  isHomeschooler: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  level: Scalars['Int']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  notificationTokens: Array<StudentNotifications>;
  orders: Array<Order>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  privacyPolicyUpdates: Array<PrivacyPolicyUpdate>;
  profileImage?: Maybe<Scalars['String']['output']>;
  promoCodeHistory: Array<PromoCodeHistory>;
  records: Array<StudentRecord>;
  referralCodes: Array<ReferralCode>;
  referralStudents?: Maybe<ReferralCodeHistory>;
  role: Role;
  school: School;
  schoolId: Scalars['Int']['output'];
  schoolText?: Maybe<Scalars['String']['output']>;
  standard: Standard;
  standardId: Scalars['Int']['output'];
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  workshopRegistrations: Array<WorkshopRegistration>;
};


export type StudentBadgesArgs = {
  cursor?: InputMaybe<StudentBadgeWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentBadgeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentBadgeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type StudentCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type StudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type StudentNotificationTokensArgs = {
  cursor?: InputMaybe<StudentNotificationsWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentNotificationsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentNotificationsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentNotificationsWhereInput>;
};


export type StudentOrdersArgs = {
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OrderWhereInput>;
};


export type StudentPrivacyPolicyUpdatesArgs = {
  cursor?: InputMaybe<PrivacyPolicyUpdateWhereUniqueInput>;
  distinct?: InputMaybe<Array<PrivacyPolicyUpdateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PrivacyPolicyUpdateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type StudentPromoCodeHistoryArgs = {
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type StudentRecordsArgs = {
  cursor?: InputMaybe<StudentRecordWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentRecordScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentRecordOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type StudentReferralCodesArgs = {
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<ReferralCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type StudentWorkshopRegistrationsArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type StudentAssignBadgeInput = {
  badge: BadgeWhereUniqueInput;
  student: StudentWhereUniqueInput;
};

export type StudentAssignBadgeResponse = {
  __typename?: 'StudentAssignBadgeResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type StudentAvgAggregate = {
  __typename?: 'StudentAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  level?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
  standardId?: Maybe<Scalars['Float']['output']>;
};

export type StudentBadge = {
  __typename?: 'StudentBadge';
  badgeId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
};

export type StudentBadgeCreateManyBadgeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['Int']['input'];
};

export type StudentBadgeCreateManyBadgeInputEnvelope = {
  data: Array<StudentBadgeCreateManyBadgeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentBadgeCreateManyStudentInput = {
  badgeId: Scalars['Int']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentBadgeCreateManyStudentInputEnvelope = {
  data: Array<StudentBadgeCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentBadgeCreateNestedManyWithoutBadgeInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyBadgeInputEnvelope>;
};

export type StudentBadgeCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyStudentInputEnvelope>;
};

export type StudentBadgeCreateOrConnectWithoutBadgeInput = {
  create: StudentBadgeCreateWithoutBadgeInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeCreateOrConnectWithoutStudentInput = {
  create: StudentBadgeCreateWithoutStudentInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeCreateWithoutBadgeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  student: StudentCreateNestedOneWithoutBadgesInput;
};

export type StudentBadgeCreateWithoutStudentInput = {
  badge: BadgeCreateNestedOneWithoutStudentBadgesInput;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StudentBadgeListRelationFilter = {
  every?: InputMaybe<StudentBadgeWhereInput>;
  none?: InputMaybe<StudentBadgeWhereInput>;
  some?: InputMaybe<StudentBadgeWhereInput>;
};

export type StudentBadgeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentBadgeOrderByWithRelationInput = {
  badge?: InputMaybe<BadgeOrderByWithRelationInput>;
  badgeId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum StudentBadgeScalarFieldEnum {
  BADGEID = 'badgeId',
  CREATEDAT = 'createdAt',
  ID = 'id',
  STUDENTID = 'studentId'
}

export type StudentBadgeScalarWhereInput = {
  AND?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  badgeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentBadgeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentBadgeUpdateManyWithWhereWithoutBadgeInput = {
  data: StudentBadgeUpdateManyMutationInput;
  where: StudentBadgeScalarWhereInput;
};

export type StudentBadgeUpdateManyWithWhereWithoutStudentInput = {
  data: StudentBadgeUpdateManyMutationInput;
  where: StudentBadgeScalarWhereInput;
};

export type StudentBadgeUpdateManyWithoutBadgeNestedInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyBadgeInputEnvelope>;
  delete?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentBadgeUpdateWithWhereUniqueWithoutBadgeInput>>;
  updateMany?: InputMaybe<Array<StudentBadgeUpdateManyWithWhereWithoutBadgeInput>>;
  upsert?: InputMaybe<Array<StudentBadgeUpsertWithWhereUniqueWithoutBadgeInput>>;
};

export type StudentBadgeUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentBadgeCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentBadgeCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentBadgeCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentBadgeUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentBadgeUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentBadgeUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentBadgeUpdateWithWhereUniqueWithoutBadgeInput = {
  data: StudentBadgeUpdateWithoutBadgeInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentBadgeUpdateWithoutStudentInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeUpdateWithoutBadgeInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutBadgesNestedInput>;
};

export type StudentBadgeUpdateWithoutStudentInput = {
  badge?: InputMaybe<BadgeUpdateOneRequiredWithoutStudentBadgesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentBadgeUpsertWithWhereUniqueWithoutBadgeInput = {
  create: StudentBadgeCreateWithoutBadgeInput;
  update: StudentBadgeUpdateWithoutBadgeInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentBadgeCreateWithoutStudentInput;
  update: StudentBadgeUpdateWithoutStudentInput;
  where: StudentBadgeWhereUniqueInput;
};

export type StudentBadgeWhereInput = {
  AND?: InputMaybe<Array<StudentBadgeWhereInput>>;
  NOT?: InputMaybe<Array<StudentBadgeWhereInput>>;
  OR?: InputMaybe<Array<StudentBadgeWhereInput>>;
  badge?: InputMaybe<BadgeRelationFilter>;
  badgeId?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentBadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentCoinLogsResponse = {
  __typename?: 'StudentCoinLogsResponse';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type StudentCount = {
  __typename?: 'StudentCount';
  badges: Scalars['Int']['output'];
  comments: Scalars['Int']['output'];
  gameLogs: Scalars['Int']['output'];
  notificationTokens: Scalars['Int']['output'];
  orders: Scalars['Int']['output'];
  privacyPolicyUpdates: Scalars['Int']['output'];
  promoCodeHistory: Scalars['Int']['output'];
  records: Scalars['Int']['output'];
  referralCodes: Scalars['Int']['output'];
  workshopRegistrations: Scalars['Int']['output'];
};


export type StudentCountBadgesArgs = {
  where?: InputMaybe<StudentBadgeWhereInput>;
};


export type StudentCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type StudentCountGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};


export type StudentCountNotificationTokensArgs = {
  where?: InputMaybe<StudentNotificationsWhereInput>;
};


export type StudentCountOrdersArgs = {
  where?: InputMaybe<OrderWhereInput>;
};


export type StudentCountPrivacyPolicyUpdatesArgs = {
  where?: InputMaybe<PrivacyPolicyUpdateWhereInput>;
};


export type StudentCountPromoCodeHistoryArgs = {
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
};


export type StudentCountRecordsArgs = {
  where?: InputMaybe<StudentRecordWhereInput>;
};


export type StudentCountReferralCodesArgs = {
  where?: InputMaybe<ReferralCodeWhereInput>;
};


export type StudentCountWorkshopRegistrationsArgs = {
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type StudentCountAggregate = {
  __typename?: 'StudentCountAggregate';
  _all: Scalars['Int']['output'];
  address1: Scalars['Int']['output'];
  address2: Scalars['Int']['output'];
  city: Scalars['Int']['output'];
  country: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  displayName: Scalars['Int']['output'];
  dob: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  isHomeschooler: Scalars['Int']['output'];
  lastLoginAt: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  level: Scalars['Int']['output'];
  meta: Scalars['Int']['output'];
  phoneNumber: Scalars['Int']['output'];
  postcode: Scalars['Int']['output'];
  profileImage: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  schoolText: Scalars['Int']['output'];
  standardId: Scalars['Int']['output'];
  state: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type StudentCreateCommentInput = {
  activity?: InputMaybe<ActivityWhereUniqueInput>;
  attachmentUrl?: InputMaybe<Scalars['String']['input']>;
  isDailyChallenge?: InputMaybe<Scalars['Boolean']['input']>;
  storyActivity?: InputMaybe<StoryActivityWhereUniqueInput>;
  text: Scalars['String']['input'];
};

export type StudentCreateInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateManySchoolInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standardId: Scalars['Int']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StudentCreateManySchoolInputEnvelope = {
  data: Array<StudentCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentCreateManyStandardInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  schoolId: Scalars['Int']['input'];
  schoolText?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StudentCreateManyStandardInputEnvelope = {
  data: Array<StudentCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentCreateManySchoolInputEnvelope>;
};

export type StudentCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentCreateManyStandardInputEnvelope>;
};

export type StudentCreateNestedOneWithoutBadgesInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<StudentCreateWithoutBadgesInput>;
};

export type StudentCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StudentCreateWithoutCommentsInput>;
};

export type StudentCreateNestedOneWithoutGameLogsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutGameLogsInput>;
  create?: InputMaybe<StudentCreateWithoutGameLogsInput>;
};

export type StudentCreateNestedOneWithoutInvitedStudentsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutInvitedStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutInvitedStudentsInput>;
};

export type StudentCreateNestedOneWithoutOrdersInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<StudentCreateWithoutOrdersInput>;
};

export type StudentCreateNestedOneWithoutPrivacyPolicyUpdatesInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPrivacyPolicyUpdatesInput>;
  create?: InputMaybe<StudentCreateWithoutPrivacyPolicyUpdatesInput>;
};

export type StudentCreateNestedOneWithoutPromoCodeHistoryInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPromoCodeHistoryInput>;
  create?: InputMaybe<StudentCreateWithoutPromoCodeHistoryInput>;
};

export type StudentCreateNestedOneWithoutRecordsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutRecordsInput>;
  create?: InputMaybe<StudentCreateWithoutRecordsInput>;
};

export type StudentCreateNestedOneWithoutReferralCodesInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralCodesInput>;
  create?: InputMaybe<StudentCreateWithoutReferralCodesInput>;
};

export type StudentCreateNestedOneWithoutReferralStudentsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutReferralStudentsInput>;
};

export type StudentCreateNestedOneWithoutWorkshopRegistrationsInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<StudentCreateWithoutWorkshopRegistrationsInput>;
};

export type StudentCreateOrConnectWithoutBadgesInput = {
  create: StudentCreateWithoutBadgesInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutCommentsInput = {
  create: StudentCreateWithoutCommentsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutGameLogsInput = {
  create: StudentCreateWithoutGameLogsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutInvitedStudentsInput = {
  create: StudentCreateWithoutInvitedStudentsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutOrdersInput = {
  create: StudentCreateWithoutOrdersInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutPrivacyPolicyUpdatesInput = {
  create: StudentCreateWithoutPrivacyPolicyUpdatesInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutPromoCodeHistoryInput = {
  create: StudentCreateWithoutPromoCodeHistoryInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutRecordsInput = {
  create: StudentCreateWithoutRecordsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutReferralCodesInput = {
  create: StudentCreateWithoutReferralCodesInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutReferralStudentsInput = {
  create: StudentCreateWithoutReferralStudentsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutSchoolInput = {
  create: StudentCreateWithoutSchoolInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutStandardInput = {
  create: StudentCreateWithoutStandardInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrConnectWithoutWorkshopRegistrationsInput = {
  create: StudentCreateWithoutWorkshopRegistrationsInput;
  where: StudentWhereUniqueInput;
};

export type StudentCreateOrderOutput = {
  __typename?: 'StudentCreateOrderOutput';
  message: Scalars['String']['output'];
  paymentUrl?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type StudentCreateOrderProductInput = {
  paymentMethod: PaymentMethod;
  products: Array<Scalars['Int']['input']>;
  shippingAddress1?: InputMaybe<Scalars['String']['input']>;
  shippingAddress2?: InputMaybe<Scalars['String']['input']>;
  shippingCity?: InputMaybe<Scalars['String']['input']>;
  shippingCountry?: InputMaybe<Scalars['String']['input']>;
  shippingFirstName?: InputMaybe<Scalars['String']['input']>;
  shippingLastName?: InputMaybe<Scalars['String']['input']>;
  shippingPhone?: InputMaybe<Scalars['String']['input']>;
  shippingPostcode?: InputMaybe<Scalars['String']['input']>;
  shippingState?: InputMaybe<Scalars['String']['input']>;
  workshop?: InputMaybe<StudentWorkshopUniqueInput>;
};

export type StudentCreateWithoutBadgesInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutCommentsInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutGameLogsInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutInvitedStudentsInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutOrdersInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutPrivacyPolicyUpdatesInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutPromoCodeHistoryInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutRecordsInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutReferralCodesInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutReferralStudentsInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutSchoolInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutStandardInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutStudentInput>;
};

export type StudentCreateWithoutWorkshopRegistrationsInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  badges?: InputMaybe<StudentBadgeCreateNestedManyWithoutStudentInput>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutCreatedByInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  displayName: Scalars['String']['input'];
  dob?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutStudentInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutInvitedStudentInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isHomeschooler?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  level?: InputMaybe<Scalars['Int']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsCreateNestedManyWithoutStudentInput>;
  orders?: InputMaybe<OrderCreateNestedManyWithoutCustomerInput>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateCreateNestedManyWithoutStudentInput>;
  profileImage?: InputMaybe<Scalars['String']['input']>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryCreateNestedManyWithoutStudentInput>;
  records?: InputMaybe<StudentRecordCreateNestedManyWithoutStudentInput>;
  referralCodes?: InputMaybe<ReferralCodeCreateNestedManyWithoutStudentInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryCreateNestedOneWithoutReferralStudentInput>;
  role?: InputMaybe<Role>;
  school: SchoolCreateNestedOneWithoutStudentsInput;
  schoolText?: InputMaybe<Scalars['String']['input']>;
  standard: StandardCreateNestedOneWithoutStudentsInput;
  state?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type StudentDailyActivity = {
  __typename?: 'StudentDailyActivity';
  answer?: Maybe<Scalars['Int']['output']>;
  answerText?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  options: Scalars['JSON']['output'];
  question: Scalars['String']['output'];
  questionImage?: Maybe<Scalars['String']['output']>;
  questionVideo?: Maybe<Scalars['String']['output']>;
};

export type StudentDailyActivityInput = {
  currentDate: Scalars['String']['input'];
  timeDiff: Scalars['Int']['input'];
};

export type StudentDailyChallenge = {
  __typename?: 'StudentDailyChallenge';
  challengeStatement?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  video?: Maybe<Scalars['String']['output']>;
};

export type StudentDailyChallengeInput = {
  timeDiff: Scalars['Int']['input'];
};

export type StudentGameLog = {
  __typename?: 'StudentGameLog';
  access: LogAccessType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  pointType?: Maybe<GamePointType>;
  pointTypeId?: Maybe<Scalars['Int']['output']>;
  points: Scalars['Int']['output'];
  student: Student;
  studentId: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  trigger?: Maybe<Trigger>;
  triggerId?: Maybe<Scalars['Int']['output']>;
  triggerType: TriggerType;
};

export type StudentGameLogAvgAggregate = {
  __typename?: 'StudentGameLogAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  modelId?: Maybe<Scalars['Float']['output']>;
  pointTypeId?: Maybe<Scalars['Float']['output']>;
  points?: Maybe<Scalars['Float']['output']>;
  studentId?: Maybe<Scalars['Float']['output']>;
  triggerId?: Maybe<Scalars['Float']['output']>;
};

export type StudentGameLogCountAggregate = {
  __typename?: 'StudentGameLogCountAggregate';
  _all: Scalars['Int']['output'];
  access: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  model: Scalars['Int']['output'];
  modelId: Scalars['Int']['output'];
  pointTypeId: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
  title: Scalars['Int']['output'];
  triggerId: Scalars['Int']['output'];
  triggerType: Scalars['Int']['output'];
};

export type StudentGameLogCreateInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  pointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutStudentGameLogsInput>;
  points?: InputMaybe<Scalars['Int']['input']>;
  student: StudentCreateNestedOneWithoutGameLogsInput;
  title: Scalars['String']['input'];
  trigger?: InputMaybe<TriggerCreateNestedOneWithoutStudentGameLogsInput>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyPointTypeInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  triggerId?: InputMaybe<Scalars['Int']['input']>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyPointTypeInputEnvelope = {
  data: Array<StudentGameLogCreateManyPointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentGameLogCreateManyStudentInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  pointTypeId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  triggerId?: InputMaybe<Scalars['Int']['input']>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyStudentInputEnvelope = {
  data: Array<StudentGameLogCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentGameLogCreateManyTriggerInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  pointTypeId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  studentId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  triggerType: TriggerType;
};

export type StudentGameLogCreateManyTriggerInputEnvelope = {
  data: Array<StudentGameLogCreateManyTriggerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentGameLogCreateNestedManyWithoutPointTypeInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyPointTypeInputEnvelope>;
};

export type StudentGameLogCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyStudentInputEnvelope>;
};

export type StudentGameLogCreateNestedManyWithoutTriggerInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutTriggerInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutTriggerInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyTriggerInputEnvelope>;
};

export type StudentGameLogCreateOrConnectWithoutPointTypeInput = {
  create: StudentGameLogCreateWithoutPointTypeInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogCreateOrConnectWithoutStudentInput = {
  create: StudentGameLogCreateWithoutStudentInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogCreateOrConnectWithoutTriggerInput = {
  create: StudentGameLogCreateWithoutTriggerInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogCreateWithoutPointTypeInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  points?: InputMaybe<Scalars['Int']['input']>;
  student: StudentCreateNestedOneWithoutGameLogsInput;
  title: Scalars['String']['input'];
  trigger?: InputMaybe<TriggerCreateNestedOneWithoutStudentGameLogsInput>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateWithoutStudentInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  pointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutStudentGameLogsInput>;
  points?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
  trigger?: InputMaybe<TriggerCreateNestedOneWithoutStudentGameLogsInput>;
  triggerType: TriggerType;
};

export type StudentGameLogCreateWithoutTriggerInput = {
  access?: InputMaybe<LogAccessType>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  pointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutStudentGameLogsInput>;
  points?: InputMaybe<Scalars['Int']['input']>;
  student: StudentCreateNestedOneWithoutGameLogsInput;
  title: Scalars['String']['input'];
  triggerType: TriggerType;
};

export type StudentGameLogListRelationFilter = {
  every?: InputMaybe<StudentGameLogWhereInput>;
  none?: InputMaybe<StudentGameLogWhereInput>;
  some?: InputMaybe<StudentGameLogWhereInput>;
};

export type StudentGameLogMaxAggregate = {
  __typename?: 'StudentGameLogMaxAggregate';
  access?: Maybe<LogAccessType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  pointTypeId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  triggerId?: Maybe<Scalars['Int']['output']>;
  triggerType?: Maybe<TriggerType>;
};

export type StudentGameLogMinAggregate = {
  __typename?: 'StudentGameLogMinAggregate';
  access?: Maybe<LogAccessType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  pointTypeId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  triggerId?: Maybe<Scalars['Int']['output']>;
  triggerType?: Maybe<TriggerType>;
};

export type StudentGameLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentGameLogOrderByWithRelationInput = {
  access?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrderInput>;
  modelId?: InputMaybe<SortOrderInput>;
  pointType?: InputMaybe<GamePointTypeOrderByWithRelationInput>;
  pointTypeId?: InputMaybe<SortOrderInput>;
  points?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  trigger?: InputMaybe<TriggerOrderByWithRelationInput>;
  triggerId?: InputMaybe<SortOrderInput>;
  triggerType?: InputMaybe<SortOrder>;
};

export enum StudentGameLogScalarFieldEnum {
  ACCESS = 'access',
  CREATEDAT = 'createdAt',
  ID = 'id',
  MODEL = 'model',
  MODELID = 'modelId',
  POINTTYPEID = 'pointTypeId',
  POINTS = 'points',
  STUDENTID = 'studentId',
  TITLE = 'title',
  TRIGGERID = 'triggerId',
  TRIGGERTYPE = 'triggerType'
}

export type StudentGameLogScalarWhereInput = {
  AND?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  access?: InputMaybe<EnumLogAccessTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  pointTypeId?: InputMaybe<IntNullableFilter>;
  points?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  triggerId?: InputMaybe<IntNullableFilter>;
  triggerType?: InputMaybe<EnumTriggerTypeFilter>;
};

export type StudentGameLogSumAggregate = {
  __typename?: 'StudentGameLogSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  pointTypeId?: Maybe<Scalars['Int']['output']>;
  points?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
  triggerId?: Maybe<Scalars['Int']['output']>;
};

export type StudentGameLogUpdateManyMutationInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpdateManyWithWhereWithoutPointTypeInput = {
  data: StudentGameLogUpdateManyMutationInput;
  where: StudentGameLogScalarWhereInput;
};

export type StudentGameLogUpdateManyWithWhereWithoutStudentInput = {
  data: StudentGameLogUpdateManyMutationInput;
  where: StudentGameLogScalarWhereInput;
};

export type StudentGameLogUpdateManyWithWhereWithoutTriggerInput = {
  data: StudentGameLogUpdateManyMutationInput;
  where: StudentGameLogScalarWhereInput;
};

export type StudentGameLogUpdateManyWithoutPointTypeNestedInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutPointTypeInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutPointTypeInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyPointTypeInputEnvelope>;
  delete?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentGameLogUpdateWithWhereUniqueWithoutPointTypeInput>>;
  updateMany?: InputMaybe<Array<StudentGameLogUpdateManyWithWhereWithoutPointTypeInput>>;
  upsert?: InputMaybe<Array<StudentGameLogUpsertWithWhereUniqueWithoutPointTypeInput>>;
};

export type StudentGameLogUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentGameLogUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentGameLogUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentGameLogUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentGameLogUpdateManyWithoutTriggerNestedInput = {
  connect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentGameLogCreateOrConnectWithoutTriggerInput>>;
  create?: InputMaybe<Array<StudentGameLogCreateWithoutTriggerInput>>;
  createMany?: InputMaybe<StudentGameLogCreateManyTriggerInputEnvelope>;
  delete?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentGameLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentGameLogWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentGameLogUpdateWithWhereUniqueWithoutTriggerInput>>;
  updateMany?: InputMaybe<Array<StudentGameLogUpdateManyWithWhereWithoutTriggerInput>>;
  upsert?: InputMaybe<Array<StudentGameLogUpsertWithWhereUniqueWithoutTriggerInput>>;
};

export type StudentGameLogUpdateWithWhereUniqueWithoutPointTypeInput = {
  data: StudentGameLogUpdateWithoutPointTypeInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentGameLogUpdateWithoutStudentInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpdateWithWhereUniqueWithoutTriggerInput = {
  data: StudentGameLogUpdateWithoutTriggerInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpdateWithoutPointTypeInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutGameLogsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trigger?: InputMaybe<TriggerUpdateOneWithoutStudentGameLogsNestedInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpdateWithoutStudentInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pointType?: InputMaybe<GamePointTypeUpdateOneWithoutStudentGameLogsNestedInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trigger?: InputMaybe<TriggerUpdateOneWithoutStudentGameLogsNestedInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpdateWithoutTriggerInput = {
  access?: InputMaybe<EnumLogAccessTypeFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pointType?: InputMaybe<GamePointTypeUpdateOneWithoutStudentGameLogsNestedInput>;
  points?: InputMaybe<IntFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutGameLogsNestedInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  triggerType?: InputMaybe<EnumTriggerTypeFieldUpdateOperationsInput>;
};

export type StudentGameLogUpsertWithWhereUniqueWithoutPointTypeInput = {
  create: StudentGameLogCreateWithoutPointTypeInput;
  update: StudentGameLogUpdateWithoutPointTypeInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentGameLogCreateWithoutStudentInput;
  update: StudentGameLogUpdateWithoutStudentInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogUpsertWithWhereUniqueWithoutTriggerInput = {
  create: StudentGameLogCreateWithoutTriggerInput;
  update: StudentGameLogUpdateWithoutTriggerInput;
  where: StudentGameLogWhereUniqueInput;
};

export type StudentGameLogWhereInput = {
  AND?: InputMaybe<Array<StudentGameLogWhereInput>>;
  NOT?: InputMaybe<Array<StudentGameLogWhereInput>>;
  OR?: InputMaybe<Array<StudentGameLogWhereInput>>;
  access?: InputMaybe<EnumLogAccessTypeFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  pointType?: InputMaybe<GamePointTypeRelationFilter>;
  pointTypeId?: InputMaybe<IntNullableFilter>;
  points?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  title?: InputMaybe<StringFilter>;
  trigger?: InputMaybe<TriggerRelationFilter>;
  triggerId?: InputMaybe<IntNullableFilter>;
  triggerType?: InputMaybe<EnumTriggerTypeFilter>;
};

export type StudentGameLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentGamePointsResponse = {
  __typename?: 'StudentGamePointsResponse';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type StudentLeaderboardResponse = {
  __typename?: 'StudentLeaderboardResponse';
  level: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  profileImage?: Maybe<Scalars['String']['output']>;
  stars: Scalars['Int']['output'];
  xps: Scalars['Int']['output'];
};

export type StudentListRelationFilter = {
  every?: InputMaybe<StudentWhereInput>;
  none?: InputMaybe<StudentWhereInput>;
  some?: InputMaybe<StudentWhereInput>;
};

export type StudentMaxAggregate = {
  __typename?: 'StudentMaxAggregate';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isHomeschooler?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  schoolText?: Maybe<Scalars['String']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StudentMinAggregate = {
  __typename?: 'StudentMinAggregate';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  dob?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isHomeschooler?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
  profileImage?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  schoolText?: Maybe<Scalars['String']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type StudentNextLevelRequirementResponse = {
  __typename?: 'StudentNextLevelRequirementResponse';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type StudentNextRandomActivityInput = {
  skillId?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentNotifications = {
  __typename?: 'StudentNotifications';
  createdAt: Scalars['DateTime']['output'];
  device: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type StudentNotificationsCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  device: Scalars['String']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  token: Scalars['String']['input'];
};

export type StudentNotificationsCreateManyStudentInputEnvelope = {
  data: Array<StudentNotificationsCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentNotificationsCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentNotificationsCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentNotificationsCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentNotificationsCreateManyStudentInputEnvelope>;
};

export type StudentNotificationsCreateOrConnectWithoutStudentInput = {
  create: StudentNotificationsCreateWithoutStudentInput;
  where: StudentNotificationsWhereUniqueInput;
};

export type StudentNotificationsCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  device: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type StudentNotificationsListRelationFilter = {
  every?: InputMaybe<StudentNotificationsWhereInput>;
  none?: InputMaybe<StudentNotificationsWhereInput>;
  some?: InputMaybe<StudentNotificationsWhereInput>;
};

export type StudentNotificationsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentNotificationsOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  device?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
};

export enum StudentNotificationsScalarFieldEnum {
  CREATEDAT = 'createdAt',
  DEVICE = 'device',
  ID = 'id',
  STUDENTID = 'studentId',
  TOKEN = 'token'
}

export type StudentNotificationsScalarWhereInput = {
  AND?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  device?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
};

export type StudentNotificationsUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  device?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StudentNotificationsUpdateManyWithWhereWithoutStudentInput = {
  data: StudentNotificationsUpdateManyMutationInput;
  where: StudentNotificationsScalarWhereInput;
};

export type StudentNotificationsUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentNotificationsCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentNotificationsCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentNotificationsCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentNotificationsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentNotificationsWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentNotificationsUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentNotificationsUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentNotificationsUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentNotificationsUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentNotificationsUpdateWithoutStudentInput;
  where: StudentNotificationsWhereUniqueInput;
};

export type StudentNotificationsUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  device?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type StudentNotificationsUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentNotificationsCreateWithoutStudentInput;
  update: StudentNotificationsUpdateWithoutStudentInput;
  where: StudentNotificationsWhereUniqueInput;
};

export type StudentNotificationsWhereInput = {
  AND?: InputMaybe<Array<StudentNotificationsWhereInput>>;
  NOT?: InputMaybe<Array<StudentNotificationsWhereInput>>;
  OR?: InputMaybe<Array<StudentNotificationsWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  device?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  token?: InputMaybe<StringFilter>;
};

export type StudentNotificationsWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type StudentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentOrderByWithRelationInput = {
  address1?: InputMaybe<SortOrderInput>;
  address2?: InputMaybe<SortOrderInput>;
  badges?: InputMaybe<StudentBadgeOrderByRelationAggregateInput>;
  city?: InputMaybe<SortOrderInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  country?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  displayName?: InputMaybe<SortOrder>;
  dob?: InputMaybe<SortOrderInput>;
  email?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  gameLogs?: InputMaybe<StudentGameLogOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryOrderByWithRelationInput>;
  isActive?: InputMaybe<SortOrder>;
  isHomeschooler?: InputMaybe<SortOrder>;
  lastLoginAt?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrder>;
  level?: InputMaybe<SortOrder>;
  meta?: InputMaybe<SortOrderInput>;
  notificationTokens?: InputMaybe<StudentNotificationsOrderByRelationAggregateInput>;
  orders?: InputMaybe<OrderOrderByRelationAggregateInput>;
  phoneNumber?: InputMaybe<SortOrderInput>;
  postcode?: InputMaybe<SortOrderInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateOrderByRelationAggregateInput>;
  profileImage?: InputMaybe<SortOrderInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryOrderByRelationAggregateInput>;
  records?: InputMaybe<StudentRecordOrderByRelationAggregateInput>;
  referralCodes?: InputMaybe<ReferralCodeOrderByRelationAggregateInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryOrderByWithRelationInput>;
  role?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  schoolText?: InputMaybe<SortOrderInput>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationOrderByRelationAggregateInput>;
};

export type StudentProductWhereInput = {
  isBestSelling?: InputMaybe<BoolFilter>;
  isNewReleased?: InputMaybe<BoolFilter>;
  isVirtual?: InputMaybe<BoolFilter>;
  skill?: InputMaybe<SkillWhereUniqueInput>;
};

export type StudentRecord = {
  __typename?: 'StudentRecord';
  createdAt: Scalars['DateTime']['output'];
  school: School;
  schoolId: Scalars['Int']['output'];
  standard: Standard;
  standardId: Scalars['Int']['output'];
  student: Student;
  studentId: Scalars['Int']['output'];
};

export type StudentRecordAvgAggregate = {
  __typename?: 'StudentRecordAvgAggregate';
  schoolId?: Maybe<Scalars['Float']['output']>;
  standardId?: Maybe<Scalars['Float']['output']>;
  studentId?: Maybe<Scalars['Float']['output']>;
};

export type StudentRecordCountAggregate = {
  __typename?: 'StudentRecordCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  standardId: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
};

export type StudentRecordCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  school: SchoolCreateNestedOneWithoutStudentRecordsInput;
  standard: StandardCreateNestedOneWithoutStudentRecordsInput;
  student: StudentCreateNestedOneWithoutRecordsInput;
};

export type StudentRecordCreateManySchoolInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  standardId: Scalars['Int']['input'];
  studentId: Scalars['Int']['input'];
};

export type StudentRecordCreateManySchoolInputEnvelope = {
  data: Array<StudentRecordCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentRecordCreateManyStandardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  schoolId: Scalars['Int']['input'];
  studentId: Scalars['Int']['input'];
};

export type StudentRecordCreateManyStandardInputEnvelope = {
  data: Array<StudentRecordCreateManyStandardInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentRecordCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  schoolId: Scalars['Int']['input'];
  standardId: Scalars['Int']['input'];
};

export type StudentRecordCreateManyStudentInputEnvelope = {
  data: Array<StudentRecordCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StudentRecordCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentRecordCreateManySchoolInputEnvelope>;
};

export type StudentRecordCreateNestedManyWithoutStandardInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStandardInputEnvelope>;
};

export type StudentRecordCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStudentInputEnvelope>;
};

export type StudentRecordCreateOrConnectWithoutSchoolInput = {
  create: StudentRecordCreateWithoutSchoolInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordCreateOrConnectWithoutStandardInput = {
  create: StudentRecordCreateWithoutStandardInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordCreateOrConnectWithoutStudentInput = {
  create: StudentRecordCreateWithoutStudentInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordCreateWithoutSchoolInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  standard: StandardCreateNestedOneWithoutStudentRecordsInput;
  student: StudentCreateNestedOneWithoutRecordsInput;
};

export type StudentRecordCreateWithoutStandardInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  school: SchoolCreateNestedOneWithoutStudentRecordsInput;
  student: StudentCreateNestedOneWithoutRecordsInput;
};

export type StudentRecordCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  school: SchoolCreateNestedOneWithoutStudentRecordsInput;
  standard: StandardCreateNestedOneWithoutStudentRecordsInput;
};

export type StudentRecordListRelationFilter = {
  every?: InputMaybe<StudentRecordWhereInput>;
  none?: InputMaybe<StudentRecordWhereInput>;
  some?: InputMaybe<StudentRecordWhereInput>;
};

export type StudentRecordMaxAggregate = {
  __typename?: 'StudentRecordMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type StudentRecordMinAggregate = {
  __typename?: 'StudentRecordMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type StudentRecordOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type StudentRecordOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  standard?: InputMaybe<StandardOrderByWithRelationInput>;
  standardId?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
};

export enum StudentRecordScalarFieldEnum {
  CREATEDAT = 'createdAt',
  SCHOOLID = 'schoolId',
  STANDARDID = 'standardId',
  STUDENTID = 'studentId'
}

export type StudentRecordScalarWhereInput = {
  AND?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standardId?: InputMaybe<IntFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentRecordStudentIdStandardIdSchoolIdCompoundUniqueInput = {
  schoolId: Scalars['Int']['input'];
  standardId: Scalars['Int']['input'];
  studentId: Scalars['Int']['input'];
};

export type StudentRecordSumAggregate = {
  __typename?: 'StudentRecordSumAggregate';
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
};

export type StudentRecordUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentRecordUpdateManyWithWhereWithoutSchoolInput = {
  data: StudentRecordUpdateManyMutationInput;
  where: StudentRecordScalarWhereInput;
};

export type StudentRecordUpdateManyWithWhereWithoutStandardInput = {
  data: StudentRecordUpdateManyMutationInput;
  where: StudentRecordScalarWhereInput;
};

export type StudentRecordUpdateManyWithWhereWithoutStudentInput = {
  data: StudentRecordUpdateManyMutationInput;
  where: StudentRecordScalarWhereInput;
};

export type StudentRecordUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentRecordCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentRecordUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<StudentRecordUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<StudentRecordUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type StudentRecordUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentRecordUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<StudentRecordUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<StudentRecordUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type StudentRecordUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentRecordCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<StudentRecordCreateWithoutStudentInput>>;
  createMany?: InputMaybe<StudentRecordCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentRecordScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentRecordWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentRecordUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<StudentRecordUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<StudentRecordUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type StudentRecordUpdateWithWhereUniqueWithoutSchoolInput = {
  data: StudentRecordUpdateWithoutSchoolInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpdateWithWhereUniqueWithoutStandardInput = {
  data: StudentRecordUpdateWithoutStandardInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpdateWithWhereUniqueWithoutStudentInput = {
  data: StudentRecordUpdateWithoutStudentInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpdateWithoutSchoolInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentRecordsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutRecordsNestedInput>;
};

export type StudentRecordUpdateWithoutStandardInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentRecordsNestedInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutRecordsNestedInput>;
};

export type StudentRecordUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentRecordsNestedInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentRecordsNestedInput>;
};

export type StudentRecordUpsertWithWhereUniqueWithoutSchoolInput = {
  create: StudentRecordCreateWithoutSchoolInput;
  update: StudentRecordUpdateWithoutSchoolInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpsertWithWhereUniqueWithoutStandardInput = {
  create: StudentRecordCreateWithoutStandardInput;
  update: StudentRecordUpdateWithoutStandardInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordUpsertWithWhereUniqueWithoutStudentInput = {
  create: StudentRecordCreateWithoutStudentInput;
  update: StudentRecordUpdateWithoutStudentInput;
  where: StudentRecordWhereUniqueInput;
};

export type StudentRecordWhereInput = {
  AND?: InputMaybe<Array<StudentRecordWhereInput>>;
  NOT?: InputMaybe<Array<StudentRecordWhereInput>>;
  OR?: InputMaybe<Array<StudentRecordWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
};

export type StudentRecordWhereUniqueInput = {
  studentId_standardId_schoolId?: InputMaybe<StudentRecordStudentIdStandardIdSchoolIdCompoundUniqueInput>;
};

export type StudentReferralCodeResponse = {
  __typename?: 'StudentReferralCodeResponse';
  code?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type StudentRegisterWorkshopOutput = {
  __typename?: 'StudentRegisterWorkshopOutput';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type StudentRelationFilter = {
  is?: InputMaybe<StudentWhereInput>;
  isNot?: InputMaybe<StudentWhereInput>;
};

export type StudentRewardResponse = {
  __typename?: 'StudentRewardResponse';
  badges?: Maybe<Array<Badge>>;
  coins: Scalars['Float']['output'];
  level: Scalars['Float']['output'];
  stars: Scalars['Float']['output'];
  totalXps: Scalars['Float']['output'];
};

export enum StudentScalarFieldEnum {
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  CITY = 'city',
  COUNTRY = 'country',
  CREATEDAT = 'createdAt',
  DISPLAYNAME = 'displayName',
  DOB = 'dob',
  EMAIL = 'email',
  FIRSTNAME = 'firstName',
  ID = 'id',
  ISACTIVE = 'isActive',
  ISHOMESCHOOLER = 'isHomeschooler',
  LASTLOGINAT = 'lastLoginAt',
  LASTNAME = 'lastName',
  LEVEL = 'level',
  META = 'meta',
  PHONENUMBER = 'phoneNumber',
  POSTCODE = 'postcode',
  PROFILEIMAGE = 'profileImage',
  ROLE = 'role',
  SCHOOLID = 'schoolId',
  SCHOOLTEXT = 'schoolText',
  STANDARDID = 'standardId',
  STATE = 'state',
  UPDATEDAT = 'updatedAt'
}

export type StudentScalarWhereInput = {
  AND?: InputMaybe<Array<StudentScalarWhereInput>>;
  NOT?: InputMaybe<Array<StudentScalarWhereInput>>;
  OR?: InputMaybe<Array<StudentScalarWhereInput>>;
  address1?: InputMaybe<StringNullableFilter>;
  address2?: InputMaybe<StringNullableFilter>;
  city?: InputMaybe<StringNullableFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isHomeschooler?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  level?: InputMaybe<IntFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringNullableFilter>;
  profileImage?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  schoolId?: InputMaybe<IntFilter>;
  schoolText?: InputMaybe<StringNullableFilter>;
  standardId?: InputMaybe<IntFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StudentSkillXpsResponse = {
  __typename?: 'StudentSkillXpsResponse';
  data?: Maybe<Scalars['JSON']['output']>;
};

export type StudentStoryActivity = {
  __typename?: 'StudentStoryActivity';
  commentsCount: Scalars['Int']['output'];
  difficultyLevel: Scalars['String']['output'];
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  meta?: Maybe<Scalars['JSON']['output']>;
  skill: PublicSkill;
  slug: Scalars['String']['output'];
  story: StudentStoryOutput;
  timeRequired: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type StudentStoryOutput = {
  __typename?: 'StudentStoryOutput';
  description?: Maybe<Scalars['String']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type StudentSumAggregate = {
  __typename?: 'StudentSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  standardId?: Maybe<Scalars['Int']['output']>;
};

export type StudentUpcomingWorkshopOutput = {
  __typename?: 'StudentUpcomingWorkshopOutput';
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type StudentUpdateInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateManyMutationInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentUpdateManyWithWhereWithoutSchoolInput = {
  data: StudentUpdateManyMutationInput;
  where: StudentScalarWhereInput;
};

export type StudentUpdateManyWithWhereWithoutStandardInput = {
  data: StudentUpdateManyMutationInput;
  where: StudentScalarWhereInput;
};

export type StudentUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<StudentCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<StudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<StudentUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<StudentUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type StudentUpdateManyWithoutStandardNestedInput = {
  connect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StudentCreateOrConnectWithoutStandardInput>>;
  create?: InputMaybe<Array<StudentCreateWithoutStandardInput>>;
  createMany?: InputMaybe<StudentCreateManyStandardInputEnvelope>;
  delete?: InputMaybe<Array<StudentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<StudentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<StudentWhereUniqueInput>>;
  set?: InputMaybe<Array<StudentWhereUniqueInput>>;
  update?: InputMaybe<Array<StudentUpdateWithWhereUniqueWithoutStandardInput>>;
  updateMany?: InputMaybe<Array<StudentUpdateManyWithWhereWithoutStandardInput>>;
  upsert?: InputMaybe<Array<StudentUpsertWithWhereUniqueWithoutStandardInput>>;
};

export type StudentUpdateOneRequiredWithoutBadgesNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<StudentCreateWithoutBadgesInput>;
  update?: InputMaybe<StudentUpdateWithoutBadgesInput>;
  upsert?: InputMaybe<StudentUpsertWithoutBadgesInput>;
};

export type StudentUpdateOneRequiredWithoutGameLogsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutGameLogsInput>;
  create?: InputMaybe<StudentCreateWithoutGameLogsInput>;
  update?: InputMaybe<StudentUpdateWithoutGameLogsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutGameLogsInput>;
};

export type StudentUpdateOneRequiredWithoutInvitedStudentsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutInvitedStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutInvitedStudentsInput>;
  update?: InputMaybe<StudentUpdateWithoutInvitedStudentsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutInvitedStudentsInput>;
};

export type StudentUpdateOneRequiredWithoutOrdersNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutOrdersInput>;
  create?: InputMaybe<StudentCreateWithoutOrdersInput>;
  update?: InputMaybe<StudentUpdateWithoutOrdersInput>;
  upsert?: InputMaybe<StudentUpsertWithoutOrdersInput>;
};

export type StudentUpdateOneRequiredWithoutPromoCodeHistoryNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutPromoCodeHistoryInput>;
  create?: InputMaybe<StudentCreateWithoutPromoCodeHistoryInput>;
  update?: InputMaybe<StudentUpdateWithoutPromoCodeHistoryInput>;
  upsert?: InputMaybe<StudentUpsertWithoutPromoCodeHistoryInput>;
};

export type StudentUpdateOneRequiredWithoutRecordsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutRecordsInput>;
  create?: InputMaybe<StudentCreateWithoutRecordsInput>;
  update?: InputMaybe<StudentUpdateWithoutRecordsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutRecordsInput>;
};

export type StudentUpdateOneRequiredWithoutReferralCodesNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralCodesInput>;
  create?: InputMaybe<StudentCreateWithoutReferralCodesInput>;
  update?: InputMaybe<StudentUpdateWithoutReferralCodesInput>;
  upsert?: InputMaybe<StudentUpsertWithoutReferralCodesInput>;
};

export type StudentUpdateOneRequiredWithoutReferralStudentsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutReferralStudentsInput>;
  create?: InputMaybe<StudentCreateWithoutReferralStudentsInput>;
  update?: InputMaybe<StudentUpdateWithoutReferralStudentsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutReferralStudentsInput>;
};

export type StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<StudentCreateWithoutWorkshopRegistrationsInput>;
  update?: InputMaybe<StudentUpdateWithoutWorkshopRegistrationsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutWorkshopRegistrationsInput>;
};

export type StudentUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<StudentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<StudentCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<StudentCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<StudentUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<StudentUpsertWithoutCommentsInput>;
};

export type StudentUpdateWithWhereUniqueWithoutSchoolInput = {
  data: StudentUpdateWithoutSchoolInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpdateWithWhereUniqueWithoutStandardInput = {
  data: StudentUpdateWithoutStandardInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpdateWithoutBadgesInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutCommentsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutGameLogsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutInvitedStudentsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutOrdersInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutPromoCodeHistoryInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutRecordsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutReferralCodesInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutReferralStudentsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutSchoolInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutStandardInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutStudentNestedInput>;
};

export type StudentUpdateWithoutWorkshopRegistrationsInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<StudentBadgeUpdateManyWithoutStudentNestedInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutCreatedByNestedInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutStudentNestedInput>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutInvitedStudentNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  level?: InputMaybe<IntFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  notificationTokens?: InputMaybe<StudentNotificationsUpdateManyWithoutStudentNestedInput>;
  orders?: InputMaybe<OrderUpdateManyWithoutCustomerNestedInput>;
  phoneNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateUpdateManyWithoutStudentNestedInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryUpdateManyWithoutStudentNestedInput>;
  records?: InputMaybe<StudentRecordUpdateManyWithoutStudentNestedInput>;
  referralCodes?: InputMaybe<ReferralCodeUpdateManyWithoutStudentNestedInput>;
  referralStudents?: InputMaybe<ReferralCodeHistoryUpdateOneWithoutReferralStudentNestedInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutStudentsNestedInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardUpdateOneRequiredWithoutStudentsNestedInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type StudentUpsertWithWhereUniqueWithoutSchoolInput = {
  create: StudentCreateWithoutSchoolInput;
  update: StudentUpdateWithoutSchoolInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpsertWithWhereUniqueWithoutStandardInput = {
  create: StudentCreateWithoutStandardInput;
  update: StudentUpdateWithoutStandardInput;
  where: StudentWhereUniqueInput;
};

export type StudentUpsertWithoutBadgesInput = {
  create: StudentCreateWithoutBadgesInput;
  update: StudentUpdateWithoutBadgesInput;
};

export type StudentUpsertWithoutCommentsInput = {
  create: StudentCreateWithoutCommentsInput;
  update: StudentUpdateWithoutCommentsInput;
};

export type StudentUpsertWithoutGameLogsInput = {
  create: StudentCreateWithoutGameLogsInput;
  update: StudentUpdateWithoutGameLogsInput;
};

export type StudentUpsertWithoutInvitedStudentsInput = {
  create: StudentCreateWithoutInvitedStudentsInput;
  update: StudentUpdateWithoutInvitedStudentsInput;
};

export type StudentUpsertWithoutOrdersInput = {
  create: StudentCreateWithoutOrdersInput;
  update: StudentUpdateWithoutOrdersInput;
};

export type StudentUpsertWithoutPromoCodeHistoryInput = {
  create: StudentCreateWithoutPromoCodeHistoryInput;
  update: StudentUpdateWithoutPromoCodeHistoryInput;
};

export type StudentUpsertWithoutRecordsInput = {
  create: StudentCreateWithoutRecordsInput;
  update: StudentUpdateWithoutRecordsInput;
};

export type StudentUpsertWithoutReferralCodesInput = {
  create: StudentCreateWithoutReferralCodesInput;
  update: StudentUpdateWithoutReferralCodesInput;
};

export type StudentUpsertWithoutReferralStudentsInput = {
  create: StudentCreateWithoutReferralStudentsInput;
  update: StudentUpdateWithoutReferralStudentsInput;
};

export type StudentUpsertWithoutWorkshopRegistrationsInput = {
  create: StudentCreateWithoutWorkshopRegistrationsInput;
  update: StudentUpdateWithoutWorkshopRegistrationsInput;
};

export type StudentWhereInput = {
  AND?: InputMaybe<Array<StudentWhereInput>>;
  NOT?: InputMaybe<Array<StudentWhereInput>>;
  OR?: InputMaybe<Array<StudentWhereInput>>;
  address1?: InputMaybe<StringNullableFilter>;
  address2?: InputMaybe<StringNullableFilter>;
  badges?: InputMaybe<StudentBadgeListRelationFilter>;
  city?: InputMaybe<StringNullableFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  country?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  displayName?: InputMaybe<StringFilter>;
  dob?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  gameLogs?: InputMaybe<StudentGameLogListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  invitedStudents?: InputMaybe<ReferralCodeHistoryRelationFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isHomeschooler?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  level?: InputMaybe<IntFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  notificationTokens?: InputMaybe<StudentNotificationsListRelationFilter>;
  orders?: InputMaybe<OrderListRelationFilter>;
  phoneNumber?: InputMaybe<StringNullableFilter>;
  postcode?: InputMaybe<StringNullableFilter>;
  privacyPolicyUpdates?: InputMaybe<PrivacyPolicyUpdateListRelationFilter>;
  profileImage?: InputMaybe<StringNullableFilter>;
  promoCodeHistory?: InputMaybe<PromoCodeHistoryListRelationFilter>;
  records?: InputMaybe<StudentRecordListRelationFilter>;
  referralCodes?: InputMaybe<ReferralCodeListRelationFilter>;
  referralStudents?: InputMaybe<ReferralCodeHistoryRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  schoolText?: InputMaybe<StringNullableFilter>;
  standard?: InputMaybe<StandardRelationFilter>;
  standardId?: InputMaybe<IntFilter>;
  state?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationListRelationFilter>;
};

export type StudentWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type StudentWorkshopUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type StudentWorkshopsOutput = {
  __typename?: 'StudentWorkshopsOutput';
  featuredImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  _count?: Maybe<TagCount>;
  activities: Array<Activity>;
  buyScript?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  jsonLD?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  productScript?: Maybe<Scalars['String']['output']>;
  seoContent?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workshops: Array<Workshop>;
};


export type TagActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type TagWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type TagAvgAggregate = {
  __typename?: 'TagAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type TagCount = {
  __typename?: 'TagCount';
  activities: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type TagCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type TagCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type TagCountAggregate = {
  __typename?: 'TagCountAggregate';
  _all: Scalars['Int']['output'];
  buyScript: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  jsonLD: Scalars['Int']['output'];
  metaTagData: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  productScript: Scalars['Int']['output'];
  seoContent: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TagCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutTagsInput>;
  buyScript?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  productScript?: InputMaybe<Scalars['String']['input']>;
  seoContent?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTagsInput>;
};

export type TagCreateNestedManyWithoutActivitiesInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<TagCreateWithoutActivitiesInput>>;
};

export type TagCreateNestedManyWithoutWorkshopsInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<TagCreateWithoutWorkshopsInput>>;
};

export type TagCreateOrConnectWithoutActivitiesInput = {
  create: TagCreateWithoutActivitiesInput;
  where: TagWhereUniqueInput;
};

export type TagCreateOrConnectWithoutWorkshopsInput = {
  create: TagCreateWithoutWorkshopsInput;
  where: TagWhereUniqueInput;
};

export type TagCreateWithoutActivitiesInput = {
  buyScript?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  productScript?: InputMaybe<Scalars['String']['input']>;
  seoContent?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTagsInput>;
};

export type TagCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutTagsInput>;
  buyScript?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  productScript?: InputMaybe<Scalars['String']['input']>;
  seoContent?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TagListRelationFilter = {
  every?: InputMaybe<TagWhereInput>;
  none?: InputMaybe<TagWhereInput>;
  some?: InputMaybe<TagWhereInput>;
};

export type TagMaxAggregate = {
  __typename?: 'TagMaxAggregate';
  buyScript?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productScript?: Maybe<Scalars['String']['output']>;
  seoContent?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TagMinAggregate = {
  __typename?: 'TagMinAggregate';
  buyScript?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productScript?: Maybe<Scalars['String']['output']>;
  seoContent?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TagOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TagOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  buyScript?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  jsonLD?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  name?: InputMaybe<SortOrder>;
  productScript?: InputMaybe<SortOrderInput>;
  seoContent?: InputMaybe<SortOrderInput>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export enum TagScalarFieldEnum {
  BUYSCRIPT = 'buyScript',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  ID = 'id',
  JSONLD = 'jsonLD',
  METATAGDATA = 'metaTagData',
  NAME = 'name',
  PRODUCTSCRIPT = 'productScript',
  SEOCONTENT = 'seoContent',
  SLUG = 'slug',
  UPDATEDAT = 'updatedAt'
}

export type TagScalarWhereInput = {
  AND?: InputMaybe<Array<TagScalarWhereInput>>;
  NOT?: InputMaybe<Array<TagScalarWhereInput>>;
  OR?: InputMaybe<Array<TagScalarWhereInput>>;
  buyScript?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  productScript?: InputMaybe<StringNullableFilter>;
  seoContent?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TagSumAggregate = {
  __typename?: 'TagSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type TagUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutTagsNestedInput>;
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTagsNestedInput>;
};

export type TagUpdateManyMutationInput = {
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpdateManyWithWhereWithoutActivitiesInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithWhereWithoutWorkshopsInput = {
  data: TagUpdateManyMutationInput;
  where: TagScalarWhereInput;
};

export type TagUpdateManyWithoutActivitiesNestedInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutActivitiesInput>>;
  create?: InputMaybe<Array<TagCreateWithoutActivitiesInput>>;
  delete?: InputMaybe<Array<TagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
  set?: InputMaybe<Array<TagWhereUniqueInput>>;
  update?: InputMaybe<Array<TagUpdateWithWhereUniqueWithoutActivitiesInput>>;
  updateMany?: InputMaybe<Array<TagUpdateManyWithWhereWithoutActivitiesInput>>;
  upsert?: InputMaybe<Array<TagUpsertWithWhereUniqueWithoutActivitiesInput>>;
};

export type TagUpdateManyWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TagCreateOrConnectWithoutWorkshopsInput>>;
  create?: InputMaybe<Array<TagCreateWithoutWorkshopsInput>>;
  delete?: InputMaybe<Array<TagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>;
  set?: InputMaybe<Array<TagWhereUniqueInput>>;
  update?: InputMaybe<Array<TagUpdateWithWhereUniqueWithoutWorkshopsInput>>;
  updateMany?: InputMaybe<Array<TagUpdateManyWithWhereWithoutWorkshopsInput>>;
  upsert?: InputMaybe<Array<TagUpsertWithWhereUniqueWithoutWorkshopsInput>>;
};

export type TagUpdateWithWhereUniqueWithoutActivitiesInput = {
  data: TagUpdateWithoutActivitiesInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithWhereUniqueWithoutWorkshopsInput = {
  data: TagUpdateWithoutWorkshopsInput;
  where: TagWhereUniqueInput;
};

export type TagUpdateWithoutActivitiesInput = {
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTagsNestedInput>;
};

export type TagUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutTagsNestedInput>;
  buyScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  jsonLD?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  productScript?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  seoContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TagUpsertWithWhereUniqueWithoutActivitiesInput = {
  create: TagCreateWithoutActivitiesInput;
  update: TagUpdateWithoutActivitiesInput;
  where: TagWhereUniqueInput;
};

export type TagUpsertWithWhereUniqueWithoutWorkshopsInput = {
  create: TagCreateWithoutWorkshopsInput;
  update: TagUpdateWithoutWorkshopsInput;
  where: TagWhereUniqueInput;
};

export type TagWhereInput = {
  AND?: InputMaybe<Array<TagWhereInput>>;
  NOT?: InputMaybe<Array<TagWhereInput>>;
  OR?: InputMaybe<Array<TagWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  buyScript?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  jsonLD?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  name?: InputMaybe<StringFilter>;
  productScript?: InputMaybe<StringNullableFilter>;
  seoContent?: InputMaybe<StringNullableFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Trigger = {
  __typename?: 'Trigger';
  _count?: Maybe<TriggerCount>;
  badge?: Maybe<Badge>;
  badgeId?: Maybe<Scalars['Int']['output']>;
  countDuration?: Maybe<Scalars['Int']['output']>;
  countPeriod?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  earn: Scalars['Int']['output'];
  gamePointType?: Maybe<GamePointType>;
  gamePointTypeId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Level>;
  levelId?: Maybe<Scalars['Int']['output']>;
  maximumEarningsPerUser: Scalars['Int']['output'];
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  studentGameLogs: Array<StudentGameLog>;
  text: Scalars['String']['output'];
  totalCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type TriggerStudentGameLogsArgs = {
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StudentGameLogWhereInput>;
};

export type TriggerAvgAggregate = {
  __typename?: 'TriggerAvgAggregate';
  badgeId?: Maybe<Scalars['Float']['output']>;
  countDuration?: Maybe<Scalars['Float']['output']>;
  earn?: Maybe<Scalars['Float']['output']>;
  gamePointTypeId?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  levelId?: Maybe<Scalars['Float']['output']>;
  maximumEarningsPerUser?: Maybe<Scalars['Float']['output']>;
  modelId?: Maybe<Scalars['Float']['output']>;
  totalCount?: Maybe<Scalars['Float']['output']>;
};

export type TriggerCount = {
  __typename?: 'TriggerCount';
  studentGameLogs: Scalars['Int']['output'];
};


export type TriggerCountStudentGameLogsArgs = {
  where?: InputMaybe<StudentGameLogWhereInput>;
};

export type TriggerCountAggregate = {
  __typename?: 'TriggerCountAggregate';
  _all: Scalars['Int']['output'];
  badgeId: Scalars['Int']['output'];
  countDuration: Scalars['Int']['output'];
  countPeriod: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  earn: Scalars['Int']['output'];
  gamePointTypeId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  key: Scalars['Int']['output'];
  label: Scalars['Int']['output'];
  levelId: Scalars['Int']['output'];
  maximumEarningsPerUser: Scalars['Int']['output'];
  model: Scalars['Int']['output'];
  modelId: Scalars['Int']['output'];
  text: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type TriggerCreateInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateManyBadgeInput = {
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  gamePointTypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['Int']['input']>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateManyBadgeInputEnvelope = {
  data: Array<TriggerCreateManyBadgeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TriggerCreateManyGamePointTypeInput = {
  badgeId?: InputMaybe<Scalars['Int']['input']>;
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  levelId?: InputMaybe<Scalars['Int']['input']>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateManyGamePointTypeInputEnvelope = {
  data: Array<TriggerCreateManyGamePointTypeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TriggerCreateManyLevelInput = {
  badgeId?: InputMaybe<Scalars['Int']['input']>;
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  gamePointTypeId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateManyLevelInputEnvelope = {
  data: Array<TriggerCreateManyLevelInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TriggerCreateNestedManyWithoutBadgeInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<TriggerCreateManyBadgeInputEnvelope>;
};

export type TriggerCreateNestedManyWithoutGamePointTypeInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutGamePointTypeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutGamePointTypeInput>>;
  createMany?: InputMaybe<TriggerCreateManyGamePointTypeInputEnvelope>;
};

export type TriggerCreateNestedManyWithoutLevelInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutLevelInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutLevelInput>>;
  createMany?: InputMaybe<TriggerCreateManyLevelInputEnvelope>;
};

export type TriggerCreateNestedOneWithoutStudentGameLogsInput = {
  connect?: InputMaybe<TriggerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TriggerCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<TriggerCreateWithoutStudentGameLogsInput>;
};

export type TriggerCreateOrConnectWithoutBadgeInput = {
  create: TriggerCreateWithoutBadgeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateOrConnectWithoutGamePointTypeInput = {
  create: TriggerCreateWithoutGamePointTypeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateOrConnectWithoutLevelInput = {
  create: TriggerCreateWithoutLevelInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateOrConnectWithoutStudentGameLogsInput = {
  create: TriggerCreateWithoutStudentGameLogsInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerCreateWithoutBadgeInput = {
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateWithoutGamePointTypeInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateWithoutLevelInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  studentGameLogs?: InputMaybe<StudentGameLogCreateNestedManyWithoutTriggerInput>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerCreateWithoutStudentGameLogsInput = {
  badge?: InputMaybe<BadgeCreateNestedOneWithoutTriggersInput>;
  countDuration?: InputMaybe<Scalars['Int']['input']>;
  countPeriod?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  earn: Scalars['Int']['input'];
  gamePointType?: InputMaybe<GamePointTypeCreateNestedOneWithoutTriggersInput>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  key: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<LevelCreateNestedOneWithoutTriggersInput>;
  maximumEarningsPerUser: Scalars['Int']['input'];
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  text: Scalars['String']['input'];
  totalCount: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerListRelationFilter = {
  every?: InputMaybe<TriggerWhereInput>;
  none?: InputMaybe<TriggerWhereInput>;
  some?: InputMaybe<TriggerWhereInput>;
};

export type TriggerMaxAggregate = {
  __typename?: 'TriggerMaxAggregate';
  badgeId?: Maybe<Scalars['Int']['output']>;
  countDuration?: Maybe<Scalars['Int']['output']>;
  countPeriod?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  earn?: Maybe<Scalars['Int']['output']>;
  gamePointTypeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['Int']['output']>;
  maximumEarningsPerUser?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TriggerMinAggregate = {
  __typename?: 'TriggerMinAggregate';
  badgeId?: Maybe<Scalars['Int']['output']>;
  countDuration?: Maybe<Scalars['Int']['output']>;
  countPeriod?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  earn?: Maybe<Scalars['Int']['output']>;
  gamePointTypeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  levelId?: Maybe<Scalars['Int']['output']>;
  maximumEarningsPerUser?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TriggerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TriggerOrderByWithRelationInput = {
  badge?: InputMaybe<BadgeOrderByWithRelationInput>;
  badgeId?: InputMaybe<SortOrderInput>;
  countDuration?: InputMaybe<SortOrderInput>;
  countPeriod?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  earn?: InputMaybe<SortOrder>;
  gamePointType?: InputMaybe<GamePointTypeOrderByWithRelationInput>;
  gamePointTypeId?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  label?: InputMaybe<SortOrderInput>;
  level?: InputMaybe<LevelOrderByWithRelationInput>;
  levelId?: InputMaybe<SortOrderInput>;
  maximumEarningsPerUser?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrderInput>;
  modelId?: InputMaybe<SortOrderInput>;
  studentGameLogs?: InputMaybe<StudentGameLogOrderByRelationAggregateInput>;
  text?: InputMaybe<SortOrder>;
  totalCount?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TriggerRelationFilter = {
  is?: InputMaybe<TriggerWhereInput>;
  isNot?: InputMaybe<TriggerWhereInput>;
};

export enum TriggerScalarFieldEnum {
  BADGEID = 'badgeId',
  COUNTDURATION = 'countDuration',
  COUNTPERIOD = 'countPeriod',
  CREATEDAT = 'createdAt',
  EARN = 'earn',
  GAMEPOINTTYPEID = 'gamePointTypeId',
  ID = 'id',
  ISACTIVE = 'isActive',
  KEY = 'key',
  LABEL = 'label',
  LEVELID = 'levelId',
  MAXIMUMEARNINGSPERUSER = 'maximumEarningsPerUser',
  MODEL = 'model',
  MODELID = 'modelId',
  TEXT = 'text',
  TOTALCOUNT = 'totalCount',
  UPDATEDAT = 'updatedAt'
}

export type TriggerScalarWhereInput = {
  AND?: InputMaybe<Array<TriggerScalarWhereInput>>;
  NOT?: InputMaybe<Array<TriggerScalarWhereInput>>;
  OR?: InputMaybe<Array<TriggerScalarWhereInput>>;
  badgeId?: InputMaybe<IntNullableFilter>;
  countDuration?: InputMaybe<IntNullableFilter>;
  countPeriod?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  earn?: InputMaybe<IntFilter>;
  gamePointTypeId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  key?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  levelId?: InputMaybe<IntNullableFilter>;
  maximumEarningsPerUser?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  totalCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TriggerSumAggregate = {
  __typename?: 'TriggerSumAggregate';
  badgeId?: Maybe<Scalars['Int']['output']>;
  countDuration?: Maybe<Scalars['Int']['output']>;
  earn?: Maybe<Scalars['Int']['output']>;
  gamePointTypeId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  levelId?: Maybe<Scalars['Int']['output']>;
  maximumEarningsPerUser?: Maybe<Scalars['Int']['output']>;
  modelId?: Maybe<Scalars['Int']['output']>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum TriggerType {
  ACHIEVEMENT_AWARD = 'ACHIEVEMENT_AWARD',
  ACHIEVEMENT_EARN = 'ACHIEVEMENT_EARN',
  ACTIVITY_UNLOCK = 'ACTIVITY_UNLOCK',
  BADGE_EARN = 'BADGE_EARN',
  COINS_ADD = 'COINS_ADD',
  COINS_EARNED = 'COINS_EARNED',
  COINS_SPENT = 'COINS_SPENT',
  COMMENT_APPROVE = 'COMMENT_APPROVE',
  COMMENT_CREATE = 'COMMENT_CREATE',
  COMMENT_REJECT = 'COMMENT_REJECT',
  CONTENT_UNLOCK = 'CONTENT_UNLOCK',
  DAILY_ACTIVITY = 'DAILY_ACTIVITY',
  DAILY_CHALLENGE = 'DAILY_CHALLENGE',
  LEVEL_UPDATE = 'LEVEL_UPDATE',
  LOG_IN = 'LOG_IN',
  LOG_OUT = 'LOG_OUT',
  PRODUCT_PURCHASE = 'PRODUCT_PURCHASE',
  QA_COMPLETE = 'QA_COMPLETE',
  RANK_AWARD = 'RANK_AWARD',
  RANK_EARN = 'RANK_EARN',
  REGISTER = 'REGISTER',
  SKILL_XPS_EARN = 'SKILL_XPS_EARN',
  STAR_EARN = 'STAR_EARN',
  STORY_COMPLETE = 'STORY_COMPLETE',
  STORY_UNLOCK = 'STORY_UNLOCK',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  XPS_EARN = 'XPS_EARN'
}

export type TriggerUpdateInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateManyMutationInput = {
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateManyWithWhereWithoutBadgeInput = {
  data: TriggerUpdateManyMutationInput;
  where: TriggerScalarWhereInput;
};

export type TriggerUpdateManyWithWhereWithoutGamePointTypeInput = {
  data: TriggerUpdateManyMutationInput;
  where: TriggerScalarWhereInput;
};

export type TriggerUpdateManyWithWhereWithoutLevelInput = {
  data: TriggerUpdateManyMutationInput;
  where: TriggerScalarWhereInput;
};

export type TriggerUpdateManyWithoutBadgeNestedInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<TriggerCreateManyBadgeInputEnvelope>;
  delete?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TriggerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  set?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  update?: InputMaybe<Array<TriggerUpdateWithWhereUniqueWithoutBadgeInput>>;
  updateMany?: InputMaybe<Array<TriggerUpdateManyWithWhereWithoutBadgeInput>>;
  upsert?: InputMaybe<Array<TriggerUpsertWithWhereUniqueWithoutBadgeInput>>;
};

export type TriggerUpdateManyWithoutGamePointTypeNestedInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutGamePointTypeInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutGamePointTypeInput>>;
  createMany?: InputMaybe<TriggerCreateManyGamePointTypeInputEnvelope>;
  delete?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TriggerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  set?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  update?: InputMaybe<Array<TriggerUpdateWithWhereUniqueWithoutGamePointTypeInput>>;
  updateMany?: InputMaybe<Array<TriggerUpdateManyWithWhereWithoutGamePointTypeInput>>;
  upsert?: InputMaybe<Array<TriggerUpsertWithWhereUniqueWithoutGamePointTypeInput>>;
};

export type TriggerUpdateManyWithoutLevelNestedInput = {
  connect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TriggerCreateOrConnectWithoutLevelInput>>;
  create?: InputMaybe<Array<TriggerCreateWithoutLevelInput>>;
  createMany?: InputMaybe<TriggerCreateManyLevelInputEnvelope>;
  delete?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TriggerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  set?: InputMaybe<Array<TriggerWhereUniqueInput>>;
  update?: InputMaybe<Array<TriggerUpdateWithWhereUniqueWithoutLevelInput>>;
  updateMany?: InputMaybe<Array<TriggerUpdateManyWithWhereWithoutLevelInput>>;
  upsert?: InputMaybe<Array<TriggerUpsertWithWhereUniqueWithoutLevelInput>>;
};

export type TriggerUpdateOneWithoutStudentGameLogsNestedInput = {
  connect?: InputMaybe<TriggerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TriggerCreateOrConnectWithoutStudentGameLogsInput>;
  create?: InputMaybe<TriggerCreateWithoutStudentGameLogsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<TriggerUpdateWithoutStudentGameLogsInput>;
  upsert?: InputMaybe<TriggerUpsertWithoutStudentGameLogsInput>;
};

export type TriggerUpdateWithWhereUniqueWithoutBadgeInput = {
  data: TriggerUpdateWithoutBadgeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpdateWithWhereUniqueWithoutGamePointTypeInput = {
  data: TriggerUpdateWithoutGamePointTypeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpdateWithWhereUniqueWithoutLevelInput = {
  data: TriggerUpdateWithoutLevelInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpdateWithoutBadgeInput = {
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateWithoutGamePointTypeInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateWithoutLevelInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  studentGameLogs?: InputMaybe<StudentGameLogUpdateManyWithoutTriggerNestedInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpdateWithoutStudentGameLogsInput = {
  badge?: InputMaybe<BadgeUpdateOneWithoutTriggersNestedInput>;
  countDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  countPeriod?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  earn?: InputMaybe<IntFieldUpdateOperationsInput>;
  gamePointType?: InputMaybe<GamePointTypeUpdateOneWithoutTriggersNestedInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  key?: InputMaybe<StringFieldUpdateOperationsInput>;
  label?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  level?: InputMaybe<LevelUpdateOneWithoutTriggersNestedInput>;
  maximumEarningsPerUser?: InputMaybe<IntFieldUpdateOperationsInput>;
  model?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  modelId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalCount?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TriggerUpsertWithWhereUniqueWithoutBadgeInput = {
  create: TriggerCreateWithoutBadgeInput;
  update: TriggerUpdateWithoutBadgeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpsertWithWhereUniqueWithoutGamePointTypeInput = {
  create: TriggerCreateWithoutGamePointTypeInput;
  update: TriggerUpdateWithoutGamePointTypeInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpsertWithWhereUniqueWithoutLevelInput = {
  create: TriggerCreateWithoutLevelInput;
  update: TriggerUpdateWithoutLevelInput;
  where: TriggerWhereUniqueInput;
};

export type TriggerUpsertWithoutStudentGameLogsInput = {
  create: TriggerCreateWithoutStudentGameLogsInput;
  update: TriggerUpdateWithoutStudentGameLogsInput;
};

export type TriggerWhereInput = {
  AND?: InputMaybe<Array<TriggerWhereInput>>;
  NOT?: InputMaybe<Array<TriggerWhereInput>>;
  OR?: InputMaybe<Array<TriggerWhereInput>>;
  badge?: InputMaybe<BadgeRelationFilter>;
  badgeId?: InputMaybe<IntNullableFilter>;
  countDuration?: InputMaybe<IntNullableFilter>;
  countPeriod?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  earn?: InputMaybe<IntFilter>;
  gamePointType?: InputMaybe<GamePointTypeRelationFilter>;
  gamePointTypeId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  key?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  level?: InputMaybe<LevelRelationFilter>;
  levelId?: InputMaybe<IntNullableFilter>;
  maximumEarningsPerUser?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringNullableFilter>;
  modelId?: InputMaybe<IntNullableFilter>;
  studentGameLogs?: InputMaybe<StudentGameLogListRelationFilter>;
  text?: InputMaybe<StringFilter>;
  totalCount?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TriggerWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProfileInput = {
  address1?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  address2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  city?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayName?: InputMaybe<StringFieldUpdateOperationsInput>;
  dob?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isHomeschooler?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationDevice?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationToken?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  profileImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schoolText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  standard?: InputMaybe<StandardWhereUniqueInput>;
  state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UpdateStudentMetaInput = {
  meta: Scalars['JSON']['input'];
};

export type UpdateStudentMetaResponse = {
  __typename?: 'UpdateStudentMetaResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  activities: Array<Activity>;
  comments: Array<Comment>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  role: Role;
  schools: Array<School>;
  standards: Array<Standard>;
  updatedAt: Scalars['DateTime']['output'];
  userLogs: Array<UserLog>;
  workshops: Array<Workshop>;
};


export type UserActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type UserCommentsArgs = {
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommentWhereInput>;
};


export type UserSchoolsArgs = {
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  distinct?: InputMaybe<Array<SchoolScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<SchoolWhereInput>;
};


export type UserStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type UserUserLogsArgs = {
  cursor?: InputMaybe<UserLogWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserLogScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserLogOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserLogWhereInput>;
};


export type UserWorkshopsArgs = {
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopWhereInput>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UserCount = {
  __typename?: 'UserCount';
  activities: Scalars['Int']['output'];
  comments: Scalars['Int']['output'];
  schools: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
  userLogs: Scalars['Int']['output'];
  workshops: Scalars['Int']['output'];
};


export type UserCountActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type UserCountCommentsArgs = {
  where?: InputMaybe<CommentWhereInput>;
};


export type UserCountSchoolsArgs = {
  where?: InputMaybe<SchoolWhereInput>;
};


export type UserCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type UserCountUserLogsArgs = {
  where?: InputMaybe<UserLogWhereInput>;
};


export type UserCountWorkshopsArgs = {
  where?: InputMaybe<WorkshopWhereInput>;
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  email: Scalars['Int']['output'];
  firstName: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive: Scalars['Int']['output'];
  lastLoginAt: Scalars['Int']['output'];
  lastName: Scalars['Int']['output'];
  phoneNumber: Scalars['Int']['output'];
  role: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type UserCreateInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateNestedManyWithoutSchoolsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutSchoolsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutSchoolsInput>>;
};

export type UserCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutStandardsInput>>;
};

export type UserCreateNestedOneWithoutActivitiesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutActivitiesInput>;
};

export type UserCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
};

export type UserCreateNestedOneWithoutUserLogsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutUserLogsInput>;
  create?: InputMaybe<UserCreateWithoutUserLogsInput>;
};

export type UserCreateNestedOneWithoutWorkshopsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<UserCreateWithoutWorkshopsInput>;
};

export type UserCreateOrConnectWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSchoolsInput = {
  create: UserCreateWithoutSchoolsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutStandardsInput = {
  create: UserCreateWithoutStandardsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutUserLogsInput = {
  create: UserCreateWithoutUserLogsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutWorkshopsInput = {
  create: UserCreateWithoutWorkshopsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutActivitiesInput = {
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutCommentsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutSchoolsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutUserLogsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshops?: InputMaybe<WorkshopCreateNestedManyWithoutTrainerInput>;
};

export type UserCreateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityCreateNestedManyWithoutApprovedByInput>;
  comments?: InputMaybe<CommentCreateNestedManyWithoutApprovedByInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastLoginAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  role?: InputMaybe<Role>;
  schools?: InputMaybe<SchoolCreateNestedManyWithoutUsersInput>;
  standards?: InputMaybe<StandardCreateNestedManyWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  userLogs?: InputMaybe<UserLogCreateNestedManyWithoutUserInput>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserLog = {
  __typename?: 'UserLog';
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  module: Scalars['String']['output'];
  moduleId: Scalars['Int']['output'];
  status: Scalars['String']['output'];
  user: User;
  userId: Scalars['Int']['output'];
};

export type UserLogAvgAggregate = {
  __typename?: 'UserLogAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  moduleId?: Maybe<Scalars['Float']['output']>;
  userId?: Maybe<Scalars['Float']['output']>;
};

export type UserLogCountAggregate = {
  __typename?: 'UserLogCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  data: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  module: Scalars['Int']['output'];
  moduleId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  userId: Scalars['Int']['output'];
};

export type UserLogCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  module: Scalars['String']['input'];
  moduleId: Scalars['Int']['input'];
  status: Scalars['String']['input'];
  user: UserCreateNestedOneWithoutUserLogsInput;
};

export type UserLogCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  module: Scalars['String']['input'];
  moduleId: Scalars['Int']['input'];
  status: Scalars['String']['input'];
};

export type UserLogCreateManyUserInputEnvelope = {
  data: Array<UserLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLogCreateManyUserInputEnvelope>;
};

export type UserLogCreateOrConnectWithoutUserInput = {
  create: UserLogCreateWithoutUserInput;
  where: UserLogWhereUniqueInput;
};

export type UserLogCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  module: Scalars['String']['input'];
  moduleId: Scalars['Int']['input'];
  status: Scalars['String']['input'];
};

export type UserLogListRelationFilter = {
  every?: InputMaybe<UserLogWhereInput>;
  none?: InputMaybe<UserLogWhereInput>;
  some?: InputMaybe<UserLogWhereInput>;
};

export type UserLogMaxAggregate = {
  __typename?: 'UserLogMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  moduleId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserLogMinAggregate = {
  __typename?: 'UserLogMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  module?: Maybe<Scalars['String']['output']>;
  moduleId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserLogOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  module?: InputMaybe<SortOrder>;
  moduleId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum UserLogScalarFieldEnum {
  CREATEDAT = 'createdAt',
  DATA = 'data',
  ID = 'id',
  MODULE = 'module',
  MODULEID = 'moduleId',
  STATUS = 'status',
  USERID = 'userId'
}

export type UserLogScalarWhereInput = {
  AND?: InputMaybe<Array<UserLogScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserLogScalarWhereInput>>;
  OR?: InputMaybe<Array<UserLogScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<IntFilter>;
  module?: InputMaybe<StringFilter>;
  moduleId?: InputMaybe<IntFilter>;
  status?: InputMaybe<StringFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserLogSumAggregate = {
  __typename?: 'UserLogSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  moduleId?: Maybe<Scalars['Int']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export type UserLogUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  module?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserLogUpdateManyWithWhereWithoutUserInput = {
  data: UserLogUpdateManyMutationInput;
  where: UserLogScalarWhereInput;
};

export type UserLogUpdateManyWithoutUserNestedInput = {
  connect?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<UserLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<UserLogCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserLogScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  set?: InputMaybe<Array<UserLogWhereUniqueInput>>;
  update?: InputMaybe<Array<UserLogUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<UserLogUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<UserLogUpsertWithWhereUniqueWithoutUserInput>>;
};

export type UserLogUpdateWithWhereUniqueWithoutUserInput = {
  data: UserLogUpdateWithoutUserInput;
  where: UserLogWhereUniqueInput;
};

export type UserLogUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']['input']>;
  module?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleId?: InputMaybe<IntFieldUpdateOperationsInput>;
  status?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type UserLogUpsertWithWhereUniqueWithoutUserInput = {
  create: UserLogCreateWithoutUserInput;
  update: UserLogUpdateWithoutUserInput;
  where: UserLogWhereUniqueInput;
};

export type UserLogWhereInput = {
  AND?: InputMaybe<Array<UserLogWhereInput>>;
  NOT?: InputMaybe<Array<UserLogWhereInput>>;
  OR?: InputMaybe<Array<UserLogWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<IntFilter>;
  module?: InputMaybe<StringFilter>;
  moduleId?: InputMaybe<IntFilter>;
  status?: InputMaybe<StringFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type UserLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  activities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  comments?: InputMaybe<CommentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrderInput>;
  firstName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  lastLoginAt?: InputMaybe<SortOrderInput>;
  lastName?: InputMaybe<SortOrder>;
  phoneNumber?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  schools?: InputMaybe<SchoolOrderByRelationAggregateInput>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userLogs?: InputMaybe<UserLogOrderByRelationAggregateInput>;
  workshops?: InputMaybe<WorkshopOrderByRelationAggregateInput>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CREATEDAT = 'createdAt',
  EMAIL = 'email',
  FIRSTNAME = 'firstName',
  ID = 'id',
  ISACTIVE = 'isActive',
  LASTLOGINAT = 'lastLoginAt',
  LASTNAME = 'lastName',
  PHONENUMBER = 'phoneNumber',
  ROLE = 'role',
  UPDATEDAT = 'updatedAt'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
};

export type UserUpdateInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutSchoolsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutStandardsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutSchoolsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutSchoolsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutSchoolsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutSchoolsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutSchoolsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutSchoolsInput>>;
};

export type UserUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type UserUpdateOneWithoutActivitiesNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivitiesInput>;
  create?: InputMaybe<UserCreateWithoutActivitiesInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutActivitiesInput>;
  upsert?: InputMaybe<UserUpsertWithoutActivitiesInput>;
};

export type UserUpdateOneWithoutCommentsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<UserCreateWithoutCommentsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommentsInput>;
};

export type UserUpdateOneWithoutWorkshopsNestedInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutWorkshopsInput>;
  create?: InputMaybe<UserCreateWithoutWorkshopsInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<UserUpdateWithoutWorkshopsInput>;
  upsert?: InputMaybe<UserUpsertWithoutWorkshopsInput>;
};

export type UserUpdateWithWhereUniqueWithoutSchoolsInput = {
  data: UserUpdateWithoutSchoolsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutStandardsInput = {
  data: UserUpdateWithoutStandardsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutActivitiesInput = {
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutCommentsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutSchoolsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutStandardsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
  workshops?: InputMaybe<WorkshopUpdateManyWithoutTrainerNestedInput>;
};

export type UserUpdateWithoutWorkshopsInput = {
  activities?: InputMaybe<ActivityUpdateManyWithoutApprovedByNestedInput>;
  comments?: InputMaybe<CommentUpdateManyWithoutApprovedByNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  firstName?: InputMaybe<StringFieldUpdateOperationsInput>;
  isActive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  lastLoginAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  lastName?: InputMaybe<StringFieldUpdateOperationsInput>;
  phoneNumber?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumRoleFieldUpdateOperationsInput>;
  schools?: InputMaybe<SchoolUpdateManyWithoutUsersNestedInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userLogs?: InputMaybe<UserLogUpdateManyWithoutUserNestedInput>;
};

export type UserUpsertWithWhereUniqueWithoutSchoolsInput = {
  create: UserCreateWithoutSchoolsInput;
  update: UserUpdateWithoutSchoolsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutStandardsInput = {
  create: UserCreateWithoutStandardsInput;
  update: UserUpdateWithoutStandardsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutActivitiesInput = {
  create: UserCreateWithoutActivitiesInput;
  update: UserUpdateWithoutActivitiesInput;
};

export type UserUpsertWithoutCommentsInput = {
  create: UserCreateWithoutCommentsInput;
  update: UserUpdateWithoutCommentsInput;
};

export type UserUpsertWithoutWorkshopsInput = {
  create: UserCreateWithoutWorkshopsInput;
  update: UserUpdateWithoutWorkshopsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activities?: InputMaybe<ActivityListRelationFilter>;
  comments?: InputMaybe<CommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  firstName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  isActive?: InputMaybe<BoolFilter>;
  lastLoginAt?: InputMaybe<DateTimeNullableFilter>;
  lastName?: InputMaybe<StringFilter>;
  phoneNumber?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  schools?: InputMaybe<SchoolListRelationFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userLogs?: InputMaybe<UserLogListRelationFilter>;
  workshops?: InputMaybe<WorkshopListRelationFilter>;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateActivityQuestionAnswerInput = {
  answers: Array<Scalars['String']['input']>;
};

export type ValidateActivityQuestionAnswerOutput = {
  __typename?: 'ValidateActivityQuestionAnswerOutput';
  answerText?: Maybe<Scalars['String']['output']>;
  incorrectText?: Maybe<Scalars['String']['output']>;
  isCorrect: Scalars['Boolean']['output'];
};

export type ValidateActivityQuestionSubmitInput = {
  answers: Array<Scalars['String']['input']>;
  questionId: Scalars['Int']['input'];
};

export type ValidateActivityQuestionSubmitOutput = {
  __typename?: 'ValidateActivityQuestionSubmitOutput';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type VerifySignUpCodeInput = {
  code: Scalars['String']['input'];
};

export type VerifyUserInput = {
  id: Scalars['String']['input'];
};

export type VerifyUserResponse = {
  __typename?: 'VerifyUserResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Workshop = {
  __typename?: 'Workshop';
  _count?: Maybe<WorkshopCount>;
  address?: Maybe<Scalars['String']['output']>;
  ageGroup?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayTime?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  feesInCurrency?: Maybe<Scalars['Int']['output']>;
  feesWithKit?: Maybe<Scalars['Int']['output']>;
  feesWithoutKit?: Maybe<Scalars['Int']['output']>;
  footerImageLarge?: Maybe<Scalars['String']['output']>;
  footerImageSmall?: Maybe<Scalars['String']['output']>;
  headerImageLarge?: Maybe<Scalars['String']['output']>;
  headerImageSmall?: Maybe<Scalars['String']['output']>;
  headerText?: Maybe<Scalars['String']['output']>;
  headerVideo?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  insiderActivities: Array<Activity>;
  isOpen: Scalars['Boolean']['output'];
  liveUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  metaTagData?: Maybe<Scalars['JSON']['output']>;
  mode: Scalars['String']['output'];
  name: Scalars['String']['output'];
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['Int']['output']>;
  promoCodes: Array<PromoCode>;
  relatedActivities: Array<Activity>;
  school: School;
  schoolId: Scalars['Int']['output'];
  skill: Skill;
  skillId: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  standards: Array<Standard>;
  startDate: Scalars['DateTime']['output'];
  tags: Array<Tag>;
  trainer?: Maybe<User>;
  trainerId?: Maybe<Scalars['Int']['output']>;
  trainerImage?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workshopRegistrations: Array<WorkshopRegistration>;
};


export type WorkshopInsiderActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopPromoCodesArgs = {
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type WorkshopRelatedActivitiesArgs = {
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopStandardsArgs = {
  cursor?: InputMaybe<StandardWhereUniqueInput>;
  distinct?: InputMaybe<Array<StandardScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<StandardOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<StandardWhereInput>;
};


export type WorkshopTagsArgs = {
  cursor?: InputMaybe<TagWhereUniqueInput>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type WorkshopWorkshopRegistrationsArgs = {
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type WorkshopAvgAggregate = {
  __typename?: 'WorkshopAvgAggregate';
  feesInCurrency?: Maybe<Scalars['Float']['output']>;
  feesWithKit?: Maybe<Scalars['Float']['output']>;
  feesWithoutKit?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  productId?: Maybe<Scalars['Float']['output']>;
  schoolId?: Maybe<Scalars['Float']['output']>;
  skillId?: Maybe<Scalars['Float']['output']>;
  trainerId?: Maybe<Scalars['Float']['output']>;
};

export type WorkshopCount = {
  __typename?: 'WorkshopCount';
  insiderActivities: Scalars['Int']['output'];
  promoCodes: Scalars['Int']['output'];
  relatedActivities: Scalars['Int']['output'];
  standards: Scalars['Int']['output'];
  tags: Scalars['Int']['output'];
  workshopRegistrations: Scalars['Int']['output'];
};


export type WorkshopCountInsiderActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopCountPromoCodesArgs = {
  where?: InputMaybe<PromoCodeWhereInput>;
};


export type WorkshopCountRelatedActivitiesArgs = {
  where?: InputMaybe<ActivityWhereInput>;
};


export type WorkshopCountStandardsArgs = {
  where?: InputMaybe<StandardWhereInput>;
};


export type WorkshopCountTagsArgs = {
  where?: InputMaybe<TagWhereInput>;
};


export type WorkshopCountWorkshopRegistrationsArgs = {
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type WorkshopCountAggregate = {
  __typename?: 'WorkshopCountAggregate';
  _all: Scalars['Int']['output'];
  address: Scalars['Int']['output'];
  ageGroup: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  description: Scalars['Int']['output'];
  displayTime: Scalars['Int']['output'];
  endDate: Scalars['Int']['output'];
  featuredImage: Scalars['Int']['output'];
  feesInCurrency: Scalars['Int']['output'];
  feesWithKit: Scalars['Int']['output'];
  feesWithoutKit: Scalars['Int']['output'];
  footerImageLarge: Scalars['Int']['output'];
  footerImageSmall: Scalars['Int']['output'];
  headerImageLarge: Scalars['Int']['output'];
  headerImageSmall: Scalars['Int']['output'];
  headerText: Scalars['Int']['output'];
  headerVideo: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  image: Scalars['Int']['output'];
  isOpen: Scalars['Int']['output'];
  liveUrl: Scalars['Int']['output'];
  location: Scalars['Int']['output'];
  meta: Scalars['Int']['output'];
  metaTagData: Scalars['Int']['output'];
  mode: Scalars['Int']['output'];
  name: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
  schoolId: Scalars['Int']['output'];
  skillId: Scalars['Int']['output'];
  slug: Scalars['Int']['output'];
  startDate: Scalars['Int']['output'];
  trainerId: Scalars['Int']['output'];
  trainerImage: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
};

export type WorkshopCreateInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateManyProductInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  schoolId: Scalars['Int']['input'];
  skillId: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  trainerId?: InputMaybe<Scalars['Int']['input']>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopCreateManyProductInputEnvelope = {
  data: Array<WorkshopCreateManyProductInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkshopCreateManySchoolInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['Int']['input']>;
  skillId: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  trainerId?: InputMaybe<Scalars['Int']['input']>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopCreateManySchoolInputEnvelope = {
  data: Array<WorkshopCreateManySchoolInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkshopCreateManySkillInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['Int']['input']>;
  schoolId: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  trainerId?: InputMaybe<Scalars['Int']['input']>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopCreateManySkillInputEnvelope = {
  data: Array<WorkshopCreateManySkillInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkshopCreateManyTrainerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productId?: InputMaybe<Scalars['Int']['input']>;
  schoolId: Scalars['Int']['input'];
  skillId: Scalars['Int']['input'];
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopCreateManyTrainerInputEnvelope = {
  data: Array<WorkshopCreateManyTrainerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkshopCreateNestedManyWithoutInsiderActivitiesInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutInsiderActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutInsiderActivitiesInput>>;
};

export type WorkshopCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutProductInput>>;
  createMany?: InputMaybe<WorkshopCreateManyProductInputEnvelope>;
};

export type WorkshopCreateNestedManyWithoutPromoCodesInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutPromoCodesInput>>;
};

export type WorkshopCreateNestedManyWithoutRelatedActivitiesInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutRelatedActivitiesInput>>;
};

export type WorkshopCreateNestedManyWithoutSchoolInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<WorkshopCreateManySchoolInputEnvelope>;
};

export type WorkshopCreateNestedManyWithoutSkillInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSkillInput>>;
  createMany?: InputMaybe<WorkshopCreateManySkillInputEnvelope>;
};

export type WorkshopCreateNestedManyWithoutStandardsInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutStandardsInput>>;
};

export type WorkshopCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTagsInput>>;
};

export type WorkshopCreateNestedManyWithoutTrainerInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTrainerInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTrainerInput>>;
  createMany?: InputMaybe<WorkshopCreateManyTrainerInputEnvelope>;
};

export type WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput = {
  connect?: InputMaybe<WorkshopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<WorkshopCreateWithoutWorkshopRegistrationsInput>;
};

export type WorkshopCreateOrConnectWithoutInsiderActivitiesInput = {
  create: WorkshopCreateWithoutInsiderActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutProductInput = {
  create: WorkshopCreateWithoutProductInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutPromoCodesInput = {
  create: WorkshopCreateWithoutPromoCodesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutRelatedActivitiesInput = {
  create: WorkshopCreateWithoutRelatedActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutSchoolInput = {
  create: WorkshopCreateWithoutSchoolInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutSkillInput = {
  create: WorkshopCreateWithoutSkillInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutStandardsInput = {
  create: WorkshopCreateWithoutStandardsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutTagsInput = {
  create: WorkshopCreateWithoutTagsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutTrainerInput = {
  create: WorkshopCreateWithoutTrainerInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateOrConnectWithoutWorkshopRegistrationsInput = {
  create: WorkshopCreateWithoutWorkshopRegistrationsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopCreateWithoutInsiderActivitiesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutProductInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutPromoCodesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutRelatedActivitiesInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutSchoolInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutSkillInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutStandardsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutTagsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutTrainerInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationCreateNestedManyWithoutWorkshopInput>;
};

export type WorkshopCreateWithoutWorkshopRegistrationsInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  ageGroup?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayTime?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  featuredImage?: InputMaybe<Scalars['String']['input']>;
  feesInCurrency?: InputMaybe<Scalars['Int']['input']>;
  feesWithKit?: InputMaybe<Scalars['Int']['input']>;
  feesWithoutKit?: InputMaybe<Scalars['Int']['input']>;
  footerImageLarge?: InputMaybe<Scalars['String']['input']>;
  footerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerImageLarge?: InputMaybe<Scalars['String']['input']>;
  headerImageSmall?: InputMaybe<Scalars['String']['input']>;
  headerText?: InputMaybe<Scalars['String']['input']>;
  headerVideo?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  insiderActivities?: InputMaybe<ActivityCreateNestedManyWithoutInsideWorkshopsInput>;
  isOpen?: InputMaybe<Scalars['Boolean']['input']>;
  liveUrl?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode: Scalars['String']['input'];
  name: Scalars['String']['input'];
  product?: InputMaybe<ProductCreateNestedOneWithoutWorkshopsInput>;
  promoCodes?: InputMaybe<PromoCodeCreateNestedManyWithoutWorkshopsInput>;
  relatedActivities?: InputMaybe<ActivityCreateNestedManyWithoutRelatedWorkshopsInput>;
  school: SchoolCreateNestedOneWithoutWorkshopsInput;
  skill: SkillCreateNestedOneWithoutWorkshopsInput;
  slug: Scalars['String']['input'];
  standards?: InputMaybe<StandardCreateNestedManyWithoutWorkshopsInput>;
  startDate: Scalars['DateTime']['input'];
  tags?: InputMaybe<TagCreateNestedManyWithoutWorkshopsInput>;
  trainer?: InputMaybe<UserCreateNestedOneWithoutWorkshopsInput>;
  trainerImage?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopListRelationFilter = {
  every?: InputMaybe<WorkshopWhereInput>;
  none?: InputMaybe<WorkshopWhereInput>;
  some?: InputMaybe<WorkshopWhereInput>;
};

export type WorkshopMaxAggregate = {
  __typename?: 'WorkshopMaxAggregate';
  address?: Maybe<Scalars['String']['output']>;
  ageGroup?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayTime?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  feesInCurrency?: Maybe<Scalars['Int']['output']>;
  feesWithKit?: Maybe<Scalars['Int']['output']>;
  feesWithoutKit?: Maybe<Scalars['Int']['output']>;
  footerImageLarge?: Maybe<Scalars['String']['output']>;
  footerImageSmall?: Maybe<Scalars['String']['output']>;
  headerImageLarge?: Maybe<Scalars['String']['output']>;
  headerImageSmall?: Maybe<Scalars['String']['output']>;
  headerText?: Maybe<Scalars['String']['output']>;
  headerVideo?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  liveUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  trainerId?: Maybe<Scalars['Int']['output']>;
  trainerImage?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkshopMinAggregate = {
  __typename?: 'WorkshopMinAggregate';
  address?: Maybe<Scalars['String']['output']>;
  ageGroup?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayTime?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  featuredImage?: Maybe<Scalars['String']['output']>;
  feesInCurrency?: Maybe<Scalars['Int']['output']>;
  feesWithKit?: Maybe<Scalars['Int']['output']>;
  feesWithoutKit?: Maybe<Scalars['Int']['output']>;
  footerImageLarge?: Maybe<Scalars['String']['output']>;
  footerImageSmall?: Maybe<Scalars['String']['output']>;
  headerImageLarge?: Maybe<Scalars['String']['output']>;
  headerImageSmall?: Maybe<Scalars['String']['output']>;
  headerText?: Maybe<Scalars['String']['output']>;
  headerVideo?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  liveUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  mode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  trainerId?: Maybe<Scalars['Int']['output']>;
  trainerImage?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkshopOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WorkshopOrderByWithRelationInput = {
  address?: InputMaybe<SortOrderInput>;
  ageGroup?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  displayTime?: InputMaybe<SortOrderInput>;
  endDate?: InputMaybe<SortOrderInput>;
  featuredImage?: InputMaybe<SortOrderInput>;
  feesInCurrency?: InputMaybe<SortOrderInput>;
  feesWithKit?: InputMaybe<SortOrderInput>;
  feesWithoutKit?: InputMaybe<SortOrderInput>;
  footerImageLarge?: InputMaybe<SortOrderInput>;
  footerImageSmall?: InputMaybe<SortOrderInput>;
  headerImageLarge?: InputMaybe<SortOrderInput>;
  headerImageSmall?: InputMaybe<SortOrderInput>;
  headerText?: InputMaybe<SortOrderInput>;
  headerVideo?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrderInput>;
  insiderActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  isOpen?: InputMaybe<SortOrder>;
  liveUrl?: InputMaybe<SortOrderInput>;
  location?: InputMaybe<SortOrderInput>;
  meta?: InputMaybe<SortOrderInput>;
  metaTagData?: InputMaybe<SortOrderInput>;
  mode?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  product?: InputMaybe<ProductOrderByWithRelationInput>;
  productId?: InputMaybe<SortOrderInput>;
  promoCodes?: InputMaybe<PromoCodeOrderByRelationAggregateInput>;
  relatedActivities?: InputMaybe<ActivityOrderByRelationAggregateInput>;
  school?: InputMaybe<SchoolOrderByWithRelationInput>;
  schoolId?: InputMaybe<SortOrder>;
  skill?: InputMaybe<SkillOrderByWithRelationInput>;
  skillId?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  standards?: InputMaybe<StandardOrderByRelationAggregateInput>;
  startDate?: InputMaybe<SortOrder>;
  tags?: InputMaybe<TagOrderByRelationAggregateInput>;
  trainer?: InputMaybe<UserOrderByWithRelationInput>;
  trainerId?: InputMaybe<SortOrderInput>;
  trainerImage?: InputMaybe<SortOrderInput>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationOrderByRelationAggregateInput>;
};

export type WorkshopRegistration = {
  __typename?: 'WorkshopRegistration';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['Int']['output']>;
  status: WorkshopStatus;
  student: Student;
  studentId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workshop: Workshop;
  workshopId: Scalars['Int']['output'];
};

export type WorkshopRegistrationAvgAggregate = {
  __typename?: 'WorkshopRegistrationAvgAggregate';
  id?: Maybe<Scalars['Float']['output']>;
  orderId?: Maybe<Scalars['Float']['output']>;
  studentId?: Maybe<Scalars['Float']['output']>;
  workshopId?: Maybe<Scalars['Float']['output']>;
};

export type WorkshopRegistrationCountAggregate = {
  __typename?: 'WorkshopRegistrationCountAggregate';
  _all: Scalars['Int']['output'];
  createdAt: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  orderId: Scalars['Int']['output'];
  status: Scalars['Int']['output'];
  studentId: Scalars['Int']['output'];
  updatedAt: Scalars['Int']['output'];
  workshopId: Scalars['Int']['output'];
};

export type WorkshopRegistrationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutWorkshopRegistrationInput>;
  status?: InputMaybe<WorkshopStatus>;
  student: StudentCreateNestedOneWithoutWorkshopRegistrationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshop: WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput;
};

export type WorkshopRegistrationCreateManyStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<WorkshopStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshopId: Scalars['Int']['input'];
};

export type WorkshopRegistrationCreateManyStudentInputEnvelope = {
  data: Array<WorkshopRegistrationCreateManyStudentInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkshopRegistrationCreateManyWorkshopInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<WorkshopStatus>;
  studentId: Scalars['Int']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopRegistrationCreateManyWorkshopInputEnvelope = {
  data: Array<WorkshopRegistrationCreateManyWorkshopInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkshopRegistrationCreateNestedManyWithoutStudentInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyStudentInputEnvelope>;
};

export type WorkshopRegistrationCreateNestedManyWithoutWorkshopInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutWorkshopInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutWorkshopInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyWorkshopInputEnvelope>;
};

export type WorkshopRegistrationCreateNestedOneWithoutOrderInput = {
  connect?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopRegistrationCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<WorkshopRegistrationCreateWithoutOrderInput>;
};

export type WorkshopRegistrationCreateOrConnectWithoutOrderInput = {
  create: WorkshopRegistrationCreateWithoutOrderInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationCreateOrConnectWithoutStudentInput = {
  create: WorkshopRegistrationCreateWithoutStudentInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationCreateOrConnectWithoutWorkshopInput = {
  create: WorkshopRegistrationCreateWithoutWorkshopInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationCreateWithoutOrderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<WorkshopStatus>;
  student: StudentCreateNestedOneWithoutWorkshopRegistrationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshop: WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput;
};

export type WorkshopRegistrationCreateWithoutStudentInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutWorkshopRegistrationInput>;
  status?: InputMaybe<WorkshopStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  workshop: WorkshopCreateNestedOneWithoutWorkshopRegistrationsInput;
};

export type WorkshopRegistrationCreateWithoutWorkshopInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  order?: InputMaybe<OrderCreateNestedOneWithoutWorkshopRegistrationInput>;
  status?: InputMaybe<WorkshopStatus>;
  student: StudentCreateNestedOneWithoutWorkshopRegistrationsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type WorkshopRegistrationListRelationFilter = {
  every?: InputMaybe<WorkshopRegistrationWhereInput>;
  none?: InputMaybe<WorkshopRegistrationWhereInput>;
  some?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export type WorkshopRegistrationMaxAggregate = {
  __typename?: 'WorkshopRegistrationMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<WorkshopStatus>;
  studentId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  workshopId?: Maybe<Scalars['Int']['output']>;
};

export type WorkshopRegistrationMinAggregate = {
  __typename?: 'WorkshopRegistrationMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<WorkshopStatus>;
  studentId?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  workshopId?: Maybe<Scalars['Int']['output']>;
};

export type WorkshopRegistrationOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type WorkshopRegistrationOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<OrderOrderByWithRelationInput>;
  orderId?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  student?: InputMaybe<StudentOrderByWithRelationInput>;
  studentId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  workshop?: InputMaybe<WorkshopOrderByWithRelationInput>;
  workshopId?: InputMaybe<SortOrder>;
};

export type WorkshopRegistrationRelationFilter = {
  is?: InputMaybe<WorkshopRegistrationWhereInput>;
  isNot?: InputMaybe<WorkshopRegistrationWhereInput>;
};

export enum WorkshopRegistrationScalarFieldEnum {
  CREATEDAT = 'createdAt',
  ID = 'id',
  ORDERID = 'orderId',
  STATUS = 'status',
  STUDENTID = 'studentId',
  UPDATEDAT = 'updatedAt',
  WORKSHOPID = 'workshopId'
}

export type WorkshopRegistrationScalarWhereInput = {
  AND?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  OR?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  orderId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumWorkshopStatusFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopId?: InputMaybe<IntFilter>;
};

export type WorkshopRegistrationSumAggregate = {
  __typename?: 'WorkshopRegistrationSumAggregate';
  id?: Maybe<Scalars['Int']['output']>;
  orderId?: Maybe<Scalars['Int']['output']>;
  studentId?: Maybe<Scalars['Int']['output']>;
  workshopId?: Maybe<Scalars['Int']['output']>;
};

export type WorkshopRegistrationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutWorkshopRegistrationNestedInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshop?: InputMaybe<WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
};

export type WorkshopRegistrationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopRegistrationUpdateManyWithWhereWithoutStudentInput = {
  data: WorkshopRegistrationUpdateManyMutationInput;
  where: WorkshopRegistrationScalarWhereInput;
};

export type WorkshopRegistrationUpdateManyWithWhereWithoutWorkshopInput = {
  data: WorkshopRegistrationUpdateManyMutationInput;
  where: WorkshopRegistrationScalarWhereInput;
};

export type WorkshopRegistrationUpdateManyWithoutStudentNestedInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutStudentInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutStudentInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyStudentInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopRegistrationUpdateWithWhereUniqueWithoutStudentInput>>;
  updateMany?: InputMaybe<Array<WorkshopRegistrationUpdateManyWithWhereWithoutStudentInput>>;
  upsert?: InputMaybe<Array<WorkshopRegistrationUpsertWithWhereUniqueWithoutStudentInput>>;
};

export type WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput = {
  connect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopRegistrationCreateOrConnectWithoutWorkshopInput>>;
  create?: InputMaybe<Array<WorkshopRegistrationCreateWithoutWorkshopInput>>;
  createMany?: InputMaybe<WorkshopRegistrationCreateManyWorkshopInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopRegistrationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopRegistrationWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopRegistrationUpdateWithWhereUniqueWithoutWorkshopInput>>;
  updateMany?: InputMaybe<Array<WorkshopRegistrationUpdateManyWithWhereWithoutWorkshopInput>>;
  upsert?: InputMaybe<Array<WorkshopRegistrationUpsertWithWhereUniqueWithoutWorkshopInput>>;
};

export type WorkshopRegistrationUpdateOneWithoutOrderNestedInput = {
  connect?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopRegistrationCreateOrConnectWithoutOrderInput>;
  create?: InputMaybe<WorkshopRegistrationCreateWithoutOrderInput>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  update?: InputMaybe<WorkshopRegistrationUpdateWithoutOrderInput>;
  upsert?: InputMaybe<WorkshopRegistrationUpsertWithoutOrderInput>;
};

export type WorkshopRegistrationUpdateWithWhereUniqueWithoutStudentInput = {
  data: WorkshopRegistrationUpdateWithoutStudentInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpdateWithWhereUniqueWithoutWorkshopInput = {
  data: WorkshopRegistrationUpdateWithoutWorkshopInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpdateWithoutOrderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshop?: InputMaybe<WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
};

export type WorkshopRegistrationUpdateWithoutStudentInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutWorkshopRegistrationNestedInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshop?: InputMaybe<WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
};

export type WorkshopRegistrationUpdateWithoutWorkshopInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  order?: InputMaybe<OrderUpdateOneWithoutWorkshopRegistrationNestedInput>;
  status?: InputMaybe<EnumWorkshopStatusFieldUpdateOperationsInput>;
  student?: InputMaybe<StudentUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopRegistrationUpsertWithWhereUniqueWithoutStudentInput = {
  create: WorkshopRegistrationCreateWithoutStudentInput;
  update: WorkshopRegistrationUpdateWithoutStudentInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpsertWithWhereUniqueWithoutWorkshopInput = {
  create: WorkshopRegistrationCreateWithoutWorkshopInput;
  update: WorkshopRegistrationUpdateWithoutWorkshopInput;
  where: WorkshopRegistrationWhereUniqueInput;
};

export type WorkshopRegistrationUpsertWithoutOrderInput = {
  create: WorkshopRegistrationCreateWithoutOrderInput;
  update: WorkshopRegistrationUpdateWithoutOrderInput;
};

export type WorkshopRegistrationWhereInput = {
  AND?: InputMaybe<Array<WorkshopRegistrationWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopRegistrationWhereInput>>;
  OR?: InputMaybe<Array<WorkshopRegistrationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  order?: InputMaybe<OrderRelationFilter>;
  orderId?: InputMaybe<IntNullableFilter>;
  status?: InputMaybe<EnumWorkshopStatusFilter>;
  student?: InputMaybe<StudentRelationFilter>;
  studentId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshop?: InputMaybe<WorkshopRelationFilter>;
  workshopId?: InputMaybe<IntFilter>;
};

export type WorkshopRegistrationWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  orderId?: InputMaybe<Scalars['Int']['input']>;
};

export type WorkshopRelationFilter = {
  is?: InputMaybe<WorkshopWhereInput>;
  isNot?: InputMaybe<WorkshopWhereInput>;
};

export enum WorkshopScalarFieldEnum {
  ADDRESS = 'address',
  AGEGROUP = 'ageGroup',
  CREATEDAT = 'createdAt',
  DESCRIPTION = 'description',
  DISPLAYTIME = 'displayTime',
  ENDDATE = 'endDate',
  FEATUREDIMAGE = 'featuredImage',
  FEESINCURRENCY = 'feesInCurrency',
  FEESWITHKIT = 'feesWithKit',
  FEESWITHOUTKIT = 'feesWithoutKit',
  FOOTERIMAGELARGE = 'footerImageLarge',
  FOOTERIMAGESMALL = 'footerImageSmall',
  HEADERIMAGELARGE = 'headerImageLarge',
  HEADERIMAGESMALL = 'headerImageSmall',
  HEADERTEXT = 'headerText',
  HEADERVIDEO = 'headerVideo',
  ID = 'id',
  IMAGE = 'image',
  ISOPEN = 'isOpen',
  LIVEURL = 'liveUrl',
  LOCATION = 'location',
  META = 'meta',
  METATAGDATA = 'metaTagData',
  MODE = 'mode',
  NAME = 'name',
  PRODUCTID = 'productId',
  SCHOOLID = 'schoolId',
  SKILLID = 'skillId',
  SLUG = 'slug',
  STARTDATE = 'startDate',
  TRAINERID = 'trainerId',
  TRAINERIMAGE = 'trainerImage',
  TYPE = 'type',
  UPDATEDAT = 'updatedAt'
}

export type WorkshopScalarWhereInput = {
  AND?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  OR?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  ageGroup?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayTime?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  feesInCurrency?: InputMaybe<IntNullableFilter>;
  feesWithKit?: InputMaybe<IntNullableFilter>;
  feesWithoutKit?: InputMaybe<IntNullableFilter>;
  footerImageLarge?: InputMaybe<StringNullableFilter>;
  footerImageSmall?: InputMaybe<StringNullableFilter>;
  headerImageLarge?: InputMaybe<StringNullableFilter>;
  headerImageSmall?: InputMaybe<StringNullableFilter>;
  headerText?: InputMaybe<StringNullableFilter>;
  headerVideo?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  isOpen?: InputMaybe<BoolFilter>;
  liveUrl?: InputMaybe<StringNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  mode?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  productId?: InputMaybe<IntNullableFilter>;
  schoolId?: InputMaybe<IntFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  trainerId?: InputMaybe<IntNullableFilter>;
  trainerImage?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum WorkshopStatus {
  CONFIRMED = 'CONFIRMED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export type WorkshopSumAggregate = {
  __typename?: 'WorkshopSumAggregate';
  feesInCurrency?: Maybe<Scalars['Int']['output']>;
  feesWithKit?: Maybe<Scalars['Int']['output']>;
  feesWithoutKit?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['Int']['output']>;
  schoolId?: Maybe<Scalars['Int']['output']>;
  skillId?: Maybe<Scalars['Int']['output']>;
  trainerId?: Maybe<Scalars['Int']['output']>;
};

export type WorkshopUpdateInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateManyMutationInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopUpdateManyWithWhereWithoutInsiderActivitiesInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutProductInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutPromoCodesInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutRelatedActivitiesInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutSchoolInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutSkillInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutStandardsInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutTagsInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithWhereWithoutTrainerInput = {
  data: WorkshopUpdateManyMutationInput;
  where: WorkshopScalarWhereInput;
};

export type WorkshopUpdateManyWithoutInsiderActivitiesNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutInsiderActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutInsiderActivitiesInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutInsiderActivitiesInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutInsiderActivitiesInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutInsiderActivitiesInput>>;
};

export type WorkshopUpdateManyWithoutProductNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutProductInput>>;
  createMany?: InputMaybe<WorkshopCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutProductInput>>;
};

export type WorkshopUpdateManyWithoutPromoCodesNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutPromoCodesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutPromoCodesInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutPromoCodesInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutPromoCodesInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutPromoCodesInput>>;
};

export type WorkshopUpdateManyWithoutRelatedActivitiesNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutRelatedActivitiesInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutRelatedActivitiesInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutRelatedActivitiesInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutRelatedActivitiesInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutRelatedActivitiesInput>>;
};

export type WorkshopUpdateManyWithoutSchoolNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSchoolInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSchoolInput>>;
  createMany?: InputMaybe<WorkshopCreateManySchoolInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutSchoolInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutSchoolInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutSchoolInput>>;
};

export type WorkshopUpdateManyWithoutSkillNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutSkillInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutSkillInput>>;
  createMany?: InputMaybe<WorkshopCreateManySkillInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutSkillInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutSkillInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutSkillInput>>;
};

export type WorkshopUpdateManyWithoutStandardsNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutStandardsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutStandardsInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutStandardsInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutStandardsInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutStandardsInput>>;
};

export type WorkshopUpdateManyWithoutTagsNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type WorkshopUpdateManyWithoutTrainerNestedInput = {
  connect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<WorkshopCreateOrConnectWithoutTrainerInput>>;
  create?: InputMaybe<Array<WorkshopCreateWithoutTrainerInput>>;
  createMany?: InputMaybe<WorkshopCreateManyTrainerInputEnvelope>;
  delete?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<WorkshopScalarWhereInput>>;
  disconnect?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  set?: InputMaybe<Array<WorkshopWhereUniqueInput>>;
  update?: InputMaybe<Array<WorkshopUpdateWithWhereUniqueWithoutTrainerInput>>;
  updateMany?: InputMaybe<Array<WorkshopUpdateManyWithWhereWithoutTrainerInput>>;
  upsert?: InputMaybe<Array<WorkshopUpsertWithWhereUniqueWithoutTrainerInput>>;
};

export type WorkshopUpdateOneRequiredWithoutWorkshopRegistrationsNestedInput = {
  connect?: InputMaybe<WorkshopWhereUniqueInput>;
  connectOrCreate?: InputMaybe<WorkshopCreateOrConnectWithoutWorkshopRegistrationsInput>;
  create?: InputMaybe<WorkshopCreateWithoutWorkshopRegistrationsInput>;
  update?: InputMaybe<WorkshopUpdateWithoutWorkshopRegistrationsInput>;
  upsert?: InputMaybe<WorkshopUpsertWithoutWorkshopRegistrationsInput>;
};

export type WorkshopUpdateWithWhereUniqueWithoutInsiderActivitiesInput = {
  data: WorkshopUpdateWithoutInsiderActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutProductInput = {
  data: WorkshopUpdateWithoutProductInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutPromoCodesInput = {
  data: WorkshopUpdateWithoutPromoCodesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutRelatedActivitiesInput = {
  data: WorkshopUpdateWithoutRelatedActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutSchoolInput = {
  data: WorkshopUpdateWithoutSchoolInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutSkillInput = {
  data: WorkshopUpdateWithoutSkillInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutStandardsInput = {
  data: WorkshopUpdateWithoutStandardsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutTagsInput = {
  data: WorkshopUpdateWithoutTagsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithWhereUniqueWithoutTrainerInput = {
  data: WorkshopUpdateWithoutTrainerInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpdateWithoutInsiderActivitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutProductInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutPromoCodesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutRelatedActivitiesInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutSchoolInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutSkillInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutStandardsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutTagsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutTrainerInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationUpdateManyWithoutWorkshopNestedInput>;
};

export type WorkshopUpdateWithoutWorkshopRegistrationsInput = {
  address?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ageGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  displayTime?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  featuredImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feesInCurrency?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  feesWithoutKit?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  footerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  footerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageLarge?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerImageSmall?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerVideo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  insiderActivities?: InputMaybe<ActivityUpdateManyWithoutInsideWorkshopsNestedInput>;
  isOpen?: InputMaybe<BoolFieldUpdateOperationsInput>;
  liveUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  meta?: InputMaybe<Scalars['JSON']['input']>;
  metaTagData?: InputMaybe<Scalars['JSON']['input']>;
  mode?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneWithoutWorkshopsNestedInput>;
  promoCodes?: InputMaybe<PromoCodeUpdateManyWithoutWorkshopsNestedInput>;
  relatedActivities?: InputMaybe<ActivityUpdateManyWithoutRelatedWorkshopsNestedInput>;
  school?: InputMaybe<SchoolUpdateOneRequiredWithoutWorkshopsNestedInput>;
  skill?: InputMaybe<SkillUpdateOneRequiredWithoutWorkshopsNestedInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  standards?: InputMaybe<StandardUpdateManyWithoutWorkshopsNestedInput>;
  startDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  tags?: InputMaybe<TagUpdateManyWithoutWorkshopsNestedInput>;
  trainer?: InputMaybe<UserUpdateOneWithoutWorkshopsNestedInput>;
  trainerImage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WorkshopUpsertWithWhereUniqueWithoutInsiderActivitiesInput = {
  create: WorkshopCreateWithoutInsiderActivitiesInput;
  update: WorkshopUpdateWithoutInsiderActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutProductInput = {
  create: WorkshopCreateWithoutProductInput;
  update: WorkshopUpdateWithoutProductInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutPromoCodesInput = {
  create: WorkshopCreateWithoutPromoCodesInput;
  update: WorkshopUpdateWithoutPromoCodesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutRelatedActivitiesInput = {
  create: WorkshopCreateWithoutRelatedActivitiesInput;
  update: WorkshopUpdateWithoutRelatedActivitiesInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutSchoolInput = {
  create: WorkshopCreateWithoutSchoolInput;
  update: WorkshopUpdateWithoutSchoolInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutSkillInput = {
  create: WorkshopCreateWithoutSkillInput;
  update: WorkshopUpdateWithoutSkillInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutStandardsInput = {
  create: WorkshopCreateWithoutStandardsInput;
  update: WorkshopUpdateWithoutStandardsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutTagsInput = {
  create: WorkshopCreateWithoutTagsInput;
  update: WorkshopUpdateWithoutTagsInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithWhereUniqueWithoutTrainerInput = {
  create: WorkshopCreateWithoutTrainerInput;
  update: WorkshopUpdateWithoutTrainerInput;
  where: WorkshopWhereUniqueInput;
};

export type WorkshopUpsertWithoutWorkshopRegistrationsInput = {
  create: WorkshopCreateWithoutWorkshopRegistrationsInput;
  update: WorkshopUpdateWithoutWorkshopRegistrationsInput;
};

export type WorkshopWhereInput = {
  AND?: InputMaybe<Array<WorkshopWhereInput>>;
  NOT?: InputMaybe<Array<WorkshopWhereInput>>;
  OR?: InputMaybe<Array<WorkshopWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  ageGroup?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  displayTime?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  featuredImage?: InputMaybe<StringNullableFilter>;
  feesInCurrency?: InputMaybe<IntNullableFilter>;
  feesWithKit?: InputMaybe<IntNullableFilter>;
  feesWithoutKit?: InputMaybe<IntNullableFilter>;
  footerImageLarge?: InputMaybe<StringNullableFilter>;
  footerImageSmall?: InputMaybe<StringNullableFilter>;
  headerImageLarge?: InputMaybe<StringNullableFilter>;
  headerImageSmall?: InputMaybe<StringNullableFilter>;
  headerText?: InputMaybe<StringNullableFilter>;
  headerVideo?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  image?: InputMaybe<StringNullableFilter>;
  insiderActivities?: InputMaybe<ActivityListRelationFilter>;
  isOpen?: InputMaybe<BoolFilter>;
  liveUrl?: InputMaybe<StringNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  meta?: InputMaybe<JsonNullableFilter>;
  metaTagData?: InputMaybe<JsonNullableFilter>;
  mode?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  product?: InputMaybe<ProductRelationFilter>;
  productId?: InputMaybe<IntNullableFilter>;
  promoCodes?: InputMaybe<PromoCodeListRelationFilter>;
  relatedActivities?: InputMaybe<ActivityListRelationFilter>;
  school?: InputMaybe<SchoolRelationFilter>;
  schoolId?: InputMaybe<IntFilter>;
  skill?: InputMaybe<SkillRelationFilter>;
  skillId?: InputMaybe<IntFilter>;
  slug?: InputMaybe<StringFilter>;
  standards?: InputMaybe<StandardListRelationFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  tags?: InputMaybe<TagListRelationFilter>;
  trainer?: InputMaybe<UserRelationFilter>;
  trainerId?: InputMaybe<IntNullableFilter>;
  trainerImage?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  workshopRegistrations?: InputMaybe<WorkshopRegistrationListRelationFilter>;
};

export type WorkshopWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type CreateActivityMutationVariables = Exact<{
  data: ActivityCreateInput;
}>;


export type CreateActivityMutation = { __typename?: 'Mutation', createActivity: { __typename?: 'Activity', id: number, slug: string } };

export type UpdateActivityMutationVariables = Exact<{
  where: ActivityWhereUniqueInput;
  data: ActivityUpdateInput;
}>;


export type UpdateActivityMutation = { __typename?: 'Mutation', updateActivity?: { __typename?: 'Activity', id: number } | null };

export type DeleteActivityMutationVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type DeleteActivityMutation = { __typename?: 'Mutation', deleteActivity?: { __typename?: 'Activity', id: number } | null };

export type ActivityQueryVariables = Exact<{
  where: ActivityWhereUniqueInput;
}>;


export type ActivityQuery = { __typename?: 'Query', activity?: { __typename?: 'Activity', id: number, title: string, slug: string, shortDescription?: string | null, description?: string | null, difficultyLevel: string, timeRequired: string, coinsToSpend: number, xps: number, questionXps: number, image?: string | null, featuredImage?: string | null, challengeStatement: string, materialsNeeded?: string | null, metaTagData?: any | null, video?: string | null, instructionImage?: string | null, instructionText?: string | null, status: ActivityStatus, scheduleTime?: any | null, isFeatured: boolean, isLive: boolean, isCommentEnabled: boolean, isChallenge: boolean, isVisible: boolean, isIndexable: boolean, displayOrder: number, jsonLD?: any | null, tags: Array<{ __typename?: 'Tag', id: number, name: string, slug: string }>, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }>, nextActivity?: { __typename?: 'Activity', id: number, title: string, slug: string } | null, skill?: { __typename?: 'Skill', id: number, name: string, slug: string } | null, questions: Array<{ __typename?: 'ActivityQuestion', id: number, questionText: string, questionImage?: string | null, type: string, options?: any | null, answers: Array<string>, answerText?: string | null, incorrectText?: string | null }> } | null };

export type ActivitiesQueryVariables = Exact<{
  where?: InputMaybe<ActivityWhereInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput> | ActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum> | ActivityScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ActivitiesQuery = { __typename?: 'Query', activities: Array<{ __typename?: 'Activity', id: number, title: string, slug: string, coinsToSpend: number, status: ActivityStatus, scheduleTime?: any | null, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }> }> };

export type AggregateActivityQueryVariables = Exact<{
  where?: InputMaybe<ActivityWhereInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput> | ActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateActivityQuery = { __typename?: 'Query', aggregateActivity: { __typename?: 'AggregateActivity', _count?: { __typename?: 'ActivityCountAggregate', id: number } | null } };

export type AllActivitiesQueryVariables = Exact<{
  where?: InputMaybe<ActivityWhereInput>;
  orderBy?: InputMaybe<Array<ActivityOrderByWithRelationInput> | ActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityScalarFieldEnum> | ActivityScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AllActivitiesQuery = { __typename?: 'Query', activities: Array<{ __typename?: 'Activity', id: number, title: string, slug: string }> };

export type CreateManyActivityQuestionMutationVariables = Exact<{
  data: Array<ActivityQuestionCreateManyInput> | ActivityQuestionCreateManyInput;
  skipDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type CreateManyActivityQuestionMutation = { __typename?: 'Mutation', createManyActivityQuestion: { __typename?: 'AffectedRowsOutput', count: number } };

export type UpdateOneActivityQuestionMutationVariables = Exact<{
  data: ActivityQuestionUpdateInput;
  where: ActivityQuestionWhereUniqueInput;
}>;


export type UpdateOneActivityQuestionMutation = { __typename?: 'Mutation', updateOneActivityQuestion?: { __typename?: 'ActivityQuestion', id: number } | null };

export type DeleteManyActivityQuestionMutationVariables = Exact<{
  where?: InputMaybe<ActivityQuestionWhereInput>;
}>;


export type DeleteManyActivityQuestionMutation = { __typename?: 'Mutation', deleteManyActivityQuestion: { __typename?: 'AffectedRowsOutput', count: number } };

export type CreateActivityBannerMutationVariables = Exact<{
  data: ActivityBannerCreateInput;
}>;


export type CreateActivityBannerMutation = { __typename?: 'Mutation', createActivityBanner: { __typename?: 'ActivityBanner', id: number } };

export type UpdateActivityBannerMutationVariables = Exact<{
  where: ActivityBannerWhereUniqueInput;
  data: ActivityBannerUpdateInput;
}>;


export type UpdateActivityBannerMutation = { __typename?: 'Mutation', updateActivityBanner?: { __typename?: 'ActivityBanner', id: number } | null };

export type DeleteActivityBannerMutationVariables = Exact<{
  where: ActivityBannerWhereUniqueInput;
}>;


export type DeleteActivityBannerMutation = { __typename?: 'Mutation', deleteActivityBanner?: { __typename?: 'ActivityBanner', id: number } | null };

export type ActivityBannersQueryVariables = Exact<{
  where?: InputMaybe<ActivityBannerWhereInput>;
  orderBy?: InputMaybe<Array<ActivityBannerOrderByWithRelationInput> | ActivityBannerOrderByWithRelationInput>;
  cursor?: InputMaybe<ActivityBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ActivityBannerScalarFieldEnum> | ActivityBannerScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ActivityBannersQuery = { __typename?: 'Query', activityBanners: Array<{ __typename?: 'ActivityBanner', id: number, image?: string | null, destinationUrl: string, displayOrder: number }> };

export type ActivityBannerQueryVariables = Exact<{
  where: ActivityBannerWhereUniqueInput;
}>;


export type ActivityBannerQuery = { __typename?: 'Query', activityBanner: { __typename?: 'ActivityBanner', id: number, image?: string | null, destinationUrl: string, displayOrder: number } };

export type VerifyUserMutationVariables = Exact<{
  data: VerifyUserInput;
}>;


export type VerifyUserMutation = { __typename?: 'Mutation', verifyUser: { __typename?: 'VerifyUserResponse', success: boolean, message: string } };

export type AdminLogInMutationVariables = Exact<{
  data: LogInInput;
}>;


export type AdminLogInMutation = { __typename?: 'Mutation', adminLogIn: { __typename?: 'LogInResponse', success: boolean, message: string, rules?: any | null, authToken?: string | null, expiresAt?: any | null } };

export type CreateBadgeMutationVariables = Exact<{
  data: BadgeCreateInput;
}>;


export type CreateBadgeMutation = { __typename?: 'Mutation', createBadge: { __typename?: 'Badge', id: number, slug: string } };

export type UpdateBadgeMutationVariables = Exact<{
  data: BadgeUpdateInput;
  where: BadgeWhereUniqueInput;
}>;


export type UpdateBadgeMutation = { __typename?: 'Mutation', updateBadge?: { __typename?: 'Badge', id: number } | null };

export type DeleteBadgeMutationVariables = Exact<{
  where: BadgeWhereUniqueInput;
}>;


export type DeleteBadgeMutation = { __typename?: 'Mutation', deleteBadge?: { __typename?: 'Badge', id: number } | null };

export type BadgesQueryVariables = Exact<{
  where?: InputMaybe<BadgeWhereInput>;
  orderBy?: InputMaybe<Array<BadgeOrderByWithRelationInput> | BadgeOrderByWithRelationInput>;
  cursor?: InputMaybe<BadgeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<BadgeScalarFieldEnum> | BadgeScalarFieldEnum>;
}>;


export type BadgesQuery = { __typename?: 'Query', badges: Array<{ __typename?: 'Badge', id: number, name: string, slug: string, image?: string | null, createdAt: any }> };

export type BadgeQueryVariables = Exact<{
  where: BadgeWhereUniqueInput;
}>;


export type BadgeQuery = { __typename?: 'Query', badge?: { __typename?: 'Badge', id: number, name: string, slug: string, image?: string | null, createdAt: any } | null };

export type AggregateBadgeQueryVariables = Exact<{
  where?: InputMaybe<BadgeWhereInput>;
  orderBy?: InputMaybe<Array<BadgeOrderByWithRelationInput> | BadgeOrderByWithRelationInput>;
  cursor?: InputMaybe<BadgeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateBadgeQuery = { __typename?: 'Query', aggregateBadge: { __typename?: 'AggregateBadge', _count?: { __typename?: 'BadgeCountAggregate', id: number } | null } };

export type UpdateCommentMutationVariables = Exact<{
  data: CommentUpdateInput;
  where: CommentWhereUniqueInput;
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment?: { __typename?: 'Comment', id: number, status?: CommentStatus | null, isFeatured: boolean } | null };

export type UpdateCommentsMutationVariables = Exact<{
  data: CommentUpdateManyMutationInput;
  where: CommentWhereInput;
}>;


export type UpdateCommentsMutation = { __typename?: 'Mutation', updateComments: { __typename?: 'AffectedRowsOutput', count: number } };

export type DeleteCommentMutationVariables = Exact<{
  where: CommentWhereUniqueInput;
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment: { __typename?: 'Comment', id: number } };

export type CommentsQueryVariables = Exact<{
  where?: InputMaybe<CommentWhereInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput> | CommentOrderByWithRelationInput>;
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<CommentScalarFieldEnum> | CommentScalarFieldEnum>;
}>;


export type CommentsQuery = { __typename?: 'Query', comments: Array<{ __typename?: 'Comment', id: number, text: string, attachmentUrl?: string | null, status?: CommentStatus | null, isFeatured: boolean, quality: number, isDailyChallenge: boolean, meta?: any | null, createdAt: any, createdBy?: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } | null, activity?: { __typename?: 'Activity', id: number, title: string, slug: string } | null, storyActivity?: { __typename?: 'StoryActivity', id: number, title: string, slug: string } | null }> };

export type AggregateCommentQueryVariables = Exact<{
  where?: InputMaybe<CommentWhereInput>;
  orderBy?: InputMaybe<Array<CommentOrderByWithRelationInput> | CommentOrderByWithRelationInput>;
  cursor?: InputMaybe<CommentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateCommentQuery = { __typename?: 'Query', aggregateComment: { __typename?: 'AggregateComment', _count?: { __typename?: 'CommentCountAggregate', id: number } | null } };

export type CreateDailyActivityMutationVariables = Exact<{
  data: DailyActivityCreateInput;
}>;


export type CreateDailyActivityMutation = { __typename?: 'Mutation', createDailyActivity: { __typename?: 'DailyActivity', id: number } };

export type UpdateDailyActivityMutationVariables = Exact<{
  where: DailyActivityWhereUniqueInput;
  data: DailyActivityUpdateInput;
}>;


export type UpdateDailyActivityMutation = { __typename?: 'Mutation', updateDailyActivity?: { __typename?: 'DailyActivity', id: number } | null };

export type DeleteDailyActivityMutationVariables = Exact<{
  where: DailyActivityWhereUniqueInput;
}>;


export type DeleteDailyActivityMutation = { __typename?: 'Mutation', deleteDailyActivity?: { __typename?: 'DailyActivity', id: number } | null };

export type DailyActivitiesQueryVariables = Exact<{
  where?: InputMaybe<DailyActivityWhereInput>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithRelationInput> | DailyActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<DailyActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<DailyActivityScalarFieldEnum> | DailyActivityScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DailyActivitiesQuery = { __typename?: 'Query', dailyActivities: Array<{ __typename?: 'DailyActivity', id: number, question: string, scheduledOn: any, standards: Array<{ __typename?: 'Standard', id: number }> }> };

export type DailyActivityQueryVariables = Exact<{
  where: DailyActivityWhereUniqueInput;
}>;


export type DailyActivityQuery = { __typename?: 'Query', dailyActivity?: { __typename?: 'DailyActivity', id: number, question: string, questionImage?: string | null, questionVideo?: string | null, scheduledOn: any, options: any, answer: number, answerText?: string | null, standards: Array<{ __typename?: 'Standard', id: number }> } | null };

export type AggregateDailyActivityQueryVariables = Exact<{
  where?: InputMaybe<DailyActivityWhereInput>;
  orderBy?: InputMaybe<Array<DailyActivityOrderByWithRelationInput> | DailyActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<DailyActivityWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateDailyActivityQuery = { __typename?: 'Query', aggregateDailyActivity: { __typename?: 'AggregateDailyActivity', _count?: { __typename?: 'DailyActivityCountAggregate', id: number } | null } };

export type GenerateUploadSignedUrlMutationVariables = Exact<{
  data: GenerateUploadSignedUrlInput;
}>;


export type GenerateUploadSignedUrlMutation = { __typename?: 'Mutation', generateUploadSignedUrl: Array<{ __typename?: 'File', name?: string | null, signedUrl?: string | null, url?: string | null }> };

export type CreateStudentGameLogMutationVariables = Exact<{
  data: StudentGameLogCreateInput;
}>;


export type CreateStudentGameLogMutation = { __typename?: 'Mutation', createStudentGameLog: { __typename?: 'StudentGameLog', id: number } };

export type DeleteStudentGameLogsMutationVariables = Exact<{
  where: StudentGameLogWhereInput;
}>;


export type DeleteStudentGameLogsMutation = { __typename?: 'Mutation', deleteStudentGameLogs: { __typename?: 'AffectedRowsOutput', count: number } };

export type StudentGameLogsQueryVariables = Exact<{
  where?: InputMaybe<StudentGameLogWhereInput>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput> | StudentGameLogOrderByWithRelationInput>;
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<StudentGameLogScalarFieldEnum> | StudentGameLogScalarFieldEnum>;
}>;


export type StudentGameLogsQuery = { __typename?: 'Query', studentGameLogs: Array<{ __typename?: 'StudentGameLog', id: number, title: string, model?: string | null, modelId?: number | null, points: number, triggerType: TriggerType, createdAt: any, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null, standard: { __typename?: 'Standard', key: string }, school: { __typename?: 'School', id: number, name: string } }, trigger?: { __typename?: 'Trigger', id: number, text: string } | null }> };

export type AggregateStudentGameLogQueryVariables = Exact<{
  where?: InputMaybe<StudentGameLogWhereInput>;
  orderBy?: InputMaybe<Array<StudentGameLogOrderByWithRelationInput> | StudentGameLogOrderByWithRelationInput>;
  cursor?: InputMaybe<StudentGameLogWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateStudentGameLogQuery = { __typename?: 'Query', aggregateStudentGameLog: { __typename?: 'AggregateStudentGameLog', _count?: { __typename?: 'StudentGameLogCountAggregate', id: number } | null } };

export type ExportStudentGameLogsQueryVariables = Exact<{
  where: ExportGameLogsInput;
}>;


export type ExportStudentGameLogsQuery = { __typename?: 'Query', exportStudentGameLogs: any };

export type AdminLeaderboardQueryVariables = Exact<{
  where: AdminLeaderboardInput;
  xpsOrderBy?: InputMaybe<SortOrder>;
  starsOrderBy?: InputMaybe<SortOrder>;
  take: Scalars['Int']['input'];
  skip: Scalars['Int']['input'];
}>;


export type AdminLeaderboardQuery = { __typename?: 'Query', adminLeaderboard?: Array<{ __typename?: 'AdminLeaderboardResponse', name: string, phoneNumber: string, xps: number, stars: number, level: number, school: string, standard: string }> | null };

export type CreateLevelMutationVariables = Exact<{
  data: LevelCreateInput;
}>;


export type CreateLevelMutation = { __typename?: 'Mutation', createLevel: { __typename?: 'Level', id: number, slug: string } };

export type UpdateLevelMutationVariables = Exact<{
  data: LevelUpdateInput;
  where: LevelWhereUniqueInput;
}>;


export type UpdateLevelMutation = { __typename?: 'Mutation', updateLevel?: { __typename?: 'Level', id: number } | null };

export type DeleteLevelMutationVariables = Exact<{
  where: LevelWhereUniqueInput;
}>;


export type DeleteLevelMutation = { __typename?: 'Mutation', deleteLevel?: { __typename?: 'Level', id: number } | null };

export type LevelsQueryVariables = Exact<{
  where?: InputMaybe<LevelWhereInput>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput> | LevelOrderByWithRelationInput>;
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<LevelScalarFieldEnum> | LevelScalarFieldEnum>;
}>;


export type LevelsQuery = { __typename?: 'Query', levels: Array<{ __typename?: 'Level', id: number, name: string, slug: string, image?: string | null, createdAt: any }> };

export type LevelQueryVariables = Exact<{
  where: LevelWhereUniqueInput;
}>;


export type LevelQuery = { __typename?: 'Query', level?: { __typename?: 'Level', id: number, name: string, slug: string, image?: string | null, createdAt: any, sequence: number } | null };

export type AggregateLevelQueryVariables = Exact<{
  where?: InputMaybe<LevelWhereInput>;
  orderBy?: InputMaybe<Array<LevelOrderByWithRelationInput> | LevelOrderByWithRelationInput>;
  cursor?: InputMaybe<LevelWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateLevelQuery = { __typename?: 'Query', aggregateLevel: { __typename?: 'AggregateLevel', _count?: { __typename?: 'LevelCountAggregate', id: number } | null } };

export type UpdateOrderMutationVariables = Exact<{
  data: OrderUpdateInput;
  where: OrderWhereUniqueInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder?: { __typename?: 'Order', id: number } | null };

export type UpdateManyOrderMutationVariables = Exact<{
  data: OrderUpdateManyMutationInput;
  where: OrderWhereInput;
}>;


export type UpdateManyOrderMutation = { __typename?: 'Mutation', updateManyOrder: { __typename?: 'AffectedRowsOutput', count: number } };

export type OrdersQueryVariables = Exact<{
  where?: InputMaybe<OrderWhereInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput> | OrderOrderByWithRelationInput>;
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<OrderScalarFieldEnum> | OrderScalarFieldEnum>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: number, status: OrderStatus, total: number, paymentMethod: PaymentMethod, paymentStatus: string, createdAt: any, product: { __typename?: 'Product', id: number, name: string, slug: string }, customer: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } }> };

export type OrderQueryVariables = Exact<{
  where: OrderWhereUniqueInput;
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: number, status: OrderStatus, total: number, paymentMethod: PaymentMethod, paymentStatus: string, billingFirstName?: string | null, billingLastName?: string | null, billingAddress1?: string | null, billingAddress2?: string | null, billingCity?: string | null, billingState?: string | null, billingCountry?: string | null, billingPostcode?: string | null, billingPhone?: string | null, shippingFirstName?: string | null, shippingLastName?: string | null, shippingAddress1?: string | null, shippingAddress2?: string | null, shippingCity?: string | null, shippingState?: string | null, shippingCountry?: string | null, shippingPostcode?: string | null, shippingPhone?: string | null, createdAt: any, product: { __typename?: 'Product', id: number, name: string, slug: string }, customer: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } } | null };

export type AggregateOrderQueryVariables = Exact<{
  where?: InputMaybe<OrderWhereInput>;
  orderBy?: InputMaybe<Array<OrderOrderByWithRelationInput> | OrderOrderByWithRelationInput>;
  cursor?: InputMaybe<OrderWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateOrderQuery = { __typename?: 'Query', aggregateOrder: { __typename?: 'AggregateOrder', _count?: { __typename?: 'OrderCountAggregate', id: number } | null } };

export type CreateTriggerMutationVariables = Exact<{
  data: TriggerCreateInput;
}>;


export type CreateTriggerMutation = { __typename?: 'Mutation', createTrigger: { __typename?: 'Trigger', id: number, text: string, key: string, totalCount: number, model?: string | null, modelId?: number | null, countDuration?: number | null, countPeriod?: string | null, earn: number, label?: string | null, maximumEarningsPerUser: number, isActive: boolean, gamePointType?: { __typename?: 'GamePointType', id: number, pluralName: string } | null } };

export type CreateGamePointTypeMutationVariables = Exact<{
  data: GamePointTypeCreateInput;
}>;


export type CreateGamePointTypeMutation = { __typename?: 'Mutation', createGamePointType: { __typename?: 'GamePointType', id: number, slug: string } };

export type UpdateGamePointTypeMutationVariables = Exact<{
  data: GamePointTypeUpdateInput;
  where: GamePointTypeWhereUniqueInput;
}>;


export type UpdateGamePointTypeMutation = { __typename?: 'Mutation', updateGamePointType?: { __typename?: 'GamePointType', id: number, slug: string } | null };

export type GamePointTypesQueryVariables = Exact<{
  where?: InputMaybe<GamePointTypeWhereInput>;
  orderBy?: InputMaybe<Array<GamePointTypeOrderByWithRelationInput> | GamePointTypeOrderByWithRelationInput>;
  cursor?: InputMaybe<GamePointTypeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<GamePointTypeScalarFieldEnum> | GamePointTypeScalarFieldEnum>;
}>;


export type GamePointTypesQuery = { __typename?: 'Query', gamePointTypes: Array<{ __typename?: 'GamePointType', id: number, singularName: string, pluralName: string, slug: string, image?: string | null, skill?: { __typename?: 'Skill', id: number, name: string, slug: string } | null }> };

export type GamePointTypeQueryVariables = Exact<{
  where: GamePointTypeWhereUniqueInput;
}>;


export type GamePointTypeQuery = { __typename?: 'Query', gamePointType?: { __typename?: 'GamePointType', id: number, singularName: string, pluralName: string, slug: string, image?: string | null, skill?: { __typename?: 'Skill', id: number, name: string, slug: string } | null } | null };

export type AggregateGamePointTypeQueryVariables = Exact<{
  where?: InputMaybe<GamePointTypeWhereInput>;
  orderBy?: InputMaybe<Array<GamePointTypeOrderByWithRelationInput> | GamePointTypeOrderByWithRelationInput>;
  cursor?: InputMaybe<GamePointTypeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateGamePointTypeQuery = { __typename?: 'Query', aggregateGamePointType: { __typename?: 'AggregateGamePointType', _count?: { __typename?: 'GamePointTypeCountAggregate', id: number } | null } };

export type SearchActivitiesQueryVariables = Exact<{
  search: Scalars['String']['input'];
}>;


export type SearchActivitiesQuery = { __typename?: 'Query', searchActivities: Array<{ __typename?: 'SearchActivity', id: number, modelId: number, title: string, type: ActivityType }> };

export type CreateProductMutationVariables = Exact<{
  data: ProductCreateInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'Product', id: number, slug: string } };

export type UpdateProductMutationVariables = Exact<{
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'Product', id: number, slug: string } | null };

export type DeleteProductMutationVariables = Exact<{
  where: ProductWhereUniqueInput;
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', deleteProduct?: { __typename?: 'Product', id: number } | null };

export type ProductsQueryVariables = Exact<{
  where?: InputMaybe<ProductWhereInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput> | ProductOrderByWithRelationInput>;
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ProductScalarFieldEnum> | ProductScalarFieldEnum>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: number, name: string, slug: string, description?: string | null, shortDescription?: string | null, price: number, inCurrency: number, skill?: { __typename?: 'Skill', id: number, name: string, slug: string } | null, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }> }> };

export type ProductQueryVariables = Exact<{
  data: ProductWhereUniqueInput;
}>;


export type ProductQuery = { __typename?: 'Query', product?: { __typename?: 'Product', id: number, name: string, slug: string, description?: string | null, shortDescription?: string | null, age?: string | null, isVirtual: boolean, price: number, discountPrice?: number | null, inCurrency: number, featuredImage?: string | null, metaTagData?: any | null, images: Array<string>, video?: string | null, isActive: boolean, isVisible: boolean, isBestSelling: boolean, isNewReleased: boolean, purchaseLink?: string | null, skill?: { __typename?: 'Skill', id: number, name: string, slug: string } | null, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }>, relatedActivities: Array<{ __typename?: 'Activity', id: number }>, relatedStoryActivities: Array<{ __typename?: 'StoryActivity', id: number }> } | null };

export type AggregateProductQueryVariables = Exact<{
  where?: InputMaybe<ProductWhereInput>;
  orderBy?: InputMaybe<Array<ProductOrderByWithRelationInput> | ProductOrderByWithRelationInput>;
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateProductQuery = { __typename?: 'Query', aggregateProduct: { __typename?: 'AggregateProduct', _count?: { __typename?: 'ProductCountAggregate', id: number } | null } };

export type CreateProductBannerMutationVariables = Exact<{
  data: ProductBannerCreateInput;
}>;


export type CreateProductBannerMutation = { __typename?: 'Mutation', createProductBanner: { __typename?: 'ProductBanner', id: number } };

export type UpdateProductBannerMutationVariables = Exact<{
  where: ProductBannerWhereUniqueInput;
  data: ProductBannerUpdateInput;
}>;


export type UpdateProductBannerMutation = { __typename?: 'Mutation', updateProductBanner?: { __typename?: 'ProductBanner', id: number } | null };

export type DeleteProductBannerMutationVariables = Exact<{
  where: ProductBannerWhereUniqueInput;
}>;


export type DeleteProductBannerMutation = { __typename?: 'Mutation', deleteProductBanner?: { __typename?: 'ProductBanner', id: number } | null };

export type ProductBannersQueryVariables = Exact<{
  where?: InputMaybe<ProductBannerWhereInput>;
  orderBy?: InputMaybe<Array<ProductBannerOrderByWithRelationInput> | ProductBannerOrderByWithRelationInput>;
  cursor?: InputMaybe<ProductBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<ProductBannerScalarFieldEnum> | ProductBannerScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ProductBannersQuery = { __typename?: 'Query', productBanners: Array<{ __typename?: 'ProductBanner', id: number, image?: string | null, destinationUrl: string, displayOrder: number }> };

export type ProductBannerQueryVariables = Exact<{
  where: ProductBannerWhereUniqueInput;
}>;


export type ProductBannerQuery = { __typename?: 'Query', productBanner?: { __typename?: 'ProductBanner', id: number, image?: string | null, destinationUrl: string, displayOrder: number } | null };

export type CreatePromoCodeMutationVariables = Exact<{
  data: PromoCodeCreateInput;
}>;


export type CreatePromoCodeMutation = { __typename?: 'Mutation', createPromoCode: { __typename?: 'PromoCode', id: number } };

export type UpdatePromoCodeMutationVariables = Exact<{
  data: PromoCodeUpdateInput;
  where: PromoCodeWhereUniqueInput;
}>;


export type UpdatePromoCodeMutation = { __typename?: 'Mutation', updatePromoCode?: { __typename?: 'PromoCode', id: number } | null };

export type DeletePromoCodeMutationVariables = Exact<{
  where: PromoCodeWhereUniqueInput;
}>;


export type DeletePromoCodeMutation = { __typename?: 'Mutation', deletePromoCode?: { __typename?: 'PromoCode', id: number } | null };

export type ApplyPromoCodeMutationVariables = Exact<{
  code: Scalars['String']['input'];
  studentId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ApplyPromoCodeMutation = { __typename?: 'Mutation', applyPromoCode: { __typename?: 'ApplyPromoCodeOutput', success: boolean, message: string } };

export type PromoCodesQueryVariables = Exact<{
  where?: InputMaybe<PromoCodeWhereInput>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput> | PromoCodeOrderByWithRelationInput>;
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromoCodeScalarFieldEnum> | PromoCodeScalarFieldEnum>;
}>;


export type PromoCodesQuery = { __typename?: 'Query', promoCodes: Array<{ __typename?: 'PromoCode', id: number, code: string, startsAt: any, expiresAt: any, coins: number, isActive: boolean, createdAt: any }> };

export type PromoCodeQueryVariables = Exact<{
  where: PromoCodeWhereUniqueInput;
}>;


export type PromoCodeQuery = { __typename?: 'Query', promoCode?: { __typename?: 'PromoCode', id: number, code: string, startsAt: any, expiresAt: any, coins: number, isActive: boolean, createdAt: any, activities: Array<{ __typename?: 'Activity', id: number, title: string }>, storyActivities: Array<{ __typename?: 'StoryActivity', id: number, title: string }>, workshops: Array<{ __typename?: 'Workshop', id: number, name: string }> } | null };

export type AggregatePromoCodeQueryVariables = Exact<{
  where?: InputMaybe<PromoCodeWhereInput>;
  orderBy?: InputMaybe<Array<PromoCodeOrderByWithRelationInput> | PromoCodeOrderByWithRelationInput>;
  cursor?: InputMaybe<PromoCodeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregatePromoCodeQuery = { __typename?: 'Query', aggregatePromoCode: { __typename?: 'AggregatePromoCode', _count?: { __typename?: 'PromoCodeCountAggregate', id: number } | null } };

export type CreatePromoCodeGroupMutationVariables = Exact<{
  data: PromoCodeGroupCreateInput;
}>;


export type CreatePromoCodeGroupMutation = { __typename?: 'Mutation', createPromoCodeGroup: { __typename?: 'PromoCodeGroup', id: number } };

export type UpdatePromoCodeGroupMutationVariables = Exact<{
  data: PromoCodeGroupUpdateInput;
  where: PromoCodeGroupWhereUniqueInput;
}>;


export type UpdatePromoCodeGroupMutation = { __typename?: 'Mutation', updatePromoCodeGroup?: { __typename?: 'PromoCodeGroup', id: number } | null };

export type DeletePromoCodeGroupMutationVariables = Exact<{
  where: PromoCodeGroupWhereUniqueInput;
}>;


export type DeletePromoCodeGroupMutation = { __typename?: 'Mutation', deletePromoCodeGroup?: { __typename?: 'PromoCodeGroup', id: number } | null };

export type PromoCodeGroupsQueryVariables = Exact<{
  where?: InputMaybe<PromoCodeGroupWhereInput>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithRelationInput> | PromoCodeGroupOrderByWithRelationInput>;
  cursor?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromoCodeGroupScalarFieldEnum> | PromoCodeGroupScalarFieldEnum>;
}>;


export type PromoCodeGroupsQuery = { __typename?: 'Query', promoCodeGroups: Array<{ __typename?: 'PromoCodeGroup', id: number, name: string, limit: number, promoCodeToPromoCodeGroups: Array<{ __typename?: 'PromoCodeToPromoCodeGroup', promoCode: { __typename?: 'PromoCode', id: number, code: string } }> }> };

export type PromoCodeGroupQueryVariables = Exact<{
  where: PromoCodeGroupWhereUniqueInput;
}>;


export type PromoCodeGroupQuery = { __typename?: 'Query', promoCodeGroup?: { __typename?: 'PromoCodeGroup', id: number, name: string, limit: number, promoCodeToPromoCodeGroups: Array<{ __typename?: 'PromoCodeToPromoCodeGroup', promoCode: { __typename?: 'PromoCode', id: number, code: string } }> } | null };

export type AggregatePromoCodeGroupQueryVariables = Exact<{
  where?: InputMaybe<PromoCodeGroupWhereInput>;
  orderBy?: InputMaybe<Array<PromoCodeGroupOrderByWithRelationInput> | PromoCodeGroupOrderByWithRelationInput>;
  cursor?: InputMaybe<PromoCodeGroupWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregatePromoCodeGroupQuery = { __typename?: 'Query', aggregatePromoCodeGroup: { __typename?: 'AggregatePromoCodeGroup', _count?: { __typename?: 'PromoCodeGroupCountAggregate', id: number } | null } };

export type PromoCodeHistoriesQueryVariables = Exact<{
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput> | PromoCodeHistoryOrderByWithRelationInput>;
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<PromoCodeHistoryScalarFieldEnum> | PromoCodeHistoryScalarFieldEnum>;
}>;


export type PromoCodeHistoriesQuery = { __typename?: 'Query', promoCodeHistories: Array<{ __typename?: 'PromoCodeHistory', id: number, coins: number, createdAt: any, promoCode: { __typename?: 'PromoCode', id: number, code: string }, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } }> };

export type PromoCodeHistoryQueryVariables = Exact<{
  where: PromoCodeHistoryWhereUniqueInput;
}>;


export type PromoCodeHistoryQuery = { __typename?: 'Query', promoCodeHistory?: { __typename?: 'PromoCodeHistory', id: number, coins: number, createdAt: any, promoCode: { __typename?: 'PromoCode', id: number, code: string }, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } } | null };

export type AggregatePromoCodeHistoryQueryVariables = Exact<{
  where?: InputMaybe<PromoCodeHistoryWhereInput>;
  orderBy?: InputMaybe<Array<PromoCodeHistoryOrderByWithRelationInput> | PromoCodeHistoryOrderByWithRelationInput>;
  cursor?: InputMaybe<PromoCodeHistoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregatePromoCodeHistoryQuery = { __typename?: 'Query', aggregatePromoCodeHistory: { __typename?: 'AggregatePromoCodeHistory', _count?: { __typename?: 'PromoCodeHistoryCountAggregate', id: number } | null } };

export type CreateReferralCodeMutationVariables = Exact<{
  data: ReferralCodeCreateInput;
}>;


export type CreateReferralCodeMutation = { __typename?: 'Mutation', createReferralCode: { __typename?: 'ReferralCode', id: number } };

export type UpdateReferralCodeMutationVariables = Exact<{
  data: ReferralCodeUpdateInput;
  where: ReferralCodeWhereUniqueInput;
}>;


export type UpdateReferralCodeMutation = { __typename?: 'Mutation', updateReferralCode?: { __typename?: 'ReferralCode', id: number } | null };

export type DeleteReferralCodeMutationVariables = Exact<{
  where: ReferralCodeWhereUniqueInput;
}>;


export type DeleteReferralCodeMutation = { __typename?: 'Mutation', deleteReferralCode?: { __typename?: 'ReferralCode', id: number } | null };

export type ReferralCodesQueryVariables = Exact<{
  where?: InputMaybe<ReferralCodeWhereInput>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput> | ReferralCodeOrderByWithRelationInput>;
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ReferralCodeScalarFieldEnum> | ReferralCodeScalarFieldEnum>;
}>;


export type ReferralCodesQuery = { __typename?: 'Query', referralCodes: Array<{ __typename?: 'ReferralCode', id: number, code: string, inviteeCoins: number, referralCoins: number, isActive: boolean, createdAt: any, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } }> };

export type ReferralCodeQueryVariables = Exact<{
  where: ReferralCodeWhereUniqueInput;
}>;


export type ReferralCodeQuery = { __typename?: 'Query', referralCode?: { __typename?: 'ReferralCode', id: number, code: string, inviteeCoins: number, referralCoins: number, isActive: boolean, createdAt: any, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } } | null };

export type AggregateReferralCodeQueryVariables = Exact<{
  where?: InputMaybe<ReferralCodeWhereInput>;
  orderBy?: InputMaybe<Array<ReferralCodeOrderByWithRelationInput> | ReferralCodeOrderByWithRelationInput>;
  cursor?: InputMaybe<ReferralCodeWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateReferralCodeQuery = { __typename?: 'Query', aggregateReferralCode: { __typename?: 'AggregateReferralCode', _count?: { __typename?: 'ReferralCodeCountAggregate', id: number } | null } };

export type ReferralCodeHistoriesQueryVariables = Exact<{
  where?: InputMaybe<ReferralCodeHistoryWhereInput>;
  orderBy?: InputMaybe<Array<ReferralCodeHistoryOrderByWithRelationInput> | ReferralCodeHistoryOrderByWithRelationInput>;
  cursor?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ReferralCodeHistoryScalarFieldEnum> | ReferralCodeHistoryScalarFieldEnum>;
}>;


export type ReferralCodeHistoriesQuery = { __typename?: 'Query', referralCodeHistories: Array<{ __typename?: 'ReferralCodeHistory', id: number, referralCode: string, inviteeCoins: number, referralCoins: number, createdAt: any, referralStudent: { __typename?: 'Student', id: number, phoneNumber?: string | null }, invitedStudent: { __typename?: 'Student', id: number, phoneNumber?: string | null } }> };

export type ReferralCodeHistoryQueryVariables = Exact<{
  where: ReferralCodeHistoryWhereUniqueInput;
}>;


export type ReferralCodeHistoryQuery = { __typename?: 'Query', referralCodeHistory?: { __typename?: 'ReferralCodeHistory', id: number, referralCode: string, inviteeCoins: number, referralCoins: number, createdAt: any, referralStudent: { __typename?: 'Student', id: number, phoneNumber?: string | null }, invitedStudent: { __typename?: 'Student', id: number, phoneNumber?: string | null } } | null };

export type AggregateReferralCodeHistoryQueryVariables = Exact<{
  where?: InputMaybe<ReferralCodeHistoryWhereInput>;
  orderBy?: InputMaybe<Array<ReferralCodeHistoryOrderByWithRelationInput> | ReferralCodeHistoryOrderByWithRelationInput>;
  cursor?: InputMaybe<ReferralCodeHistoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateReferralCodeHistoryQuery = { __typename?: 'Query', aggregateReferralCodeHistory: { __typename?: 'AggregateReferralCodeHistory', _count?: { __typename?: 'ReferralCodeHistoryCountAggregate', id: number } | null } };

export type CreateReportMutationVariables = Exact<{
  data: ReportCreateInput;
}>;


export type CreateReportMutation = { __typename?: 'Mutation', createReport: { __typename?: 'Report', id: number } };

export type UpdateReportMutationVariables = Exact<{
  data: ReportUpdateInput;
  where: ReportWhereUniqueInput;
}>;


export type UpdateReportMutation = { __typename?: 'Mutation', updateReport?: { __typename?: 'Report', id: number } | null };

export type DeleteReportMutationVariables = Exact<{
  where: ReportWhereUniqueInput;
}>;


export type DeleteReportMutation = { __typename?: 'Mutation', deleteReport?: { __typename?: 'Report', id: number } | null };

export type ReportsQueryVariables = Exact<{
  where?: InputMaybe<ReportWhereInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput> | ReportOrderByWithRelationInput>;
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<ReportScalarFieldEnum> | ReportScalarFieldEnum>;
}>;


export type ReportsQuery = { __typename?: 'Query', reports: Array<{ __typename?: 'Report', id: number, title: string, description: string, status: Status, attachment?: string | null, displayOrder: number, createdAt: any, standard: { __typename?: 'Standard', id: number, key: string }, school: { __typename?: 'School', id: number, name: string } }> };

export type ReportQueryVariables = Exact<{
  where: ReportWhereUniqueInput;
}>;


export type ReportQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: number, title: string, description: string, status: Status, attachment?: string | null, displayOrder: number, createdAt: any, standard: { __typename?: 'Standard', id: number, key: string }, school: { __typename?: 'School', id: number, name: string } } | null };

export type AggregateReportQueryVariables = Exact<{
  where?: InputMaybe<ReportWhereInput>;
  orderBy?: InputMaybe<Array<ReportOrderByWithRelationInput> | ReportOrderByWithRelationInput>;
  cursor?: InputMaybe<ReportWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateReportQuery = { __typename?: 'Query', aggregateReport: { __typename?: 'AggregateReport', _count?: { __typename?: 'ReportCountAggregate', id: number } | null } };

export type CreateSamskaraMutationVariables = Exact<{
  data: SamskaraCreateInput;
}>;


export type CreateSamskaraMutation = { __typename?: 'Mutation', createSamskara: { __typename?: 'Samskara', id: number } };

export type UpdateSamskaraMutationVariables = Exact<{
  data: SamskaraUpdateInput;
  where: SamskaraWhereUniqueInput;
}>;


export type UpdateSamskaraMutation = { __typename?: 'Mutation', updateSamskara?: { __typename?: 'Samskara', id: number } | null };

export type DeleteSamskaraMutationVariables = Exact<{
  where: SamskaraWhereUniqueInput;
}>;


export type DeleteSamskaraMutation = { __typename?: 'Mutation', deleteSamskara?: { __typename?: 'Samskara', id: number } | null };

export type SamskarasQueryVariables = Exact<{
  where?: InputMaybe<SamskaraWhereInput>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput> | SamskaraOrderByWithRelationInput>;
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SamskaraScalarFieldEnum> | SamskaraScalarFieldEnum>;
}>;


export type SamskarasQuery = { __typename?: 'Query', samskaras: Array<{ __typename?: 'Samskara', id: number, title: string, description: string, attachment?: string | null, status: Status, video?: string | null, displayOrder: number, createdAt: any, category: { __typename?: 'SamskaraCategory', id: number, value?: string | null }, standard: { __typename?: 'Standard', id: number, value: string }, school: { __typename?: 'School', id: number, name: string } }> };

export type SamskaraQueryVariables = Exact<{
  where: SamskaraWhereUniqueInput;
}>;


export type SamskaraQuery = { __typename?: 'Query', samskara?: { __typename?: 'Samskara', id: number, title: string, description: string, attachment?: string | null, status: Status, video?: string | null, displayOrder: number, createdAt: any, category: { __typename?: 'SamskaraCategory', id: number, value?: string | null }, standard: { __typename?: 'Standard', id: number, value: string }, school: { __typename?: 'School', id: number, name: string } } | null };

export type AggregateSamskaraQueryVariables = Exact<{
  where?: InputMaybe<SamskaraWhereInput>;
  orderBy?: InputMaybe<Array<SamskaraOrderByWithRelationInput> | SamskaraOrderByWithRelationInput>;
  cursor?: InputMaybe<SamskaraWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateSamskaraQuery = { __typename?: 'Query', aggregateSamskara: { __typename?: 'AggregateSamskara', _count?: { __typename?: 'SamskaraCountAggregate', id: number } | null } };

export type SamskaraCategoriesQueryVariables = Exact<{
  where?: InputMaybe<SamskaraCategoryWhereInput>;
  orderBy?: InputMaybe<Array<SamskaraCategoryOrderByWithRelationInput> | SamskaraCategoryOrderByWithRelationInput>;
  cursor?: InputMaybe<SamskaraCategoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SamskaraCategoryScalarFieldEnum> | SamskaraCategoryScalarFieldEnum>;
}>;


export type SamskaraCategoriesQuery = { __typename?: 'Query', samskaraCategories: Array<{ __typename?: 'SamskaraCategory', id: number, key: string, image: string, value?: string | null }> };

export type CreateSchoolMutationVariables = Exact<{
  data: SchoolCreateInput;
}>;


export type CreateSchoolMutation = { __typename?: 'Mutation', createSchool: { __typename?: 'School', id: number } };

export type UpdateSchoolMutationVariables = Exact<{
  data: SchoolUpdateInput;
  where: SchoolWhereUniqueInput;
}>;


export type UpdateSchoolMutation = { __typename?: 'Mutation', updateSchool?: { __typename?: 'School', id: number } | null };

export type DeleteSchoolMutationVariables = Exact<{
  where: SchoolWhereUniqueInput;
}>;


export type DeleteSchoolMutation = { __typename?: 'Mutation', deleteSchool?: { __typename?: 'School', id: number } | null };

export type SchoolsQueryVariables = Exact<{
  where?: InputMaybe<SchoolWhereInput>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput> | SchoolOrderByWithRelationInput>;
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SchoolScalarFieldEnum> | SchoolScalarFieldEnum>;
}>;


export type SchoolsQuery = { __typename?: 'Query', schools: Array<{ __typename?: 'School', id: number, name: string, code: string, group?: string | null, createdAt: any }> };

export type SchoolQueryVariables = Exact<{
  where: SchoolWhereUniqueInput;
}>;


export type SchoolQuery = { __typename?: 'Query', school?: { __typename?: 'School', id: number, name: string, code: string, group?: string | null, area?: string | null, city?: string | null, state?: string | null, country?: string | null, createdAt: any } | null };

export type AggregateSchoolQueryVariables = Exact<{
  where?: InputMaybe<SchoolWhereInput>;
  orderBy?: InputMaybe<Array<SchoolOrderByWithRelationInput> | SchoolOrderByWithRelationInput>;
  cursor?: InputMaybe<SchoolWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateSchoolQuery = { __typename?: 'Query', aggregateSchool: { __typename?: 'AggregateSchool', _count?: { __typename?: 'SchoolCountAggregate', id: number } | null } };

export type CreateSchoolUpdateMutationVariables = Exact<{
  data: SchoolUpdateCreateInput;
}>;


export type CreateSchoolUpdateMutation = { __typename?: 'Mutation', createSchoolUpdate: { __typename?: 'SchoolUpdate', id: number } };

export type UpdateSchoolUpdateMutationVariables = Exact<{
  where: SchoolUpdateWhereUniqueInput;
  data: SchoolUpdateUpdateInput;
}>;


export type UpdateSchoolUpdateMutation = { __typename?: 'Mutation', updateSchoolUpdate?: { __typename?: 'SchoolUpdate', id: number } | null };

export type DeleteSchoolUpdateMutationVariables = Exact<{
  where: SchoolUpdateWhereUniqueInput;
}>;


export type DeleteSchoolUpdateMutation = { __typename?: 'Mutation', deleteSchoolUpdate?: { __typename?: 'SchoolUpdate', id: number } | null };

export type SchoolUpdateQueryVariables = Exact<{
  where: SchoolUpdateWhereUniqueInput;
}>;


export type SchoolUpdateQuery = { __typename?: 'Query', schoolUpdate?: { __typename?: 'SchoolUpdate', id: number, title: string, description?: string | null, attachment?: string | null, asset?: string | null, tags: any, createdAt: any, school: { __typename?: 'School', id: number, name: string }, standards: Array<{ __typename?: 'Standard', id: number }> } | null };

export type SchoolUpdatesQueryVariables = Exact<{
  where?: InputMaybe<SchoolUpdateWhereInput>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput> | SchoolUpdateOrderByWithRelationInput>;
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SchoolUpdateScalarFieldEnum> | SchoolUpdateScalarFieldEnum>;
}>;


export type SchoolUpdatesQuery = { __typename?: 'Query', schoolUpdates: Array<{ __typename?: 'SchoolUpdate', id: number, title: string, description?: string | null, attachment?: string | null, asset?: string | null, tags: any, createdAt: any, school: { __typename?: 'School', id: number, name: string }, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }> }> };

export type AggregateSchoolUpdateQueryVariables = Exact<{
  where?: InputMaybe<SchoolUpdateWhereInput>;
  orderBy?: InputMaybe<Array<SchoolUpdateOrderByWithRelationInput> | SchoolUpdateOrderByWithRelationInput>;
  cursor?: InputMaybe<SchoolUpdateWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateSchoolUpdateQuery = { __typename?: 'Query', aggregateSchoolUpdate: { __typename?: 'AggregateSchoolUpdate', _count?: { __typename?: 'SchoolUpdateCountAggregate', id: number } | null } };

export type CreateSkillMutationVariables = Exact<{
  data: SkillCreateInput;
}>;


export type CreateSkillMutation = { __typename?: 'Mutation', createSkill: { __typename?: 'Skill', id: number, slug: string } };

export type UpdateSkillMutationVariables = Exact<{
  where: SkillWhereUniqueInput;
  data: SkillUpdateInput;
}>;


export type UpdateSkillMutation = { __typename?: 'Mutation', updateSkill?: { __typename?: 'Skill', id: number } | null };

export type DeleteSkillMutationVariables = Exact<{
  where: SkillWhereUniqueInput;
}>;


export type DeleteSkillMutation = { __typename?: 'Mutation', deleteSkill?: { __typename?: 'Skill', id: number } | null };

export type SkillsQueryVariables = Exact<{
  where?: InputMaybe<SkillWhereInput>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput> | SkillOrderByWithRelationInput>;
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<SkillScalarFieldEnum> | SkillScalarFieldEnum>;
}>;


export type SkillsQuery = { __typename?: 'Query', skills: Array<{ __typename?: 'Skill', id: number, name: string, slug: string, displayOrder: number, standards: Array<{ __typename?: 'Standard', id: number, key: string }>, school?: { __typename?: 'School', id: number, name: string } | null }> };

export type SkillQueryVariables = Exact<{
  data: SkillWhereUniqueInput;
}>;


export type SkillQuery = { __typename?: 'Query', skill?: { __typename?: 'Skill', id: number, name: string, slug: string, description?: string | null, image?: string | null, featuredImage?: string | null, bundle?: string | null, type: SkillType, metaTagData?: any | null, displayOrder: number, isActive: boolean, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }>, school?: { __typename?: 'School', id: number, name: string } | null } | null };

export type AggregateSkillQueryVariables = Exact<{
  where?: InputMaybe<SkillWhereInput>;
  orderBy?: InputMaybe<Array<SkillOrderByWithRelationInput> | SkillOrderByWithRelationInput>;
  cursor?: InputMaybe<SkillWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateSkillQuery = { __typename?: 'Query', aggregateSkill: { __typename?: 'AggregateSkill', _count?: { __typename?: 'SkillCountAggregate', id: number } | null } };

export type StandardsQueryVariables = Exact<{ [key: string]: never; }>;


export type StandardsQuery = { __typename?: 'Query', standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }> };

export type CreateStoryMutationVariables = Exact<{
  data: StoryCreateInput;
}>;


export type CreateStoryMutation = { __typename?: 'Mutation', createStory: { __typename?: 'Story', id: number, slug: string } };

export type UpdateStoryMutationVariables = Exact<{
  data: StoryUpdateInput;
  where: StoryWhereUniqueInput;
}>;


export type UpdateStoryMutation = { __typename?: 'Mutation', updateStory?: { __typename?: 'Story', id: number } | null };

export type DeleteStoryMutationVariables = Exact<{
  where: StoryWhereUniqueInput;
}>;


export type DeleteStoryMutation = { __typename?: 'Mutation', deleteStory?: { __typename?: 'Story', id: number } | null };

export type StoriesQueryVariables = Exact<{
  where?: InputMaybe<StoryWhereInput>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput> | StoryOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<StoryScalarFieldEnum> | StoryScalarFieldEnum>;
}>;


export type StoriesQuery = { __typename?: 'Query', stories: Array<{ __typename?: 'Story', id: number, title: string, slug: string, displayOrder: number, createdAt: any, standards: Array<{ __typename?: 'Standard', id: number, key: string }>, tag?: { __typename?: 'StoryTag', id: number, key: string, text?: string | null } | null, school?: { __typename?: 'School', id: number, name: string } | null }> };

export type StoryQueryVariables = Exact<{
  data: StoryWhereUniqueInput;
}>;


export type StoryQuery = { __typename?: 'Query', story?: { __typename?: 'Story', id: number, title: string, image?: string | null, slug: string, description?: string | null, featuredImage?: string | null, displayOrder: number, createdAt: any, school?: { __typename?: 'School', id: number, name: string } | null, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }>, tag?: { __typename?: 'StoryTag', id: number, key: string, text?: string | null } | null } | null };

export type AggregateStoryQueryVariables = Exact<{
  where?: InputMaybe<StoryWhereInput>;
  orderBy?: InputMaybe<Array<StoryOrderByWithRelationInput> | StoryOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateStoryQuery = { __typename?: 'Query', aggregateStory: { __typename?: 'AggregateStory', _count?: { __typename?: 'StoryCountAggregate', id: number } | null } };

export type CreateStoryActivityMutationVariables = Exact<{
  data: StoryActivityCreateInput;
}>;


export type CreateStoryActivityMutation = { __typename?: 'Mutation', createStoryActivity: { __typename?: 'StoryActivity', id: number, slug: string } };

export type UpdateStoryActivityMutationVariables = Exact<{
  where: StoryActivityWhereUniqueInput;
  data: StoryActivityUpdateInput;
}>;


export type UpdateStoryActivityMutation = { __typename?: 'Mutation', updateStoryActivity?: { __typename?: 'StoryActivity', id: number } | null };

export type StoryActivityQueryVariables = Exact<{
  where: StoryActivityWhereUniqueInput;
}>;


export type StoryActivityQuery = { __typename?: 'Query', storyActivity?: { __typename?: 'StoryActivity', id: number, title: string, slug: string, timeRequired: string, coinsToSpend: number, xps: number, image?: string | null, featuredImage?: string | null, challengeStatement: string, content: string, video?: string | null, audio?: string | null, status: ActivityStatus, isFeatured: boolean, displayOrder: number, isCommentEnabled: boolean, story: { __typename?: 'Story', id: number, slug: string, title: string }, standards: Array<{ __typename?: 'Standard', id: number, key: string }>, skill: { __typename?: 'Skill', id: number, slug: string, name: string } } | null };

export type StoryActivitiesQueryVariables = Exact<{
  where?: InputMaybe<StoryActivityWhereInput>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput> | StoryActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryActivityScalarFieldEnum> | StoryActivityScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type StoryActivitiesQuery = { __typename?: 'Query', storyActivities: Array<{ __typename?: 'StoryActivity', id: number, title: string, slug: string, coinsToSpend: number, xps: number, featuredImage?: string | null, status: ActivityStatus, isFeatured: boolean, displayOrder: number, isCommentEnabled: boolean, standards: Array<{ __typename?: 'Standard', id: number, key: string }> }> };

export type AggregateStoryActivityQueryVariables = Exact<{
  where?: InputMaybe<StoryActivityWhereInput>;
  orderBy?: InputMaybe<Array<StoryActivityOrderByWithRelationInput> | StoryActivityOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryActivityWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateStoryActivityQuery = { __typename?: 'Query', aggregateStoryActivity: { __typename?: 'AggregateStoryActivity', _count?: { __typename?: 'StoryActivityCountAggregate', id: number } | null } };

export type CreateStoryBannerMutationVariables = Exact<{
  data: StoryBannerCreateInput;
}>;


export type CreateStoryBannerMutation = { __typename?: 'Mutation', createStoryBanner: { __typename?: 'StoryBanner', id: number } };

export type UpdateStoryBannerMutationVariables = Exact<{
  where: StoryBannerWhereUniqueInput;
  data: StoryBannerUpdateInput;
}>;


export type UpdateStoryBannerMutation = { __typename?: 'Mutation', updateStoryBanner?: { __typename?: 'StoryBanner', id: number } | null };

export type DeleteStoryBannerMutationVariables = Exact<{
  where: StoryBannerWhereUniqueInput;
}>;


export type DeleteStoryBannerMutation = { __typename?: 'Mutation', deleteStoryBanner?: { __typename?: 'StoryBanner', id: number } | null };

export type StoryBannersQueryVariables = Exact<{
  where?: InputMaybe<StoryBannerWhereInput>;
  orderBy?: InputMaybe<Array<StoryBannerOrderByWithRelationInput> | StoryBannerOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<StoryBannerScalarFieldEnum> | StoryBannerScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type StoryBannersQuery = { __typename?: 'Query', storyBanners: Array<{ __typename?: 'StoryBanner', id: number, image?: string | null, destinationUrl: string, displayOrder: number }> };

export type StoryBannerQueryVariables = Exact<{
  where: StoryBannerWhereUniqueInput;
}>;


export type StoryBannerQuery = { __typename?: 'Query', storyBanner?: { __typename?: 'StoryBanner', id: number, image?: string | null, destinationUrl: string, displayOrder: number } | null };

export type CreateStoryTagMutationVariables = Exact<{
  data: StoryTagCreateInput;
}>;


export type CreateStoryTagMutation = { __typename?: 'Mutation', createStoryTag: { __typename?: 'StoryTag', id: number } };

export type UpdateStoryTagMutationVariables = Exact<{
  data: StoryTagUpdateInput;
  where: StoryTagWhereUniqueInput;
}>;


export type UpdateStoryTagMutation = { __typename?: 'Mutation', updateStoryTag?: { __typename?: 'StoryTag', id: number } | null };

export type DeleteStoryTagMutationVariables = Exact<{
  where: StoryTagWhereUniqueInput;
}>;


export type DeleteStoryTagMutation = { __typename?: 'Mutation', deleteStoryTag?: { __typename?: 'StoryTag', id: number } | null };

export type StoryTagsQueryVariables = Exact<{
  where?: InputMaybe<StoryTagWhereInput>;
  orderBy?: InputMaybe<Array<StoryTagOrderByWithRelationInput> | StoryTagOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryTagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<StoryTagScalarFieldEnum> | StoryTagScalarFieldEnum>;
}>;


export type StoryTagsQuery = { __typename?: 'Query', storyTags: Array<{ __typename?: 'StoryTag', id: number, key: string, text?: string | null, image?: string | null }> };

export type StoryTagQueryVariables = Exact<{
  where: StoryTagWhereUniqueInput;
}>;


export type StoryTagQuery = { __typename?: 'Query', storyTag?: { __typename?: 'StoryTag', id: number, key: string, text?: string | null, image?: string | null } | null };

export type AggregateStoryTagQueryVariables = Exact<{
  where?: InputMaybe<StoryTagWhereInput>;
  orderBy?: InputMaybe<Array<StoryTagOrderByWithRelationInput> | StoryTagOrderByWithRelationInput>;
  cursor?: InputMaybe<StoryTagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateStoryTagQuery = { __typename?: 'Query', aggregateStoryTag: { __typename?: 'AggregateStoryTag', _count?: { __typename?: 'StoryTagCountAggregate', id: number } | null } };

export type CreateStudentMutationVariables = Exact<{
  data: StudentCreateInput;
}>;


export type CreateStudentMutation = { __typename?: 'Mutation', createStudent: { __typename?: 'Student', id: number, phoneNumber?: string | null } };

export type UpdateStudentMutationVariables = Exact<{
  where: StudentWhereUniqueInput;
  data: StudentUpdateInput;
}>;


export type UpdateStudentMutation = { __typename?: 'Mutation', updateStudent?: { __typename?: 'Student', id: number } | null };

export type StudentAssignBadgeMutationVariables = Exact<{
  data: StudentAssignBadgeInput;
}>;


export type StudentAssignBadgeMutation = { __typename?: 'Mutation', studentAssignBadge?: { __typename?: 'StudentAssignBadgeResponse', success: boolean, message: string } | null };

export type StudentRemoveBadgeMutationVariables = Exact<{
  where: StudentBadgeWhereUniqueInput;
}>;


export type StudentRemoveBadgeMutation = { __typename?: 'Mutation', studentRemoveBadge?: { __typename?: 'StudentAssignBadgeResponse', success: boolean, message: string } | null };

export type ImportStudentsMutationVariables = Exact<{
  file: Scalars['Upload']['input'];
}>;


export type ImportStudentsMutation = { __typename?: 'Mutation', importStudents?: { __typename?: 'ImportStudentsResponse', success: boolean, message: string } | null };

export type StudentsQueryVariables = Exact<{
  where?: InputMaybe<StudentWhereInput>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput> | StudentOrderByWithRelationInput>;
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  distinct?: InputMaybe<Array<StudentScalarFieldEnum> | StudentScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type StudentsQuery = { __typename?: 'Query', students: Array<{ __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null, email?: string | null, isActive: boolean, schoolText?: string | null, lastLoginAt?: any | null, createdAt: any, updatedAt: any, standard: { __typename?: 'Standard', id: number, key: string }, school: { __typename?: 'School', id: number, name: string } }> };

export type AggregateStudentQueryVariables = Exact<{
  where?: InputMaybe<StudentWhereInput>;
  orderBy?: InputMaybe<Array<StudentOrderByWithRelationInput> | StudentOrderByWithRelationInput>;
  cursor?: InputMaybe<StudentWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateStudentQuery = { __typename?: 'Query', aggregateStudent: { __typename?: 'AggregateStudent', _count?: { __typename?: 'StudentCountAggregate', id: number } | null } };

export type StudentQueryVariables = Exact<{
  where: StudentWhereUniqueInput;
}>;


export type StudentQuery = { __typename?: 'Query', student?: { __typename?: 'Student', id: number, firstName: string, lastName: string, displayName: string, email?: string | null, phoneNumber?: string | null, dob?: string | null, level: number, address1?: string | null, address2?: string | null, country?: string | null, state?: string | null, city?: string | null, isHomeschooler: boolean, schoolText?: string | null, profileImage?: string | null, isActive: boolean, standard: { __typename?: 'Standard', id: number, value: string }, school: { __typename?: 'School', id: number, name: string } } | null };

export type StudentGamePointsQueryVariables = Exact<{
  where: StudentWhereUniqueInput;
}>;


export type StudentGamePointsQuery = { __typename?: 'Query', studentGamePoints?: { __typename?: 'StudentGamePointsResponse', data?: any | null } | null };

export type CreateTagMutationVariables = Exact<{
  data: TagCreateInput;
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'Tag', id: number, slug: string } };

export type UpdateTagMutationVariables = Exact<{
  where: TagWhereUniqueInput;
  data: TagUpdateInput;
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', updateTag?: { __typename?: 'Tag', id: number } | null };

export type DeleteTagMutationVariables = Exact<{
  where: TagWhereUniqueInput;
}>;


export type DeleteTagMutation = { __typename?: 'Mutation', deleteTag?: { __typename?: 'Tag', id: number } | null };

export type TagsQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput> | TagOrderByWithRelationInput>;
  cursor?: InputMaybe<TagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TagScalarFieldEnum> | TagScalarFieldEnum>;
}>;


export type TagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: number, name: string, slug: string, createdAt: any }> };

export type AggregateTagQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
  orderBy?: InputMaybe<Array<TagOrderByWithRelationInput> | TagOrderByWithRelationInput>;
  cursor?: InputMaybe<TagWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateTagQuery = { __typename?: 'Query', aggregateTag: { __typename?: 'AggregateTag', _count?: { __typename?: 'TagCountAggregate', id: number } | null } };

export type TagQueryVariables = Exact<{
  where: TagWhereUniqueInput;
}>;


export type TagQuery = { __typename?: 'Query', tag?: { __typename?: 'Tag', id: number, name: string, slug: string, description?: string | null, seoContent?: string | null, metaTagData?: any | null, jsonLD?: any | null, productScript?: string | null, buyScript?: string | null } | null };

export type UpdateTriggerMutationVariables = Exact<{
  data: TriggerUpdateInput;
  where: TriggerWhereUniqueInput;
}>;


export type UpdateTriggerMutation = { __typename?: 'Mutation', updateTrigger?: { __typename?: 'Trigger', id: number, text: string, key: string, totalCount: number, model?: string | null, modelId?: number | null, countDuration?: number | null, countPeriod?: string | null, earn: number, label?: string | null, maximumEarningsPerUser: number, isActive: boolean, gamePointType?: { __typename?: 'GamePointType', id: number, pluralName: string } | null } | null };

export type DeleteTriggerMutationVariables = Exact<{
  where: TriggerWhereUniqueInput;
}>;


export type DeleteTriggerMutation = { __typename?: 'Mutation', deleteTrigger?: { __typename?: 'Trigger', id: number } | null };

export type TriggersQueryVariables = Exact<{
  where?: InputMaybe<TriggerWhereInput>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput> | TriggerOrderByWithRelationInput>;
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<TriggerScalarFieldEnum> | TriggerScalarFieldEnum>;
}>;


export type TriggersQuery = { __typename?: 'Query', triggers: Array<{ __typename?: 'Trigger', id: number, text: string, key: string, totalCount: number, model?: string | null, modelId?: number | null, countDuration?: number | null, countPeriod?: string | null, earn: number, label?: string | null, maximumEarningsPerUser: number, isActive: boolean, gamePointType?: { __typename?: 'GamePointType', id: number, pluralName: string } | null }> };

export type AggregateTriggerQueryVariables = Exact<{
  where?: InputMaybe<TriggerWhereInput>;
  orderBy?: InputMaybe<Array<TriggerOrderByWithRelationInput> | TriggerOrderByWithRelationInput>;
  cursor?: InputMaybe<TriggerWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateTriggerQuery = { __typename?: 'Query', aggregateTrigger: { __typename?: 'AggregateTrigger', _count?: { __typename?: 'TriggerCountAggregate', id: number } | null } };

export type CreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number } };

export type UpdateUserMutationVariables = Exact<{
  where: UserWhereUniqueInput;
  data: UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: number } | null };

export type UsersQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum> | UserScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: number, firstName: string, lastName: string, phoneNumber: string, role: Role, isActive: boolean, schools: Array<{ __typename?: 'School', name: string }>, standards: Array<{ __typename?: 'Standard', value: string }> }> };

export type AggregateUserQueryVariables = Exact<{
  where?: InputMaybe<UserWhereInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput> | UserOrderByWithRelationInput>;
  cursor?: InputMaybe<UserWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateUserQuery = { __typename?: 'Query', aggregateUser: { __typename?: 'AggregateUser', _count?: { __typename?: 'UserCountAggregate', id: number } | null } };

export type UserQueryVariables = Exact<{
  data: UserWhereUniqueInput;
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: number, firstName: string, lastName: string, email?: string | null, phoneNumber: string, role: Role, isActive: boolean, standards: Array<{ __typename?: 'Standard', id: number, value: string }>, schools: Array<{ __typename?: 'School', id: number, name: string }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'MeResponse', id: number, firstName: string, lastName: string, role: string, rules: any } | null };

export type CreateWorkshopMutationVariables = Exact<{
  data: WorkshopCreateInput;
}>;


export type CreateWorkshopMutation = { __typename?: 'Mutation', createWorkshop: { __typename?: 'Workshop', id: number, slug: string } };

export type UpdateWorkshopMutationVariables = Exact<{
  data: WorkshopUpdateInput;
  where: WorkshopWhereUniqueInput;
}>;


export type UpdateWorkshopMutation = { __typename?: 'Mutation', updateWorkshop?: { __typename?: 'Workshop', id: number, slug: string } | null };

export type DeleteWorkshopMutationVariables = Exact<{
  where: WorkshopWhereUniqueInput;
}>;


export type DeleteWorkshopMutation = { __typename?: 'Mutation', deleteWorkshop?: { __typename?: 'Workshop', id: number } | null };

export type WorkshopsQueryVariables = Exact<{
  where?: InputMaybe<WorkshopWhereInput>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput> | WorkshopOrderByWithRelationInput>;
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum> | WorkshopScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type WorkshopsQuery = { __typename?: 'Query', workshops: Array<{ __typename?: 'Workshop', id: number, name: string, slug: string, startDate: any, displayTime?: string | null, feesWithKit?: number | null, feesInCurrency?: number | null, feesWithoutKit?: number | null, ageGroup?: string | null, type: string, mode: string, skill: { __typename?: 'Skill', slug: string, name: string }, school: { __typename?: 'School', id: number, name: string } }> };

export type AggregateWorkshopQueryVariables = Exact<{
  where?: InputMaybe<WorkshopWhereInput>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput> | WorkshopOrderByWithRelationInput>;
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateWorkshopQuery = { __typename?: 'Query', aggregateWorkshop: { __typename?: 'AggregateWorkshop', _count?: { __typename?: 'WorkshopCountAggregate', id: number } | null } };

export type WorkshopQueryVariables = Exact<{
  data: WorkshopWhereUniqueInput;
}>;


export type WorkshopQuery = { __typename?: 'Query', workshop?: { __typename?: 'Workshop', id: number, name: string, slug: string, description?: string | null, image?: string | null, type: string, mode: string, ageGroup?: string | null, startDate: any, endDate?: any | null, featuredImage?: string | null, displayTime?: string | null, metaTagData?: any | null, isOpen: boolean, trainerImage?: string | null, location?: string | null, address?: string | null, feesWithKit?: number | null, feesInCurrency?: number | null, feesWithoutKit?: number | null, liveUrl?: string | null, headerImageLarge?: string | null, headerImageSmall?: string | null, headerVideo?: string | null, headerText?: string | null, footerImageLarge?: string | null, footerImageSmall?: string | null, skill: { __typename?: 'Skill', id: number, slug: string, name: string }, school: { __typename?: 'School', id: number, name: string }, trainer?: { __typename?: 'User', id: number, firstName: string, lastName: string } | null, product?: { __typename?: 'Product', id: number, name: string } | null, standards: Array<{ __typename?: 'Standard', id: number, key: string, value: string }>, insiderActivities: Array<{ __typename?: 'Activity', id: number, title: string, slug: string }>, relatedActivities: Array<{ __typename?: 'Activity', id: number, title: string, slug: string }>, tags: Array<{ __typename?: 'Tag', id: number, name: string, slug: string }> } | null };

export type SearchWorkshopsQueryVariables = Exact<{
  where?: InputMaybe<WorkshopWhereInput>;
  orderBy?: InputMaybe<Array<WorkshopOrderByWithRelationInput> | WorkshopOrderByWithRelationInput>;
  cursor?: InputMaybe<WorkshopWhereUniqueInput>;
  distinct?: InputMaybe<Array<WorkshopScalarFieldEnum> | WorkshopScalarFieldEnum>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchWorkshopsQuery = { __typename?: 'Query', workshops: Array<{ __typename?: 'Workshop', id: number, name: string }> };

export type CreateWorkshopRegistrationMutationVariables = Exact<{
  data: WorkshopRegistrationCreateInput;
}>;


export type CreateWorkshopRegistrationMutation = { __typename?: 'Mutation', createWorkshopRegistration: { __typename?: 'WorkshopRegistration', id: number } };

export type UpdateWorkshopRegistrationMutationVariables = Exact<{
  data: WorkshopRegistrationUpdateInput;
  where: WorkshopRegistrationWhereUniqueInput;
}>;


export type UpdateWorkshopRegistrationMutation = { __typename?: 'Mutation', updateWorkshopRegistration?: { __typename?: 'WorkshopRegistration', id: number } | null };

export type DeleteWorkshopRegistrationMutationVariables = Exact<{
  where: WorkshopRegistrationWhereUniqueInput;
}>;


export type DeleteWorkshopRegistrationMutation = { __typename?: 'Mutation', deleteWorkshopRegistration?: { __typename?: 'WorkshopRegistration', id: number } | null };

export type UpdateManyWorkshopRegistrationMutationVariables = Exact<{
  data: WorkshopRegistrationUpdateManyMutationInput;
  where: WorkshopRegistrationWhereInput;
}>;


export type UpdateManyWorkshopRegistrationMutation = { __typename?: 'Mutation', updateManyWorkshopRegistration: { __typename?: 'AffectedRowsOutput', count: number } };

export type WorkshopRegistrationsQueryVariables = Exact<{
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput> | WorkshopRegistrationOrderByWithRelationInput>;
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  distinct?: InputMaybe<Array<WorkshopRegistrationScalarFieldEnum> | WorkshopRegistrationScalarFieldEnum>;
}>;


export type WorkshopRegistrationsQuery = { __typename?: 'Query', workshopRegistrations: Array<{ __typename?: 'WorkshopRegistration', id: number, status: WorkshopStatus, createdAt: any, workshop: { __typename?: 'Workshop', id: number, name: string, slug: string }, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } }> };

export type WorkshopRegistrationQueryVariables = Exact<{
  where: WorkshopRegistrationWhereUniqueInput;
}>;


export type WorkshopRegistrationQuery = { __typename?: 'Query', workshopRegistration?: { __typename?: 'WorkshopRegistration', id: number, status: WorkshopStatus, createdAt: any, workshop: { __typename?: 'Workshop', id: number, name: string, slug: string }, student: { __typename?: 'Student', id: number, firstName: string, lastName: string, phoneNumber?: string | null } } | null };

export type AggregateWorkshopRegistrationQueryVariables = Exact<{
  where?: InputMaybe<WorkshopRegistrationWhereInput>;
  orderBy?: InputMaybe<Array<WorkshopRegistrationOrderByWithRelationInput> | WorkshopRegistrationOrderByWithRelationInput>;
  cursor?: InputMaybe<WorkshopRegistrationWhereUniqueInput>;
  take?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AggregateWorkshopRegistrationQuery = { __typename?: 'Query', aggregateWorkshopRegistration: { __typename?: 'AggregateWorkshopRegistration', _count?: { __typename?: 'WorkshopRegistrationCountAggregate', id: number } | null } };


export const CreateActivityDocument = gql`
    mutation createActivity($data: ActivityCreateInput!) {
  createActivity(data: $data) {
    id
    slug
  }
}
    `;
export type CreateActivityMutationFn = Apollo.MutationFunction<CreateActivityMutation, CreateActivityMutationVariables>;

/**
 * __useCreateActivityMutation__
 *
 * To run a mutation, you first call `useCreateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityMutation, { data, loading, error }] = useCreateActivityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityMutation, CreateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivityMutation, CreateActivityMutationVariables>(CreateActivityDocument, options);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = Apollo.MutationResult<CreateActivityMutation>;
export type CreateActivityMutationOptions = Apollo.BaseMutationOptions<CreateActivityMutation, CreateActivityMutationVariables>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($where: ActivityWhereUniqueInput!, $data: ActivityUpdateInput!) {
  updateActivity(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateActivityMutationFn = Apollo.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, options);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>;
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<UpdateActivityMutation, UpdateActivityMutationVariables>;
export const DeleteActivityDocument = gql`
    mutation deleteActivity($where: ActivityWhereUniqueInput!) {
  deleteActivity(where: $where) {
    id
  }
}
    `;
export type DeleteActivityMutationFn = Apollo.MutationFunction<DeleteActivityMutation, DeleteActivityMutationVariables>;

/**
 * __useDeleteActivityMutation__
 *
 * To run a mutation, you first call `useDeleteActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityMutation, { data, loading, error }] = useDeleteActivityMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DeleteActivityDocument, options);
      }
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export type DeleteActivityMutationOptions = Apollo.BaseMutationOptions<DeleteActivityMutation, DeleteActivityMutationVariables>;
export const ActivityDocument = gql`
    query activity($where: ActivityWhereUniqueInput!) {
  activity(where: $where) {
    id
    title
    slug
    shortDescription
    description
    difficultyLevel
    timeRequired
    coinsToSpend
    xps
    questionXps
    image
    featuredImage
    challengeStatement
    materialsNeeded
    metaTagData
    video
    instructionImage
    instructionText
    status
    scheduleTime
    isFeatured
    isLive
    isCommentEnabled
    isChallenge
    isVisible
    isIndexable
    xps
    displayOrder
    jsonLD
    tags {
      id
      name
      slug
    }
    standards {
      id
      key
      value
    }
    nextActivity {
      id
      title
      slug
    }
    skill {
      id
      name
      slug
    }
    questions(orderBy: {order: asc}) {
      id
      questionText
      questionImage
      type
      options
      answers
      answerText
      incorrectText
    }
  }
}
    `;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useActivityQuery(baseOptions: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
      }
export function useActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
        }
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<typeof useActivityLazyQuery>;
export type ActivityQueryResult = Apollo.QueryResult<ActivityQuery, ActivityQueryVariables>;
export const ActivitiesDocument = gql`
    query activities($where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!], $cursor: ActivityWhereUniqueInput, $distinct: [ActivityScalarFieldEnum!], $skip: Int, $take: Int) {
  activities(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    title
    slug
    coinsToSpend
    status
    standards {
      id
      key
      value
    }
    scheduleTime
  }
}
    `;

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
      }
export function useActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivitiesQuery, ActivitiesQueryVariables>(ActivitiesDocument, options);
        }
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>;
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>;
export type ActivitiesQueryResult = Apollo.QueryResult<ActivitiesQuery, ActivitiesQueryVariables>;
export const AggregateActivityDocument = gql`
    query aggregateActivity($where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!], $cursor: ActivityWhereUniqueInput, $skip: Int, $take: Int) {
  aggregateActivity(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateActivityQuery__
 *
 * To run a query within a React component, call `useAggregateActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAggregateActivityQuery(baseOptions?: Apollo.QueryHookOptions<AggregateActivityQuery, AggregateActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateActivityQuery, AggregateActivityQueryVariables>(AggregateActivityDocument, options);
      }
export function useAggregateActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateActivityQuery, AggregateActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateActivityQuery, AggregateActivityQueryVariables>(AggregateActivityDocument, options);
        }
export type AggregateActivityQueryHookResult = ReturnType<typeof useAggregateActivityQuery>;
export type AggregateActivityLazyQueryHookResult = ReturnType<typeof useAggregateActivityLazyQuery>;
export type AggregateActivityQueryResult = Apollo.QueryResult<AggregateActivityQuery, AggregateActivityQueryVariables>;
export const AllActivitiesDocument = gql`
    query allActivities($where: ActivityWhereInput, $orderBy: [ActivityOrderByWithRelationInput!], $cursor: ActivityWhereUniqueInput, $distinct: [ActivityScalarFieldEnum!], $skip: Int, $take: Int) {
  activities(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    title
    slug
  }
}
    `;

/**
 * __useAllActivitiesQuery__
 *
 * To run a query within a React component, call `useAllActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAllActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<AllActivitiesQuery, AllActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllActivitiesQuery, AllActivitiesQueryVariables>(AllActivitiesDocument, options);
      }
export function useAllActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllActivitiesQuery, AllActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllActivitiesQuery, AllActivitiesQueryVariables>(AllActivitiesDocument, options);
        }
export type AllActivitiesQueryHookResult = ReturnType<typeof useAllActivitiesQuery>;
export type AllActivitiesLazyQueryHookResult = ReturnType<typeof useAllActivitiesLazyQuery>;
export type AllActivitiesQueryResult = Apollo.QueryResult<AllActivitiesQuery, AllActivitiesQueryVariables>;
export const CreateManyActivityQuestionDocument = gql`
    mutation createManyActivityQuestion($data: [ActivityQuestionCreateManyInput!]!, $skipDuplicates: Boolean) {
  createManyActivityQuestion(data: $data, skipDuplicates: $skipDuplicates) {
    count
  }
}
    `;
export type CreateManyActivityQuestionMutationFn = Apollo.MutationFunction<CreateManyActivityQuestionMutation, CreateManyActivityQuestionMutationVariables>;

/**
 * __useCreateManyActivityQuestionMutation__
 *
 * To run a mutation, you first call `useCreateManyActivityQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyActivityQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyActivityQuestionMutation, { data, loading, error }] = useCreateManyActivityQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      skipDuplicates: // value for 'skipDuplicates'
 *   },
 * });
 */
export function useCreateManyActivityQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyActivityQuestionMutation, CreateManyActivityQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyActivityQuestionMutation, CreateManyActivityQuestionMutationVariables>(CreateManyActivityQuestionDocument, options);
      }
export type CreateManyActivityQuestionMutationHookResult = ReturnType<typeof useCreateManyActivityQuestionMutation>;
export type CreateManyActivityQuestionMutationResult = Apollo.MutationResult<CreateManyActivityQuestionMutation>;
export type CreateManyActivityQuestionMutationOptions = Apollo.BaseMutationOptions<CreateManyActivityQuestionMutation, CreateManyActivityQuestionMutationVariables>;
export const UpdateOneActivityQuestionDocument = gql`
    mutation updateOneActivityQuestion($data: ActivityQuestionUpdateInput!, $where: ActivityQuestionWhereUniqueInput!) {
  updateOneActivityQuestion(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOneActivityQuestionMutationFn = Apollo.MutationFunction<UpdateOneActivityQuestionMutation, UpdateOneActivityQuestionMutationVariables>;

/**
 * __useUpdateOneActivityQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateOneActivityQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneActivityQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneActivityQuestionMutation, { data, loading, error }] = useUpdateOneActivityQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneActivityQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneActivityQuestionMutation, UpdateOneActivityQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneActivityQuestionMutation, UpdateOneActivityQuestionMutationVariables>(UpdateOneActivityQuestionDocument, options);
      }
export type UpdateOneActivityQuestionMutationHookResult = ReturnType<typeof useUpdateOneActivityQuestionMutation>;
export type UpdateOneActivityQuestionMutationResult = Apollo.MutationResult<UpdateOneActivityQuestionMutation>;
export type UpdateOneActivityQuestionMutationOptions = Apollo.BaseMutationOptions<UpdateOneActivityQuestionMutation, UpdateOneActivityQuestionMutationVariables>;
export const DeleteManyActivityQuestionDocument = gql`
    mutation deleteManyActivityQuestion($where: ActivityQuestionWhereInput) {
  deleteManyActivityQuestion(where: $where) {
    count
  }
}
    `;
export type DeleteManyActivityQuestionMutationFn = Apollo.MutationFunction<DeleteManyActivityQuestionMutation, DeleteManyActivityQuestionMutationVariables>;

/**
 * __useDeleteManyActivityQuestionMutation__
 *
 * To run a mutation, you first call `useDeleteManyActivityQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManyActivityQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManyActivityQuestionMutation, { data, loading, error }] = useDeleteManyActivityQuestionMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManyActivityQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteManyActivityQuestionMutation, DeleteManyActivityQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteManyActivityQuestionMutation, DeleteManyActivityQuestionMutationVariables>(DeleteManyActivityQuestionDocument, options);
      }
export type DeleteManyActivityQuestionMutationHookResult = ReturnType<typeof useDeleteManyActivityQuestionMutation>;
export type DeleteManyActivityQuestionMutationResult = Apollo.MutationResult<DeleteManyActivityQuestionMutation>;
export type DeleteManyActivityQuestionMutationOptions = Apollo.BaseMutationOptions<DeleteManyActivityQuestionMutation, DeleteManyActivityQuestionMutationVariables>;
export const CreateActivityBannerDocument = gql`
    mutation createActivityBanner($data: ActivityBannerCreateInput!) {
  createActivityBanner(data: $data) {
    id
  }
}
    `;
export type CreateActivityBannerMutationFn = Apollo.MutationFunction<CreateActivityBannerMutation, CreateActivityBannerMutationVariables>;

/**
 * __useCreateActivityBannerMutation__
 *
 * To run a mutation, you first call `useCreateActivityBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityBannerMutation, { data, loading, error }] = useCreateActivityBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateActivityBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityBannerMutation, CreateActivityBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivityBannerMutation, CreateActivityBannerMutationVariables>(CreateActivityBannerDocument, options);
      }
export type CreateActivityBannerMutationHookResult = ReturnType<typeof useCreateActivityBannerMutation>;
export type CreateActivityBannerMutationResult = Apollo.MutationResult<CreateActivityBannerMutation>;
export type CreateActivityBannerMutationOptions = Apollo.BaseMutationOptions<CreateActivityBannerMutation, CreateActivityBannerMutationVariables>;
export const UpdateActivityBannerDocument = gql`
    mutation updateActivityBanner($where: ActivityBannerWhereUniqueInput!, $data: ActivityBannerUpdateInput!) {
  updateActivityBanner(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateActivityBannerMutationFn = Apollo.MutationFunction<UpdateActivityBannerMutation, UpdateActivityBannerMutationVariables>;

/**
 * __useUpdateActivityBannerMutation__
 *
 * To run a mutation, you first call `useUpdateActivityBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityBannerMutation, { data, loading, error }] = useUpdateActivityBannerMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateActivityBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityBannerMutation, UpdateActivityBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityBannerMutation, UpdateActivityBannerMutationVariables>(UpdateActivityBannerDocument, options);
      }
export type UpdateActivityBannerMutationHookResult = ReturnType<typeof useUpdateActivityBannerMutation>;
export type UpdateActivityBannerMutationResult = Apollo.MutationResult<UpdateActivityBannerMutation>;
export type UpdateActivityBannerMutationOptions = Apollo.BaseMutationOptions<UpdateActivityBannerMutation, UpdateActivityBannerMutationVariables>;
export const DeleteActivityBannerDocument = gql`
    mutation deleteActivityBanner($where: ActivityBannerWhereUniqueInput!) {
  deleteActivityBanner(where: $where) {
    id
  }
}
    `;
export type DeleteActivityBannerMutationFn = Apollo.MutationFunction<DeleteActivityBannerMutation, DeleteActivityBannerMutationVariables>;

/**
 * __useDeleteActivityBannerMutation__
 *
 * To run a mutation, you first call `useDeleteActivityBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActivityBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActivityBannerMutation, { data, loading, error }] = useDeleteActivityBannerMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteActivityBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityBannerMutation, DeleteActivityBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityBannerMutation, DeleteActivityBannerMutationVariables>(DeleteActivityBannerDocument, options);
      }
export type DeleteActivityBannerMutationHookResult = ReturnType<typeof useDeleteActivityBannerMutation>;
export type DeleteActivityBannerMutationResult = Apollo.MutationResult<DeleteActivityBannerMutation>;
export type DeleteActivityBannerMutationOptions = Apollo.BaseMutationOptions<DeleteActivityBannerMutation, DeleteActivityBannerMutationVariables>;
export const ActivityBannersDocument = gql`
    query activityBanners($where: ActivityBannerWhereInput, $orderBy: [ActivityBannerOrderByWithRelationInput!], $cursor: ActivityBannerWhereUniqueInput, $distinct: [ActivityBannerScalarFieldEnum!], $skip: Int, $take: Int) {
  activityBanners(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `;

/**
 * __useActivityBannersQuery__
 *
 * To run a query within a React component, call `useActivityBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityBannersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useActivityBannersQuery(baseOptions?: Apollo.QueryHookOptions<ActivityBannersQuery, ActivityBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityBannersQuery, ActivityBannersQueryVariables>(ActivityBannersDocument, options);
      }
export function useActivityBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityBannersQuery, ActivityBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityBannersQuery, ActivityBannersQueryVariables>(ActivityBannersDocument, options);
        }
export type ActivityBannersQueryHookResult = ReturnType<typeof useActivityBannersQuery>;
export type ActivityBannersLazyQueryHookResult = ReturnType<typeof useActivityBannersLazyQuery>;
export type ActivityBannersQueryResult = Apollo.QueryResult<ActivityBannersQuery, ActivityBannersQueryVariables>;
export const ActivityBannerDocument = gql`
    query activityBanner($where: ActivityBannerWhereUniqueInput!) {
  activityBanner(where: $where) {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `;

/**
 * __useActivityBannerQuery__
 *
 * To run a query within a React component, call `useActivityBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityBannerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useActivityBannerQuery(baseOptions: Apollo.QueryHookOptions<ActivityBannerQuery, ActivityBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityBannerQuery, ActivityBannerQueryVariables>(ActivityBannerDocument, options);
      }
export function useActivityBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityBannerQuery, ActivityBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityBannerQuery, ActivityBannerQueryVariables>(ActivityBannerDocument, options);
        }
export type ActivityBannerQueryHookResult = ReturnType<typeof useActivityBannerQuery>;
export type ActivityBannerLazyQueryHookResult = ReturnType<typeof useActivityBannerLazyQuery>;
export type ActivityBannerQueryResult = Apollo.QueryResult<ActivityBannerQuery, ActivityBannerQueryVariables>;
export const VerifyUserDocument = gql`
    mutation verifyUser($data: VerifyUserInput!) {
  verifyUser(data: $data) {
    success
    message
  }
}
    `;
export type VerifyUserMutationFn = Apollo.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, options);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = Apollo.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = Apollo.BaseMutationOptions<VerifyUserMutation, VerifyUserMutationVariables>;
export const AdminLogInDocument = gql`
    mutation adminLogIn($data: LogInInput!) {
  adminLogIn(data: $data) {
    success
    message
    rules
    authToken
    expiresAt
  }
}
    `;
export type AdminLogInMutationFn = Apollo.MutationFunction<AdminLogInMutation, AdminLogInMutationVariables>;

/**
 * __useAdminLogInMutation__
 *
 * To run a mutation, you first call `useAdminLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLogInMutation, { data, loading, error }] = useAdminLogInMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAdminLogInMutation(baseOptions?: Apollo.MutationHookOptions<AdminLogInMutation, AdminLogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLogInMutation, AdminLogInMutationVariables>(AdminLogInDocument, options);
      }
export type AdminLogInMutationHookResult = ReturnType<typeof useAdminLogInMutation>;
export type AdminLogInMutationResult = Apollo.MutationResult<AdminLogInMutation>;
export type AdminLogInMutationOptions = Apollo.BaseMutationOptions<AdminLogInMutation, AdminLogInMutationVariables>;
export const CreateBadgeDocument = gql`
    mutation createBadge($data: BadgeCreateInput!) {
  createBadge(data: $data) {
    id
    slug
  }
}
    `;
export type CreateBadgeMutationFn = Apollo.MutationFunction<CreateBadgeMutation, CreateBadgeMutationVariables>;

/**
 * __useCreateBadgeMutation__
 *
 * To run a mutation, you first call `useCreateBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBadgeMutation, { data, loading, error }] = useCreateBadgeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBadgeMutation(baseOptions?: Apollo.MutationHookOptions<CreateBadgeMutation, CreateBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBadgeMutation, CreateBadgeMutationVariables>(CreateBadgeDocument, options);
      }
export type CreateBadgeMutationHookResult = ReturnType<typeof useCreateBadgeMutation>;
export type CreateBadgeMutationResult = Apollo.MutationResult<CreateBadgeMutation>;
export type CreateBadgeMutationOptions = Apollo.BaseMutationOptions<CreateBadgeMutation, CreateBadgeMutationVariables>;
export const UpdateBadgeDocument = gql`
    mutation updateBadge($data: BadgeUpdateInput!, $where: BadgeWhereUniqueInput!) {
  updateBadge(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateBadgeMutationFn = Apollo.MutationFunction<UpdateBadgeMutation, UpdateBadgeMutationVariables>;

/**
 * __useUpdateBadgeMutation__
 *
 * To run a mutation, you first call `useUpdateBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBadgeMutation, { data, loading, error }] = useUpdateBadgeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateBadgeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBadgeMutation, UpdateBadgeMutationVariables>(UpdateBadgeDocument, options);
      }
export type UpdateBadgeMutationHookResult = ReturnType<typeof useUpdateBadgeMutation>;
export type UpdateBadgeMutationResult = Apollo.MutationResult<UpdateBadgeMutation>;
export type UpdateBadgeMutationOptions = Apollo.BaseMutationOptions<UpdateBadgeMutation, UpdateBadgeMutationVariables>;
export const DeleteBadgeDocument = gql`
    mutation deleteBadge($where: BadgeWhereUniqueInput!) {
  deleteBadge(where: $where) {
    id
  }
}
    `;
export type DeleteBadgeMutationFn = Apollo.MutationFunction<DeleteBadgeMutation, DeleteBadgeMutationVariables>;

/**
 * __useDeleteBadgeMutation__
 *
 * To run a mutation, you first call `useDeleteBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBadgeMutation, { data, loading, error }] = useDeleteBadgeMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteBadgeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBadgeMutation, DeleteBadgeMutationVariables>(DeleteBadgeDocument, options);
      }
export type DeleteBadgeMutationHookResult = ReturnType<typeof useDeleteBadgeMutation>;
export type DeleteBadgeMutationResult = Apollo.MutationResult<DeleteBadgeMutation>;
export type DeleteBadgeMutationOptions = Apollo.BaseMutationOptions<DeleteBadgeMutation, DeleteBadgeMutationVariables>;
export const BadgesDocument = gql`
    query badges($where: BadgeWhereInput, $orderBy: [BadgeOrderByWithRelationInput!], $cursor: BadgeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [BadgeScalarFieldEnum!]) {
  badges(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    slug
    image
    createdAt
  }
}
    `;

/**
 * __useBadgesQuery__
 *
 * To run a query within a React component, call `useBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useBadgesQuery(baseOptions?: Apollo.QueryHookOptions<BadgesQuery, BadgesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BadgesQuery, BadgesQueryVariables>(BadgesDocument, options);
      }
export function useBadgesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BadgesQuery, BadgesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BadgesQuery, BadgesQueryVariables>(BadgesDocument, options);
        }
export type BadgesQueryHookResult = ReturnType<typeof useBadgesQuery>;
export type BadgesLazyQueryHookResult = ReturnType<typeof useBadgesLazyQuery>;
export type BadgesQueryResult = Apollo.QueryResult<BadgesQuery, BadgesQueryVariables>;
export const BadgeDocument = gql`
    query badge($where: BadgeWhereUniqueInput!) {
  badge(where: $where) {
    id
    name
    slug
    image
    createdAt
  }
}
    `;

/**
 * __useBadgeQuery__
 *
 * To run a query within a React component, call `useBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBadgeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBadgeQuery(baseOptions: Apollo.QueryHookOptions<BadgeQuery, BadgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BadgeQuery, BadgeQueryVariables>(BadgeDocument, options);
      }
export function useBadgeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BadgeQuery, BadgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BadgeQuery, BadgeQueryVariables>(BadgeDocument, options);
        }
export type BadgeQueryHookResult = ReturnType<typeof useBadgeQuery>;
export type BadgeLazyQueryHookResult = ReturnType<typeof useBadgeLazyQuery>;
export type BadgeQueryResult = Apollo.QueryResult<BadgeQuery, BadgeQueryVariables>;
export const AggregateBadgeDocument = gql`
    query aggregateBadge($where: BadgeWhereInput, $orderBy: [BadgeOrderByWithRelationInput!], $cursor: BadgeWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateBadge(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateBadgeQuery__
 *
 * To run a query within a React component, call `useAggregateBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateBadgeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateBadgeQuery(baseOptions?: Apollo.QueryHookOptions<AggregateBadgeQuery, AggregateBadgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateBadgeQuery, AggregateBadgeQueryVariables>(AggregateBadgeDocument, options);
      }
export function useAggregateBadgeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateBadgeQuery, AggregateBadgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateBadgeQuery, AggregateBadgeQueryVariables>(AggregateBadgeDocument, options);
        }
export type AggregateBadgeQueryHookResult = ReturnType<typeof useAggregateBadgeQuery>;
export type AggregateBadgeLazyQueryHookResult = ReturnType<typeof useAggregateBadgeLazyQuery>;
export type AggregateBadgeQueryResult = Apollo.QueryResult<AggregateBadgeQuery, AggregateBadgeQueryVariables>;
export const UpdateCommentDocument = gql`
    mutation updateComment($data: CommentUpdateInput!, $where: CommentWhereUniqueInput!) {
  updateComment(data: $data, where: $where) {
    id
    status
    isFeatured
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpdateCommentsDocument = gql`
    mutation updateComments($data: CommentUpdateManyMutationInput!, $where: CommentWhereInput!) {
  updateComments(data: $data, where: $where) {
    count
  }
}
    `;
export type UpdateCommentsMutationFn = Apollo.MutationFunction<UpdateCommentsMutation, UpdateCommentsMutationVariables>;

/**
 * __useUpdateCommentsMutation__
 *
 * To run a mutation, you first call `useUpdateCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentsMutation, { data, loading, error }] = useUpdateCommentsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateCommentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentsMutation, UpdateCommentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentsMutation, UpdateCommentsMutationVariables>(UpdateCommentsDocument, options);
      }
export type UpdateCommentsMutationHookResult = ReturnType<typeof useUpdateCommentsMutation>;
export type UpdateCommentsMutationResult = Apollo.MutationResult<UpdateCommentsMutation>;
export type UpdateCommentsMutationOptions = Apollo.BaseMutationOptions<UpdateCommentsMutation, UpdateCommentsMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($where: CommentWhereUniqueInput!) {
  deleteComment(where: $where) {
    id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const CommentsDocument = gql`
    query comments($where: CommentWhereInput, $orderBy: [CommentOrderByWithRelationInput!], $cursor: CommentWhereUniqueInput, $take: Int, $skip: Int, $distinct: [CommentScalarFieldEnum!]) {
  comments(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    text
    attachmentUrl
    status
    isFeatured
    quality
    isDailyChallenge
    meta
    createdBy {
      id
      firstName
      lastName
      phoneNumber
    }
    activity {
      id
      title
      slug
    }
    storyActivity {
      id
      title
      slug
    }
    createdAt
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const AggregateCommentDocument = gql`
    query aggregateComment($where: CommentWhereInput, $orderBy: [CommentOrderByWithRelationInput!], $cursor: CommentWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateComment(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateCommentQuery__
 *
 * To run a query within a React component, call `useAggregateCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateCommentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateCommentQuery(baseOptions?: Apollo.QueryHookOptions<AggregateCommentQuery, AggregateCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateCommentQuery, AggregateCommentQueryVariables>(AggregateCommentDocument, options);
      }
export function useAggregateCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateCommentQuery, AggregateCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateCommentQuery, AggregateCommentQueryVariables>(AggregateCommentDocument, options);
        }
export type AggregateCommentQueryHookResult = ReturnType<typeof useAggregateCommentQuery>;
export type AggregateCommentLazyQueryHookResult = ReturnType<typeof useAggregateCommentLazyQuery>;
export type AggregateCommentQueryResult = Apollo.QueryResult<AggregateCommentQuery, AggregateCommentQueryVariables>;
export const CreateDailyActivityDocument = gql`
    mutation createDailyActivity($data: DailyActivityCreateInput!) {
  createDailyActivity(data: $data) {
    id
  }
}
    `;
export type CreateDailyActivityMutationFn = Apollo.MutationFunction<CreateDailyActivityMutation, CreateDailyActivityMutationVariables>;

/**
 * __useCreateDailyActivityMutation__
 *
 * To run a mutation, you first call `useCreateDailyActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDailyActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDailyActivityMutation, { data, loading, error }] = useCreateDailyActivityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDailyActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateDailyActivityMutation, CreateDailyActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDailyActivityMutation, CreateDailyActivityMutationVariables>(CreateDailyActivityDocument, options);
      }
export type CreateDailyActivityMutationHookResult = ReturnType<typeof useCreateDailyActivityMutation>;
export type CreateDailyActivityMutationResult = Apollo.MutationResult<CreateDailyActivityMutation>;
export type CreateDailyActivityMutationOptions = Apollo.BaseMutationOptions<CreateDailyActivityMutation, CreateDailyActivityMutationVariables>;
export const UpdateDailyActivityDocument = gql`
    mutation updateDailyActivity($where: DailyActivityWhereUniqueInput!, $data: DailyActivityUpdateInput!) {
  updateDailyActivity(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateDailyActivityMutationFn = Apollo.MutationFunction<UpdateDailyActivityMutation, UpdateDailyActivityMutationVariables>;

/**
 * __useUpdateDailyActivityMutation__
 *
 * To run a mutation, you first call `useUpdateDailyActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDailyActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDailyActivityMutation, { data, loading, error }] = useUpdateDailyActivityMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDailyActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDailyActivityMutation, UpdateDailyActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDailyActivityMutation, UpdateDailyActivityMutationVariables>(UpdateDailyActivityDocument, options);
      }
export type UpdateDailyActivityMutationHookResult = ReturnType<typeof useUpdateDailyActivityMutation>;
export type UpdateDailyActivityMutationResult = Apollo.MutationResult<UpdateDailyActivityMutation>;
export type UpdateDailyActivityMutationOptions = Apollo.BaseMutationOptions<UpdateDailyActivityMutation, UpdateDailyActivityMutationVariables>;
export const DeleteDailyActivityDocument = gql`
    mutation deleteDailyActivity($where: DailyActivityWhereUniqueInput!) {
  deleteDailyActivity(where: $where) {
    id
  }
}
    `;
export type DeleteDailyActivityMutationFn = Apollo.MutationFunction<DeleteDailyActivityMutation, DeleteDailyActivityMutationVariables>;

/**
 * __useDeleteDailyActivityMutation__
 *
 * To run a mutation, you first call `useDeleteDailyActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDailyActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDailyActivityMutation, { data, loading, error }] = useDeleteDailyActivityMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteDailyActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDailyActivityMutation, DeleteDailyActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDailyActivityMutation, DeleteDailyActivityMutationVariables>(DeleteDailyActivityDocument, options);
      }
export type DeleteDailyActivityMutationHookResult = ReturnType<typeof useDeleteDailyActivityMutation>;
export type DeleteDailyActivityMutationResult = Apollo.MutationResult<DeleteDailyActivityMutation>;
export type DeleteDailyActivityMutationOptions = Apollo.BaseMutationOptions<DeleteDailyActivityMutation, DeleteDailyActivityMutationVariables>;
export const DailyActivitiesDocument = gql`
    query dailyActivities($where: DailyActivityWhereInput, $orderBy: [DailyActivityOrderByWithRelationInput!], $cursor: DailyActivityWhereUniqueInput, $distinct: [DailyActivityScalarFieldEnum!], $skip: Int, $take: Int) {
  dailyActivities(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    question
    scheduledOn
    standards {
      id
    }
  }
}
    `;

/**
 * __useDailyActivitiesQuery__
 *
 * To run a query within a React component, call `useDailyActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useDailyActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<DailyActivitiesQuery, DailyActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyActivitiesQuery, DailyActivitiesQueryVariables>(DailyActivitiesDocument, options);
      }
export function useDailyActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyActivitiesQuery, DailyActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyActivitiesQuery, DailyActivitiesQueryVariables>(DailyActivitiesDocument, options);
        }
export type DailyActivitiesQueryHookResult = ReturnType<typeof useDailyActivitiesQuery>;
export type DailyActivitiesLazyQueryHookResult = ReturnType<typeof useDailyActivitiesLazyQuery>;
export type DailyActivitiesQueryResult = Apollo.QueryResult<DailyActivitiesQuery, DailyActivitiesQueryVariables>;
export const DailyActivityDocument = gql`
    query dailyActivity($where: DailyActivityWhereUniqueInput!) {
  dailyActivity(where: $where) {
    id
    question
    questionImage
    questionVideo
    scheduledOn
    options
    standards {
      id
    }
    answer
    answerText
  }
}
    `;

/**
 * __useDailyActivityQuery__
 *
 * To run a query within a React component, call `useDailyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDailyActivityQuery(baseOptions: Apollo.QueryHookOptions<DailyActivityQuery, DailyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyActivityQuery, DailyActivityQueryVariables>(DailyActivityDocument, options);
      }
export function useDailyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyActivityQuery, DailyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyActivityQuery, DailyActivityQueryVariables>(DailyActivityDocument, options);
        }
export type DailyActivityQueryHookResult = ReturnType<typeof useDailyActivityQuery>;
export type DailyActivityLazyQueryHookResult = ReturnType<typeof useDailyActivityLazyQuery>;
export type DailyActivityQueryResult = Apollo.QueryResult<DailyActivityQuery, DailyActivityQueryVariables>;
export const AggregateDailyActivityDocument = gql`
    query aggregateDailyActivity($where: DailyActivityWhereInput, $orderBy: [DailyActivityOrderByWithRelationInput!], $cursor: DailyActivityWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateDailyActivity(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateDailyActivityQuery__
 *
 * To run a query within a React component, call `useAggregateDailyActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateDailyActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateDailyActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateDailyActivityQuery(baseOptions?: Apollo.QueryHookOptions<AggregateDailyActivityQuery, AggregateDailyActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateDailyActivityQuery, AggregateDailyActivityQueryVariables>(AggregateDailyActivityDocument, options);
      }
export function useAggregateDailyActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateDailyActivityQuery, AggregateDailyActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateDailyActivityQuery, AggregateDailyActivityQueryVariables>(AggregateDailyActivityDocument, options);
        }
export type AggregateDailyActivityQueryHookResult = ReturnType<typeof useAggregateDailyActivityQuery>;
export type AggregateDailyActivityLazyQueryHookResult = ReturnType<typeof useAggregateDailyActivityLazyQuery>;
export type AggregateDailyActivityQueryResult = Apollo.QueryResult<AggregateDailyActivityQuery, AggregateDailyActivityQueryVariables>;
export const GenerateUploadSignedUrlDocument = gql`
    mutation generateUploadSignedUrl($data: GenerateUploadSignedUrlInput!) {
  generateUploadSignedUrl(data: $data) {
    name
    signedUrl
    url
  }
}
    `;
export type GenerateUploadSignedUrlMutationFn = Apollo.MutationFunction<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>;

/**
 * __useGenerateUploadSignedUrlMutation__
 *
 * To run a mutation, you first call `useGenerateUploadSignedUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUploadSignedUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUploadSignedUrlMutation, { data, loading, error }] = useGenerateUploadSignedUrlMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGenerateUploadSignedUrlMutation(baseOptions?: Apollo.MutationHookOptions<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>(GenerateUploadSignedUrlDocument, options);
      }
export type GenerateUploadSignedUrlMutationHookResult = ReturnType<typeof useGenerateUploadSignedUrlMutation>;
export type GenerateUploadSignedUrlMutationResult = Apollo.MutationResult<GenerateUploadSignedUrlMutation>;
export type GenerateUploadSignedUrlMutationOptions = Apollo.BaseMutationOptions<GenerateUploadSignedUrlMutation, GenerateUploadSignedUrlMutationVariables>;
export const CreateStudentGameLogDocument = gql`
    mutation createStudentGameLog($data: StudentGameLogCreateInput!) {
  createStudentGameLog(data: $data) {
    id
  }
}
    `;
export type CreateStudentGameLogMutationFn = Apollo.MutationFunction<CreateStudentGameLogMutation, CreateStudentGameLogMutationVariables>;

/**
 * __useCreateStudentGameLogMutation__
 *
 * To run a mutation, you first call `useCreateStudentGameLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentGameLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentGameLogMutation, { data, loading, error }] = useCreateStudentGameLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStudentGameLogMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudentGameLogMutation, CreateStudentGameLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudentGameLogMutation, CreateStudentGameLogMutationVariables>(CreateStudentGameLogDocument, options);
      }
export type CreateStudentGameLogMutationHookResult = ReturnType<typeof useCreateStudentGameLogMutation>;
export type CreateStudentGameLogMutationResult = Apollo.MutationResult<CreateStudentGameLogMutation>;
export type CreateStudentGameLogMutationOptions = Apollo.BaseMutationOptions<CreateStudentGameLogMutation, CreateStudentGameLogMutationVariables>;
export const DeleteStudentGameLogsDocument = gql`
    mutation deleteStudentGameLogs($where: StudentGameLogWhereInput!) {
  deleteStudentGameLogs(where: $where) {
    count
  }
}
    `;
export type DeleteStudentGameLogsMutationFn = Apollo.MutationFunction<DeleteStudentGameLogsMutation, DeleteStudentGameLogsMutationVariables>;

/**
 * __useDeleteStudentGameLogsMutation__
 *
 * To run a mutation, you first call `useDeleteStudentGameLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStudentGameLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStudentGameLogsMutation, { data, loading, error }] = useDeleteStudentGameLogsMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteStudentGameLogsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStudentGameLogsMutation, DeleteStudentGameLogsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStudentGameLogsMutation, DeleteStudentGameLogsMutationVariables>(DeleteStudentGameLogsDocument, options);
      }
export type DeleteStudentGameLogsMutationHookResult = ReturnType<typeof useDeleteStudentGameLogsMutation>;
export type DeleteStudentGameLogsMutationResult = Apollo.MutationResult<DeleteStudentGameLogsMutation>;
export type DeleteStudentGameLogsMutationOptions = Apollo.BaseMutationOptions<DeleteStudentGameLogsMutation, DeleteStudentGameLogsMutationVariables>;
export const StudentGameLogsDocument = gql`
    query studentGameLogs($where: StudentGameLogWhereInput, $orderBy: [StudentGameLogOrderByWithRelationInput!], $cursor: StudentGameLogWhereUniqueInput, $take: Int, $skip: Int, $distinct: [StudentGameLogScalarFieldEnum!]) {
  studentGameLogs(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    title
    student {
      id
      firstName
      lastName
      phoneNumber
      standard {
        key
      }
      school {
        id
        name
      }
    }
    model
    modelId
    points
    trigger {
      id
      text
    }
    triggerType
    createdAt
  }
}
    `;

/**
 * __useStudentGameLogsQuery__
 *
 * To run a query within a React component, call `useStudentGameLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentGameLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentGameLogsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useStudentGameLogsQuery(baseOptions?: Apollo.QueryHookOptions<StudentGameLogsQuery, StudentGameLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentGameLogsQuery, StudentGameLogsQueryVariables>(StudentGameLogsDocument, options);
      }
export function useStudentGameLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentGameLogsQuery, StudentGameLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentGameLogsQuery, StudentGameLogsQueryVariables>(StudentGameLogsDocument, options);
        }
export type StudentGameLogsQueryHookResult = ReturnType<typeof useStudentGameLogsQuery>;
export type StudentGameLogsLazyQueryHookResult = ReturnType<typeof useStudentGameLogsLazyQuery>;
export type StudentGameLogsQueryResult = Apollo.QueryResult<StudentGameLogsQuery, StudentGameLogsQueryVariables>;
export const AggregateStudentGameLogDocument = gql`
    query aggregateStudentGameLog($where: StudentGameLogWhereInput, $orderBy: [StudentGameLogOrderByWithRelationInput!], $cursor: StudentGameLogWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateStudentGameLog(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateStudentGameLogQuery__
 *
 * To run a query within a React component, call `useAggregateStudentGameLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateStudentGameLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateStudentGameLogQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateStudentGameLogQuery(baseOptions?: Apollo.QueryHookOptions<AggregateStudentGameLogQuery, AggregateStudentGameLogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateStudentGameLogQuery, AggregateStudentGameLogQueryVariables>(AggregateStudentGameLogDocument, options);
      }
export function useAggregateStudentGameLogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateStudentGameLogQuery, AggregateStudentGameLogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateStudentGameLogQuery, AggregateStudentGameLogQueryVariables>(AggregateStudentGameLogDocument, options);
        }
export type AggregateStudentGameLogQueryHookResult = ReturnType<typeof useAggregateStudentGameLogQuery>;
export type AggregateStudentGameLogLazyQueryHookResult = ReturnType<typeof useAggregateStudentGameLogLazyQuery>;
export type AggregateStudentGameLogQueryResult = Apollo.QueryResult<AggregateStudentGameLogQuery, AggregateStudentGameLogQueryVariables>;
export const ExportStudentGameLogsDocument = gql`
    query exportStudentGameLogs($where: ExportGameLogsInput!) {
  exportStudentGameLogs(where: $where)
}
    `;

/**
 * __useExportStudentGameLogsQuery__
 *
 * To run a query within a React component, call `useExportStudentGameLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportStudentGameLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportStudentGameLogsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useExportStudentGameLogsQuery(baseOptions: Apollo.QueryHookOptions<ExportStudentGameLogsQuery, ExportStudentGameLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportStudentGameLogsQuery, ExportStudentGameLogsQueryVariables>(ExportStudentGameLogsDocument, options);
      }
export function useExportStudentGameLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportStudentGameLogsQuery, ExportStudentGameLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportStudentGameLogsQuery, ExportStudentGameLogsQueryVariables>(ExportStudentGameLogsDocument, options);
        }
export type ExportStudentGameLogsQueryHookResult = ReturnType<typeof useExportStudentGameLogsQuery>;
export type ExportStudentGameLogsLazyQueryHookResult = ReturnType<typeof useExportStudentGameLogsLazyQuery>;
export type ExportStudentGameLogsQueryResult = Apollo.QueryResult<ExportStudentGameLogsQuery, ExportStudentGameLogsQueryVariables>;
export const AdminLeaderboardDocument = gql`
    query adminLeaderboard($where: AdminLeaderboardInput!, $xpsOrderBy: SortOrder, $starsOrderBy: SortOrder, $take: Int!, $skip: Int!) {
  adminLeaderboard(
    where: $where
    xpsOrderBy: $xpsOrderBy
    starsOrderBy: $starsOrderBy
    take: $take
    skip: $skip
  ) {
    name
    phoneNumber
    xps
    stars
    level
    school
    standard
  }
}
    `;

/**
 * __useAdminLeaderboardQuery__
 *
 * To run a query within a React component, call `useAdminLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminLeaderboardQuery({
 *   variables: {
 *      where: // value for 'where'
 *      xpsOrderBy: // value for 'xpsOrderBy'
 *      starsOrderBy: // value for 'starsOrderBy'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAdminLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<AdminLeaderboardQuery, AdminLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminLeaderboardQuery, AdminLeaderboardQueryVariables>(AdminLeaderboardDocument, options);
      }
export function useAdminLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminLeaderboardQuery, AdminLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminLeaderboardQuery, AdminLeaderboardQueryVariables>(AdminLeaderboardDocument, options);
        }
export type AdminLeaderboardQueryHookResult = ReturnType<typeof useAdminLeaderboardQuery>;
export type AdminLeaderboardLazyQueryHookResult = ReturnType<typeof useAdminLeaderboardLazyQuery>;
export type AdminLeaderboardQueryResult = Apollo.QueryResult<AdminLeaderboardQuery, AdminLeaderboardQueryVariables>;
export const CreateLevelDocument = gql`
    mutation createLevel($data: LevelCreateInput!) {
  createLevel(data: $data) {
    id
    slug
  }
}
    `;
export type CreateLevelMutationFn = Apollo.MutationFunction<CreateLevelMutation, CreateLevelMutationVariables>;

/**
 * __useCreateLevelMutation__
 *
 * To run a mutation, you first call `useCreateLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLevelMutation, { data, loading, error }] = useCreateLevelMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLevelMutation(baseOptions?: Apollo.MutationHookOptions<CreateLevelMutation, CreateLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLevelMutation, CreateLevelMutationVariables>(CreateLevelDocument, options);
      }
export type CreateLevelMutationHookResult = ReturnType<typeof useCreateLevelMutation>;
export type CreateLevelMutationResult = Apollo.MutationResult<CreateLevelMutation>;
export type CreateLevelMutationOptions = Apollo.BaseMutationOptions<CreateLevelMutation, CreateLevelMutationVariables>;
export const UpdateLevelDocument = gql`
    mutation updateLevel($data: LevelUpdateInput!, $where: LevelWhereUniqueInput!) {
  updateLevel(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateLevelMutationFn = Apollo.MutationFunction<UpdateLevelMutation, UpdateLevelMutationVariables>;

/**
 * __useUpdateLevelMutation__
 *
 * To run a mutation, you first call `useUpdateLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLevelMutation, { data, loading, error }] = useUpdateLevelMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLevelMutation, UpdateLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLevelMutation, UpdateLevelMutationVariables>(UpdateLevelDocument, options);
      }
export type UpdateLevelMutationHookResult = ReturnType<typeof useUpdateLevelMutation>;
export type UpdateLevelMutationResult = Apollo.MutationResult<UpdateLevelMutation>;
export type UpdateLevelMutationOptions = Apollo.BaseMutationOptions<UpdateLevelMutation, UpdateLevelMutationVariables>;
export const DeleteLevelDocument = gql`
    mutation deleteLevel($where: LevelWhereUniqueInput!) {
  deleteLevel(where: $where) {
    id
  }
}
    `;
export type DeleteLevelMutationFn = Apollo.MutationFunction<DeleteLevelMutation, DeleteLevelMutationVariables>;

/**
 * __useDeleteLevelMutation__
 *
 * To run a mutation, you first call `useDeleteLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLevelMutation, { data, loading, error }] = useDeleteLevelMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteLevelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLevelMutation, DeleteLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLevelMutation, DeleteLevelMutationVariables>(DeleteLevelDocument, options);
      }
export type DeleteLevelMutationHookResult = ReturnType<typeof useDeleteLevelMutation>;
export type DeleteLevelMutationResult = Apollo.MutationResult<DeleteLevelMutation>;
export type DeleteLevelMutationOptions = Apollo.BaseMutationOptions<DeleteLevelMutation, DeleteLevelMutationVariables>;
export const LevelsDocument = gql`
    query levels($where: LevelWhereInput, $orderBy: [LevelOrderByWithRelationInput!], $cursor: LevelWhereUniqueInput, $take: Int, $skip: Int, $distinct: [LevelScalarFieldEnum!]) {
  levels(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    slug
    image
    createdAt
  }
}
    `;

/**
 * __useLevelsQuery__
 *
 * To run a query within a React component, call `useLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useLevelsQuery(baseOptions?: Apollo.QueryHookOptions<LevelsQuery, LevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LevelsQuery, LevelsQueryVariables>(LevelsDocument, options);
      }
export function useLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LevelsQuery, LevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LevelsQuery, LevelsQueryVariables>(LevelsDocument, options);
        }
export type LevelsQueryHookResult = ReturnType<typeof useLevelsQuery>;
export type LevelsLazyQueryHookResult = ReturnType<typeof useLevelsLazyQuery>;
export type LevelsQueryResult = Apollo.QueryResult<LevelsQuery, LevelsQueryVariables>;
export const LevelDocument = gql`
    query level($where: LevelWhereUniqueInput!) {
  level(where: $where) {
    id
    name
    slug
    image
    createdAt
    sequence
  }
}
    `;

/**
 * __useLevelQuery__
 *
 * To run a query within a React component, call `useLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLevelQuery(baseOptions: Apollo.QueryHookOptions<LevelQuery, LevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LevelQuery, LevelQueryVariables>(LevelDocument, options);
      }
export function useLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LevelQuery, LevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LevelQuery, LevelQueryVariables>(LevelDocument, options);
        }
export type LevelQueryHookResult = ReturnType<typeof useLevelQuery>;
export type LevelLazyQueryHookResult = ReturnType<typeof useLevelLazyQuery>;
export type LevelQueryResult = Apollo.QueryResult<LevelQuery, LevelQueryVariables>;
export const AggregateLevelDocument = gql`
    query aggregateLevel($where: LevelWhereInput, $orderBy: [LevelOrderByWithRelationInput!], $cursor: LevelWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateLevel(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateLevelQuery__
 *
 * To run a query within a React component, call `useAggregateLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateLevelQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateLevelQuery(baseOptions?: Apollo.QueryHookOptions<AggregateLevelQuery, AggregateLevelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateLevelQuery, AggregateLevelQueryVariables>(AggregateLevelDocument, options);
      }
export function useAggregateLevelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateLevelQuery, AggregateLevelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateLevelQuery, AggregateLevelQueryVariables>(AggregateLevelDocument, options);
        }
export type AggregateLevelQueryHookResult = ReturnType<typeof useAggregateLevelQuery>;
export type AggregateLevelLazyQueryHookResult = ReturnType<typeof useAggregateLevelLazyQuery>;
export type AggregateLevelQueryResult = Apollo.QueryResult<AggregateLevelQuery, AggregateLevelQueryVariables>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($data: OrderUpdateInput!, $where: OrderWhereUniqueInput!) {
  updateOrder(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateManyOrderDocument = gql`
    mutation updateManyOrder($data: OrderUpdateManyMutationInput!, $where: OrderWhereInput!) {
  updateManyOrder(data: $data, where: $where) {
    count
  }
}
    `;
export type UpdateManyOrderMutationFn = Apollo.MutationFunction<UpdateManyOrderMutation, UpdateManyOrderMutationVariables>;

/**
 * __useUpdateManyOrderMutation__
 *
 * To run a mutation, you first call `useUpdateManyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyOrderMutation, { data, loading, error }] = useUpdateManyOrderMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateManyOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyOrderMutation, UpdateManyOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyOrderMutation, UpdateManyOrderMutationVariables>(UpdateManyOrderDocument, options);
      }
export type UpdateManyOrderMutationHookResult = ReturnType<typeof useUpdateManyOrderMutation>;
export type UpdateManyOrderMutationResult = Apollo.MutationResult<UpdateManyOrderMutation>;
export type UpdateManyOrderMutationOptions = Apollo.BaseMutationOptions<UpdateManyOrderMutation, UpdateManyOrderMutationVariables>;
export const OrdersDocument = gql`
    query orders($where: OrderWhereInput, $orderBy: [OrderOrderByWithRelationInput!], $cursor: OrderWhereUniqueInput, $take: Int, $skip: Int, $distinct: [OrderScalarFieldEnum!]) {
  orders(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    product {
      id
      name
      slug
    }
    customer {
      id
      firstName
      lastName
      phoneNumber
    }
    status
    total
    paymentMethod
    paymentStatus
    createdAt
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
    query order($where: OrderWhereUniqueInput!) {
  order(where: $where) {
    id
    product {
      id
      name
      slug
    }
    customer {
      id
      firstName
      lastName
      phoneNumber
    }
    status
    total
    paymentMethod
    paymentStatus
    billingFirstName
    billingLastName
    billingAddress1
    billingAddress2
    billingCity
    billingState
    billingCountry
    billingPostcode
    billingPhone
    shippingFirstName
    shippingLastName
    shippingAddress1
    shippingAddress2
    shippingCity
    shippingState
    shippingCountry
    shippingPostcode
    shippingPhone
    createdAt
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const AggregateOrderDocument = gql`
    query aggregateOrder($where: OrderWhereInput, $orderBy: [OrderOrderByWithRelationInput!], $cursor: OrderWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateOrder(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateOrderQuery__
 *
 * To run a query within a React component, call `useAggregateOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateOrderQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateOrderQuery(baseOptions?: Apollo.QueryHookOptions<AggregateOrderQuery, AggregateOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateOrderQuery, AggregateOrderQueryVariables>(AggregateOrderDocument, options);
      }
export function useAggregateOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateOrderQuery, AggregateOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateOrderQuery, AggregateOrderQueryVariables>(AggregateOrderDocument, options);
        }
export type AggregateOrderQueryHookResult = ReturnType<typeof useAggregateOrderQuery>;
export type AggregateOrderLazyQueryHookResult = ReturnType<typeof useAggregateOrderLazyQuery>;
export type AggregateOrderQueryResult = Apollo.QueryResult<AggregateOrderQuery, AggregateOrderQueryVariables>;
export const CreateTriggerDocument = gql`
    mutation createTrigger($data: TriggerCreateInput!) {
  createTrigger(data: $data) {
    id
    text
    key
    totalCount
    model
    modelId
    countDuration
    countPeriod
    earn
    gamePointType {
      id
      pluralName
    }
    label
    maximumEarningsPerUser
    isActive
  }
}
    `;
export type CreateTriggerMutationFn = Apollo.MutationFunction<CreateTriggerMutation, CreateTriggerMutationVariables>;

/**
 * __useCreateTriggerMutation__
 *
 * To run a mutation, you first call `useCreateTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTriggerMutation, { data, loading, error }] = useCreateTriggerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTriggerMutation(baseOptions?: Apollo.MutationHookOptions<CreateTriggerMutation, CreateTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTriggerMutation, CreateTriggerMutationVariables>(CreateTriggerDocument, options);
      }
export type CreateTriggerMutationHookResult = ReturnType<typeof useCreateTriggerMutation>;
export type CreateTriggerMutationResult = Apollo.MutationResult<CreateTriggerMutation>;
export type CreateTriggerMutationOptions = Apollo.BaseMutationOptions<CreateTriggerMutation, CreateTriggerMutationVariables>;
export const CreateGamePointTypeDocument = gql`
    mutation createGamePointType($data: GamePointTypeCreateInput!) {
  createGamePointType(data: $data) {
    id
    slug
  }
}
    `;
export type CreateGamePointTypeMutationFn = Apollo.MutationFunction<CreateGamePointTypeMutation, CreateGamePointTypeMutationVariables>;

/**
 * __useCreateGamePointTypeMutation__
 *
 * To run a mutation, you first call `useCreateGamePointTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGamePointTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGamePointTypeMutation, { data, loading, error }] = useCreateGamePointTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGamePointTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateGamePointTypeMutation, CreateGamePointTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGamePointTypeMutation, CreateGamePointTypeMutationVariables>(CreateGamePointTypeDocument, options);
      }
export type CreateGamePointTypeMutationHookResult = ReturnType<typeof useCreateGamePointTypeMutation>;
export type CreateGamePointTypeMutationResult = Apollo.MutationResult<CreateGamePointTypeMutation>;
export type CreateGamePointTypeMutationOptions = Apollo.BaseMutationOptions<CreateGamePointTypeMutation, CreateGamePointTypeMutationVariables>;
export const UpdateGamePointTypeDocument = gql`
    mutation updateGamePointType($data: GamePointTypeUpdateInput!, $where: GamePointTypeWhereUniqueInput!) {
  updateGamePointType(data: $data, where: $where) {
    id
    slug
  }
}
    `;
export type UpdateGamePointTypeMutationFn = Apollo.MutationFunction<UpdateGamePointTypeMutation, UpdateGamePointTypeMutationVariables>;

/**
 * __useUpdateGamePointTypeMutation__
 *
 * To run a mutation, you first call `useUpdateGamePointTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGamePointTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGamePointTypeMutation, { data, loading, error }] = useUpdateGamePointTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateGamePointTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGamePointTypeMutation, UpdateGamePointTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGamePointTypeMutation, UpdateGamePointTypeMutationVariables>(UpdateGamePointTypeDocument, options);
      }
export type UpdateGamePointTypeMutationHookResult = ReturnType<typeof useUpdateGamePointTypeMutation>;
export type UpdateGamePointTypeMutationResult = Apollo.MutationResult<UpdateGamePointTypeMutation>;
export type UpdateGamePointTypeMutationOptions = Apollo.BaseMutationOptions<UpdateGamePointTypeMutation, UpdateGamePointTypeMutationVariables>;
export const GamePointTypesDocument = gql`
    query gamePointTypes($where: GamePointTypeWhereInput, $orderBy: [GamePointTypeOrderByWithRelationInput!], $cursor: GamePointTypeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [GamePointTypeScalarFieldEnum!]) {
  gamePointTypes(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    singularName
    pluralName
    slug
    image
    skill {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGamePointTypesQuery__
 *
 * To run a query within a React component, call `useGamePointTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamePointTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamePointTypesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useGamePointTypesQuery(baseOptions?: Apollo.QueryHookOptions<GamePointTypesQuery, GamePointTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GamePointTypesQuery, GamePointTypesQueryVariables>(GamePointTypesDocument, options);
      }
export function useGamePointTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamePointTypesQuery, GamePointTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GamePointTypesQuery, GamePointTypesQueryVariables>(GamePointTypesDocument, options);
        }
export type GamePointTypesQueryHookResult = ReturnType<typeof useGamePointTypesQuery>;
export type GamePointTypesLazyQueryHookResult = ReturnType<typeof useGamePointTypesLazyQuery>;
export type GamePointTypesQueryResult = Apollo.QueryResult<GamePointTypesQuery, GamePointTypesQueryVariables>;
export const GamePointTypeDocument = gql`
    query gamePointType($where: GamePointTypeWhereUniqueInput!) {
  gamePointType(where: $where) {
    id
    singularName
    pluralName
    slug
    image
    skill {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useGamePointTypeQuery__
 *
 * To run a query within a React component, call `useGamePointTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamePointTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamePointTypeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGamePointTypeQuery(baseOptions: Apollo.QueryHookOptions<GamePointTypeQuery, GamePointTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GamePointTypeQuery, GamePointTypeQueryVariables>(GamePointTypeDocument, options);
      }
export function useGamePointTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GamePointTypeQuery, GamePointTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GamePointTypeQuery, GamePointTypeQueryVariables>(GamePointTypeDocument, options);
        }
export type GamePointTypeQueryHookResult = ReturnType<typeof useGamePointTypeQuery>;
export type GamePointTypeLazyQueryHookResult = ReturnType<typeof useGamePointTypeLazyQuery>;
export type GamePointTypeQueryResult = Apollo.QueryResult<GamePointTypeQuery, GamePointTypeQueryVariables>;
export const AggregateGamePointTypeDocument = gql`
    query aggregateGamePointType($where: GamePointTypeWhereInput, $orderBy: [GamePointTypeOrderByWithRelationInput!], $cursor: GamePointTypeWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateGamePointType(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateGamePointTypeQuery__
 *
 * To run a query within a React component, call `useAggregateGamePointTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateGamePointTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateGamePointTypeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateGamePointTypeQuery(baseOptions?: Apollo.QueryHookOptions<AggregateGamePointTypeQuery, AggregateGamePointTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateGamePointTypeQuery, AggregateGamePointTypeQueryVariables>(AggregateGamePointTypeDocument, options);
      }
export function useAggregateGamePointTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateGamePointTypeQuery, AggregateGamePointTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateGamePointTypeQuery, AggregateGamePointTypeQueryVariables>(AggregateGamePointTypeDocument, options);
        }
export type AggregateGamePointTypeQueryHookResult = ReturnType<typeof useAggregateGamePointTypeQuery>;
export type AggregateGamePointTypeLazyQueryHookResult = ReturnType<typeof useAggregateGamePointTypeLazyQuery>;
export type AggregateGamePointTypeQueryResult = Apollo.QueryResult<AggregateGamePointTypeQuery, AggregateGamePointTypeQueryVariables>;
export const SearchActivitiesDocument = gql`
    query searchActivities($search: String!) {
  searchActivities(where: {title: {contains: $search, mode: insensitive}}) {
    id
    modelId
    title
    type
  }
}
    `;

/**
 * __useSearchActivitiesQuery__
 *
 * To run a query within a React component, call `useSearchActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchActivitiesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchActivitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchActivitiesQuery, SearchActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchActivitiesQuery, SearchActivitiesQueryVariables>(SearchActivitiesDocument, options);
      }
export function useSearchActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchActivitiesQuery, SearchActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchActivitiesQuery, SearchActivitiesQueryVariables>(SearchActivitiesDocument, options);
        }
export type SearchActivitiesQueryHookResult = ReturnType<typeof useSearchActivitiesQuery>;
export type SearchActivitiesLazyQueryHookResult = ReturnType<typeof useSearchActivitiesLazyQuery>;
export type SearchActivitiesQueryResult = Apollo.QueryResult<SearchActivitiesQuery, SearchActivitiesQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($data: ProductCreateInput!) {
  createProduct(data: $data) {
    id
    slug
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($data: ProductUpdateInput!, $where: ProductWhereUniqueInput!) {
  updateProduct(data: $data, where: $where) {
    id
    slug
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($where: ProductWhereUniqueInput!) {
  deleteProduct(where: $where) {
    id
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const ProductsDocument = gql`
    query products($where: ProductWhereInput, $orderBy: [ProductOrderByWithRelationInput!], $cursor: ProductWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ProductScalarFieldEnum!]) {
  products(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    slug
    description
    shortDescription
    price
    inCurrency
    skill {
      id
      name
      slug
    }
    standards {
      id
      key
      value
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductDocument = gql`
    query product($data: ProductWhereUniqueInput!) {
  product(where: $data) {
    id
    name
    slug
    description
    shortDescription
    skill {
      id
      name
      slug
    }
    standards {
      id
      key
      value
    }
    relatedActivities {
      id
    }
    relatedStoryActivities {
      id
    }
    age
    isVirtual
    price
    discountPrice
    inCurrency
    featuredImage
    metaTagData
    images
    video
    isActive
    isVisible
    isBestSelling
    isNewReleased
    purchaseLink
  }
}
    `;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const AggregateProductDocument = gql`
    query aggregateProduct($where: ProductWhereInput, $orderBy: [ProductOrderByWithRelationInput!], $cursor: ProductWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateProduct(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateProductQuery__
 *
 * To run a query within a React component, call `useAggregateProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateProductQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateProductQuery(baseOptions?: Apollo.QueryHookOptions<AggregateProductQuery, AggregateProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateProductQuery, AggregateProductQueryVariables>(AggregateProductDocument, options);
      }
export function useAggregateProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateProductQuery, AggregateProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateProductQuery, AggregateProductQueryVariables>(AggregateProductDocument, options);
        }
export type AggregateProductQueryHookResult = ReturnType<typeof useAggregateProductQuery>;
export type AggregateProductLazyQueryHookResult = ReturnType<typeof useAggregateProductLazyQuery>;
export type AggregateProductQueryResult = Apollo.QueryResult<AggregateProductQuery, AggregateProductQueryVariables>;
export const CreateProductBannerDocument = gql`
    mutation createProductBanner($data: ProductBannerCreateInput!) {
  createProductBanner(data: $data) {
    id
  }
}
    `;
export type CreateProductBannerMutationFn = Apollo.MutationFunction<CreateProductBannerMutation, CreateProductBannerMutationVariables>;

/**
 * __useCreateProductBannerMutation__
 *
 * To run a mutation, you first call `useCreateProductBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductBannerMutation, { data, loading, error }] = useCreateProductBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductBannerMutation, CreateProductBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductBannerMutation, CreateProductBannerMutationVariables>(CreateProductBannerDocument, options);
      }
export type CreateProductBannerMutationHookResult = ReturnType<typeof useCreateProductBannerMutation>;
export type CreateProductBannerMutationResult = Apollo.MutationResult<CreateProductBannerMutation>;
export type CreateProductBannerMutationOptions = Apollo.BaseMutationOptions<CreateProductBannerMutation, CreateProductBannerMutationVariables>;
export const UpdateProductBannerDocument = gql`
    mutation updateProductBanner($where: ProductBannerWhereUniqueInput!, $data: ProductBannerUpdateInput!) {
  updateProductBanner(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateProductBannerMutationFn = Apollo.MutationFunction<UpdateProductBannerMutation, UpdateProductBannerMutationVariables>;

/**
 * __useUpdateProductBannerMutation__
 *
 * To run a mutation, you first call `useUpdateProductBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductBannerMutation, { data, loading, error }] = useUpdateProductBannerMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductBannerMutation, UpdateProductBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductBannerMutation, UpdateProductBannerMutationVariables>(UpdateProductBannerDocument, options);
      }
export type UpdateProductBannerMutationHookResult = ReturnType<typeof useUpdateProductBannerMutation>;
export type UpdateProductBannerMutationResult = Apollo.MutationResult<UpdateProductBannerMutation>;
export type UpdateProductBannerMutationOptions = Apollo.BaseMutationOptions<UpdateProductBannerMutation, UpdateProductBannerMutationVariables>;
export const DeleteProductBannerDocument = gql`
    mutation deleteProductBanner($where: ProductBannerWhereUniqueInput!) {
  deleteProductBanner(where: $where) {
    id
  }
}
    `;
export type DeleteProductBannerMutationFn = Apollo.MutationFunction<DeleteProductBannerMutation, DeleteProductBannerMutationVariables>;

/**
 * __useDeleteProductBannerMutation__
 *
 * To run a mutation, you first call `useDeleteProductBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductBannerMutation, { data, loading, error }] = useDeleteProductBannerMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteProductBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductBannerMutation, DeleteProductBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductBannerMutation, DeleteProductBannerMutationVariables>(DeleteProductBannerDocument, options);
      }
export type DeleteProductBannerMutationHookResult = ReturnType<typeof useDeleteProductBannerMutation>;
export type DeleteProductBannerMutationResult = Apollo.MutationResult<DeleteProductBannerMutation>;
export type DeleteProductBannerMutationOptions = Apollo.BaseMutationOptions<DeleteProductBannerMutation, DeleteProductBannerMutationVariables>;
export const ProductBannersDocument = gql`
    query productBanners($where: ProductBannerWhereInput, $orderBy: [ProductBannerOrderByWithRelationInput!], $cursor: ProductBannerWhereUniqueInput, $distinct: [ProductBannerScalarFieldEnum!], $skip: Int, $take: Int) {
  productBanners(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `;

/**
 * __useProductBannersQuery__
 *
 * To run a query within a React component, call `useProductBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBannersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useProductBannersQuery(baseOptions?: Apollo.QueryHookOptions<ProductBannersQuery, ProductBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBannersQuery, ProductBannersQueryVariables>(ProductBannersDocument, options);
      }
export function useProductBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBannersQuery, ProductBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBannersQuery, ProductBannersQueryVariables>(ProductBannersDocument, options);
        }
export type ProductBannersQueryHookResult = ReturnType<typeof useProductBannersQuery>;
export type ProductBannersLazyQueryHookResult = ReturnType<typeof useProductBannersLazyQuery>;
export type ProductBannersQueryResult = Apollo.QueryResult<ProductBannersQuery, ProductBannersQueryVariables>;
export const ProductBannerDocument = gql`
    query productBanner($where: ProductBannerWhereUniqueInput!) {
  productBanner(where: $where) {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `;

/**
 * __useProductBannerQuery__
 *
 * To run a query within a React component, call `useProductBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductBannerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useProductBannerQuery(baseOptions: Apollo.QueryHookOptions<ProductBannerQuery, ProductBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBannerQuery, ProductBannerQueryVariables>(ProductBannerDocument, options);
      }
export function useProductBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBannerQuery, ProductBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBannerQuery, ProductBannerQueryVariables>(ProductBannerDocument, options);
        }
export type ProductBannerQueryHookResult = ReturnType<typeof useProductBannerQuery>;
export type ProductBannerLazyQueryHookResult = ReturnType<typeof useProductBannerLazyQuery>;
export type ProductBannerQueryResult = Apollo.QueryResult<ProductBannerQuery, ProductBannerQueryVariables>;
export const CreatePromoCodeDocument = gql`
    mutation createPromoCode($data: PromoCodeCreateInput!) {
  createPromoCode(data: $data) {
    id
  }
}
    `;
export type CreatePromoCodeMutationFn = Apollo.MutationFunction<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;

/**
 * __useCreatePromoCodeMutation__
 *
 * To run a mutation, you first call `useCreatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoCodeMutation, { data, loading, error }] = useCreatePromoCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>(CreatePromoCodeDocument, options);
      }
export type CreatePromoCodeMutationHookResult = ReturnType<typeof useCreatePromoCodeMutation>;
export type CreatePromoCodeMutationResult = Apollo.MutationResult<CreatePromoCodeMutation>;
export type CreatePromoCodeMutationOptions = Apollo.BaseMutationOptions<CreatePromoCodeMutation, CreatePromoCodeMutationVariables>;
export const UpdatePromoCodeDocument = gql`
    mutation updatePromoCode($data: PromoCodeUpdateInput!, $where: PromoCodeWhereUniqueInput!) {
  updatePromoCode(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdatePromoCodeMutationFn = Apollo.MutationFunction<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>;

/**
 * __useUpdatePromoCodeMutation__
 *
 * To run a mutation, you first call `useUpdatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoCodeMutation, { data, loading, error }] = useUpdatePromoCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>(UpdatePromoCodeDocument, options);
      }
export type UpdatePromoCodeMutationHookResult = ReturnType<typeof useUpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationResult = Apollo.MutationResult<UpdatePromoCodeMutation>;
export type UpdatePromoCodeMutationOptions = Apollo.BaseMutationOptions<UpdatePromoCodeMutation, UpdatePromoCodeMutationVariables>;
export const DeletePromoCodeDocument = gql`
    mutation deletePromoCode($where: PromoCodeWhereUniqueInput!) {
  deletePromoCode(where: $where) {
    id
  }
}
    `;
export type DeletePromoCodeMutationFn = Apollo.MutationFunction<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>;

/**
 * __useDeletePromoCodeMutation__
 *
 * To run a mutation, you first call `useDeletePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoCodeMutation, { data, loading, error }] = useDeletePromoCodeMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>(DeletePromoCodeDocument, options);
      }
export type DeletePromoCodeMutationHookResult = ReturnType<typeof useDeletePromoCodeMutation>;
export type DeletePromoCodeMutationResult = Apollo.MutationResult<DeletePromoCodeMutation>;
export type DeletePromoCodeMutationOptions = Apollo.BaseMutationOptions<DeletePromoCodeMutation, DeletePromoCodeMutationVariables>;
export const ApplyPromoCodeDocument = gql`
    mutation applyPromoCode($code: String!, $studentId: Int) {
  applyPromoCode(code: $code, studentId: $studentId) {
    success
    message
  }
}
    `;
export type ApplyPromoCodeMutationFn = Apollo.MutationFunction<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>;

/**
 * __useApplyPromoCodeMutation__
 *
 * To run a mutation, you first call `useApplyPromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyPromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyPromoCodeMutation, { data, loading, error }] = useApplyPromoCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      studentId: // value for 'studentId'
 *   },
 * });
 */
export function useApplyPromoCodeMutation(baseOptions?: Apollo.MutationHookOptions<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>(ApplyPromoCodeDocument, options);
      }
export type ApplyPromoCodeMutationHookResult = ReturnType<typeof useApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationResult = Apollo.MutationResult<ApplyPromoCodeMutation>;
export type ApplyPromoCodeMutationOptions = Apollo.BaseMutationOptions<ApplyPromoCodeMutation, ApplyPromoCodeMutationVariables>;
export const PromoCodesDocument = gql`
    query promoCodes($where: PromoCodeWhereInput, $orderBy: [PromoCodeOrderByWithRelationInput!], $cursor: PromoCodeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromoCodeScalarFieldEnum!]) {
  promoCodes(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    code
    startsAt
    expiresAt
    coins
    isActive
    createdAt
  }
}
    `;

/**
 * __usePromoCodesQuery__
 *
 * To run a query within a React component, call `usePromoCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePromoCodesQuery(baseOptions?: Apollo.QueryHookOptions<PromoCodesQuery, PromoCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodesQuery, PromoCodesQueryVariables>(PromoCodesDocument, options);
      }
export function usePromoCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodesQuery, PromoCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodesQuery, PromoCodesQueryVariables>(PromoCodesDocument, options);
        }
export type PromoCodesQueryHookResult = ReturnType<typeof usePromoCodesQuery>;
export type PromoCodesLazyQueryHookResult = ReturnType<typeof usePromoCodesLazyQuery>;
export type PromoCodesQueryResult = Apollo.QueryResult<PromoCodesQuery, PromoCodesQueryVariables>;
export const PromoCodeDocument = gql`
    query promoCode($where: PromoCodeWhereUniqueInput!) {
  promoCode(where: $where) {
    id
    code
    startsAt
    expiresAt
    coins
    activities {
      id
      title
    }
    storyActivities {
      id
      title
    }
    workshops {
      id
      name
    }
    isActive
    createdAt
  }
}
    `;

/**
 * __usePromoCodeQuery__
 *
 * To run a query within a React component, call `usePromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePromoCodeQuery(baseOptions: Apollo.QueryHookOptions<PromoCodeQuery, PromoCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeQuery, PromoCodeQueryVariables>(PromoCodeDocument, options);
      }
export function usePromoCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeQuery, PromoCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeQuery, PromoCodeQueryVariables>(PromoCodeDocument, options);
        }
export type PromoCodeQueryHookResult = ReturnType<typeof usePromoCodeQuery>;
export type PromoCodeLazyQueryHookResult = ReturnType<typeof usePromoCodeLazyQuery>;
export type PromoCodeQueryResult = Apollo.QueryResult<PromoCodeQuery, PromoCodeQueryVariables>;
export const AggregatePromoCodeDocument = gql`
    query aggregatePromoCode($where: PromoCodeWhereInput, $orderBy: [PromoCodeOrderByWithRelationInput!], $cursor: PromoCodeWhereUniqueInput, $take: Int, $skip: Int) {
  aggregatePromoCode(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregatePromoCodeQuery__
 *
 * To run a query within a React component, call `useAggregatePromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePromoCodeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregatePromoCodeQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePromoCodeQuery, AggregatePromoCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePromoCodeQuery, AggregatePromoCodeQueryVariables>(AggregatePromoCodeDocument, options);
      }
export function useAggregatePromoCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePromoCodeQuery, AggregatePromoCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePromoCodeQuery, AggregatePromoCodeQueryVariables>(AggregatePromoCodeDocument, options);
        }
export type AggregatePromoCodeQueryHookResult = ReturnType<typeof useAggregatePromoCodeQuery>;
export type AggregatePromoCodeLazyQueryHookResult = ReturnType<typeof useAggregatePromoCodeLazyQuery>;
export type AggregatePromoCodeQueryResult = Apollo.QueryResult<AggregatePromoCodeQuery, AggregatePromoCodeQueryVariables>;
export const CreatePromoCodeGroupDocument = gql`
    mutation createPromoCodeGroup($data: PromoCodeGroupCreateInput!) {
  createPromoCodeGroup(data: $data) {
    id
  }
}
    `;
export type CreatePromoCodeGroupMutationFn = Apollo.MutationFunction<CreatePromoCodeGroupMutation, CreatePromoCodeGroupMutationVariables>;

/**
 * __useCreatePromoCodeGroupMutation__
 *
 * To run a mutation, you first call `useCreatePromoCodeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePromoCodeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPromoCodeGroupMutation, { data, loading, error }] = useCreatePromoCodeGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePromoCodeGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreatePromoCodeGroupMutation, CreatePromoCodeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePromoCodeGroupMutation, CreatePromoCodeGroupMutationVariables>(CreatePromoCodeGroupDocument, options);
      }
export type CreatePromoCodeGroupMutationHookResult = ReturnType<typeof useCreatePromoCodeGroupMutation>;
export type CreatePromoCodeGroupMutationResult = Apollo.MutationResult<CreatePromoCodeGroupMutation>;
export type CreatePromoCodeGroupMutationOptions = Apollo.BaseMutationOptions<CreatePromoCodeGroupMutation, CreatePromoCodeGroupMutationVariables>;
export const UpdatePromoCodeGroupDocument = gql`
    mutation updatePromoCodeGroup($data: PromoCodeGroupUpdateInput!, $where: PromoCodeGroupWhereUniqueInput!) {
  updatePromoCodeGroup(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdatePromoCodeGroupMutationFn = Apollo.MutationFunction<UpdatePromoCodeGroupMutation, UpdatePromoCodeGroupMutationVariables>;

/**
 * __useUpdatePromoCodeGroupMutation__
 *
 * To run a mutation, you first call `useUpdatePromoCodeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePromoCodeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePromoCodeGroupMutation, { data, loading, error }] = useUpdatePromoCodeGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdatePromoCodeGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePromoCodeGroupMutation, UpdatePromoCodeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePromoCodeGroupMutation, UpdatePromoCodeGroupMutationVariables>(UpdatePromoCodeGroupDocument, options);
      }
export type UpdatePromoCodeGroupMutationHookResult = ReturnType<typeof useUpdatePromoCodeGroupMutation>;
export type UpdatePromoCodeGroupMutationResult = Apollo.MutationResult<UpdatePromoCodeGroupMutation>;
export type UpdatePromoCodeGroupMutationOptions = Apollo.BaseMutationOptions<UpdatePromoCodeGroupMutation, UpdatePromoCodeGroupMutationVariables>;
export const DeletePromoCodeGroupDocument = gql`
    mutation deletePromoCodeGroup($where: PromoCodeGroupWhereUniqueInput!) {
  deletePromoCodeGroup(where: $where) {
    id
  }
}
    `;
export type DeletePromoCodeGroupMutationFn = Apollo.MutationFunction<DeletePromoCodeGroupMutation, DeletePromoCodeGroupMutationVariables>;

/**
 * __useDeletePromoCodeGroupMutation__
 *
 * To run a mutation, you first call `useDeletePromoCodeGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePromoCodeGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePromoCodeGroupMutation, { data, loading, error }] = useDeletePromoCodeGroupMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePromoCodeGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeletePromoCodeGroupMutation, DeletePromoCodeGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePromoCodeGroupMutation, DeletePromoCodeGroupMutationVariables>(DeletePromoCodeGroupDocument, options);
      }
export type DeletePromoCodeGroupMutationHookResult = ReturnType<typeof useDeletePromoCodeGroupMutation>;
export type DeletePromoCodeGroupMutationResult = Apollo.MutationResult<DeletePromoCodeGroupMutation>;
export type DeletePromoCodeGroupMutationOptions = Apollo.BaseMutationOptions<DeletePromoCodeGroupMutation, DeletePromoCodeGroupMutationVariables>;
export const PromoCodeGroupsDocument = gql`
    query promoCodeGroups($where: PromoCodeGroupWhereInput, $orderBy: [PromoCodeGroupOrderByWithRelationInput!], $cursor: PromoCodeGroupWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromoCodeGroupScalarFieldEnum!]) {
  promoCodeGroups(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    limit
    promoCodeToPromoCodeGroups {
      promoCode {
        id
        code
      }
    }
  }
}
    `;

/**
 * __usePromoCodeGroupsQuery__
 *
 * To run a query within a React component, call `usePromoCodeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeGroupsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePromoCodeGroupsQuery(baseOptions?: Apollo.QueryHookOptions<PromoCodeGroupsQuery, PromoCodeGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeGroupsQuery, PromoCodeGroupsQueryVariables>(PromoCodeGroupsDocument, options);
      }
export function usePromoCodeGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeGroupsQuery, PromoCodeGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeGroupsQuery, PromoCodeGroupsQueryVariables>(PromoCodeGroupsDocument, options);
        }
export type PromoCodeGroupsQueryHookResult = ReturnType<typeof usePromoCodeGroupsQuery>;
export type PromoCodeGroupsLazyQueryHookResult = ReturnType<typeof usePromoCodeGroupsLazyQuery>;
export type PromoCodeGroupsQueryResult = Apollo.QueryResult<PromoCodeGroupsQuery, PromoCodeGroupsQueryVariables>;
export const PromoCodeGroupDocument = gql`
    query promoCodeGroup($where: PromoCodeGroupWhereUniqueInput!) {
  promoCodeGroup(where: $where) {
    id
    name
    limit
    promoCodeToPromoCodeGroups {
      promoCode {
        id
        code
      }
    }
  }
}
    `;

/**
 * __usePromoCodeGroupQuery__
 *
 * To run a query within a React component, call `usePromoCodeGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeGroupQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePromoCodeGroupQuery(baseOptions: Apollo.QueryHookOptions<PromoCodeGroupQuery, PromoCodeGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeGroupQuery, PromoCodeGroupQueryVariables>(PromoCodeGroupDocument, options);
      }
export function usePromoCodeGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeGroupQuery, PromoCodeGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeGroupQuery, PromoCodeGroupQueryVariables>(PromoCodeGroupDocument, options);
        }
export type PromoCodeGroupQueryHookResult = ReturnType<typeof usePromoCodeGroupQuery>;
export type PromoCodeGroupLazyQueryHookResult = ReturnType<typeof usePromoCodeGroupLazyQuery>;
export type PromoCodeGroupQueryResult = Apollo.QueryResult<PromoCodeGroupQuery, PromoCodeGroupQueryVariables>;
export const AggregatePromoCodeGroupDocument = gql`
    query aggregatePromoCodeGroup($where: PromoCodeGroupWhereInput, $orderBy: [PromoCodeGroupOrderByWithRelationInput!], $cursor: PromoCodeGroupWhereUniqueInput, $take: Int, $skip: Int) {
  aggregatePromoCodeGroup(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregatePromoCodeGroupQuery__
 *
 * To run a query within a React component, call `useAggregatePromoCodeGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePromoCodeGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePromoCodeGroupQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregatePromoCodeGroupQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePromoCodeGroupQuery, AggregatePromoCodeGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePromoCodeGroupQuery, AggregatePromoCodeGroupQueryVariables>(AggregatePromoCodeGroupDocument, options);
      }
export function useAggregatePromoCodeGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePromoCodeGroupQuery, AggregatePromoCodeGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePromoCodeGroupQuery, AggregatePromoCodeGroupQueryVariables>(AggregatePromoCodeGroupDocument, options);
        }
export type AggregatePromoCodeGroupQueryHookResult = ReturnType<typeof useAggregatePromoCodeGroupQuery>;
export type AggregatePromoCodeGroupLazyQueryHookResult = ReturnType<typeof useAggregatePromoCodeGroupLazyQuery>;
export type AggregatePromoCodeGroupQueryResult = Apollo.QueryResult<AggregatePromoCodeGroupQuery, AggregatePromoCodeGroupQueryVariables>;
export const PromoCodeHistoriesDocument = gql`
    query promoCodeHistories($where: PromoCodeHistoryWhereInput, $orderBy: [PromoCodeHistoryOrderByWithRelationInput!], $cursor: PromoCodeHistoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [PromoCodeHistoryScalarFieldEnum!]) {
  promoCodeHistories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    promoCode {
      id
      code
    }
    student {
      id
      firstName
      lastName
      phoneNumber
    }
    coins
    createdAt
  }
}
    `;

/**
 * __usePromoCodeHistoriesQuery__
 *
 * To run a query within a React component, call `usePromoCodeHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeHistoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function usePromoCodeHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<PromoCodeHistoriesQuery, PromoCodeHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeHistoriesQuery, PromoCodeHistoriesQueryVariables>(PromoCodeHistoriesDocument, options);
      }
export function usePromoCodeHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeHistoriesQuery, PromoCodeHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeHistoriesQuery, PromoCodeHistoriesQueryVariables>(PromoCodeHistoriesDocument, options);
        }
export type PromoCodeHistoriesQueryHookResult = ReturnType<typeof usePromoCodeHistoriesQuery>;
export type PromoCodeHistoriesLazyQueryHookResult = ReturnType<typeof usePromoCodeHistoriesLazyQuery>;
export type PromoCodeHistoriesQueryResult = Apollo.QueryResult<PromoCodeHistoriesQuery, PromoCodeHistoriesQueryVariables>;
export const PromoCodeHistoryDocument = gql`
    query promoCodeHistory($where: PromoCodeHistoryWhereUniqueInput!) {
  promoCodeHistory(where: $where) {
    id
    promoCode {
      id
      code
    }
    student {
      id
      firstName
      lastName
      phoneNumber
    }
    coins
    createdAt
  }
}
    `;

/**
 * __usePromoCodeHistoryQuery__
 *
 * To run a query within a React component, call `usePromoCodeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePromoCodeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePromoCodeHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function usePromoCodeHistoryQuery(baseOptions: Apollo.QueryHookOptions<PromoCodeHistoryQuery, PromoCodeHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PromoCodeHistoryQuery, PromoCodeHistoryQueryVariables>(PromoCodeHistoryDocument, options);
      }
export function usePromoCodeHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PromoCodeHistoryQuery, PromoCodeHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PromoCodeHistoryQuery, PromoCodeHistoryQueryVariables>(PromoCodeHistoryDocument, options);
        }
export type PromoCodeHistoryQueryHookResult = ReturnType<typeof usePromoCodeHistoryQuery>;
export type PromoCodeHistoryLazyQueryHookResult = ReturnType<typeof usePromoCodeHistoryLazyQuery>;
export type PromoCodeHistoryQueryResult = Apollo.QueryResult<PromoCodeHistoryQuery, PromoCodeHistoryQueryVariables>;
export const AggregatePromoCodeHistoryDocument = gql`
    query aggregatePromoCodeHistory($where: PromoCodeHistoryWhereInput, $orderBy: [PromoCodeHistoryOrderByWithRelationInput!], $cursor: PromoCodeHistoryWhereUniqueInput, $take: Int, $skip: Int) {
  aggregatePromoCodeHistory(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregatePromoCodeHistoryQuery__
 *
 * To run a query within a React component, call `useAggregatePromoCodeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregatePromoCodeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregatePromoCodeHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregatePromoCodeHistoryQuery(baseOptions?: Apollo.QueryHookOptions<AggregatePromoCodeHistoryQuery, AggregatePromoCodeHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregatePromoCodeHistoryQuery, AggregatePromoCodeHistoryQueryVariables>(AggregatePromoCodeHistoryDocument, options);
      }
export function useAggregatePromoCodeHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregatePromoCodeHistoryQuery, AggregatePromoCodeHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregatePromoCodeHistoryQuery, AggregatePromoCodeHistoryQueryVariables>(AggregatePromoCodeHistoryDocument, options);
        }
export type AggregatePromoCodeHistoryQueryHookResult = ReturnType<typeof useAggregatePromoCodeHistoryQuery>;
export type AggregatePromoCodeHistoryLazyQueryHookResult = ReturnType<typeof useAggregatePromoCodeHistoryLazyQuery>;
export type AggregatePromoCodeHistoryQueryResult = Apollo.QueryResult<AggregatePromoCodeHistoryQuery, AggregatePromoCodeHistoryQueryVariables>;
export const CreateReferralCodeDocument = gql`
    mutation createReferralCode($data: ReferralCodeCreateInput!) {
  createReferralCode(data: $data) {
    id
  }
}
    `;
export type CreateReferralCodeMutationFn = Apollo.MutationFunction<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>;

/**
 * __useCreateReferralCodeMutation__
 *
 * To run a mutation, you first call `useCreateReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralCodeMutation, { data, loading, error }] = useCreateReferralCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReferralCodeMutation(baseOptions?: Apollo.MutationHookOptions<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>(CreateReferralCodeDocument, options);
      }
export type CreateReferralCodeMutationHookResult = ReturnType<typeof useCreateReferralCodeMutation>;
export type CreateReferralCodeMutationResult = Apollo.MutationResult<CreateReferralCodeMutation>;
export type CreateReferralCodeMutationOptions = Apollo.BaseMutationOptions<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>;
export const UpdateReferralCodeDocument = gql`
    mutation updateReferralCode($data: ReferralCodeUpdateInput!, $where: ReferralCodeWhereUniqueInput!) {
  updateReferralCode(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateReferralCodeMutationFn = Apollo.MutationFunction<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>;

/**
 * __useUpdateReferralCodeMutation__
 *
 * To run a mutation, you first call `useUpdateReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferralCodeMutation, { data, loading, error }] = useUpdateReferralCodeMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateReferralCodeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>(UpdateReferralCodeDocument, options);
      }
export type UpdateReferralCodeMutationHookResult = ReturnType<typeof useUpdateReferralCodeMutation>;
export type UpdateReferralCodeMutationResult = Apollo.MutationResult<UpdateReferralCodeMutation>;
export type UpdateReferralCodeMutationOptions = Apollo.BaseMutationOptions<UpdateReferralCodeMutation, UpdateReferralCodeMutationVariables>;
export const DeleteReferralCodeDocument = gql`
    mutation deleteReferralCode($where: ReferralCodeWhereUniqueInput!) {
  deleteReferralCode(where: $where) {
    id
  }
}
    `;
export type DeleteReferralCodeMutationFn = Apollo.MutationFunction<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>;

/**
 * __useDeleteReferralCodeMutation__
 *
 * To run a mutation, you first call `useDeleteReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReferralCodeMutation, { data, loading, error }] = useDeleteReferralCodeMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteReferralCodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>(DeleteReferralCodeDocument, options);
      }
export type DeleteReferralCodeMutationHookResult = ReturnType<typeof useDeleteReferralCodeMutation>;
export type DeleteReferralCodeMutationResult = Apollo.MutationResult<DeleteReferralCodeMutation>;
export type DeleteReferralCodeMutationOptions = Apollo.BaseMutationOptions<DeleteReferralCodeMutation, DeleteReferralCodeMutationVariables>;
export const ReferralCodesDocument = gql`
    query referralCodes($where: ReferralCodeWhereInput, $orderBy: [ReferralCodeOrderByWithRelationInput!], $cursor: ReferralCodeWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ReferralCodeScalarFieldEnum!]) {
  referralCodes(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    code
    student {
      id
      firstName
      lastName
      phoneNumber
    }
    inviteeCoins
    referralCoins
    isActive
    createdAt
  }
}
    `;

/**
 * __useReferralCodesQuery__
 *
 * To run a query within a React component, call `useReferralCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useReferralCodesQuery(baseOptions?: Apollo.QueryHookOptions<ReferralCodesQuery, ReferralCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralCodesQuery, ReferralCodesQueryVariables>(ReferralCodesDocument, options);
      }
export function useReferralCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodesQuery, ReferralCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralCodesQuery, ReferralCodesQueryVariables>(ReferralCodesDocument, options);
        }
export type ReferralCodesQueryHookResult = ReturnType<typeof useReferralCodesQuery>;
export type ReferralCodesLazyQueryHookResult = ReturnType<typeof useReferralCodesLazyQuery>;
export type ReferralCodesQueryResult = Apollo.QueryResult<ReferralCodesQuery, ReferralCodesQueryVariables>;
export const ReferralCodeDocument = gql`
    query referralCode($where: ReferralCodeWhereUniqueInput!) {
  referralCode(where: $where) {
    id
    code
    student {
      id
      firstName
      lastName
      phoneNumber
    }
    inviteeCoins
    referralCoins
    isActive
    createdAt
  }
}
    `;

/**
 * __useReferralCodeQuery__
 *
 * To run a query within a React component, call `useReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReferralCodeQuery(baseOptions: Apollo.QueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(ReferralCodeDocument, options);
      }
export function useReferralCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(ReferralCodeDocument, options);
        }
export type ReferralCodeQueryHookResult = ReturnType<typeof useReferralCodeQuery>;
export type ReferralCodeLazyQueryHookResult = ReturnType<typeof useReferralCodeLazyQuery>;
export type ReferralCodeQueryResult = Apollo.QueryResult<ReferralCodeQuery, ReferralCodeQueryVariables>;
export const AggregateReferralCodeDocument = gql`
    query aggregateReferralCode($where: ReferralCodeWhereInput, $orderBy: [ReferralCodeOrderByWithRelationInput!], $cursor: ReferralCodeWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateReferralCode(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateReferralCodeQuery__
 *
 * To run a query within a React component, call `useAggregateReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateReferralCodeQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateReferralCodeQuery(baseOptions?: Apollo.QueryHookOptions<AggregateReferralCodeQuery, AggregateReferralCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateReferralCodeQuery, AggregateReferralCodeQueryVariables>(AggregateReferralCodeDocument, options);
      }
export function useAggregateReferralCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateReferralCodeQuery, AggregateReferralCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateReferralCodeQuery, AggregateReferralCodeQueryVariables>(AggregateReferralCodeDocument, options);
        }
export type AggregateReferralCodeQueryHookResult = ReturnType<typeof useAggregateReferralCodeQuery>;
export type AggregateReferralCodeLazyQueryHookResult = ReturnType<typeof useAggregateReferralCodeLazyQuery>;
export type AggregateReferralCodeQueryResult = Apollo.QueryResult<AggregateReferralCodeQuery, AggregateReferralCodeQueryVariables>;
export const ReferralCodeHistoriesDocument = gql`
    query referralCodeHistories($where: ReferralCodeHistoryWhereInput, $orderBy: [ReferralCodeHistoryOrderByWithRelationInput!], $cursor: ReferralCodeHistoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ReferralCodeHistoryScalarFieldEnum!]) {
  referralCodeHistories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    referralCode
    referralStudent {
      id
      phoneNumber
    }
    invitedStudent {
      id
      phoneNumber
    }
    inviteeCoins
    referralCoins
    createdAt
  }
}
    `;

/**
 * __useReferralCodeHistoriesQuery__
 *
 * To run a query within a React component, call `useReferralCodeHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeHistoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useReferralCodeHistoriesQuery(baseOptions?: Apollo.QueryHookOptions<ReferralCodeHistoriesQuery, ReferralCodeHistoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralCodeHistoriesQuery, ReferralCodeHistoriesQueryVariables>(ReferralCodeHistoriesDocument, options);
      }
export function useReferralCodeHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeHistoriesQuery, ReferralCodeHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralCodeHistoriesQuery, ReferralCodeHistoriesQueryVariables>(ReferralCodeHistoriesDocument, options);
        }
export type ReferralCodeHistoriesQueryHookResult = ReturnType<typeof useReferralCodeHistoriesQuery>;
export type ReferralCodeHistoriesLazyQueryHookResult = ReturnType<typeof useReferralCodeHistoriesLazyQuery>;
export type ReferralCodeHistoriesQueryResult = Apollo.QueryResult<ReferralCodeHistoriesQuery, ReferralCodeHistoriesQueryVariables>;
export const ReferralCodeHistoryDocument = gql`
    query referralCodeHistory($where: ReferralCodeHistoryWhereUniqueInput!) {
  referralCodeHistory(where: $where) {
    id
    referralCode
    referralStudent {
      id
      phoneNumber
    }
    invitedStudent {
      id
      phoneNumber
    }
    inviteeCoins
    referralCoins
    createdAt
  }
}
    `;

/**
 * __useReferralCodeHistoryQuery__
 *
 * To run a query within a React component, call `useReferralCodeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReferralCodeHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReferralCodeHistoryQuery, ReferralCodeHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReferralCodeHistoryQuery, ReferralCodeHistoryQueryVariables>(ReferralCodeHistoryDocument, options);
      }
export function useReferralCodeHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeHistoryQuery, ReferralCodeHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReferralCodeHistoryQuery, ReferralCodeHistoryQueryVariables>(ReferralCodeHistoryDocument, options);
        }
export type ReferralCodeHistoryQueryHookResult = ReturnType<typeof useReferralCodeHistoryQuery>;
export type ReferralCodeHistoryLazyQueryHookResult = ReturnType<typeof useReferralCodeHistoryLazyQuery>;
export type ReferralCodeHistoryQueryResult = Apollo.QueryResult<ReferralCodeHistoryQuery, ReferralCodeHistoryQueryVariables>;
export const AggregateReferralCodeHistoryDocument = gql`
    query aggregateReferralCodeHistory($where: ReferralCodeHistoryWhereInput, $orderBy: [ReferralCodeHistoryOrderByWithRelationInput!], $cursor: ReferralCodeHistoryWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateReferralCodeHistory(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateReferralCodeHistoryQuery__
 *
 * To run a query within a React component, call `useAggregateReferralCodeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateReferralCodeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateReferralCodeHistoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateReferralCodeHistoryQuery(baseOptions?: Apollo.QueryHookOptions<AggregateReferralCodeHistoryQuery, AggregateReferralCodeHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateReferralCodeHistoryQuery, AggregateReferralCodeHistoryQueryVariables>(AggregateReferralCodeHistoryDocument, options);
      }
export function useAggregateReferralCodeHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateReferralCodeHistoryQuery, AggregateReferralCodeHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateReferralCodeHistoryQuery, AggregateReferralCodeHistoryQueryVariables>(AggregateReferralCodeHistoryDocument, options);
        }
export type AggregateReferralCodeHistoryQueryHookResult = ReturnType<typeof useAggregateReferralCodeHistoryQuery>;
export type AggregateReferralCodeHistoryLazyQueryHookResult = ReturnType<typeof useAggregateReferralCodeHistoryLazyQuery>;
export type AggregateReferralCodeHistoryQueryResult = Apollo.QueryResult<AggregateReferralCodeHistoryQuery, AggregateReferralCodeHistoryQueryVariables>;
export const CreateReportDocument = gql`
    mutation createReport($data: ReportCreateInput!) {
  createReport(data: $data) {
    id
  }
}
    `;
export type CreateReportMutationFn = Apollo.MutationFunction<CreateReportMutation, CreateReportMutationVariables>;

/**
 * __useCreateReportMutation__
 *
 * To run a mutation, you first call `useCreateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportMutation, { data, loading, error }] = useCreateReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportMutation, CreateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportMutation, CreateReportMutationVariables>(CreateReportDocument, options);
      }
export type CreateReportMutationHookResult = ReturnType<typeof useCreateReportMutation>;
export type CreateReportMutationResult = Apollo.MutationResult<CreateReportMutation>;
export type CreateReportMutationOptions = Apollo.BaseMutationOptions<CreateReportMutation, CreateReportMutationVariables>;
export const UpdateReportDocument = gql`
    mutation updateReport($data: ReportUpdateInput!, $where: ReportWhereUniqueInput!) {
  updateReport(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateReportMutationFn = Apollo.MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const DeleteReportDocument = gql`
    mutation deleteReport($where: ReportWhereUniqueInput!) {
  deleteReport(where: $where) {
    id
  }
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const ReportsDocument = gql`
    query reports($where: ReportWhereInput, $orderBy: [ReportOrderByWithRelationInput!], $cursor: ReportWhereUniqueInput, $take: Int, $skip: Int, $distinct: [ReportScalarFieldEnum!]) {
  reports(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    title
    description
    status
    attachment
    displayOrder
    standard {
      id
      key
    }
    school {
      id
      name
    }
    createdAt
  }
}
    `;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ReportDocument = gql`
    query report($where: ReportWhereUniqueInput!) {
  report(where: $where) {
    id
    title
    description
    status
    attachment
    standard {
      id
      key
    }
    school {
      id
      name
    }
    displayOrder
    createdAt
  }
}
    `;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const AggregateReportDocument = gql`
    query aggregateReport($where: ReportWhereInput, $orderBy: [ReportOrderByWithRelationInput!], $cursor: ReportWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateReport(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateReportQuery__
 *
 * To run a query within a React component, call `useAggregateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateReportQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateReportQuery(baseOptions?: Apollo.QueryHookOptions<AggregateReportQuery, AggregateReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateReportQuery, AggregateReportQueryVariables>(AggregateReportDocument, options);
      }
export function useAggregateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateReportQuery, AggregateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateReportQuery, AggregateReportQueryVariables>(AggregateReportDocument, options);
        }
export type AggregateReportQueryHookResult = ReturnType<typeof useAggregateReportQuery>;
export type AggregateReportLazyQueryHookResult = ReturnType<typeof useAggregateReportLazyQuery>;
export type AggregateReportQueryResult = Apollo.QueryResult<AggregateReportQuery, AggregateReportQueryVariables>;
export const CreateSamskaraDocument = gql`
    mutation createSamskara($data: SamskaraCreateInput!) {
  createSamskara(data: $data) {
    id
  }
}
    `;
export type CreateSamskaraMutationFn = Apollo.MutationFunction<CreateSamskaraMutation, CreateSamskaraMutationVariables>;

/**
 * __useCreateSamskaraMutation__
 *
 * To run a mutation, you first call `useCreateSamskaraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSamskaraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSamskaraMutation, { data, loading, error }] = useCreateSamskaraMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSamskaraMutation(baseOptions?: Apollo.MutationHookOptions<CreateSamskaraMutation, CreateSamskaraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSamskaraMutation, CreateSamskaraMutationVariables>(CreateSamskaraDocument, options);
      }
export type CreateSamskaraMutationHookResult = ReturnType<typeof useCreateSamskaraMutation>;
export type CreateSamskaraMutationResult = Apollo.MutationResult<CreateSamskaraMutation>;
export type CreateSamskaraMutationOptions = Apollo.BaseMutationOptions<CreateSamskaraMutation, CreateSamskaraMutationVariables>;
export const UpdateSamskaraDocument = gql`
    mutation updateSamskara($data: SamskaraUpdateInput!, $where: SamskaraWhereUniqueInput!) {
  updateSamskara(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateSamskaraMutationFn = Apollo.MutationFunction<UpdateSamskaraMutation, UpdateSamskaraMutationVariables>;

/**
 * __useUpdateSamskaraMutation__
 *
 * To run a mutation, you first call `useUpdateSamskaraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSamskaraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSamskaraMutation, { data, loading, error }] = useUpdateSamskaraMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSamskaraMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSamskaraMutation, UpdateSamskaraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSamskaraMutation, UpdateSamskaraMutationVariables>(UpdateSamskaraDocument, options);
      }
export type UpdateSamskaraMutationHookResult = ReturnType<typeof useUpdateSamskaraMutation>;
export type UpdateSamskaraMutationResult = Apollo.MutationResult<UpdateSamskaraMutation>;
export type UpdateSamskaraMutationOptions = Apollo.BaseMutationOptions<UpdateSamskaraMutation, UpdateSamskaraMutationVariables>;
export const DeleteSamskaraDocument = gql`
    mutation deleteSamskara($where: SamskaraWhereUniqueInput!) {
  deleteSamskara(where: $where) {
    id
  }
}
    `;
export type DeleteSamskaraMutationFn = Apollo.MutationFunction<DeleteSamskaraMutation, DeleteSamskaraMutationVariables>;

/**
 * __useDeleteSamskaraMutation__
 *
 * To run a mutation, you first call `useDeleteSamskaraMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSamskaraMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSamskaraMutation, { data, loading, error }] = useDeleteSamskaraMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSamskaraMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSamskaraMutation, DeleteSamskaraMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSamskaraMutation, DeleteSamskaraMutationVariables>(DeleteSamskaraDocument, options);
      }
export type DeleteSamskaraMutationHookResult = ReturnType<typeof useDeleteSamskaraMutation>;
export type DeleteSamskaraMutationResult = Apollo.MutationResult<DeleteSamskaraMutation>;
export type DeleteSamskaraMutationOptions = Apollo.BaseMutationOptions<DeleteSamskaraMutation, DeleteSamskaraMutationVariables>;
export const SamskarasDocument = gql`
    query samskaras($where: SamskaraWhereInput, $orderBy: [SamskaraOrderByWithRelationInput!], $cursor: SamskaraWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SamskaraScalarFieldEnum!]) {
  samskaras(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    title
    description
    attachment
    status
    video
    category {
      id
      value
    }
    standard {
      id
      value
    }
    school {
      id
      name
    }
    displayOrder
    createdAt
  }
}
    `;

/**
 * __useSamskarasQuery__
 *
 * To run a query within a React component, call `useSamskarasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamskarasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamskarasQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSamskarasQuery(baseOptions?: Apollo.QueryHookOptions<SamskarasQuery, SamskarasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SamskarasQuery, SamskarasQueryVariables>(SamskarasDocument, options);
      }
export function useSamskarasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SamskarasQuery, SamskarasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SamskarasQuery, SamskarasQueryVariables>(SamskarasDocument, options);
        }
export type SamskarasQueryHookResult = ReturnType<typeof useSamskarasQuery>;
export type SamskarasLazyQueryHookResult = ReturnType<typeof useSamskarasLazyQuery>;
export type SamskarasQueryResult = Apollo.QueryResult<SamskarasQuery, SamskarasQueryVariables>;
export const SamskaraDocument = gql`
    query samskara($where: SamskaraWhereUniqueInput!) {
  samskara(where: $where) {
    id
    title
    description
    attachment
    status
    video
    category {
      id
      value
    }
    standard {
      id
      value
    }
    school {
      id
      name
    }
    displayOrder
    createdAt
  }
}
    `;

/**
 * __useSamskaraQuery__
 *
 * To run a query within a React component, call `useSamskaraQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamskaraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamskaraQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSamskaraQuery(baseOptions: Apollo.QueryHookOptions<SamskaraQuery, SamskaraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SamskaraQuery, SamskaraQueryVariables>(SamskaraDocument, options);
      }
export function useSamskaraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SamskaraQuery, SamskaraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SamskaraQuery, SamskaraQueryVariables>(SamskaraDocument, options);
        }
export type SamskaraQueryHookResult = ReturnType<typeof useSamskaraQuery>;
export type SamskaraLazyQueryHookResult = ReturnType<typeof useSamskaraLazyQuery>;
export type SamskaraQueryResult = Apollo.QueryResult<SamskaraQuery, SamskaraQueryVariables>;
export const AggregateSamskaraDocument = gql`
    query aggregateSamskara($where: SamskaraWhereInput, $orderBy: [SamskaraOrderByWithRelationInput!], $cursor: SamskaraWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateSamskara(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateSamskaraQuery__
 *
 * To run a query within a React component, call `useAggregateSamskaraQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateSamskaraQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateSamskaraQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateSamskaraQuery(baseOptions?: Apollo.QueryHookOptions<AggregateSamskaraQuery, AggregateSamskaraQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateSamskaraQuery, AggregateSamskaraQueryVariables>(AggregateSamskaraDocument, options);
      }
export function useAggregateSamskaraLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateSamskaraQuery, AggregateSamskaraQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateSamskaraQuery, AggregateSamskaraQueryVariables>(AggregateSamskaraDocument, options);
        }
export type AggregateSamskaraQueryHookResult = ReturnType<typeof useAggregateSamskaraQuery>;
export type AggregateSamskaraLazyQueryHookResult = ReturnType<typeof useAggregateSamskaraLazyQuery>;
export type AggregateSamskaraQueryResult = Apollo.QueryResult<AggregateSamskaraQuery, AggregateSamskaraQueryVariables>;
export const SamskaraCategoriesDocument = gql`
    query samskaraCategories($where: SamskaraCategoryWhereInput, $orderBy: [SamskaraCategoryOrderByWithRelationInput!], $cursor: SamskaraCategoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SamskaraCategoryScalarFieldEnum!]) {
  samskaraCategories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    key
    image
    value
  }
}
    `;

/**
 * __useSamskaraCategoriesQuery__
 *
 * To run a query within a React component, call `useSamskaraCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSamskaraCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSamskaraCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSamskaraCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SamskaraCategoriesQuery, SamskaraCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SamskaraCategoriesQuery, SamskaraCategoriesQueryVariables>(SamskaraCategoriesDocument, options);
      }
export function useSamskaraCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SamskaraCategoriesQuery, SamskaraCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SamskaraCategoriesQuery, SamskaraCategoriesQueryVariables>(SamskaraCategoriesDocument, options);
        }
export type SamskaraCategoriesQueryHookResult = ReturnType<typeof useSamskaraCategoriesQuery>;
export type SamskaraCategoriesLazyQueryHookResult = ReturnType<typeof useSamskaraCategoriesLazyQuery>;
export type SamskaraCategoriesQueryResult = Apollo.QueryResult<SamskaraCategoriesQuery, SamskaraCategoriesQueryVariables>;
export const CreateSchoolDocument = gql`
    mutation createSchool($data: SchoolCreateInput!) {
  createSchool(data: $data) {
    id
  }
}
    `;
export type CreateSchoolMutationFn = Apollo.MutationFunction<CreateSchoolMutation, CreateSchoolMutationVariables>;

/**
 * __useCreateSchoolMutation__
 *
 * To run a mutation, you first call `useCreateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolMutation, { data, loading, error }] = useCreateSchoolMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolMutation, CreateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolMutation, CreateSchoolMutationVariables>(CreateSchoolDocument, options);
      }
export type CreateSchoolMutationHookResult = ReturnType<typeof useCreateSchoolMutation>;
export type CreateSchoolMutationResult = Apollo.MutationResult<CreateSchoolMutation>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<CreateSchoolMutation, CreateSchoolMutationVariables>;
export const UpdateSchoolDocument = gql`
    mutation updateSchool($data: SchoolUpdateInput!, $where: SchoolWhereUniqueInput!) {
  updateSchool(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<UpdateSchoolMutation, UpdateSchoolMutationVariables>;

/**
 * __useUpdateSchoolMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolMutation, { data, loading, error }] = useUpdateSchoolMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSchoolMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolMutation, UpdateSchoolMutationVariables>(UpdateSchoolDocument, options);
      }
export type UpdateSchoolMutationHookResult = ReturnType<typeof useUpdateSchoolMutation>;
export type UpdateSchoolMutationResult = Apollo.MutationResult<UpdateSchoolMutation>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolMutation, UpdateSchoolMutationVariables>;
export const DeleteSchoolDocument = gql`
    mutation deleteSchool($where: SchoolWhereUniqueInput!) {
  deleteSchool(where: $where) {
    id
  }
}
    `;
export type DeleteSchoolMutationFn = Apollo.MutationFunction<DeleteSchoolMutation, DeleteSchoolMutationVariables>;

/**
 * __useDeleteSchoolMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolMutation, { data, loading, error }] = useDeleteSchoolMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSchoolMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolMutation, DeleteSchoolMutationVariables>(DeleteSchoolDocument, options);
      }
export type DeleteSchoolMutationHookResult = ReturnType<typeof useDeleteSchoolMutation>;
export type DeleteSchoolMutationResult = Apollo.MutationResult<DeleteSchoolMutation>;
export type DeleteSchoolMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolMutation, DeleteSchoolMutationVariables>;
export const SchoolsDocument = gql`
    query schools($where: SchoolWhereInput, $orderBy: [SchoolOrderByWithRelationInput!], $cursor: SchoolWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SchoolScalarFieldEnum!]) {
  schools(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    code
    group
    createdAt
  }
}
    `;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
      }
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
        }
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const SchoolDocument = gql`
    query school($where: SchoolWhereUniqueInput!) {
  school(where: $where) {
    id
    name
    code
    group
    area
    city
    state
    country
    createdAt
  }
}
    `;

/**
 * __useSchoolQuery__
 *
 * To run a query within a React component, call `useSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSchoolQuery(baseOptions: Apollo.QueryHookOptions<SchoolQuery, SchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolQuery, SchoolQueryVariables>(SchoolDocument, options);
      }
export function useSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolQuery, SchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolQuery, SchoolQueryVariables>(SchoolDocument, options);
        }
export type SchoolQueryHookResult = ReturnType<typeof useSchoolQuery>;
export type SchoolLazyQueryHookResult = ReturnType<typeof useSchoolLazyQuery>;
export type SchoolQueryResult = Apollo.QueryResult<SchoolQuery, SchoolQueryVariables>;
export const AggregateSchoolDocument = gql`
    query aggregateSchool($where: SchoolWhereInput, $orderBy: [SchoolOrderByWithRelationInput!], $cursor: SchoolWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateSchool(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateSchoolQuery__
 *
 * To run a query within a React component, call `useAggregateSchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateSchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateSchoolQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateSchoolQuery(baseOptions?: Apollo.QueryHookOptions<AggregateSchoolQuery, AggregateSchoolQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateSchoolQuery, AggregateSchoolQueryVariables>(AggregateSchoolDocument, options);
      }
export function useAggregateSchoolLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateSchoolQuery, AggregateSchoolQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateSchoolQuery, AggregateSchoolQueryVariables>(AggregateSchoolDocument, options);
        }
export type AggregateSchoolQueryHookResult = ReturnType<typeof useAggregateSchoolQuery>;
export type AggregateSchoolLazyQueryHookResult = ReturnType<typeof useAggregateSchoolLazyQuery>;
export type AggregateSchoolQueryResult = Apollo.QueryResult<AggregateSchoolQuery, AggregateSchoolQueryVariables>;
export const CreateSchoolUpdateDocument = gql`
    mutation createSchoolUpdate($data: SchoolUpdateCreateInput!) {
  createSchoolUpdate(data: $data) {
    id
  }
}
    `;
export type CreateSchoolUpdateMutationFn = Apollo.MutationFunction<CreateSchoolUpdateMutation, CreateSchoolUpdateMutationVariables>;

/**
 * __useCreateSchoolUpdateMutation__
 *
 * To run a mutation, you first call `useCreateSchoolUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSchoolUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSchoolUpdateMutation, { data, loading, error }] = useCreateSchoolUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSchoolUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchoolUpdateMutation, CreateSchoolUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchoolUpdateMutation, CreateSchoolUpdateMutationVariables>(CreateSchoolUpdateDocument, options);
      }
export type CreateSchoolUpdateMutationHookResult = ReturnType<typeof useCreateSchoolUpdateMutation>;
export type CreateSchoolUpdateMutationResult = Apollo.MutationResult<CreateSchoolUpdateMutation>;
export type CreateSchoolUpdateMutationOptions = Apollo.BaseMutationOptions<CreateSchoolUpdateMutation, CreateSchoolUpdateMutationVariables>;
export const UpdateSchoolUpdateDocument = gql`
    mutation updateSchoolUpdate($where: SchoolUpdateWhereUniqueInput!, $data: SchoolUpdateUpdateInput!) {
  updateSchoolUpdate(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateSchoolUpdateMutationFn = Apollo.MutationFunction<UpdateSchoolUpdateMutation, UpdateSchoolUpdateMutationVariables>;

/**
 * __useUpdateSchoolUpdateMutation__
 *
 * To run a mutation, you first call `useUpdateSchoolUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSchoolUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSchoolUpdateMutation, { data, loading, error }] = useUpdateSchoolUpdateMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSchoolUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchoolUpdateMutation, UpdateSchoolUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchoolUpdateMutation, UpdateSchoolUpdateMutationVariables>(UpdateSchoolUpdateDocument, options);
      }
export type UpdateSchoolUpdateMutationHookResult = ReturnType<typeof useUpdateSchoolUpdateMutation>;
export type UpdateSchoolUpdateMutationResult = Apollo.MutationResult<UpdateSchoolUpdateMutation>;
export type UpdateSchoolUpdateMutationOptions = Apollo.BaseMutationOptions<UpdateSchoolUpdateMutation, UpdateSchoolUpdateMutationVariables>;
export const DeleteSchoolUpdateDocument = gql`
    mutation deleteSchoolUpdate($where: SchoolUpdateWhereUniqueInput!) {
  deleteSchoolUpdate(where: $where) {
    id
  }
}
    `;
export type DeleteSchoolUpdateMutationFn = Apollo.MutationFunction<DeleteSchoolUpdateMutation, DeleteSchoolUpdateMutationVariables>;

/**
 * __useDeleteSchoolUpdateMutation__
 *
 * To run a mutation, you first call `useDeleteSchoolUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSchoolUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSchoolUpdateMutation, { data, loading, error }] = useDeleteSchoolUpdateMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSchoolUpdateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchoolUpdateMutation, DeleteSchoolUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchoolUpdateMutation, DeleteSchoolUpdateMutationVariables>(DeleteSchoolUpdateDocument, options);
      }
export type DeleteSchoolUpdateMutationHookResult = ReturnType<typeof useDeleteSchoolUpdateMutation>;
export type DeleteSchoolUpdateMutationResult = Apollo.MutationResult<DeleteSchoolUpdateMutation>;
export type DeleteSchoolUpdateMutationOptions = Apollo.BaseMutationOptions<DeleteSchoolUpdateMutation, DeleteSchoolUpdateMutationVariables>;
export const SchoolUpdateDocument = gql`
    query schoolUpdate($where: SchoolUpdateWhereUniqueInput!) {
  schoolUpdate(where: $where) {
    id
    title
    description
    attachment
    school {
      id
      name
    }
    standards {
      id
    }
    asset
    tags
    createdAt
  }
}
    `;

/**
 * __useSchoolUpdateQuery__
 *
 * To run a query within a React component, call `useSchoolUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolUpdateQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSchoolUpdateQuery(baseOptions: Apollo.QueryHookOptions<SchoolUpdateQuery, SchoolUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolUpdateQuery, SchoolUpdateQueryVariables>(SchoolUpdateDocument, options);
      }
export function useSchoolUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolUpdateQuery, SchoolUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolUpdateQuery, SchoolUpdateQueryVariables>(SchoolUpdateDocument, options);
        }
export type SchoolUpdateQueryHookResult = ReturnType<typeof useSchoolUpdateQuery>;
export type SchoolUpdateLazyQueryHookResult = ReturnType<typeof useSchoolUpdateLazyQuery>;
export type SchoolUpdateQueryResult = Apollo.QueryResult<SchoolUpdateQuery, SchoolUpdateQueryVariables>;
export const SchoolUpdatesDocument = gql`
    query schoolUpdates($where: SchoolUpdateWhereInput, $orderBy: [SchoolUpdateOrderByWithRelationInput!], $cursor: SchoolUpdateWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SchoolUpdateScalarFieldEnum!]) {
  schoolUpdates(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    title
    description
    attachment
    school {
      id
      name
    }
    standards {
      id
      key
      value
    }
    asset
    tags
    createdAt
  }
}
    `;

/**
 * __useSchoolUpdatesQuery__
 *
 * To run a query within a React component, call `useSchoolUpdatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolUpdatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolUpdatesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSchoolUpdatesQuery(baseOptions?: Apollo.QueryHookOptions<SchoolUpdatesQuery, SchoolUpdatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchoolUpdatesQuery, SchoolUpdatesQueryVariables>(SchoolUpdatesDocument, options);
      }
export function useSchoolUpdatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolUpdatesQuery, SchoolUpdatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchoolUpdatesQuery, SchoolUpdatesQueryVariables>(SchoolUpdatesDocument, options);
        }
export type SchoolUpdatesQueryHookResult = ReturnType<typeof useSchoolUpdatesQuery>;
export type SchoolUpdatesLazyQueryHookResult = ReturnType<typeof useSchoolUpdatesLazyQuery>;
export type SchoolUpdatesQueryResult = Apollo.QueryResult<SchoolUpdatesQuery, SchoolUpdatesQueryVariables>;
export const AggregateSchoolUpdateDocument = gql`
    query aggregateSchoolUpdate($where: SchoolUpdateWhereInput, $orderBy: [SchoolUpdateOrderByWithRelationInput!], $cursor: SchoolUpdateWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateSchoolUpdate(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateSchoolUpdateQuery__
 *
 * To run a query within a React component, call `useAggregateSchoolUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateSchoolUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateSchoolUpdateQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateSchoolUpdateQuery(baseOptions?: Apollo.QueryHookOptions<AggregateSchoolUpdateQuery, AggregateSchoolUpdateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateSchoolUpdateQuery, AggregateSchoolUpdateQueryVariables>(AggregateSchoolUpdateDocument, options);
      }
export function useAggregateSchoolUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateSchoolUpdateQuery, AggregateSchoolUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateSchoolUpdateQuery, AggregateSchoolUpdateQueryVariables>(AggregateSchoolUpdateDocument, options);
        }
export type AggregateSchoolUpdateQueryHookResult = ReturnType<typeof useAggregateSchoolUpdateQuery>;
export type AggregateSchoolUpdateLazyQueryHookResult = ReturnType<typeof useAggregateSchoolUpdateLazyQuery>;
export type AggregateSchoolUpdateQueryResult = Apollo.QueryResult<AggregateSchoolUpdateQuery, AggregateSchoolUpdateQueryVariables>;
export const CreateSkillDocument = gql`
    mutation createSkill($data: SkillCreateInput!) {
  createSkill(data: $data) {
    id
    slug
  }
}
    `;
export type CreateSkillMutationFn = Apollo.MutationFunction<CreateSkillMutation, CreateSkillMutationVariables>;

/**
 * __useCreateSkillMutation__
 *
 * To run a mutation, you first call `useCreateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSkillMutation, { data, loading, error }] = useCreateSkillMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSkillMutation(baseOptions?: Apollo.MutationHookOptions<CreateSkillMutation, CreateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSkillMutation, CreateSkillMutationVariables>(CreateSkillDocument, options);
      }
export type CreateSkillMutationHookResult = ReturnType<typeof useCreateSkillMutation>;
export type CreateSkillMutationResult = Apollo.MutationResult<CreateSkillMutation>;
export type CreateSkillMutationOptions = Apollo.BaseMutationOptions<CreateSkillMutation, CreateSkillMutationVariables>;
export const UpdateSkillDocument = gql`
    mutation updateSkill($where: SkillWhereUniqueInput!, $data: SkillUpdateInput!) {
  updateSkill(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateSkillMutationFn = Apollo.MutationFunction<UpdateSkillMutation, UpdateSkillMutationVariables>;

/**
 * __useUpdateSkillMutation__
 *
 * To run a mutation, you first call `useUpdateSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkillMutation, { data, loading, error }] = useUpdateSkillMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSkillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkillMutation, UpdateSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkillMutation, UpdateSkillMutationVariables>(UpdateSkillDocument, options);
      }
export type UpdateSkillMutationHookResult = ReturnType<typeof useUpdateSkillMutation>;
export type UpdateSkillMutationResult = Apollo.MutationResult<UpdateSkillMutation>;
export type UpdateSkillMutationOptions = Apollo.BaseMutationOptions<UpdateSkillMutation, UpdateSkillMutationVariables>;
export const DeleteSkillDocument = gql`
    mutation deleteSkill($where: SkillWhereUniqueInput!) {
  deleteSkill(where: $where) {
    id
  }
}
    `;
export type DeleteSkillMutationFn = Apollo.MutationFunction<DeleteSkillMutation, DeleteSkillMutationVariables>;

/**
 * __useDeleteSkillMutation__
 *
 * To run a mutation, you first call `useDeleteSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSkillMutation, { data, loading, error }] = useDeleteSkillMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteSkillMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSkillMutation, DeleteSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSkillMutation, DeleteSkillMutationVariables>(DeleteSkillDocument, options);
      }
export type DeleteSkillMutationHookResult = ReturnType<typeof useDeleteSkillMutation>;
export type DeleteSkillMutationResult = Apollo.MutationResult<DeleteSkillMutation>;
export type DeleteSkillMutationOptions = Apollo.BaseMutationOptions<DeleteSkillMutation, DeleteSkillMutationVariables>;
export const SkillsDocument = gql`
    query skills($where: SkillWhereInput, $orderBy: [SkillOrderByWithRelationInput!], $cursor: SkillWhereUniqueInput, $take: Int, $skip: Int, $distinct: [SkillScalarFieldEnum!]) {
  skills(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    name
    slug
    displayOrder
    standards {
      id
      key
    }
    school {
      id
      name
    }
  }
}
    `;

/**
 * __useSkillsQuery__
 *
 * To run a query within a React component, call `useSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useSkillsQuery(baseOptions?: Apollo.QueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, options);
      }
export function useSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillsQuery, SkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillsQuery, SkillsQueryVariables>(SkillsDocument, options);
        }
export type SkillsQueryHookResult = ReturnType<typeof useSkillsQuery>;
export type SkillsLazyQueryHookResult = ReturnType<typeof useSkillsLazyQuery>;
export type SkillsQueryResult = Apollo.QueryResult<SkillsQuery, SkillsQueryVariables>;
export const SkillDocument = gql`
    query skill($data: SkillWhereUniqueInput!) {
  skill(where: $data) {
    id
    name
    slug
    description
    standards {
      id
      key
      value
    }
    school {
      id
      name
    }
    image
    featuredImage
    bundle
    type
    metaTagData
    displayOrder
    isActive
  }
}
    `;

/**
 * __useSkillQuery__
 *
 * To run a query within a React component, call `useSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSkillQuery(baseOptions: Apollo.QueryHookOptions<SkillQuery, SkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillQuery, SkillQueryVariables>(SkillDocument, options);
      }
export function useSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillQuery, SkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillQuery, SkillQueryVariables>(SkillDocument, options);
        }
export type SkillQueryHookResult = ReturnType<typeof useSkillQuery>;
export type SkillLazyQueryHookResult = ReturnType<typeof useSkillLazyQuery>;
export type SkillQueryResult = Apollo.QueryResult<SkillQuery, SkillQueryVariables>;
export const AggregateSkillDocument = gql`
    query aggregateSkill($where: SkillWhereInput, $orderBy: [SkillOrderByWithRelationInput!], $cursor: SkillWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateSkill(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateSkillQuery__
 *
 * To run a query within a React component, call `useAggregateSkillQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateSkillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateSkillQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateSkillQuery(baseOptions?: Apollo.QueryHookOptions<AggregateSkillQuery, AggregateSkillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateSkillQuery, AggregateSkillQueryVariables>(AggregateSkillDocument, options);
      }
export function useAggregateSkillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateSkillQuery, AggregateSkillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateSkillQuery, AggregateSkillQueryVariables>(AggregateSkillDocument, options);
        }
export type AggregateSkillQueryHookResult = ReturnType<typeof useAggregateSkillQuery>;
export type AggregateSkillLazyQueryHookResult = ReturnType<typeof useAggregateSkillLazyQuery>;
export type AggregateSkillQueryResult = Apollo.QueryResult<AggregateSkillQuery, AggregateSkillQueryVariables>;
export const StandardsDocument = gql`
    query standards {
  standards(orderBy: {id: asc}) {
    id
    key
    value
  }
}
    `;

/**
 * __useStandardsQuery__
 *
 * To run a query within a React component, call `useStandardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStandardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStandardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStandardsQuery(baseOptions?: Apollo.QueryHookOptions<StandardsQuery, StandardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StandardsQuery, StandardsQueryVariables>(StandardsDocument, options);
      }
export function useStandardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StandardsQuery, StandardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StandardsQuery, StandardsQueryVariables>(StandardsDocument, options);
        }
export type StandardsQueryHookResult = ReturnType<typeof useStandardsQuery>;
export type StandardsLazyQueryHookResult = ReturnType<typeof useStandardsLazyQuery>;
export type StandardsQueryResult = Apollo.QueryResult<StandardsQuery, StandardsQueryVariables>;
export const CreateStoryDocument = gql`
    mutation createStory($data: StoryCreateInput!) {
  createStory(data: $data) {
    id
    slug
  }
}
    `;
export type CreateStoryMutationFn = Apollo.MutationFunction<CreateStoryMutation, CreateStoryMutationVariables>;

/**
 * __useCreateStoryMutation__
 *
 * To run a mutation, you first call `useCreateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryMutation, { data, loading, error }] = useCreateStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryMutation, CreateStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryMutation, CreateStoryMutationVariables>(CreateStoryDocument, options);
      }
export type CreateStoryMutationHookResult = ReturnType<typeof useCreateStoryMutation>;
export type CreateStoryMutationResult = Apollo.MutationResult<CreateStoryMutation>;
export type CreateStoryMutationOptions = Apollo.BaseMutationOptions<CreateStoryMutation, CreateStoryMutationVariables>;
export const UpdateStoryDocument = gql`
    mutation updateStory($data: StoryUpdateInput!, $where: StoryWhereUniqueInput!) {
  updateStory(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateStoryMutationFn = Apollo.MutationFunction<UpdateStoryMutation, UpdateStoryMutationVariables>;

/**
 * __useUpdateStoryMutation__
 *
 * To run a mutation, you first call `useUpdateStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryMutation, { data, loading, error }] = useUpdateStoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateStoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryMutation, UpdateStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryMutation, UpdateStoryMutationVariables>(UpdateStoryDocument, options);
      }
export type UpdateStoryMutationHookResult = ReturnType<typeof useUpdateStoryMutation>;
export type UpdateStoryMutationResult = Apollo.MutationResult<UpdateStoryMutation>;
export type UpdateStoryMutationOptions = Apollo.BaseMutationOptions<UpdateStoryMutation, UpdateStoryMutationVariables>;
export const DeleteStoryDocument = gql`
    mutation deleteStory($where: StoryWhereUniqueInput!) {
  deleteStory(where: $where) {
    id
  }
}
    `;
export type DeleteStoryMutationFn = Apollo.MutationFunction<DeleteStoryMutation, DeleteStoryMutationVariables>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryMutation, DeleteStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryMutation, DeleteStoryMutationVariables>(DeleteStoryDocument, options);
      }
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<DeleteStoryMutation, DeleteStoryMutationVariables>;
export const StoriesDocument = gql`
    query stories($where: StoryWhereInput, $orderBy: [StoryOrderByWithRelationInput!], $cursor: StoryWhereUniqueInput, $take: Int, $skip: Int, $distinct: [StoryScalarFieldEnum!]) {
  stories(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    title
    slug
    displayOrder
    standards {
      id
      key
    }
    tag {
      id
      key
      text
    }
    school {
      id
      name
    }
    createdAt
  }
}
    `;

/**
 * __useStoriesQuery__
 *
 * To run a query within a React component, call `useStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useStoriesQuery(baseOptions?: Apollo.QueryHookOptions<StoriesQuery, StoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, options);
      }
export function useStoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoriesQuery, StoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoriesQuery, StoriesQueryVariables>(StoriesDocument, options);
        }
export type StoriesQueryHookResult = ReturnType<typeof useStoriesQuery>;
export type StoriesLazyQueryHookResult = ReturnType<typeof useStoriesLazyQuery>;
export type StoriesQueryResult = Apollo.QueryResult<StoriesQuery, StoriesQueryVariables>;
export const StoryDocument = gql`
    query story($data: StoryWhereUniqueInput!) {
  story(where: $data) {
    id
    title
    image
    slug
    description
    featuredImage
    displayOrder
    school {
      id
      name
    }
    standards {
      id
      key
      value
    }
    tag {
      id
      key
      text
    }
    createdAt
  }
}
    `;

/**
 * __useStoryQuery__
 *
 * To run a query within a React component, call `useStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStoryQuery(baseOptions: Apollo.QueryHookOptions<StoryQuery, StoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options);
      }
export function useStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryQuery, StoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryQuery, StoryQueryVariables>(StoryDocument, options);
        }
export type StoryQueryHookResult = ReturnType<typeof useStoryQuery>;
export type StoryLazyQueryHookResult = ReturnType<typeof useStoryLazyQuery>;
export type StoryQueryResult = Apollo.QueryResult<StoryQuery, StoryQueryVariables>;
export const AggregateStoryDocument = gql`
    query aggregateStory($where: StoryWhereInput, $orderBy: [StoryOrderByWithRelationInput!], $cursor: StoryWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateStory(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateStoryQuery__
 *
 * To run a query within a React component, call `useAggregateStoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateStoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateStoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateStoryQuery(baseOptions?: Apollo.QueryHookOptions<AggregateStoryQuery, AggregateStoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateStoryQuery, AggregateStoryQueryVariables>(AggregateStoryDocument, options);
      }
export function useAggregateStoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateStoryQuery, AggregateStoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateStoryQuery, AggregateStoryQueryVariables>(AggregateStoryDocument, options);
        }
export type AggregateStoryQueryHookResult = ReturnType<typeof useAggregateStoryQuery>;
export type AggregateStoryLazyQueryHookResult = ReturnType<typeof useAggregateStoryLazyQuery>;
export type AggregateStoryQueryResult = Apollo.QueryResult<AggregateStoryQuery, AggregateStoryQueryVariables>;
export const CreateStoryActivityDocument = gql`
    mutation createStoryActivity($data: StoryActivityCreateInput!) {
  createStoryActivity(data: $data) {
    id
    slug
  }
}
    `;
export type CreateStoryActivityMutationFn = Apollo.MutationFunction<CreateStoryActivityMutation, CreateStoryActivityMutationVariables>;

/**
 * __useCreateStoryActivityMutation__
 *
 * To run a mutation, you first call `useCreateStoryActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryActivityMutation, { data, loading, error }] = useCreateStoryActivityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoryActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryActivityMutation, CreateStoryActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryActivityMutation, CreateStoryActivityMutationVariables>(CreateStoryActivityDocument, options);
      }
export type CreateStoryActivityMutationHookResult = ReturnType<typeof useCreateStoryActivityMutation>;
export type CreateStoryActivityMutationResult = Apollo.MutationResult<CreateStoryActivityMutation>;
export type CreateStoryActivityMutationOptions = Apollo.BaseMutationOptions<CreateStoryActivityMutation, CreateStoryActivityMutationVariables>;
export const UpdateStoryActivityDocument = gql`
    mutation updateStoryActivity($where: StoryActivityWhereUniqueInput!, $data: StoryActivityUpdateInput!) {
  updateStoryActivity(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateStoryActivityMutationFn = Apollo.MutationFunction<UpdateStoryActivityMutation, UpdateStoryActivityMutationVariables>;

/**
 * __useUpdateStoryActivityMutation__
 *
 * To run a mutation, you first call `useUpdateStoryActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryActivityMutation, { data, loading, error }] = useUpdateStoryActivityMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStoryActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryActivityMutation, UpdateStoryActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryActivityMutation, UpdateStoryActivityMutationVariables>(UpdateStoryActivityDocument, options);
      }
export type UpdateStoryActivityMutationHookResult = ReturnType<typeof useUpdateStoryActivityMutation>;
export type UpdateStoryActivityMutationResult = Apollo.MutationResult<UpdateStoryActivityMutation>;
export type UpdateStoryActivityMutationOptions = Apollo.BaseMutationOptions<UpdateStoryActivityMutation, UpdateStoryActivityMutationVariables>;
export const StoryActivityDocument = gql`
    query storyActivity($where: StoryActivityWhereUniqueInput!) {
  storyActivity(where: $where) {
    id
    title
    slug
    timeRequired
    coinsToSpend
    xps
    image
    featuredImage
    challengeStatement
    content
    video
    audio
    story {
      id
      slug
      title
    }
    standards {
      id
      key
    }
    status
    isFeatured
    displayOrder
    skill {
      id
      slug
      name
    }
    isCommentEnabled
  }
}
    `;

/**
 * __useStoryActivityQuery__
 *
 * To run a query within a React component, call `useStoryActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStoryActivityQuery(baseOptions: Apollo.QueryHookOptions<StoryActivityQuery, StoryActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryActivityQuery, StoryActivityQueryVariables>(StoryActivityDocument, options);
      }
export function useStoryActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryActivityQuery, StoryActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryActivityQuery, StoryActivityQueryVariables>(StoryActivityDocument, options);
        }
export type StoryActivityQueryHookResult = ReturnType<typeof useStoryActivityQuery>;
export type StoryActivityLazyQueryHookResult = ReturnType<typeof useStoryActivityLazyQuery>;
export type StoryActivityQueryResult = Apollo.QueryResult<StoryActivityQuery, StoryActivityQueryVariables>;
export const StoryActivitiesDocument = gql`
    query storyActivities($where: StoryActivityWhereInput, $orderBy: [StoryActivityOrderByWithRelationInput!], $cursor: StoryActivityWhereUniqueInput, $distinct: [StoryActivityScalarFieldEnum!], $skip: Int, $take: Int) {
  storyActivities(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    title
    slug
    coinsToSpend
    xps
    featuredImage
    standards {
      id
      key
    }
    status
    isFeatured
    displayOrder
    isCommentEnabled
  }
}
    `;

/**
 * __useStoryActivitiesQuery__
 *
 * To run a query within a React component, call `useStoryActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryActivitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStoryActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<StoryActivitiesQuery, StoryActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryActivitiesQuery, StoryActivitiesQueryVariables>(StoryActivitiesDocument, options);
      }
export function useStoryActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryActivitiesQuery, StoryActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryActivitiesQuery, StoryActivitiesQueryVariables>(StoryActivitiesDocument, options);
        }
export type StoryActivitiesQueryHookResult = ReturnType<typeof useStoryActivitiesQuery>;
export type StoryActivitiesLazyQueryHookResult = ReturnType<typeof useStoryActivitiesLazyQuery>;
export type StoryActivitiesQueryResult = Apollo.QueryResult<StoryActivitiesQuery, StoryActivitiesQueryVariables>;
export const AggregateStoryActivityDocument = gql`
    query aggregateStoryActivity($where: StoryActivityWhereInput, $orderBy: [StoryActivityOrderByWithRelationInput!], $cursor: StoryActivityWhereUniqueInput, $skip: Int, $take: Int) {
  aggregateStoryActivity(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateStoryActivityQuery__
 *
 * To run a query within a React component, call `useAggregateStoryActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateStoryActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateStoryActivityQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAggregateStoryActivityQuery(baseOptions?: Apollo.QueryHookOptions<AggregateStoryActivityQuery, AggregateStoryActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateStoryActivityQuery, AggregateStoryActivityQueryVariables>(AggregateStoryActivityDocument, options);
      }
export function useAggregateStoryActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateStoryActivityQuery, AggregateStoryActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateStoryActivityQuery, AggregateStoryActivityQueryVariables>(AggregateStoryActivityDocument, options);
        }
export type AggregateStoryActivityQueryHookResult = ReturnType<typeof useAggregateStoryActivityQuery>;
export type AggregateStoryActivityLazyQueryHookResult = ReturnType<typeof useAggregateStoryActivityLazyQuery>;
export type AggregateStoryActivityQueryResult = Apollo.QueryResult<AggregateStoryActivityQuery, AggregateStoryActivityQueryVariables>;
export const CreateStoryBannerDocument = gql`
    mutation createStoryBanner($data: StoryBannerCreateInput!) {
  createStoryBanner(data: $data) {
    id
  }
}
    `;
export type CreateStoryBannerMutationFn = Apollo.MutationFunction<CreateStoryBannerMutation, CreateStoryBannerMutationVariables>;

/**
 * __useCreateStoryBannerMutation__
 *
 * To run a mutation, you first call `useCreateStoryBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryBannerMutation, { data, loading, error }] = useCreateStoryBannerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoryBannerMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryBannerMutation, CreateStoryBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryBannerMutation, CreateStoryBannerMutationVariables>(CreateStoryBannerDocument, options);
      }
export type CreateStoryBannerMutationHookResult = ReturnType<typeof useCreateStoryBannerMutation>;
export type CreateStoryBannerMutationResult = Apollo.MutationResult<CreateStoryBannerMutation>;
export type CreateStoryBannerMutationOptions = Apollo.BaseMutationOptions<CreateStoryBannerMutation, CreateStoryBannerMutationVariables>;
export const UpdateStoryBannerDocument = gql`
    mutation updateStoryBanner($where: StoryBannerWhereUniqueInput!, $data: StoryBannerUpdateInput!) {
  updateStoryBanner(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateStoryBannerMutationFn = Apollo.MutationFunction<UpdateStoryBannerMutation, UpdateStoryBannerMutationVariables>;

/**
 * __useUpdateStoryBannerMutation__
 *
 * To run a mutation, you first call `useUpdateStoryBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryBannerMutation, { data, loading, error }] = useUpdateStoryBannerMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStoryBannerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryBannerMutation, UpdateStoryBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryBannerMutation, UpdateStoryBannerMutationVariables>(UpdateStoryBannerDocument, options);
      }
export type UpdateStoryBannerMutationHookResult = ReturnType<typeof useUpdateStoryBannerMutation>;
export type UpdateStoryBannerMutationResult = Apollo.MutationResult<UpdateStoryBannerMutation>;
export type UpdateStoryBannerMutationOptions = Apollo.BaseMutationOptions<UpdateStoryBannerMutation, UpdateStoryBannerMutationVariables>;
export const DeleteStoryBannerDocument = gql`
    mutation deleteStoryBanner($where: StoryBannerWhereUniqueInput!) {
  deleteStoryBanner(where: $where) {
    id
  }
}
    `;
export type DeleteStoryBannerMutationFn = Apollo.MutationFunction<DeleteStoryBannerMutation, DeleteStoryBannerMutationVariables>;

/**
 * __useDeleteStoryBannerMutation__
 *
 * To run a mutation, you first call `useDeleteStoryBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryBannerMutation, { data, loading, error }] = useDeleteStoryBannerMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteStoryBannerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryBannerMutation, DeleteStoryBannerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryBannerMutation, DeleteStoryBannerMutationVariables>(DeleteStoryBannerDocument, options);
      }
export type DeleteStoryBannerMutationHookResult = ReturnType<typeof useDeleteStoryBannerMutation>;
export type DeleteStoryBannerMutationResult = Apollo.MutationResult<DeleteStoryBannerMutation>;
export type DeleteStoryBannerMutationOptions = Apollo.BaseMutationOptions<DeleteStoryBannerMutation, DeleteStoryBannerMutationVariables>;
export const StoryBannersDocument = gql`
    query storyBanners($where: StoryBannerWhereInput, $orderBy: [StoryBannerOrderByWithRelationInput!], $cursor: StoryBannerWhereUniqueInput, $distinct: [StoryBannerScalarFieldEnum!], $skip: Int, $take: Int) {
  storyBanners(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `;

/**
 * __useStoryBannersQuery__
 *
 * To run a query within a React component, call `useStoryBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryBannersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStoryBannersQuery(baseOptions?: Apollo.QueryHookOptions<StoryBannersQuery, StoryBannersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryBannersQuery, StoryBannersQueryVariables>(StoryBannersDocument, options);
      }
export function useStoryBannersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryBannersQuery, StoryBannersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryBannersQuery, StoryBannersQueryVariables>(StoryBannersDocument, options);
        }
export type StoryBannersQueryHookResult = ReturnType<typeof useStoryBannersQuery>;
export type StoryBannersLazyQueryHookResult = ReturnType<typeof useStoryBannersLazyQuery>;
export type StoryBannersQueryResult = Apollo.QueryResult<StoryBannersQuery, StoryBannersQueryVariables>;
export const StoryBannerDocument = gql`
    query storyBanner($where: StoryBannerWhereUniqueInput!) {
  storyBanner(where: $where) {
    id
    image
    destinationUrl
    displayOrder
  }
}
    `;

/**
 * __useStoryBannerQuery__
 *
 * To run a query within a React component, call `useStoryBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryBannerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStoryBannerQuery(baseOptions: Apollo.QueryHookOptions<StoryBannerQuery, StoryBannerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryBannerQuery, StoryBannerQueryVariables>(StoryBannerDocument, options);
      }
export function useStoryBannerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryBannerQuery, StoryBannerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryBannerQuery, StoryBannerQueryVariables>(StoryBannerDocument, options);
        }
export type StoryBannerQueryHookResult = ReturnType<typeof useStoryBannerQuery>;
export type StoryBannerLazyQueryHookResult = ReturnType<typeof useStoryBannerLazyQuery>;
export type StoryBannerQueryResult = Apollo.QueryResult<StoryBannerQuery, StoryBannerQueryVariables>;
export const CreateStoryTagDocument = gql`
    mutation createStoryTag($data: StoryTagCreateInput!) {
  createStoryTag(data: $data) {
    id
  }
}
    `;
export type CreateStoryTagMutationFn = Apollo.MutationFunction<CreateStoryTagMutation, CreateStoryTagMutationVariables>;

/**
 * __useCreateStoryTagMutation__
 *
 * To run a mutation, you first call `useCreateStoryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryTagMutation, { data, loading, error }] = useCreateStoryTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStoryTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoryTagMutation, CreateStoryTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoryTagMutation, CreateStoryTagMutationVariables>(CreateStoryTagDocument, options);
      }
export type CreateStoryTagMutationHookResult = ReturnType<typeof useCreateStoryTagMutation>;
export type CreateStoryTagMutationResult = Apollo.MutationResult<CreateStoryTagMutation>;
export type CreateStoryTagMutationOptions = Apollo.BaseMutationOptions<CreateStoryTagMutation, CreateStoryTagMutationVariables>;
export const UpdateStoryTagDocument = gql`
    mutation updateStoryTag($data: StoryTagUpdateInput!, $where: StoryTagWhereUniqueInput!) {
  updateStoryTag(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateStoryTagMutationFn = Apollo.MutationFunction<UpdateStoryTagMutation, UpdateStoryTagMutationVariables>;

/**
 * __useUpdateStoryTagMutation__
 *
 * To run a mutation, you first call `useUpdateStoryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryTagMutation, { data, loading, error }] = useUpdateStoryTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateStoryTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoryTagMutation, UpdateStoryTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoryTagMutation, UpdateStoryTagMutationVariables>(UpdateStoryTagDocument, options);
      }
export type UpdateStoryTagMutationHookResult = ReturnType<typeof useUpdateStoryTagMutation>;
export type UpdateStoryTagMutationResult = Apollo.MutationResult<UpdateStoryTagMutation>;
export type UpdateStoryTagMutationOptions = Apollo.BaseMutationOptions<UpdateStoryTagMutation, UpdateStoryTagMutationVariables>;
export const DeleteStoryTagDocument = gql`
    mutation deleteStoryTag($where: StoryTagWhereUniqueInput!) {
  deleteStoryTag(where: $where) {
    id
  }
}
    `;
export type DeleteStoryTagMutationFn = Apollo.MutationFunction<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>;

/**
 * __useDeleteStoryTagMutation__
 *
 * To run a mutation, you first call `useDeleteStoryTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryTagMutation, { data, loading, error }] = useDeleteStoryTagMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteStoryTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>(DeleteStoryTagDocument, options);
      }
export type DeleteStoryTagMutationHookResult = ReturnType<typeof useDeleteStoryTagMutation>;
export type DeleteStoryTagMutationResult = Apollo.MutationResult<DeleteStoryTagMutation>;
export type DeleteStoryTagMutationOptions = Apollo.BaseMutationOptions<DeleteStoryTagMutation, DeleteStoryTagMutationVariables>;
export const StoryTagsDocument = gql`
    query storyTags($where: StoryTagWhereInput, $orderBy: [StoryTagOrderByWithRelationInput!], $cursor: StoryTagWhereUniqueInput, $take: Int, $skip: Int, $distinct: [StoryTagScalarFieldEnum!]) {
  storyTags(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    key
    text
    image
  }
}
    `;

/**
 * __useStoryTagsQuery__
 *
 * To run a query within a React component, call `useStoryTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useStoryTagsQuery(baseOptions?: Apollo.QueryHookOptions<StoryTagsQuery, StoryTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryTagsQuery, StoryTagsQueryVariables>(StoryTagsDocument, options);
      }
export function useStoryTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryTagsQuery, StoryTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryTagsQuery, StoryTagsQueryVariables>(StoryTagsDocument, options);
        }
export type StoryTagsQueryHookResult = ReturnType<typeof useStoryTagsQuery>;
export type StoryTagsLazyQueryHookResult = ReturnType<typeof useStoryTagsLazyQuery>;
export type StoryTagsQueryResult = Apollo.QueryResult<StoryTagsQuery, StoryTagsQueryVariables>;
export const StoryTagDocument = gql`
    query storyTag($where: StoryTagWhereUniqueInput!) {
  storyTag(where: $where) {
    id
    key
    text
    image
  }
}
    `;

/**
 * __useStoryTagQuery__
 *
 * To run a query within a React component, call `useStoryTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStoryTagQuery(baseOptions: Apollo.QueryHookOptions<StoryTagQuery, StoryTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StoryTagQuery, StoryTagQueryVariables>(StoryTagDocument, options);
      }
export function useStoryTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StoryTagQuery, StoryTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StoryTagQuery, StoryTagQueryVariables>(StoryTagDocument, options);
        }
export type StoryTagQueryHookResult = ReturnType<typeof useStoryTagQuery>;
export type StoryTagLazyQueryHookResult = ReturnType<typeof useStoryTagLazyQuery>;
export type StoryTagQueryResult = Apollo.QueryResult<StoryTagQuery, StoryTagQueryVariables>;
export const AggregateStoryTagDocument = gql`
    query aggregateStoryTag($where: StoryTagWhereInput, $orderBy: [StoryTagOrderByWithRelationInput!], $cursor: StoryTagWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateStoryTag(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateStoryTagQuery__
 *
 * To run a query within a React component, call `useAggregateStoryTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateStoryTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateStoryTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateStoryTagQuery(baseOptions?: Apollo.QueryHookOptions<AggregateStoryTagQuery, AggregateStoryTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateStoryTagQuery, AggregateStoryTagQueryVariables>(AggregateStoryTagDocument, options);
      }
export function useAggregateStoryTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateStoryTagQuery, AggregateStoryTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateStoryTagQuery, AggregateStoryTagQueryVariables>(AggregateStoryTagDocument, options);
        }
export type AggregateStoryTagQueryHookResult = ReturnType<typeof useAggregateStoryTagQuery>;
export type AggregateStoryTagLazyQueryHookResult = ReturnType<typeof useAggregateStoryTagLazyQuery>;
export type AggregateStoryTagQueryResult = Apollo.QueryResult<AggregateStoryTagQuery, AggregateStoryTagQueryVariables>;
export const CreateStudentDocument = gql`
    mutation createStudent($data: StudentCreateInput!) {
  createStudent(data: $data) {
    id
    phoneNumber
  }
}
    `;
export type CreateStudentMutationFn = Apollo.MutationFunction<CreateStudentMutation, CreateStudentMutationVariables>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStudentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudentMutation, CreateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudentMutation, CreateStudentMutationVariables>(CreateStudentDocument, options);
      }
export type CreateStudentMutationHookResult = ReturnType<typeof useCreateStudentMutation>;
export type CreateStudentMutationResult = Apollo.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = Apollo.BaseMutationOptions<CreateStudentMutation, CreateStudentMutationVariables>;
export const UpdateStudentDocument = gql`
    mutation updateStudent($where: StudentWhereUniqueInput!, $data: StudentUpdateInput!) {
  updateStudent(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateStudentMutationFn = Apollo.MutationFunction<UpdateStudentMutation, UpdateStudentMutationVariables>;

/**
 * __useUpdateStudentMutation__
 *
 * To run a mutation, you first call `useUpdateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStudentMutation, { data, loading, error }] = useUpdateStudentMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStudentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStudentMutation, UpdateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStudentMutation, UpdateStudentMutationVariables>(UpdateStudentDocument, options);
      }
export type UpdateStudentMutationHookResult = ReturnType<typeof useUpdateStudentMutation>;
export type UpdateStudentMutationResult = Apollo.MutationResult<UpdateStudentMutation>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<UpdateStudentMutation, UpdateStudentMutationVariables>;
export const StudentAssignBadgeDocument = gql`
    mutation studentAssignBadge($data: StudentAssignBadgeInput!) {
  studentAssignBadge(data: $data) {
    success
    message
  }
}
    `;
export type StudentAssignBadgeMutationFn = Apollo.MutationFunction<StudentAssignBadgeMutation, StudentAssignBadgeMutationVariables>;

/**
 * __useStudentAssignBadgeMutation__
 *
 * To run a mutation, you first call `useStudentAssignBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentAssignBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentAssignBadgeMutation, { data, loading, error }] = useStudentAssignBadgeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStudentAssignBadgeMutation(baseOptions?: Apollo.MutationHookOptions<StudentAssignBadgeMutation, StudentAssignBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentAssignBadgeMutation, StudentAssignBadgeMutationVariables>(StudentAssignBadgeDocument, options);
      }
export type StudentAssignBadgeMutationHookResult = ReturnType<typeof useStudentAssignBadgeMutation>;
export type StudentAssignBadgeMutationResult = Apollo.MutationResult<StudentAssignBadgeMutation>;
export type StudentAssignBadgeMutationOptions = Apollo.BaseMutationOptions<StudentAssignBadgeMutation, StudentAssignBadgeMutationVariables>;
export const StudentRemoveBadgeDocument = gql`
    mutation studentRemoveBadge($where: StudentBadgeWhereUniqueInput!) {
  studentRemoveBadge(where: $where) {
    success
    message
  }
}
    `;
export type StudentRemoveBadgeMutationFn = Apollo.MutationFunction<StudentRemoveBadgeMutation, StudentRemoveBadgeMutationVariables>;

/**
 * __useStudentRemoveBadgeMutation__
 *
 * To run a mutation, you first call `useStudentRemoveBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStudentRemoveBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [studentRemoveBadgeMutation, { data, loading, error }] = useStudentRemoveBadgeMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentRemoveBadgeMutation(baseOptions?: Apollo.MutationHookOptions<StudentRemoveBadgeMutation, StudentRemoveBadgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StudentRemoveBadgeMutation, StudentRemoveBadgeMutationVariables>(StudentRemoveBadgeDocument, options);
      }
export type StudentRemoveBadgeMutationHookResult = ReturnType<typeof useStudentRemoveBadgeMutation>;
export type StudentRemoveBadgeMutationResult = Apollo.MutationResult<StudentRemoveBadgeMutation>;
export type StudentRemoveBadgeMutationOptions = Apollo.BaseMutationOptions<StudentRemoveBadgeMutation, StudentRemoveBadgeMutationVariables>;
export const ImportStudentsDocument = gql`
    mutation importStudents($file: Upload!) {
  importStudents(file: $file) {
    success
    message
  }
}
    `;
export type ImportStudentsMutationFn = Apollo.MutationFunction<ImportStudentsMutation, ImportStudentsMutationVariables>;

/**
 * __useImportStudentsMutation__
 *
 * To run a mutation, you first call `useImportStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importStudentsMutation, { data, loading, error }] = useImportStudentsMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportStudentsMutation(baseOptions?: Apollo.MutationHookOptions<ImportStudentsMutation, ImportStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportStudentsMutation, ImportStudentsMutationVariables>(ImportStudentsDocument, options);
      }
export type ImportStudentsMutationHookResult = ReturnType<typeof useImportStudentsMutation>;
export type ImportStudentsMutationResult = Apollo.MutationResult<ImportStudentsMutation>;
export type ImportStudentsMutationOptions = Apollo.BaseMutationOptions<ImportStudentsMutation, ImportStudentsMutationVariables>;
export const StudentsDocument = gql`
    query students($where: StudentWhereInput, $orderBy: [StudentOrderByWithRelationInput!], $cursor: StudentWhereUniqueInput, $distinct: [StudentScalarFieldEnum!], $skip: Int, $take: Int) {
  students(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    firstName
    lastName
    phoneNumber
    email
    standard {
      id
      key
    }
    school {
      id
      name
    }
    isActive
    schoolText
    lastLoginAt
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useStudentsQuery__
 *
 * To run a query within a React component, call `useStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useStudentsQuery(baseOptions?: Apollo.QueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
      }
export function useStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentsQuery, StudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentsQuery, StudentsQueryVariables>(StudentsDocument, options);
        }
export type StudentsQueryHookResult = ReturnType<typeof useStudentsQuery>;
export type StudentsLazyQueryHookResult = ReturnType<typeof useStudentsLazyQuery>;
export type StudentsQueryResult = Apollo.QueryResult<StudentsQuery, StudentsQueryVariables>;
export const AggregateStudentDocument = gql`
    query aggregateStudent($where: StudentWhereInput, $orderBy: [StudentOrderByWithRelationInput!], $cursor: StudentWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateStudent(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateStudentQuery__
 *
 * To run a query within a React component, call `useAggregateStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateStudentQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateStudentQuery(baseOptions?: Apollo.QueryHookOptions<AggregateStudentQuery, AggregateStudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateStudentQuery, AggregateStudentQueryVariables>(AggregateStudentDocument, options);
      }
export function useAggregateStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateStudentQuery, AggregateStudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateStudentQuery, AggregateStudentQueryVariables>(AggregateStudentDocument, options);
        }
export type AggregateStudentQueryHookResult = ReturnType<typeof useAggregateStudentQuery>;
export type AggregateStudentLazyQueryHookResult = ReturnType<typeof useAggregateStudentLazyQuery>;
export type AggregateStudentQueryResult = Apollo.QueryResult<AggregateStudentQuery, AggregateStudentQueryVariables>;
export const StudentDocument = gql`
    query student($where: StudentWhereUniqueInput!) {
  student(where: $where) {
    id
    firstName
    lastName
    displayName
    email
    phoneNumber
    standard {
      id
      value
    }
    school {
      id
      name
    }
    dob
    level
    address1
    address2
    country
    state
    city
    isHomeschooler
    schoolText
    profileImage
    isActive
  }
}
    `;

/**
 * __useStudentQuery__
 *
 * To run a query within a React component, call `useStudentQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentQuery(baseOptions: Apollo.QueryHookOptions<StudentQuery, StudentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentQuery, StudentQueryVariables>(StudentDocument, options);
      }
export function useStudentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentQuery, StudentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentQuery, StudentQueryVariables>(StudentDocument, options);
        }
export type StudentQueryHookResult = ReturnType<typeof useStudentQuery>;
export type StudentLazyQueryHookResult = ReturnType<typeof useStudentLazyQuery>;
export type StudentQueryResult = Apollo.QueryResult<StudentQuery, StudentQueryVariables>;
export const StudentGamePointsDocument = gql`
    query studentGamePoints($where: StudentWhereUniqueInput!) {
  studentGamePoints(where: $where) {
    data
  }
}
    `;

/**
 * __useStudentGamePointsQuery__
 *
 * To run a query within a React component, call `useStudentGamePointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentGamePointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentGamePointsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useStudentGamePointsQuery(baseOptions: Apollo.QueryHookOptions<StudentGamePointsQuery, StudentGamePointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentGamePointsQuery, StudentGamePointsQueryVariables>(StudentGamePointsDocument, options);
      }
export function useStudentGamePointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentGamePointsQuery, StudentGamePointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentGamePointsQuery, StudentGamePointsQueryVariables>(StudentGamePointsDocument, options);
        }
export type StudentGamePointsQueryHookResult = ReturnType<typeof useStudentGamePointsQuery>;
export type StudentGamePointsLazyQueryHookResult = ReturnType<typeof useStudentGamePointsLazyQuery>;
export type StudentGamePointsQueryResult = Apollo.QueryResult<StudentGamePointsQuery, StudentGamePointsQueryVariables>;
export const CreateTagDocument = gql`
    mutation createTag($data: TagCreateInput!) {
  createTag(data: $data) {
    id
    slug
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation updateTag($where: TagWhereUniqueInput!, $data: TagUpdateInput!) {
  updateTag(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation deleteTag($where: TagWhereUniqueInput!) {
  deleteTag(where: $where) {
    id
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const TagsDocument = gql`
    query tags($where: TagWhereInput, $orderBy: [TagOrderByWithRelationInput!], $cursor: TagWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TagScalarFieldEnum!]) {
  tags(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    name
    slug
    createdAt
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const AggregateTagDocument = gql`
    query aggregateTag($where: TagWhereInput, $orderBy: [TagOrderByWithRelationInput!], $cursor: TagWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateTag(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateTagQuery__
 *
 * To run a query within a React component, call `useAggregateTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateTagQuery(baseOptions?: Apollo.QueryHookOptions<AggregateTagQuery, AggregateTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateTagQuery, AggregateTagQueryVariables>(AggregateTagDocument, options);
      }
export function useAggregateTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateTagQuery, AggregateTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateTagQuery, AggregateTagQueryVariables>(AggregateTagDocument, options);
        }
export type AggregateTagQueryHookResult = ReturnType<typeof useAggregateTagQuery>;
export type AggregateTagLazyQueryHookResult = ReturnType<typeof useAggregateTagLazyQuery>;
export type AggregateTagQueryResult = Apollo.QueryResult<AggregateTagQuery, AggregateTagQueryVariables>;
export const TagDocument = gql`
    query tag($where: TagWhereUniqueInput!) {
  tag(where: $where) {
    id
    name
    slug
    description
    seoContent
    metaTagData
    jsonLD
    productScript
    buyScript
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const UpdateTriggerDocument = gql`
    mutation updateTrigger($data: TriggerUpdateInput!, $where: TriggerWhereUniqueInput!) {
  updateTrigger(data: $data, where: $where) {
    id
    text
    key
    totalCount
    model
    modelId
    countDuration
    countPeriod
    earn
    gamePointType {
      id
      pluralName
    }
    label
    maximumEarningsPerUser
    isActive
  }
}
    `;
export type UpdateTriggerMutationFn = Apollo.MutationFunction<UpdateTriggerMutation, UpdateTriggerMutationVariables>;

/**
 * __useUpdateTriggerMutation__
 *
 * To run a mutation, you first call `useUpdateTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTriggerMutation, { data, loading, error }] = useUpdateTriggerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateTriggerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTriggerMutation, UpdateTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTriggerMutation, UpdateTriggerMutationVariables>(UpdateTriggerDocument, options);
      }
export type UpdateTriggerMutationHookResult = ReturnType<typeof useUpdateTriggerMutation>;
export type UpdateTriggerMutationResult = Apollo.MutationResult<UpdateTriggerMutation>;
export type UpdateTriggerMutationOptions = Apollo.BaseMutationOptions<UpdateTriggerMutation, UpdateTriggerMutationVariables>;
export const DeleteTriggerDocument = gql`
    mutation deleteTrigger($where: TriggerWhereUniqueInput!) {
  deleteTrigger(where: $where) {
    id
  }
}
    `;
export type DeleteTriggerMutationFn = Apollo.MutationFunction<DeleteTriggerMutation, DeleteTriggerMutationVariables>;

/**
 * __useDeleteTriggerMutation__
 *
 * To run a mutation, you first call `useDeleteTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTriggerMutation, { data, loading, error }] = useDeleteTriggerMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteTriggerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTriggerMutation, DeleteTriggerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTriggerMutation, DeleteTriggerMutationVariables>(DeleteTriggerDocument, options);
      }
export type DeleteTriggerMutationHookResult = ReturnType<typeof useDeleteTriggerMutation>;
export type DeleteTriggerMutationResult = Apollo.MutationResult<DeleteTriggerMutation>;
export type DeleteTriggerMutationOptions = Apollo.BaseMutationOptions<DeleteTriggerMutation, DeleteTriggerMutationVariables>;
export const TriggersDocument = gql`
    query triggers($where: TriggerWhereInput, $orderBy: [TriggerOrderByWithRelationInput!], $cursor: TriggerWhereUniqueInput, $take: Int, $skip: Int, $distinct: [TriggerScalarFieldEnum!]) {
  triggers(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    text
    key
    totalCount
    model
    modelId
    countDuration
    countPeriod
    earn
    gamePointType {
      id
      pluralName
    }
    label
    maximumEarningsPerUser
    isActive
  }
}
    `;

/**
 * __useTriggersQuery__
 *
 * To run a query within a React component, call `useTriggersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTriggersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTriggersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useTriggersQuery(baseOptions?: Apollo.QueryHookOptions<TriggersQuery, TriggersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TriggersQuery, TriggersQueryVariables>(TriggersDocument, options);
      }
export function useTriggersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TriggersQuery, TriggersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TriggersQuery, TriggersQueryVariables>(TriggersDocument, options);
        }
export type TriggersQueryHookResult = ReturnType<typeof useTriggersQuery>;
export type TriggersLazyQueryHookResult = ReturnType<typeof useTriggersLazyQuery>;
export type TriggersQueryResult = Apollo.QueryResult<TriggersQuery, TriggersQueryVariables>;
export const AggregateTriggerDocument = gql`
    query aggregateTrigger($where: TriggerWhereInput, $orderBy: [TriggerOrderByWithRelationInput!], $cursor: TriggerWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateTrigger(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateTriggerQuery__
 *
 * To run a query within a React component, call `useAggregateTriggerQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateTriggerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateTriggerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateTriggerQuery(baseOptions?: Apollo.QueryHookOptions<AggregateTriggerQuery, AggregateTriggerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateTriggerQuery, AggregateTriggerQueryVariables>(AggregateTriggerDocument, options);
      }
export function useAggregateTriggerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateTriggerQuery, AggregateTriggerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateTriggerQuery, AggregateTriggerQueryVariables>(AggregateTriggerDocument, options);
        }
export type AggregateTriggerQueryHookResult = ReturnType<typeof useAggregateTriggerQuery>;
export type AggregateTriggerLazyQueryHookResult = ReturnType<typeof useAggregateTriggerLazyQuery>;
export type AggregateTriggerQueryResult = Apollo.QueryResult<AggregateTriggerQuery, AggregateTriggerQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($where: UserWhereUniqueInput!, $data: UserUpdateInput!) {
  updateUser(where: $where, data: $data) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UsersDocument = gql`
    query users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!], $cursor: UserWhereUniqueInput, $distinct: [UserScalarFieldEnum!], $skip: Int, $take: Int) {
  users(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    firstName
    lastName
    phoneNumber
    schools {
      name
    }
    standards {
      value
    }
    role
    isActive
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const AggregateUserDocument = gql`
    query aggregateUser($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!], $cursor: UserWhereUniqueInput, $skip: Int, $take: Int) {
  aggregateUser(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    skip: $skip
    take: $take
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateUserQuery__
 *
 * To run a query within a React component, call `useAggregateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateUserQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useAggregateUserQuery(baseOptions?: Apollo.QueryHookOptions<AggregateUserQuery, AggregateUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateUserQuery, AggregateUserQueryVariables>(AggregateUserDocument, options);
      }
export function useAggregateUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateUserQuery, AggregateUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateUserQuery, AggregateUserQueryVariables>(AggregateUserDocument, options);
        }
export type AggregateUserQueryHookResult = ReturnType<typeof useAggregateUserQuery>;
export type AggregateUserLazyQueryHookResult = ReturnType<typeof useAggregateUserLazyQuery>;
export type AggregateUserQueryResult = Apollo.QueryResult<AggregateUserQuery, AggregateUserQueryVariables>;
export const UserDocument = gql`
    query user($data: UserWhereUniqueInput!) {
  user(where: $data) {
    id
    firstName
    lastName
    email
    phoneNumber
    standards {
      id
      value
    }
    schools {
      id
      name
    }
    role
    isActive
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    firstName
    lastName
    role
    rules
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateWorkshopDocument = gql`
    mutation createWorkshop($data: WorkshopCreateInput!) {
  createWorkshop(data: $data) {
    id
    slug
  }
}
    `;
export type CreateWorkshopMutationFn = Apollo.MutationFunction<CreateWorkshopMutation, CreateWorkshopMutationVariables>;

/**
 * __useCreateWorkshopMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopMutation, { data, loading, error }] = useCreateWorkshopMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkshopMutation, CreateWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkshopMutation, CreateWorkshopMutationVariables>(CreateWorkshopDocument, options);
      }
export type CreateWorkshopMutationHookResult = ReturnType<typeof useCreateWorkshopMutation>;
export type CreateWorkshopMutationResult = Apollo.MutationResult<CreateWorkshopMutation>;
export type CreateWorkshopMutationOptions = Apollo.BaseMutationOptions<CreateWorkshopMutation, CreateWorkshopMutationVariables>;
export const UpdateWorkshopDocument = gql`
    mutation updateWorkshop($data: WorkshopUpdateInput!, $where: WorkshopWhereUniqueInput!) {
  updateWorkshop(data: $data, where: $where) {
    id
    slug
  }
}
    `;
export type UpdateWorkshopMutationFn = Apollo.MutationFunction<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>;

/**
 * __useUpdateWorkshopMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopMutation, { data, loading, error }] = useUpdateWorkshopMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>(UpdateWorkshopDocument, options);
      }
export type UpdateWorkshopMutationHookResult = ReturnType<typeof useUpdateWorkshopMutation>;
export type UpdateWorkshopMutationResult = Apollo.MutationResult<UpdateWorkshopMutation>;
export type UpdateWorkshopMutationOptions = Apollo.BaseMutationOptions<UpdateWorkshopMutation, UpdateWorkshopMutationVariables>;
export const DeleteWorkshopDocument = gql`
    mutation deleteWorkshop($where: WorkshopWhereUniqueInput!) {
  deleteWorkshop(where: $where) {
    id
  }
}
    `;
export type DeleteWorkshopMutationFn = Apollo.MutationFunction<DeleteWorkshopMutation, DeleteWorkshopMutationVariables>;

/**
 * __useDeleteWorkshopMutation__
 *
 * To run a mutation, you first call `useDeleteWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkshopMutation, { data, loading, error }] = useDeleteWorkshopMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteWorkshopMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkshopMutation, DeleteWorkshopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkshopMutation, DeleteWorkshopMutationVariables>(DeleteWorkshopDocument, options);
      }
export type DeleteWorkshopMutationHookResult = ReturnType<typeof useDeleteWorkshopMutation>;
export type DeleteWorkshopMutationResult = Apollo.MutationResult<DeleteWorkshopMutation>;
export type DeleteWorkshopMutationOptions = Apollo.BaseMutationOptions<DeleteWorkshopMutation, DeleteWorkshopMutationVariables>;
export const WorkshopsDocument = gql`
    query workshops($where: WorkshopWhereInput, $orderBy: [WorkshopOrderByWithRelationInput!], $cursor: WorkshopWhereUniqueInput, $distinct: [WorkshopScalarFieldEnum!], $skip: Int, $take: Int) {
  workshops(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    name
    slug
    startDate
    displayTime
    feesWithKit
    feesInCurrency
    feesWithoutKit
    ageGroup
    type
    mode
    skill {
      slug
      name
    }
    school {
      id
      name
    }
  }
}
    `;

/**
 * __useWorkshopsQuery__
 *
 * To run a query within a React component, call `useWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<WorkshopsQuery, WorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkshopsQuery, WorkshopsQueryVariables>(WorkshopsDocument, options);
      }
export function useWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkshopsQuery, WorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkshopsQuery, WorkshopsQueryVariables>(WorkshopsDocument, options);
        }
export type WorkshopsQueryHookResult = ReturnType<typeof useWorkshopsQuery>;
export type WorkshopsLazyQueryHookResult = ReturnType<typeof useWorkshopsLazyQuery>;
export type WorkshopsQueryResult = Apollo.QueryResult<WorkshopsQuery, WorkshopsQueryVariables>;
export const AggregateWorkshopDocument = gql`
    query aggregateWorkshop($where: WorkshopWhereInput, $orderBy: [WorkshopOrderByWithRelationInput!], $cursor: WorkshopWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateWorkshop(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateWorkshopQuery__
 *
 * To run a query within a React component, call `useAggregateWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateWorkshopQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateWorkshopQuery(baseOptions?: Apollo.QueryHookOptions<AggregateWorkshopQuery, AggregateWorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateWorkshopQuery, AggregateWorkshopQueryVariables>(AggregateWorkshopDocument, options);
      }
export function useAggregateWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateWorkshopQuery, AggregateWorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateWorkshopQuery, AggregateWorkshopQueryVariables>(AggregateWorkshopDocument, options);
        }
export type AggregateWorkshopQueryHookResult = ReturnType<typeof useAggregateWorkshopQuery>;
export type AggregateWorkshopLazyQueryHookResult = ReturnType<typeof useAggregateWorkshopLazyQuery>;
export type AggregateWorkshopQueryResult = Apollo.QueryResult<AggregateWorkshopQuery, AggregateWorkshopQueryVariables>;
export const WorkshopDocument = gql`
    query workshop($data: WorkshopWhereUniqueInput!) {
  workshop(where: $data) {
    id
    name
    slug
    description
    image
    type
    mode
    skill {
      id
      slug
      name
    }
    ageGroup
    startDate
    endDate
    featuredImage
    displayTime
    metaTagData
    isOpen
    school {
      id
      name
    }
    trainer {
      id
      firstName
      lastName
    }
    product {
      id
      name
    }
    trainerImage
    location
    address
    feesWithKit
    feesInCurrency
    feesWithoutKit
    liveUrl
    headerImageLarge
    headerImageSmall
    headerVideo
    headerText
    footerImageLarge
    footerImageSmall
    standards {
      id
      key
      value
    }
    insiderActivities {
      id
      title
      slug
    }
    relatedActivities {
      id
      title
      slug
    }
    tags {
      id
      name
      slug
    }
  }
}
    `;

/**
 * __useWorkshopQuery__
 *
 * To run a query within a React component, call `useWorkshopQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWorkshopQuery(baseOptions: Apollo.QueryHookOptions<WorkshopQuery, WorkshopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkshopQuery, WorkshopQueryVariables>(WorkshopDocument, options);
      }
export function useWorkshopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkshopQuery, WorkshopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkshopQuery, WorkshopQueryVariables>(WorkshopDocument, options);
        }
export type WorkshopQueryHookResult = ReturnType<typeof useWorkshopQuery>;
export type WorkshopLazyQueryHookResult = ReturnType<typeof useWorkshopLazyQuery>;
export type WorkshopQueryResult = Apollo.QueryResult<WorkshopQuery, WorkshopQueryVariables>;
export const SearchWorkshopsDocument = gql`
    query searchWorkshops($where: WorkshopWhereInput, $orderBy: [WorkshopOrderByWithRelationInput!], $cursor: WorkshopWhereUniqueInput, $distinct: [WorkshopScalarFieldEnum!], $skip: Int, $take: Int) {
  workshops(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    distinct: $distinct
    skip: $skip
    take: $take
  ) {
    id
    name
  }
}
    `;

/**
 * __useSearchWorkshopsQuery__
 *
 * To run a query within a React component, call `useSearchWorkshopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchWorkshopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchWorkshopsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      distinct: // value for 'distinct'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useSearchWorkshopsQuery(baseOptions?: Apollo.QueryHookOptions<SearchWorkshopsQuery, SearchWorkshopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchWorkshopsQuery, SearchWorkshopsQueryVariables>(SearchWorkshopsDocument, options);
      }
export function useSearchWorkshopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchWorkshopsQuery, SearchWorkshopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchWorkshopsQuery, SearchWorkshopsQueryVariables>(SearchWorkshopsDocument, options);
        }
export type SearchWorkshopsQueryHookResult = ReturnType<typeof useSearchWorkshopsQuery>;
export type SearchWorkshopsLazyQueryHookResult = ReturnType<typeof useSearchWorkshopsLazyQuery>;
export type SearchWorkshopsQueryResult = Apollo.QueryResult<SearchWorkshopsQuery, SearchWorkshopsQueryVariables>;
export const CreateWorkshopRegistrationDocument = gql`
    mutation createWorkshopRegistration($data: WorkshopRegistrationCreateInput!) {
  createWorkshopRegistration(data: $data) {
    id
  }
}
    `;
export type CreateWorkshopRegistrationMutationFn = Apollo.MutationFunction<CreateWorkshopRegistrationMutation, CreateWorkshopRegistrationMutationVariables>;

/**
 * __useCreateWorkshopRegistrationMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopRegistrationMutation, { data, loading, error }] = useCreateWorkshopRegistrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWorkshopRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkshopRegistrationMutation, CreateWorkshopRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkshopRegistrationMutation, CreateWorkshopRegistrationMutationVariables>(CreateWorkshopRegistrationDocument, options);
      }
export type CreateWorkshopRegistrationMutationHookResult = ReturnType<typeof useCreateWorkshopRegistrationMutation>;
export type CreateWorkshopRegistrationMutationResult = Apollo.MutationResult<CreateWorkshopRegistrationMutation>;
export type CreateWorkshopRegistrationMutationOptions = Apollo.BaseMutationOptions<CreateWorkshopRegistrationMutation, CreateWorkshopRegistrationMutationVariables>;
export const UpdateWorkshopRegistrationDocument = gql`
    mutation updateWorkshopRegistration($data: WorkshopRegistrationUpdateInput!, $where: WorkshopRegistrationWhereUniqueInput!) {
  updateWorkshopRegistration(data: $data, where: $where) {
    id
  }
}
    `;
export type UpdateWorkshopRegistrationMutationFn = Apollo.MutationFunction<UpdateWorkshopRegistrationMutation, UpdateWorkshopRegistrationMutationVariables>;

/**
 * __useUpdateWorkshopRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateWorkshopRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkshopRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkshopRegistrationMutation, { data, loading, error }] = useUpdateWorkshopRegistrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateWorkshopRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkshopRegistrationMutation, UpdateWorkshopRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkshopRegistrationMutation, UpdateWorkshopRegistrationMutationVariables>(UpdateWorkshopRegistrationDocument, options);
      }
export type UpdateWorkshopRegistrationMutationHookResult = ReturnType<typeof useUpdateWorkshopRegistrationMutation>;
export type UpdateWorkshopRegistrationMutationResult = Apollo.MutationResult<UpdateWorkshopRegistrationMutation>;
export type UpdateWorkshopRegistrationMutationOptions = Apollo.BaseMutationOptions<UpdateWorkshopRegistrationMutation, UpdateWorkshopRegistrationMutationVariables>;
export const DeleteWorkshopRegistrationDocument = gql`
    mutation deleteWorkshopRegistration($where: WorkshopRegistrationWhereUniqueInput!) {
  deleteWorkshopRegistration(where: $where) {
    id
  }
}
    `;
export type DeleteWorkshopRegistrationMutationFn = Apollo.MutationFunction<DeleteWorkshopRegistrationMutation, DeleteWorkshopRegistrationMutationVariables>;

/**
 * __useDeleteWorkshopRegistrationMutation__
 *
 * To run a mutation, you first call `useDeleteWorkshopRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkshopRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkshopRegistrationMutation, { data, loading, error }] = useDeleteWorkshopRegistrationMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteWorkshopRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkshopRegistrationMutation, DeleteWorkshopRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkshopRegistrationMutation, DeleteWorkshopRegistrationMutationVariables>(DeleteWorkshopRegistrationDocument, options);
      }
export type DeleteWorkshopRegistrationMutationHookResult = ReturnType<typeof useDeleteWorkshopRegistrationMutation>;
export type DeleteWorkshopRegistrationMutationResult = Apollo.MutationResult<DeleteWorkshopRegistrationMutation>;
export type DeleteWorkshopRegistrationMutationOptions = Apollo.BaseMutationOptions<DeleteWorkshopRegistrationMutation, DeleteWorkshopRegistrationMutationVariables>;
export const UpdateManyWorkshopRegistrationDocument = gql`
    mutation updateManyWorkshopRegistration($data: WorkshopRegistrationUpdateManyMutationInput!, $where: WorkshopRegistrationWhereInput!) {
  updateManyWorkshopRegistration(data: $data, where: $where) {
    count
  }
}
    `;
export type UpdateManyWorkshopRegistrationMutationFn = Apollo.MutationFunction<UpdateManyWorkshopRegistrationMutation, UpdateManyWorkshopRegistrationMutationVariables>;

/**
 * __useUpdateManyWorkshopRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateManyWorkshopRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyWorkshopRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyWorkshopRegistrationMutation, { data, loading, error }] = useUpdateManyWorkshopRegistrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateManyWorkshopRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyWorkshopRegistrationMutation, UpdateManyWorkshopRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyWorkshopRegistrationMutation, UpdateManyWorkshopRegistrationMutationVariables>(UpdateManyWorkshopRegistrationDocument, options);
      }
export type UpdateManyWorkshopRegistrationMutationHookResult = ReturnType<typeof useUpdateManyWorkshopRegistrationMutation>;
export type UpdateManyWorkshopRegistrationMutationResult = Apollo.MutationResult<UpdateManyWorkshopRegistrationMutation>;
export type UpdateManyWorkshopRegistrationMutationOptions = Apollo.BaseMutationOptions<UpdateManyWorkshopRegistrationMutation, UpdateManyWorkshopRegistrationMutationVariables>;
export const WorkshopRegistrationsDocument = gql`
    query workshopRegistrations($where: WorkshopRegistrationWhereInput, $orderBy: [WorkshopRegistrationOrderByWithRelationInput!], $cursor: WorkshopRegistrationWhereUniqueInput, $take: Int, $skip: Int, $distinct: [WorkshopRegistrationScalarFieldEnum!]) {
  workshopRegistrations(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
    distinct: $distinct
  ) {
    id
    workshop {
      id
      name
      slug
    }
    student {
      id
      firstName
      lastName
      phoneNumber
    }
    status
    createdAt
  }
}
    `;

/**
 * __useWorkshopRegistrationsQuery__
 *
 * To run a query within a React component, call `useWorkshopRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopRegistrationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      distinct: // value for 'distinct'
 *   },
 * });
 */
export function useWorkshopRegistrationsQuery(baseOptions?: Apollo.QueryHookOptions<WorkshopRegistrationsQuery, WorkshopRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkshopRegistrationsQuery, WorkshopRegistrationsQueryVariables>(WorkshopRegistrationsDocument, options);
      }
export function useWorkshopRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkshopRegistrationsQuery, WorkshopRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkshopRegistrationsQuery, WorkshopRegistrationsQueryVariables>(WorkshopRegistrationsDocument, options);
        }
export type WorkshopRegistrationsQueryHookResult = ReturnType<typeof useWorkshopRegistrationsQuery>;
export type WorkshopRegistrationsLazyQueryHookResult = ReturnType<typeof useWorkshopRegistrationsLazyQuery>;
export type WorkshopRegistrationsQueryResult = Apollo.QueryResult<WorkshopRegistrationsQuery, WorkshopRegistrationsQueryVariables>;
export const WorkshopRegistrationDocument = gql`
    query workshopRegistration($where: WorkshopRegistrationWhereUniqueInput!) {
  workshopRegistration(where: $where) {
    id
    workshop {
      id
      name
      slug
    }
    student {
      id
      firstName
      lastName
      phoneNumber
    }
    status
    createdAt
  }
}
    `;

/**
 * __useWorkshopRegistrationQuery__
 *
 * To run a query within a React component, call `useWorkshopRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkshopRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkshopRegistrationQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useWorkshopRegistrationQuery(baseOptions: Apollo.QueryHookOptions<WorkshopRegistrationQuery, WorkshopRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkshopRegistrationQuery, WorkshopRegistrationQueryVariables>(WorkshopRegistrationDocument, options);
      }
export function useWorkshopRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkshopRegistrationQuery, WorkshopRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkshopRegistrationQuery, WorkshopRegistrationQueryVariables>(WorkshopRegistrationDocument, options);
        }
export type WorkshopRegistrationQueryHookResult = ReturnType<typeof useWorkshopRegistrationQuery>;
export type WorkshopRegistrationLazyQueryHookResult = ReturnType<typeof useWorkshopRegistrationLazyQuery>;
export type WorkshopRegistrationQueryResult = Apollo.QueryResult<WorkshopRegistrationQuery, WorkshopRegistrationQueryVariables>;
export const AggregateWorkshopRegistrationDocument = gql`
    query aggregateWorkshopRegistration($where: WorkshopRegistrationWhereInput, $orderBy: [WorkshopRegistrationOrderByWithRelationInput!], $cursor: WorkshopRegistrationWhereUniqueInput, $take: Int, $skip: Int) {
  aggregateWorkshopRegistration(
    where: $where
    orderBy: $orderBy
    cursor: $cursor
    take: $take
    skip: $skip
  ) {
    _count {
      id
    }
  }
}
    `;

/**
 * __useAggregateWorkshopRegistrationQuery__
 *
 * To run a query within a React component, call `useAggregateWorkshopRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAggregateWorkshopRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAggregateWorkshopRegistrationQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      cursor: // value for 'cursor'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useAggregateWorkshopRegistrationQuery(baseOptions?: Apollo.QueryHookOptions<AggregateWorkshopRegistrationQuery, AggregateWorkshopRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AggregateWorkshopRegistrationQuery, AggregateWorkshopRegistrationQueryVariables>(AggregateWorkshopRegistrationDocument, options);
      }
export function useAggregateWorkshopRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AggregateWorkshopRegistrationQuery, AggregateWorkshopRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AggregateWorkshopRegistrationQuery, AggregateWorkshopRegistrationQueryVariables>(AggregateWorkshopRegistrationDocument, options);
        }
export type AggregateWorkshopRegistrationQueryHookResult = ReturnType<typeof useAggregateWorkshopRegistrationQuery>;
export type AggregateWorkshopRegistrationLazyQueryHookResult = ReturnType<typeof useAggregateWorkshopRegistrationLazyQuery>;
export type AggregateWorkshopRegistrationQueryResult = Apollo.QueryResult<AggregateWorkshopRegistrationQuery, AggregateWorkshopRegistrationQueryVariables>;