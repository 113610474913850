import { Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"

interface IModulePageHeader {
  title: string
  primaryButtonText?: string | null
  onPrimaryButtonClick?: Function
  onBackButtonClick?: any
}

export default function ModulePageHeader({ title, primaryButtonText, onPrimaryButtonClick, onBackButtonClick }: IModulePageHeader) {
  return (
    <div className="module-page-header">
      <div className="module-header-heading">
        <div className="module-page-header-heading-left">
          {onBackButtonClick && <div className="module-page-header-back" onClick={onBackButtonClick}>
            <div role="button" tabIndex={0} className="page-header-back-button" aria-label="Back" style={{ border: "0px", background: "transparent", padding: "0px", lineHeight: "inherit", display: "inline-block" }}>
              <ArrowLeftOutlined />
            </div>
          </div>}
          <span className="module-page-header-heading-title" style={{ flex: "0 auto 1" }} title={title}>{title}</span>
          <span style={{ flex: "1", margin: "4px 0" }}>{primaryButtonText && <Button size="small" type="primary" onClick={e => {
            if (onPrimaryButtonClick) {
              onPrimaryButtonClick(e)
            }
          }}>{primaryButtonText}</Button>}
          </span>
        </div>
      </div>
    </div>
  )
}
