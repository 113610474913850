import { Layout } from "antd"
const { Footer } = Layout

export default function FooterComponent() {
  return (
    <Footer style={{ textAlign: "end", backgroundColor: "white" }}>
      MySkillShaala by IEIDidactics
      <br />
      Made by{" "}<span role="img" aria-label="superhero">🦸</span>{" "}at <a href="https://viyet.in" target="_blank" rel="noreferrer">Viyet</a>
    </Footer>
  )
}
