import { useContext } from "react"
import Link from "next/link"
import { Avatar, Menu, Dropdown } from "antd"
import { AppContext } from "@lib/AppContext"
import { DownOutlined, UserOutlined, PoweroffOutlined } from "@ant-design/icons"


export default function HeaderProfile() {
  const { state: { user } } = useContext(AppContext)
  return (
    <Dropdown
      placement="bottomRight"
      overlay={<Menu>
        <Menu.Item icon={<UserOutlined />}>
          <Link href="/profile">
            Profile
          </Link>
        </Menu.Item>
        <Menu.Item icon={<PoweroffOutlined />}>
          <Link href="/logout">
            Logout
          </Link>
        </Menu.Item>
      </Menu>}>
      <div style={{ float: "right", marginRight: "24px" }}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Avatar size={40}>{user.firstName?.charAt(0)}</Avatar>
          {" "}
          <span>
            {`${user.firstName || ""} ${user.lastName || ""}`}
            {" "}
            <DownOutlined />
          </span>
        </a>
      </div>
    </Dropdown>
  );
}
