import { useContext, useEffect, useLayoutEffect, useState } from "react"
import Link from "next/link"
import { useRouter, usePathname } from "next/navigation"
import Image from "next/image"
import { omit } from "lodash"
import NProgress from "nprogress"
import { AppContext } from "@lib/AppContext"
import { AbilityContext } from "@utils/ability/Can"
import { Badge, Layout, Menu, Space, SubMenuProps } from "antd"
import { DashboardOutlined, BankOutlined, AppstoreOutlined, CommentOutlined, ControlOutlined, CarryOutOutlined, IdcardOutlined, ReadOutlined, SolutionOutlined, TrophyOutlined, WalletOutlined, TagsOutlined, ScheduleOutlined, QrcodeOutlined, TeamOutlined, VideoCameraOutlined, VideoCameraAddOutlined, ShoppingOutlined, ShoppingCartOutlined, CalendarOutlined, BuildOutlined, AccountBookOutlined, QuestionOutlined, TableOutlined, BookOutlined, DeploymentUnitOutlined } from "@ant-design/icons"
import { CommentStatus, OrderStatus, useAggregateCommentQuery, useAggregateOrderQuery, useAggregateWorkshopRegistrationQuery, WorkshopStatus } from "@generated/graphql"

const { Sider } = Layout
const { SubMenu } = Menu

interface ISubMenu extends SubMenuProps {
  hidden: boolean
}

function StyledSubMenu(props: ISubMenu) {
  if (props.hidden) {
    return null
  } else {
    return <SubMenu {...omit(props, "hidden")} />
  }
}

export default function Sidebar() {
  const router = useRouter()
  const pathname = usePathname()
  const [currentPage, setCurrentPage] = useState("dashboard")
  const { state: { user, sidebarCollapsed } } = useContext(AppContext)
  const ability = useContext<any>(AbilityContext)

  const { data: commentCountData } = useAggregateCommentQuery({
    variables: {
      where: {
        status: {
          equals: CommentStatus.PENDING
        }
      }
    },
    skip: user.role === "TEACHER"
  })

  const { data: orderCountData } = useAggregateOrderQuery({
    variables: {
      where: {
        status: {
          equals: OrderStatus.PENDING
        }
      }
    },
    skip: user.role === "TEACHER"
  })

  const { data: workshopRegistrationCountData } = useAggregateWorkshopRegistrationQuery({
    variables: {
      where: {
        status: {
          equals: WorkshopStatus.PENDING
        }
      }
    },
    skip: user.role === "TEACHER"
  })

  useLayoutEffect(() => {
    NProgress.configure({
      parent: "#site-content-layout",
      showSpinner: false,
    })
  }, [])

  // useEffect(() => {
  //   router.events.on("routeChangeStart", () => {
  //     NProgress.start()
  //   })
  //   router.events.on("routeChangeComplete", () => {
  //     NProgress.done()
  //   })
  // }, [router.events])

  useEffect(() => {
    if (pathname) {
      const routes = pathname.split("/")
      if (routes.length > 2) {
        if (pathname.indexOf("activities") >= 0) {
          setCurrentPage("skills")
        }
        if (pathname.indexOf("game") >= 0) {
          setCurrentPage(routes[2])
        }
        if (pathname.indexOf("promo") >= 0) {
          if (pathname.indexOf("logs") >= 0) {
            setCurrentPage("promo-code-logs")
          } else {
            setCurrentPage("promo-codes")
          }
        }
        if (pathname.indexOf("referral") >= 0) {
          if (pathname.indexOf("logs") >= 0) {
            setCurrentPage("referral-code-logs")
          } else {
            setCurrentPage("referral-codes")
          }
        }
      } else {
        if (routes[1] === "samskara-categories") {
          setCurrentPage("samskara")
        } else {
          setCurrentPage(routes[1])
        }
      }
    }
  }, [pathname, router])

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={sidebarCollapsed}>
      <div style={{ paddingTop: "10px", paddingBottom: "10px", textAlign: "center" }}>
        <Image
          src="/assets/img/logo.png"
          height={sidebarCollapsed ? 50 : 100}
          width={sidebarCollapsed ? 50 : 120}
          alt="sidebar-logo"
        />
      </div>
      <Menu theme="dark"
        mode="vertical"
        selectedKeys={[currentPage]}>
        <Menu.Item
          key="dashboard"
          icon={<DashboardOutlined />}
          hidden={!ability.can("list", "Skill")}>
          <Link href={"/dashboard"}>Dashboard</Link>
        </Menu.Item>

        <StyledSubMenu
          key="skill-menu"
          icon={<AppstoreOutlined />}
          title="Skills"
          hidden={!ability.can("list", "SKill")}>
          <Menu.Item
            key="skills"
            icon={<BuildOutlined />}
            hidden={!ability.can("list", "Skill")}>
            <Link href={"/skills?page=1&page-size=10"}>Skills</Link>
          </Menu.Item>
          <Menu.Item
            key="tags"
            icon={<DeploymentUnitOutlined />}
            hidden={!ability.can("list", "Tag")}>
            <Link href={"/tags?page=1&page-size=10"}>Tags</Link>
          </Menu.Item>
          <Menu.Item
            key="activity-banners"
            icon={<VideoCameraAddOutlined />}
            hidden={!ability.can("list", "ActivityBanner")}>
            <Link href={"/activity-banners"}>Activity Banners</Link>
          </Menu.Item>
        </StyledSubMenu>

        <Menu.Item
          key="comments"
          icon={<CommentOutlined />}
          hidden={!ability.can("list", "Comment")}>
          <Link href={"/comments?page=1&page-size=10"}>
            <Space>
              Submissions
              <Badge
                count={commentCountData?.aggregateComment._count?.id}
              />
            </Space>
          </Link>
        </Menu.Item>

        <Menu.Item
          key="daily-activities"
          icon={<CalendarOutlined />}
          hidden={!ability.can("list", "DailyActivity")}>
          <Link href={"/daily-activities?page=1&page-size=10"}>Daily Activities</Link>
        </Menu.Item>

        <StyledSubMenu
          key="story-menu"
          icon={<AppstoreOutlined />}
          title="Bit Learning"
          hidden={!ability.can("list", "Story")}>
          <Menu.Item
            key="stories"
            icon={<VideoCameraOutlined />}
            hidden={!ability.can("list", "Story")}>
            <Link href={"/stories?page=1&page-size=10"}>Stories</Link>
          </Menu.Item>

          <Menu.Item
            key="story-banners"
            icon={<VideoCameraAddOutlined />}
            hidden={!ability.can("list", "StoryBanner")}>
            <Link href={"/story-banners"}>Story Banners</Link>
          </Menu.Item>

          <Menu.Item
            key="story-tags"
            icon={<TagsOutlined />}
            hidden={!ability.can("list", "StoryTag")}>
            <Link href={"/story-tags"}>Story Tags</Link>
          </Menu.Item>
        </StyledSubMenu>

        <StyledSubMenu
          key="game"
          title="Game"
          icon={<AppstoreOutlined />}
          hidden={!ability.can("list", "Game")}>
          <Menu.Item
            key="point-types"
            icon={<WalletOutlined />}
            hidden={!ability.can("list", "PointTypes")}>
            <Link href={"/game/point-types?page=1&page-size=10"}>Point Types</Link>
          </Menu.Item>
          <Menu.Item
            key="badges"
            icon={<TrophyOutlined />}
            hidden={!ability.can("list", "Badge")}>
            <Link href={"/game/badges?page=1&page-size=10"}>Badges</Link>
          </Menu.Item>
          <Menu.Item
            key="levels"
            icon={<ControlOutlined />}
            hidden={!ability.can("list", "Level")}>
            <Link href={"/game/levels?page=1&page-size=10"}>Levels</Link>
          </Menu.Item>
          <Menu.Item
            key="leaderboard"
            icon={<ControlOutlined />}>
            <Link href={"/game/leaderboard"}>Leaderboard</Link>
          </Menu.Item>
          <Menu.Item
            key="logs"
            icon={<SolutionOutlined />}
            hidden={!ability.can("list", "StudentGameLog")}>
            <Link href={"/game/logs?page=1&page-size=10"}>Logs</Link>
          </Menu.Item>
        </StyledSubMenu>

        <StyledSubMenu
          key="workshop-menu"
          icon={<AppstoreOutlined />}
          hidden={!ability.can("list", "Workshop")}
          title={<Space>Workshops<Badge
            count={workshopRegistrationCountData?.aggregateWorkshopRegistration._count?.id} /></Space>}>
          <Menu.Item
            key="workshops"
            icon={<ScheduleOutlined />}
            hidden={!ability.can("list", "Workshop")}>
            <Link href={"/workshops?page=1&page-size=10"}>Workshops</Link>
          </Menu.Item>

          <Menu.Item
            key="workshop-registrations"
            icon={<CarryOutOutlined />}
            hidden={!ability.can("list", "WorkshopRegistration")}>
            <Link href={"/workshop-registrations?page=1&page-size=10"}>Registrations</Link>
          </Menu.Item>
        </StyledSubMenu>

        <StyledSubMenu
          key="product-menu"
          icon={<AppstoreOutlined />}
          hidden={!ability.can("list", "Product")}
          title={<Space>Products<Badge
            count={orderCountData?.aggregateOrder._count?.id} /></Space>}>
          <Menu.Item
            key="products"
            icon={<ShoppingOutlined />}
            hidden={!ability.can("list", "Product")}>
            <Link href={"/products?page=1&page-size=10"}>Products</Link>
          </Menu.Item>

          <Menu.Item
            key="orders"
            icon={<ShoppingCartOutlined />}
            hidden={!ability.can("list", "Order")}>
            <Link href={"/orders?page=1&page-size=10"}>Orders</Link>
          </Menu.Item>

          <Menu.Item
            key="product-banners"
            icon={<VideoCameraAddOutlined />}
            hidden={!ability.can("list", "ProductBanner")}>
            <Link href={"/product-banners"}>Product Banners</Link>
          </Menu.Item>
        </StyledSubMenu>

        <StyledSubMenu
          key="codes"
          icon={<WalletOutlined />}
          title="Codes"
          hidden={!ability.can("list", "PromoCode") || !ability.can("list", "ReferralCode")}>
          <Menu.ItemGroup
            key="promo-codes"
            title="Promo">
            <Menu.Item
              key="promo-codes"
              icon={<QrcodeOutlined />}
              hidden={!ability.can("list", "PromoCode")}>
              <Link href={"/promo-codes?page=1&page-size=10"}>Codes</Link>
            </Menu.Item>
            <Menu.Item
              key="promo-code-logs"
              icon={<ReadOutlined />}
              hidden={!ability.can("list", "PromoCode")}>
              <Link href={"/promo-codes/logs?page=1&page-size=10"}>Logs</Link>
            </Menu.Item>
            <Menu.Item
              key="promo-code-groups"
              icon={<AccountBookOutlined />}
              hidden={!ability.can("list", "PromoCodeGroup")}>
              <Link href={"/promo-code-groups?page=1&page-size=10"}>Groups</Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup
            key="referral-code-menu"
            title="Referral">
            <Menu.Item
              key="referral-codes"
              icon={<QrcodeOutlined />}
              hidden={!ability.can("list", "ReferralCode")}>
              <Link href={"/referral-codes?page=1&page-size=10"}>Codes</Link>
            </Menu.Item>
            <Menu.Item
              key="referral-code-logs"
              icon={<ReadOutlined />}
              hidden={!ability.can("list", "ReferralCodeHistory")}>
              <Link href={"/referral-codes/logs?page=1&page-size=10"}>Logs</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </StyledSubMenu>

        <Menu.Item
          key="students"
          icon={<IdcardOutlined />}
          hidden={!ability.can("list", "Student")}>
          <Link href={"/students?page=1&page-size=10"}>Students</Link>
        </Menu.Item>

        <Menu.Item
          key="users"
          icon={<TeamOutlined />}
          hidden={!ability.can("list", "User")}>
          <Link href={"/users?page=1&page-size=10"}>Users</Link>
        </Menu.Item>
        <Menu.Item
          key="schools"
          icon={<BankOutlined />}
          hidden={!ability.can("list", "School")}>
          <Link href={"/schools?page=1&page-size=10"}>Schools</Link>
        </Menu.Item>

        <Menu.Item
          key="updates"
          icon={<QuestionOutlined />}
          hidden={!ability.can("list", "Update")}>
          <Link href={"/updates?page=1&page-size=5"}>Updates</Link>
        </Menu.Item>

        <Menu.Item
          key="reports"
          icon={<TableOutlined />}
          hidden={!ability.can("list", "Report")}>
          <Link href={"/reports?page=1&page-size=10"}>Reports</Link>
        </Menu.Item>

        <Menu.Item
          key="samskara"
          icon={<BookOutlined />}
          hidden={!ability.can("list", "Samskara")}>
          <Link href={user.role === "TEACHER" ? "/samskara-categories" : "/samskara?page=1&page-size=10"}>Samskara</Link>
        </Menu.Item>

      </Menu>
    </Sider>
  );
}
