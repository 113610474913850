import { ReactNode, createContext, useReducer } from "react"

export enum ActionType {
  SET_USER,
  SET_SIDEBAR_COLLAPSED
}

type IMe = { __typename?: "MeResponse", id: number, firstName: string, lastName: string, role: string, rules: any }

type AppState = {
  user: IMe
  sidebarCollapsed: boolean
}

const initialState: AppState = {
  user: {
    __typename: "MeResponse",
    id: 0,
    firstName: "",
    lastName: "",
    role: "",
    rules: "",
  },
  sidebarCollapsed: false
}

interface SetUserAction {
  type: ActionType.SET_USER
  payload: IMe
}

interface SetSidebarStatusAction {
  type: ActionType.SET_SIDEBAR_COLLAPSED
  payload: boolean
}

type IAction = SetUserAction | SetSidebarStatusAction

type IAppContext = {
  state: AppState
  dispatch: React.Dispatch<IAction>
}

const AppContext = createContext<IAppContext>({ state: initialState, dispatch: () => null })
const { Provider } = AppContext

const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer((state: AppState, action: IAction): AppState => {
    const { type, payload } = action
    switch (type) {
      case ActionType.SET_USER:
        return { ...state, user: payload }
      case ActionType.SET_SIDEBAR_COLLAPSED:
        return { ...state, sidebarCollapsed: payload }
      default:
        return state
    }
  }, initialState)

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { AppContext, AppProvider }
