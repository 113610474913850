import { Spin } from "antd"
import { LoadingOutlined } from "@ant-design/icons"

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export default function Spinner() {
  return (
    <Spin indicator={antIcon} />
  )
}
